import React from "react";
import { parseCookies, setCookie } from "nookies";
import { isBrowser } from "../utils";
import { publicRuntimeConfig } from "@/lib/config";

const useCookieStorage = (
  key,
  initialValue = null,
  options = {},
) => {
  if (!isBrowser) {
    return [initialValue, () => undefined];
  }

  const optionsWithDefaults = {
    domain: publicRuntimeConfig.CLOVERLEAF_COOKIE_DOMAIN,
    maxAge: 30 * 24 * 60 * 60 * 1000, // default to 30 days, in ms
    sameSite: "strict", // default option to strict
    path: "/",
    ...options,
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [state, setState] = React.useState(() => {
    try {
      const cookies = parseCookies();

      const cookieValue = cookies[key];

      if (!cookieValue || typeof cookieValue !== "string" || cookieValue === "null") {
        setCookie(null, key, JSON.stringify(initialValue), optionsWithDefaults);

        return initialValue;
      }

      return JSON.parse(cookieValue ?? "null");
    }
    catch {
      console.debug("useCookieStorage caught an error");

      return initialValue;
    }
  });

  // eslint-disable-next-line react-hooks/rules-of-hooks
  React.useEffect(() => {
    try {
      const serializedState = JSON.stringify(state ?? null);
      setCookie(null, key, serializedState, optionsWithDefaults);
    }
    catch {
      console.debug("useCookieStorage caught an error");
    }
  });

  return [state, setState];
};

export { useCookieStorage };
