import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Grommet,
  Main
} from "grommet";
import { themeStyle } from "@/cloverleaf-ui/theme";
import Toasts from "../../../../components/composite/Toasts";
import ErrorBoundary from "../../../../components/ui/ErrorBoundary";

/**
 * @param {boolean} nested - this is a nested Layout, meaning the old Layout component is a parent to this one - hide the footer and toast components
 */
function BaseLayout({
  background = "background-light-blue",
  contentBackground = undefined,
  children = undefined,
  nested = false,
  ...props
}) {
  return (
    <Grommet full theme={themeStyle}>
      <Main
        align="center"
        background={background}
        pad="none"
        height="100vh"
      >
        {!nested && (
          /**
            TODO: Remove conditional when parent Layout component is deprecated, or Toast component is updated to cloverleaf-ui
            If nested is true, do not duplicate the Toasts component while the parent Layout is already rendering it.
            This is not an issue now, but prevents potentially duplicating the Toast messages.
          */
          <Toasts />
        )}
        <Box
          align="center"
          background={contentBackground}
          flex={false}
          pad={nested ? { vertical: "large" } : "medium"}
          {...props}
        >
          <ErrorBoundary>
            {children}
          </ErrorBoundary>
        </Box>
      </Main>
    </Grommet>
  );
}

BaseLayout.propTypes = {
  background: PropTypes.any,
  contentBackground: PropTypes.any,
  children: PropTypes.node,
  navbar: PropTypes.bool,
  nested: PropTypes.bool,
  showFooter: PropTypes.bool,
};

export { BaseLayout };
