import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  Box,
  CheckBoxGroup,
  Heading,
  Text
} from "grommet";
import { DROP_POSITION } from "@/cloverleaf-ui/constants";
import { TutorialDrop } from "../components/TutorialDrop";
import { useLocale } from "@/lib/hooks";

/**
 * Hide the <span> tag that gets rendered with the CheckBoxes.
 */
const NoLabelCheckBoxGroup = styled(CheckBoxGroup)`
  & > label > span { display: none }
`;

const defaultAnswer = {
  most: {},
  least: {},
};

const isMost = (key, answer) => key === answer?.most?.value;
const isLeast = (key, answer) => key === answer?.least?.value;

function DiscExampleQuestion({
  onChange = () => undefined,
}) {
  const { t } = useLocale();

  const [value, setValue] = React.useState();
  const [answer, setAnswer] = React.useState(defaultAnswer);
  const [walkthrough, setWalkthrough] = React.useState(-1);

  // trigger re-render so we have the targetRef
  React.useEffect(() => {
    setWalkthrough(0);
  }, []);

  const targetRef = {
    kind: React.useRef(),
    happy: React.useRef(),
    "awful-and-stinky": React.useRef(),
  };

  const question = {
    number: 0,
    answers: [
      {
        description: t("disc.instructions.example.answer-1"),
        descriptionKey: "kind",
      },
      {
        description: t("disc.instructions.example.answer-2"),
        descriptionKey: "happy",
      },
      {
        description: t("disc.instructions.example.answer-3"),
        descriptionKey: "generous",
      },
      {
        description: t("disc.instructions.example.answer-4"),
        descriptionKey: "awful-and-stinky",
      },
    ],
  };

  const tutorial = [
    { target: "kind", tip: t("disc.instructions.tooltip-1") },
    { target: "awful-and-stinky", tip: t("disc.instructions.tooltip-2") },
    { target: "kind", tip: t("disc.instructions.tooltip-3") },
    { target: "happy", tip: t("disc.instructions.tooltip-4") },
  ];

  const answers = question.answers.map(q => ({
    disabled: Boolean(
      // For tutorial
      (walkthrough < tutorial.length && q.descriptionKey !== tutorial[walkthrough]?.target)
      // Default logic
      || answer.most?.value && answer.least?.value && !isMost(q.descriptionKey, answer) && !isLeast(q.descriptionKey, answer)
    ),
    label: q.description,
    value: q.descriptionKey,
  }));

  const handleOnChange = ({ option }) => {
    // Option does not pass the value, so we have to get it.
    const selectedOption = answers.find(a => a.label === option.label);

    let newAnswer = { ...answer };

    if (answer.most.value === selectedOption.value) {
      // Deselect Most
      newAnswer.most = {};
    }
    else if (answer.least.value === selectedOption.value) {
      // Deselect Least
      newAnswer.least = {};
    }
    else if (!answer.most.value) {
      // Select Most
      newAnswer.most = selectedOption;
    }
    else {
      // Select Least
      newAnswer.least = selectedOption;
    }

    newAnswer = {
      ...answer,
      ...newAnswer,
    };

    setAnswer(newAnswer);

    /**
     * Set value to an array,
     * CheckBoxGroup value is an array of checked options
     */
    setValue(Object.values(newAnswer).map(a => a.value));

    // Tutorial Specific
    const nextStep = walkthrough + 1;

    if (nextStep < tutorial.length) {
      return setWalkthrough(nextStep);
    }

    setWalkthrough(nextStep);

    return onChange({
      question: question.number,
      answer: newAnswer,
    });
  };

  return (
    <React.Fragment>
      <Box
        align="center"
        alignSelf="center"
        margin={{ vertical: "medium" }}
        gap="medium"
      >
        <Heading margin="none" size="small">
          {question.prompt}
        </Heading>
        <NoLabelCheckBoxGroup
          align="center"
          direction="row"
          focusIndicator={false}
          gap="none"
          id={question.number.toString()}
          justify="center"
          name={question.number.toString()}
          onChange={handleOnChange}
          options={answers}
          responsive={false}
          value={value}
          valueKey="value"
          wrap
        >
          {(option, { checked }) => {
            let boxProps = {};
            let textProps = {};

            if (checked && isMost(option.value, answer)) boxProps.background = "green600";
            else if (checked && isLeast(option.value, answer)) boxProps.background = "#df6100";

            if (!checked && !answer.most.value) textProps.color = "green600";
            else if (!checked && !answer.least.value) textProps.color = "#df6100";

            return (
              <Box
                ref={targetRef[option.value]}
                a11yTitle={option.label}
                align="center"
                border
                elevation="xsmall"
                justify="center"
                pad={{
                  horizontal: "medium",
                }}
                margin="xxsmall"
                height="xsmall"
                round="xsmall"
                width="small"
                {...boxProps}
              >
                <Text capitalize textAlign="center" {...textProps}>
                  {option.label}
                </Text>
              </Box>
            );
          }}
        </NoLabelCheckBoxGroup>
      </Box>
      {tutorial[walkthrough] && targetRef[tutorial[walkthrough].target] && (
        <TutorialDrop
          align={DROP_POSITION.top}
          margin={{ bottom: "medium" }}
          target={targetRef[tutorial[walkthrough].target].current}
          width="small"
        >
          {tutorial[walkthrough].tip}
        </TutorialDrop>
      )}
    </React.Fragment>
  );
}

DiscExampleQuestion.propTypes = {
  onChange: PropTypes.func,
};

export { DiscExampleQuestion };
