import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Text,
  Tip as GrommetTip
} from "grommet";
import { DROP_POSITION } from "@/cloverleaf-ui/constants";
import {
  topCaret,
  rightCaret,
  bottomCaret,
  leftCaret
} from "./carets";

/**
 * @param {bool} bubble - remove caret from tooltip for when positioning is risky
 * @returns Styled Tip Content
 */
function TipContentComponent({
  bubble = false,
  message = "",
  position = "top",
}, ref) {
  let caret;
  let direction = "row";
  let round = "xsmall";

  // Base tool tip, rounded box with message
  const content = [
    <Box
      key="tooltip-content"
      background="grey300"
      direction="row"
      pad={{
        horizontal: "medium",
        vertical: "xsmall",
      }}
      round={round}
    >
      <Text color="grey900" size="small">{message}</Text>
    </Box>,
  ];

  /**
   * If user is not specifically requesting a bubble tooltip, include the pointer
   */
  if (!bubble) {
    switch (position) {
      case "top":
        caret = topCaret;
        direction = "column";
        content.push(caret);
        break;

      case "bottom":
        caret = bottomCaret;
        direction = "column";
        content.unshift(caret);
        break;

      case "left":
        caret = rightCaret;
        round = { corner: "left", size: "xsmall" };
        content.push(caret);
        break;

      case "right":
        caret = leftCaret;
        round = { corner: "right", size: "xsmall" };
        content.unshift(caret);
        break;

      default:
        // avoid an unlikely render error,
        // position prop will already default to "top"
        caret = null;
    }
  }

  return (
    <Box
      ref={ref}
      align="center"
      direction={direction}
    >
      {content}
    </Box>
  );
}

const TipContent = React.forwardRef(TipContentComponent);

TipContent.propTypes = {
  bubble: PropTypes.bool,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  position: PropTypes.oneOf(["top", "right", "bottom", "left"]),
};

function TipComponent({
  dropProps = {},
  bubble = false,
  message = "",
  position = "top",
  ...props
}, ref) {
  const drop = {
    align: DROP_POSITION[position],
    ...dropProps,
  };

  return (
    <GrommetTip
      ref={ref}
      content={<TipContent bubble={bubble} message={message} position={position} />}
      dropProps={drop}
      plain
      {...props}
    />
  );
}

const Tip = React.forwardRef(TipComponent);

Tip.propTypes = {
  dropProps: PropTypes.object,
  ...TipContent.propTypes,
};

export { Tip, TipContent };
