import withProps from "../hoc/withProps";
import { Flex } from "./FlexBox";

const ContentBoxHeaderRow = withProps(() => ({
  w: 1,
  justify: "space-between",
  align: "center",
}))(Flex);

export default ContentBoxHeaderRow;
