import React from "react";
import PropTypes from "prop-types";
import debounce from "lodash/debounce";
import {
  Box,
  RadioButtonGroup,
  RangeInput,
  Stack,
  Text
} from "grommet";
import { QuestionCard } from "../components/QuestionCard";
import TimeSlider from "@/components/ui/TimeSlider";

export const ENERGY_RHYTHM_QUESTION_TYPE = {
  TIME: "TIME",
  CHOICE: "CHOICE",
};

const EnergyRhythmQuestion = React.forwardRef(({
  onChange = () => undefined,
  selected = undefined,
  question = undefined,
}, ref) => {
  const defaultValue = parseInt(selected);

  const [value, setValue] = React.useState(defaultValue || "");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnChange = React.useCallback(
    /**
     * Usually use /fp/debounce but this was the
     * only way could get this to work.
     */
    debounce((v) => onChange(v), 500)
    , [onChange]);

  // const debouncedOnChange = React.useCallback(debounce(onChange, 500), []);

  const handleOnChange = (event) => {
    const answer = parseInt(event.target.value);

    setValue(answer);

    return onChange({
      question: question.number,
      answer,
    });
  };

  const handleOnSelect = (newValue) => {
    const answer = parseInt(newValue);

    setValue(answer);

    return debouncedOnChange({
      question: question.number,
      answer,
    });
  };

  if (!question) {
    return null;
  }

  if (question.type === ENERGY_RHYTHM_QUESTION_TYPE.TIME) {
    return (
      <QuestionCard ref={ref} prompt={question.prompt}>
        <Box margin={{ vertical: "small" }}>
          <TimeSlider
            name={question.number}
            options={question.options}
            onChange={(v) => handleOnSelect(v)}
            value={value || 8.5}
          />
        </Box>
      </QuestionCard>
    )
  }

  if (question.type === "new-time-component") {
    return (
      <QuestionCard ref={ref} prompt={question.prompt}>
        <Box align="center" gap="small">
          <Stack>
            <Box
              border={{
                color: "grey600",
                side: "all",
              }}
              direction="row"
              gap="small"
              justify="between"
            >
              {question.options.map(({ label, value }) => (
                <Box
                  key={value}
                  border={{
                    side: "vertical",
                  }}
                  width="xxsmall"
                  height="xxsmall"
                  align="center"
                  pad="small"
                >
                  <Text
                    size="xsmall"
                    style={{ fontFamily: "monospace" }}
                  >
                    {label}
                  </Text>
                </Box>
              ))}
            </Box>
            <RangeInput
              min={5}
              max={12}
              onChange={handleOnSelect}
              step={0.5}
              value={value}
            />
          </Stack>
          <Box align="center">
            <Text color="green600">{value}</Text>
          </Box>
        </Box>
      </QuestionCard>
    );
  }

  return (
    <QuestionCard ref={ref} prompt={question.prompt}>
      <RadioButtonGroup
        name={question.number}
        gap="medium"
        onChange={handleOnChange}
        options={question.options}
        value={value}
      />
    </QuestionCard>
  );
});

EnergyRhythmQuestion.displayName = "EnergyRhythmQuestion";

EnergyRhythmQuestion.propTypes = {
  onChange: PropTypes.func,
  question: PropTypes.shape({
    number: PropTypes.string,
    prompt: PropTypes.string,
    type: PropTypes.oneOf(Object.values(ENERGY_RHYTHM_QUESTION_TYPE)),
    options: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    })),
  }),
  selected: PropTypes.any,
};

export { EnergyRhythmQuestion };
