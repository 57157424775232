import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Heading,
  List,
  Text
} from "grommet";
import { Icon } from "@/cloverleaf-ui/components";
import { QuestionCard } from "../components/QuestionCard";
import { useLocale } from "@/lib/hooks";

const answers = [
  { // Most important
    label: "motivating-values.answers.0",
    value: 0,
  },
  { // Very important
    label: "motivating-values.answers.1",
    value: 1,
  },
  { // Important
    label: "motivating-values.answers.2",
    value: 2,
  },
  { // Less important
    label: "motivating-values.answers.3",
    value: 3,
  },
  { // Not important
    label: "motivating-values.answers.4",
    value: 4,
  },
  { // Least important
    label: "motivating-values.answers.5",
    value: 5,
  },
];

function MotivatingValuesQuestionComponent({
  onChange = () => undefined,
  onNext,
  selected,
  question,
  tutorial,
}, ref) {
  const { t } = useLocale();

  const [value, setValue] = React.useState(selected || question?.options || []);

  const handleOnNext = () => {
    onChange({
      question: question.number,
      answer: value,
    });

    return onNext();
  };

  const handleOnChange = (order) => {
    if (tutorial) {
      onChange({
        question: question.number,
        answer: order,
      });
    }

    return setValue(order);
  };

  if (!question) {
    return null;
  }

  return (
    <QuestionCard
      ref={ref}
      gap="small"
      width="100%"
    >
      <Box align="center" direction="column">
        <Text>{t("motivating-values.answers.heading")}</Text>
        <Heading margin="none" size="small">{question.subject}</Heading>
      </Box>
      <Box border margin="small" round="small" width="large">
        <List
          border={false}
          data={value}
          onOrder={handleOnChange}
        >
          {(option, index) => (
            <Box
              align="center"
              direction="row"
              gap="medium"
            >
              <Box width="55px">
                <Text size="small">
                  {t(answers[index].label)}
                </Text>
              </Box>
              <Box
                align="center"
                elevation="small"
                direction="row"
                pad="xsmall"
                gap="small"
                round="xxsmall"
                width="75%"
              >
                <Icon color="green600" icon={["far", "arrows"]} />
                {option}
              </Box>
            </Box>
          )}
        </List>
      </Box>
      {!tutorial && (
        <Button
          label={t("next")}
          onClick={handleOnNext}
          primary
        />
      )}
    </QuestionCard>
  );
}

const MotivatingValuesQuestion = React.forwardRef(MotivatingValuesQuestionComponent);

MotivatingValuesQuestion.propTypes = {
  onChange: PropTypes.func,
  question: PropTypes.shape({
    number: PropTypes.number,
    subject: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.string),
  }),
  selected: PropTypes.any,
};

export { MotivatingValuesQuestion };
