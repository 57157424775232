import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Heading,
  Text,
} from "grommet";
import { Icon, } from "../../../atoms";
import { Message } from "./Message";

function Banner({
  borderStyle = {
    color: "blue600",
    side: "all",
  },
  contentProps = {},
  height = { min: "86px" },
  icon = undefined,
  link = undefined,
  subtitle = undefined,
  title = undefined,
  helpTip = undefined,
  ...props
}) {
  const linkPropsToMakeTheButton = {
    color: "blue600",
    hoverIndicator: ({
      background: "blue700",
    }),
    plain: false,
    primary: true,
    style: ({
      color: "white",
      fontSize: "16px",
      borderRadius: "4px",
      padding: "8px 20px",
    }),
    ...link,
  };

  return (
    <Message
      background="blue500"
      border={borderStyle}
      color="text"
      direction="row-responsive"
      gap="small"
      height={height}
      icon={icon}
      kind="banner"
      link={link ? { ...linkPropsToMakeTheButton } : undefined}
      responsive={false}
      round="10px"
      contentGap="xsmall"
      {...props}
    >
      <Box direction="column" {...contentProps}>
        {title && (
          <Box direction="row" align="center" gap="small" responsive={false}>
            <Heading level="3" size="small" margin="none">{title}</Heading>
            {helpTip &&
              <Icon
                icon={["fas", "question-circle"]}
                tip={{
                  message: (
                    <Box width={{ max: "350px" }}>
                      <Text>{helpTip}</Text>
                    </Box>
                  ),
                }}
              />
            }
          </Box>
        )}
        {subtitle && <Text margin="none">{subtitle}</Text>}
      </Box>
    </Message>
  );
}

Banner.propTypes = {
  borderStyle: PropTypes.object,
  contentProps: PropTypes.object,
  height: PropTypes.object,
  icon: PropTypes.object,
  link: PropTypes.object,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  helpTip: PropTypes.string,
};

export { Banner };
