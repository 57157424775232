import React from "react";
import { func, bool } from "prop-types";
import styled from "styled-components";
import { iconProp } from "../../utils/propTypes";
import {
  GREY_OPAQUE,
  GREEN,
  WHITE,
  DARK_GREEN
} from "../../utils/theme";
import Button from "../Button";
import Icon from "../Icon";

const StyledButton = styled(Button)`
  margin: 0;
  padding: 0;
  height: 50px;
  flex: 0 0 50px;
  border-radius: 0;
  color: ${GREY_OPAQUE};
  border-color: ${GREY_OPAQUE};
  &:first-child {
    border-radius: 10px 0 0 10px;
  }
  &:last-child {
    border-radius: 0 10px 10px 0;
  }
  &:hover {
    color: ${GREEN};
    border-color: ${GREEN};
  }
`;

const SelectedButton = styled(StyledButton)`
  color: ${WHITE};
  border-color: ${GREEN};
  background-color: ${GREEN};
  &:hover {
    color: ${WHITE};
    border-color: ${DARK_GREEN};
    background-color: ${DARK_GREEN};
  }
`;

function IconRowButton({ icon, onClick = () => undefined, selected = false }) {
  const Component = selected ? SelectedButton : StyledButton;

  return (
    <Component block small onClick={() => onClick(icon)}>
      <Icon
        icon={["fas", icon]}
        size="2x"
        fixedWidth
      />
    </Component>
  );
}

IconRowButton.propTypes = {
  icon: iconProp.isRequired,
  onClick: func,
  selected: bool,
};

export default IconRowButton;
