import styled from "styled-components";
import { withTooltip } from "../hoc/withTooltip";
import {
  DEFAULT_FONT,
  BLACK,
  LIGHT_GREY
} from "../utils/theme";

const StyledDiv = styled.div`
  outline: 0;
  padding: 0 16px;
  height: 30px;
  border: none;
  border-radius: 15px;
  font-family: ${DEFAULT_FONT};
  font-size: 11px;
  font-weight: ${props => props.fontWeight || "normal"};
  background-color: ${props => props.color || LIGHT_GREY};
  color: ${props => props.textColor || BLACK};
  align-items: center;
  display: flex;
`;

const Chip = withTooltip(StyledDiv);

export default Chip;
