import curry from "lodash/fp/curry";
import inRange from "lodash/fp/inRange";
import size from "lodash/fp/size";
import {
  INVALID_EMAIL_FORMAT,
  PASSWORD_ERROR,
  REQUIRED
} from "./strings";

// Include the max value - inRange does not
const betweenRange = (min, max, n) => inRange(min, max + 1, n);

export const required = value => (value ? undefined : REQUIRED);

// Valid email format.
// False means it is valid, an error string is returned if it fails.
export const email = value => (
  // Regex from OWASP https://owasp.org/www-community/OWASP_Validation_Regex_Repository
  value && !/^[a-zA-Z0-9_+&*-]+(?:\.[a-zA-Z0-9'_+&*-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,63}$/
    .test(value) ? INVALID_EMAIL_FORMAT : undefined
);

/**
 * Password must be at least 8 characters, include at least 1 uppercase and 1 lowercase character, and include at least 1 number
 * Explanation of each of the capture groups
 *   ---
 *   (?=.*[a-z])    — Must contain a lowercase letter
 *   (?=.*[A-Z])    — Must contain an uppercase letter
 *   (?=.*\d)       — Must contain a number
 *   [a-zA-Z\d\w\W] — List of allowed characters (All uppercase & lowercase letters, numbers, "words" & "not words")
 *   {8,}           — Must contain at least 8 characters
 */
export const password = value => (value && !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/.test(value)
  ? PASSWORD_ERROR
  : undefined);

/**
 * Zipcde either 5 or 9 characters
 */
export const zipcode = value => (value && !/^[0-9]{5}(?:-[0-9]{4})?$/.test(value) ? "Invalid Zipcode" : undefined);

/**
 * Returns a function that validates that
 * the value must be at least the defined amount of characters
 * @param {Number} length minimum length
 */
export const minLength = (length = 0) =>
  value => (typeof value !== "string" || value.length < length
    ? `Must be at least ${length} characters`
    : undefined);

/**
 * Returns a function that validates that
 * the value must be no more than the defined amount of characters
 * @param {Number} length maximum length
 */
export const maxLength = length =>
  value => (typeof value !== "string" || value.length > length
    ? `Must be no more than ${length} characters`
    : undefined);

/**
 * Value must contain only letters (case-insensitive)
 */
export const lettersOnly = value => (value && /[^a-zA-Z]+/g.test(value) ? "Must be letters only" : undefined);

/**
 * Value can't be bigger or smaller than the safe number thresholds
 */
export const safeNumber = value => (value && (Number(value) > Number.MAX_SAFE_INTEGER || Number(value) < Number.MIN_SAFE_INTEGER) ? "Invalid Number" : undefined);

/**
 * Value must contain only numbers
 */
export const numbersOnly = value => (value && !/(\d)+/g.test(Number(value)) ? "Must be numbers only" : safeNumber(value));

/**
 * Value must contain only letters and numbers (case-insensitive)
 */
export const alphaNumeric = value => (value && /[^a-zA-Z0-9]+/g.test(value) ? "Must be numbers or letters" : undefined);

/**
 * Value must contain only letters and numbers and hyphens (case-insensitive)
 */
export const urlSafe = value => (value && /[^a-zA-Z0-9-]+/g.test(value) ? "Must be numbers, letters or - (hyphen)" : undefined);

/**
 * Value must be within the specified number range
 */
export const withinRange = curry((min, max, value) => (betweenRange(min, max, value) ? undefined : `Must be between ${min}-${max}`));

/**
 * Value must be within the specified number range or equal to the specified value
 */
export const withinRangeOr = curry((min, max, or, value) => (value === or || (betweenRange(min, max, value)) ? undefined : `Must be between ${min}-${max}`));

/**
 * Value must be defined and not an empty object/array
 */
export const requireSelection = value => (value && size(value) > 0 ? undefined : "Must choose an option");

/**
 * Value must contain only lowercase characters
 */
export const lowercase = value => (typeof value !== "string" || value.toLowerCase() !== value ? "Must be lowercase letters only" : undefined);

/**
 * Value must be a valid domain name
 */
export const domain = value => (
  (value
    && !/^([a-zA-Z0-9-]+\.)+(([Xx][Nn]--)?[A-Za-z0-9-]{2,20})$/
      .test(value)) ? "Invalid domain format" : undefined
);
