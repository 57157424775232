import { gql } from "@apollo/client";

export const SURVEY = {
  "coaching-impact": "COACHING_IMPACT",
};

const questionFragment = gql`
  fragment QuestionFragment on SurveyQuestion {
    id
    type
    prompt
    options {
      label
      answerId
    }
    answer {
      ... on IdArraySurveyAnswer {
        value
      }
      ... on StringSurveyAnswer {
        stringValue: value
      }
    }
  }
`;

const surveyFragment = gql`
  fragment SurveyFragment on Survey {
    id
    currentPage
    treatmentId
    pages {
      pageType
      content {
        type
        content
        position
      }
      ... on DemoPage {
        questions {
          ...QuestionFragment
        }
      }
      ... on QuestionPage {
        pageNumber
        totalPages
        questions {
          ...QuestionFragment
        }
      }
      ... on InterestsPage {
        questions {
          ...QuestionFragment
        }
      }
    }
  }
  ${questionFragment}
`;

export const SurveyQuery = gql`
  query assessmentQuery(
    $surveyId: ID!,
    $isOnboarding: Boolean,
    $isInProgress: Boolean,
  ) {
    survey(
      surveyId: $surveyId,
      isOnboarding: $isOnboarding,
      isInProgress: $isInProgress,
    ) {
      ...SurveyFragment
    }
  }
  ${surveyFragment}
`;

export const AnswerSurveyMutation = gql`
  mutation answerSurveyQuestion(
    $surveyId: ID!,
    $questionId: ID!,
    $answer: SurveyAnswerInput!,
    $isInProgress: Boolean,
    $isOnboarding: Boolean,
  ) {
    answerSurveyQuestion (
      surveyId: $surveyId,
      questionId: $questionId,
      answer: $answer,
      isInProgress: $isInProgress,
      isOnboarding: $isOnboarding,
    ) { 
      ...SurveyFragment
    }
  }
  ${surveyFragment}
`;

export const ClearSurveyMutation = gql `
  mutation AnswerSurveyQuestion(
    $surveyId: ID!,
    $isInProgress: Boolean,
    $isOnboarding: Boolean,
  ) {
    clearSurveyQuestion(
      surveyId: $surveyId,
      isInProgress: $isInProgress,
      isOnboarding: $isOnboarding,
    ) {
      ...SurveyFragment
    }
  }
  ${surveyFragment}
`;
