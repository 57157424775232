import React from "react";
import { gql } from "@apollo/client";
import { Icon, TipContent } from "@/cloverleaf-ui/components";
import { usePersistedCacheQuery, useLocale } from "../../../../lib/hooks";
import { EVENT } from "../../../utils/constants";
import { ButtonLink } from "../../ButtonLink";

const GET_NOTIFICATIONS_QUERY = gql`
  query notifications {
    notifications {
      id
      count
    }
  }
`;

function NotificationBell() {
  const { t } = useLocale();

  const [notificationCount, setNotificationCount] = React.useState(0);

  const { loading, data } = usePersistedCacheQuery(GET_NOTIFICATIONS_QUERY);

  React.useEffect(() => {
    if (data?.notifications?.count) {
      setNotificationCount(data.notifications.count);
    }
  }, [data]);

  if (loading || !data?.notifications) {
    return null;
  }

  if (notificationCount === 0) {
    return (
      <Icon
        disabled
        icon="bell"
        color="grey400"
        size="lg"
      />
    );
  }

  return (
    <ButtonLink
      a11yTitle="Notifications"
      badge={notificationCount}
      href={{ pathname: "/notifications" }}
      icon={(
        <Icon
          icon="bell"
          color="grey400"
          size="lg"
        />
      )}
      logEventProps={{
        elementName: "notification-bell-button",
        eventName: EVENT.NotificationBellClicked.name,
        notificationCount,
      }}
      plain
      style={{ verticalAlign: "middle" }}
      tip={{
        content: <TipContent message={t("notifications")} position="bottom" />,
        plain: true,
      }}
    />
  );
}

export { NotificationBell };
