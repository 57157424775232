import React from "react";
import PropTypes from "prop-types";
import { css } from "styled-components";
import { Tabs as GrommetTabs, ThemeContext } from "grommet";

const pilledTabsTheme = {
  tab: {
    background: "green500",
    color: "white",
    active: {
      background: "white",
      color: "green600",
    },
    hover: {
      color: "white",
    },
    border: {
      side: "all",
      size: "xsmall",
    },
    margin: undefined,
    pad: "xsmall",
    extend: ({ theme }) => css`
      border-radius: ${theme.global.edgeSize.small};
      text-align: center;
    `,
  },
  tabs: {
    header: {
      background: "green500",
      border: { size: "0px" },
      extend: ({ theme }) => css`
        align-self: stretch;
        border-radius: ${theme.global.edgeSize.small};
        box-shadow: ${theme.global.elevation.light.small};
        margin-bottom: ${theme.global.edgeSize.xxsmall};
        margin-left: ${theme.global.edgeSize.large};
        margin-right: ${theme.global.edgeSize.large};
        flex-wrap: nowrap;
      `,
    },
  },
};

function Tabs({
  children,
  ...props
}) {
  return (
    <ThemeContext.Extend value={pilledTabsTheme}>
      <GrommetTabs {...props}>
        {children}
      </GrommetTabs>
    </ThemeContext.Extend>
  );
}

Tabs.propTypes = {
  children: PropTypes.any.isRequired,
};

export { Tabs };
