/* eslint-disable unused-imports/no-unused-vars */
import React from "react";
import {
  func,
  string,
  shape
} from "prop-types";
import ConfirmPrompt from "../composite/ConfirmPrompt";
import { Tooltip } from "./withTooltip";

const withConfirmAction = (Component) => {
  class PromptWithToggle extends React.PureComponent {
    state = { show: false };

    static propTypes = {
      prompt: string,
      additionalInfo: string,
      onClick: func.isRequired,
      onNegativeClick: func,
      positiveTooltip: string,
      negativeTooltip: string,
      tooltip: shape({
        title: string.isRequired,
      }),
    };

    static defaultProps = {
      prompt: undefined,
      additionalInfo: undefined,
      onNegativeClick: () => undefined,
      positiveTooltip: undefined,
      negativeTooltip: undefined,
      tooltip: undefined,
    };

    handleClick = onClick => () => {
      onClick();
      this.hidePrompt();
    }

    showPrompt = () => {
      this.setState({ show: true });
    }

    hidePrompt = () => {
      this.setState({ show: false });
    }

    renderTooltip = () => {
      const {
        prompt, onClick, onNegativeClick, additionalInfo, positiveTooltip, negativeTooltip,
      } = this.props;

      return (
        <ConfirmPrompt
          prompt={prompt}
          additionalInfo={additionalInfo}
          onPositiveClick={this.handleClick(onClick)}
          onNegativeClick={this.handleClick(onNegativeClick)}
          positiveTooltip={positiveTooltip}
          negativeTooltip={negativeTooltip}
        />
      );
    }

    renderInner = props => (
      <Tooltip
        {...{
          theme: "light",
          position: "bottom",
          animation: "fade",
          visible: this.state.show,
          html: this.renderTooltip(),
          interactive: true,
        }}
      >
        <Component
          {...props}
          onClick={this.showPrompt}
        />
      </Tooltip>
    );

    render() {
      const {
        prompt,
        onClick,
        onNegativeClick,
        additionalInfo,
        positiveTooltip,
        negativeTooltip,
        tooltip,
        ...otherProps
      } = this.props;

      return (
        tooltip ? (
          <Tooltip html={<span>{tooltip.title}</span>} theme="cloverleaf" arrow>
            {this.renderInner(otherProps)}
          </Tooltip>
        )
          : this.renderInner(otherProps)
      );
    }
  }

  return PromptWithToggle;
};

export default withConfirmAction;
