const WEEKDAYS = [
  Date.UTC(1970, 1, 2),
  Date.UTC(1970, 1, 3),
  Date.UTC(1970, 1, 4),
  Date.UTC(1970, 1, 5),
  Date.UTC(1970, 1, 6),
  Date.UTC(1970, 1, 7),
  Date.UTC(1970, 1, 8),
];

export function getLocalizedWeekdayString({ weekday = 0, type = "long", locale = "en" }) {
  return new Intl.DateTimeFormat(locale, { weekday: type })
    .format(WEEKDAYS[weekday]);
}
