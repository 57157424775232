import { gql, useMutation } from "@apollo/client";
import * as Sentry from "@sentry/nextjs";

export const DELETE_USER_MUTATION = gql`
  mutation deleteUser($userId: ID!) {
    deleteUser(userId: $userId) {
      success
    }
  }
`;

/**
 *
 * @param {Object} targetUserId - user id of account to delete
 * @returns
 */
export const useDeleteUserMutation = ({ targetUserId } = {}) => {
  const [
    deleteUser, {
      data,
      loading,
      error,
    },
  ] = useMutation(DELETE_USER_MUTATION, {
    variables: { userId: targetUserId },
  });

  const handleDeleteUser = (userId) => {
    const deleteUserId = userId || targetUserId;

    return deleteUser({
      variables: {
        userId: deleteUserId,
      },
    }).then((result) => {
      if (result?.data?.deleteUser?.success) {
        return Promise.resolve(result?.data?.deleteUser?.success);
      }

      return Promise.reject(Error("Unable to delete the account"));
    })
      .catch((e) => {
        Sentry.captureException(e, {
          extra: {
            message: `Error deleting userId: ${deleteUserId}`,
          },
        });

        return Promise.reject(error);
      });
  };

  return {
    error,
    loading,
    result: data,
    deleteUser: handleDeleteUser,
  };
};
