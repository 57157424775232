export const DELETE = "Delete";
export const SUBMIT = "Submit";
export const SUBMITTING = "Submitting";
export const SAVE = "Save";
export const SAVING = "Saving";
export const RESET = "Reset";
export const ON = "on";
export const GROUP_NAME = "Group name";
export const PRIVATE = "Private";
export const HIDDEN = "Hidden";
export const NEXT = "Next";
export const PREV = "Prev";
export const CANCEL = "Cancel";
export const DONE = "Done";
export const ERROR = "Error";
export const FINISH = "Finish";
export const SETTINGS = "Settings";
export const VIEW = "View";
export const PAGE = "Page";
export const PAGES = "Pages";
export const BACK = "Back";
export const OF = "Of";
export const COMPLETED = "Completed";
export const DELETE_USER = "Delete User";
export const DELETE_USER_SUCCESS = "Successfully deleted the user";
export const TEAM_ADMIN = "Team Admin";
export const START_TYPING = "Start typing...";
export const ADD_USER_TEAM_SUCCESS = "Successfully sent invitation(s). You'll see teammates once they accept.";
export const ADD_USER_TEAM_FAIL = "Failed to add people to Team";
export const NO_RESULTS = "No Results Found";
export const ACTIVE_TEAM = "Active Team";
export const ACTIVE_GROUP = "Active Group";
export const ORGANIZATION_ADMIN_OF = "Organization admin of:";
export const DOWNLOAD_REPORTS = "Download Reports:";
export const GROUP_ADMIN_OF = "Group admin of:";
export const NO_ACTIVE_TEAM = "Not on any Team";
export const APPROVE_CANDIDATE = "Approve";
export const REJECT_CANDIDATE = "Reject";
export const CANDIDATE_APPROVED = "Candidate successfully approved";
export const CANDIDATE_REJECTED = "Candidate was rejected";
export const USER_ADDED = "Added person to the Team";
export const CANDIDATE = "Candidate";
export const INVITATION = "Invitation";
export const REVOKE_INVITATION = "Revoke Invitation";
export const ADMIN = "Admin";
export const NAME = "Name";
export const TYPE = "Type";
export const EMAIL = "Email";
export const WORK_EMAIL = "Work Email";
export const TAKE_ASSESSMENT = "Take Assessment";
export const ENTER_SCORES_TYPE = "I know my type";
export const ARE_YOU_SURE = "Are you sure?";
export const REMOVE_FROM_ORG_INFO = "This person will be removed from all Teams in the Organization. Teams the person owns will be removed from the Organization.";
export const REMOVE_FROM_ADMINS_INFO = "This person will lose Organization Admin privileges and will no loner be able to manage other members of the Organization.";
export const MEMBER = "Member";
export const OVERRIDE = "Override";
export const STATUS = "Status";
export const STATUS_COLON = "Status:";
/* DISC letter descriptive words */
export const DOMINANCE = "Dominance";
export const INFLUENCE = "Influence";
export const STEADINESS = "Steadiness";
export const CONSCIENTIOUSNESS = "Conscientiousness";
export const COMPLIANCE = "Compliance";
export const STRENGTHS_FINDER_LABEL = "CliftonStrengths®";
export const VIA_LABEL = "VIA";
export const SIXTEEN_TYPES_LABEL = "16 Types";
export const CULTURE_PULSE_LABEL = "Culture Pulse";
export const MOTIVATING_VALUES_LABEL = "Motivating Values";
export const DISC_LABEL = "DISC";
export const DRIVING_FORCES_LABEL = "12 Driving Forces";
export const ENNEAGRAM_LABEL = "Enneagram";
export const INSTINCTIVEDRIVES_LABEL = "Instinctive Drives";
export const ENERGYRHYTHM_LABEL = "Energy Rhythm";
export const STRENGTHSCOPE_LABEL = "Strengthscope®";
export const CLIENT_NAVIGATOR = "Client Navigator";
export const FAVORITE_WORLD = "Favorite World";
export const INFORMATION = "Information";
export const DECISIONS = "Decisions";
export const STRUCTURE = "Structure";
export const COMMENTS = "Comments";
export const INSIGHT_FEEDBACK_SUCCESS = "Feedback submitted successfully";
export const INSIGHT_FEEDBACK_FAIL = "There was an error when submitting feedback";
export const INSIGHT_FEEDBACK_PLACEHOLDER = "Please share your comments with us and help us improve!";
export const YES = "Yes";
export const NO = "No";
export const CLOSE = "Close";
export const TAGS = "Tags";
export const PEOPLE = "People";
export const TEAMS = "Teams";
export const TAG = "tag";
export const CREATE_GROUP_DESCRIPTION = "Groups are a great way to give leaders access to the teams and people they oversee whether it be for branches, regions, departments, roles or client groups. You can organize people and teams into groups, then set administrative authority over those groups. An administrator of a group can see all the people and teams in their group, and in the groups within their group, but cannot see the groups that are not nested within their own.";
export const LEARN_MORE_ABOUT_GROUPS = "Learn more about groups and how they work.";
export const CANDIDATE_NAME = "Candidate Name";
export const ALLOW_GROUP_JOIN = "Allow members of your organization to find and join this group";
export const ADD_EVALUATORS = "Add Evaluators";
export const PENDING_EVALUATORS = "Pending Evaluators";
export const COMPLETED_EVALUATORS = "Completed Evaluators";
export const EDIT = "Edit";
export const NO_SCORES = "No Scores";
export const CREATE = "Create";
export const ENTER_TO_ADD = "Press Enter to Add";
export const GO_BACK_AND_EDIT = "Go back and edit";
export const VIA = "VIA";
export const INVALID_FORM_TOOLTIP = "Fill out all information to continue";
export const CLOSE_MODAL = "Close";
export const ROLES = "Roles";
export const DISC = "Disc";
export const CULTURE = "Culture";
export const MANAGER = "A Manager.";
export const WERE_SORRY = "We're sorry!";
export const WERE_SORRY_GONE_WRONG = "We're sorry — something's gone wrong.";
export const ERROR_REFRESH = "We're sorry — something's gone wrong. Try refreshing or contact us";
export const BEEN_NOTIFIED = "Our team has been notified.";
// Team Dashboard Nugget Titles
export const TEAM_ROLES = "Team Roles";
export const TEAM_ROLE_GRID = "Team Role Grid";
export const THINKING_STYLE_COMPARISON = "Thinking Styles Comparison";
export const BEST_TIME_OF_DAY = "Best Time of Day";
export const CREATE_NEW_TEAM = "Create new Team";
export const CREATE_TEAM = "Create Team";
export const CONTRAST = "Contrast";
export const TEAM_SETTINGS = "Team Settings";
export const NOT_ENOUGH_DATA = "Not Enough Data";
export const UPLOAD_PHOTO = "UPLOAD PHOTO";
export const LOADING_PHOTO = "Loading Photo...";
export const LOADING = "Loading...";
export const GRAPH = "Graph";
export const AVERAGE = "Average";
export const GRAPH_VIEW = "Graph View";
export const AVERAGE_VIEW = "Average View";
export const SEE_FULL_PROFILE = "See Full Profile";
export const ERROR_TRY_AGAIN = "An error has occured, please try again";
export const INVALID_EMAIL_ACCEPT = "Your email does not match the invited email. You can add a secondary email in your account settings and then try again.";
export const ADD_MEMBERS = "Add Members";
export const ADD_ADMINS = "Add Admins";
export const REMOVE_FROM_THIS_TEAM = "Remove from this team";
export const NO_PERMISSION_TO_REMOVE = "You do not have admin permission";
export const REMOVE_FROM_ORGANIZATION = "Remove from this Organization";
export const PUT_ME_HERE = "Put me here";
export const REMOVE_CONNECTION = "Remove connection.";
export const FOLLOWING_PEOPLE_DO_NOT_HAVE_SCORES = "The following people do not have the required scores for this section:";
export const TEAM_RESULTS_MAY_BE_AFFECTED = "Team results may be affected until all Team Members have completed the assessment(s).";
export const SEND_EMAIL_REMINDER = "Send email reminder";
export const INCOMPLETE = "Incomplete";
export const SENT_EMAIL_REMINDER = "Reminder Sent Successfully!";
export const SENT_EMAIL_REMINDER_ERROR = "Failed to send reminder, try again.";
export const ERROR_OCCURRED_REFRESH = "An error occurred, please refresh the page.";
export const ERROR_OCCURRED_TRY_AGAIN = "An error occurred, please try again.";
export const INVITATION_PENDING = "Invitation Pending";
export const ADD_NEW_TEAM = "Add Team";
export const ADD_PERSON = "Add Person";
export const ADD_ADMIN = "Add Admin";
export const ADD_NEW_GROUP = "Add Group";
export const CREATE_NEW_TEAM_SUCCESS = "Successfully created a new team";
export const SELECT_SUB_GROUP_FROM_LEFT = "Select a group from the left side menu.";
export const LEAVE_THIS_TEAM = "Leave this Team";
export const LEAVE_TEAM_SUCCESS = "Successfully left the Team";
export const LEAVE_TEAM_FAILURE = "Unable to leave the Team";
export const APPLY = "Apply";
export const TOGGLE_CANDIDATE = "Toggle Candidate Visibility";
export const TOGGLE_TEAM_MEMBER = "Toggle Team Member Visibility";
export const CANDIDATES_SHOWN_TOOLTIP = "Candidates are shown";
export const PARTIAL_SHOWN_TOOLTIP = "Partial Team is shown";
export const ALL_CANDIDATES = "All Candidates";
export const TEAM_MEMBER = "Team Member";
export const INVITE = "Invite";
export const TEAM_IS_PRIVATE_INSTRUCTIONS = "Visible to admins only. Edit the team to change visibility.";
export const SEARCH_BY_NAME = "Search By Name";
export const HELPFUL_QUESTION = "Helpful?";
export const YOU = "You";
export const PERSONAL = "Personal";
export const ORGANIZATION = "Organization";
export const GO_TO_DASHBOARD = "Go to dashboard";
export const YOUR_RESULT = "Your Result";
export const PURCHASE_REPORT = "Purchase Report";
export const SEARCH = "Search";
export const USER_STATUS = "user status";
export const HOME = "Home";
export const INVITE_ERROR = "Uh oh, looks like something went wrong while accepting this invite.";
export const BILLING_SETTINGS = "Billing Settings";
export const ORGANIZATION_ROLES = "Organization Roles";
export const NOTIFICATION_PAGE = "Notification Page";
export const CREATE_ORGANIZATION_SUCCESS = "Successfully created a new Organization";
export const REMOVE_FROM_ORG_SUCCESS = "Successfully removed user from organization";
export const REMOVE_AS_ORG_ADMIN = "Remove as Org Admin";
export const FREE = "Free";
export const TEAM = "Team";
export const ENTERPRISE = "Enterprise";
export const CONTINUE = "Continue";
export const UPGRADE = "Upgrade";
export const CONTACT = "Contact";
export const IS_ADMIN = "Is An Admin";
export const ENABLED = "Enabled";
export const DISABLED = "Disabled";
export const CREATED_AT = "Created at";
export const COMPANY = "Company";
export const POSITION = "Position";
export const REMOVE_TEAM_ADMIN_SUCCESS = "Successfully removed person as Team Admin";
export const REMOVE_TEAM_ADMIN = "Remove Team Admin";
export const SEARCH_BY_NAME_OR_EMAIL = "Search by name or email";
export const ADMIN_OF = "Admin of:";
export const FILTER_COLUMNS = "Filter columns";
export const HIDE_FILTERS = "Hide filters";
export const USERS = "Users";
export const ORGANIZATIONS = "Organizations";
export const CONFIGURATION_PRESETS_LOWERCASE = "Configuration Presets";
export const SUBDOMAIN = "Subdomain";
export const DESCRIPTION = "Description";
export const USER_COUNT = "User count";
export const PLAN_TIER = "Plan tier";
export const ORG_STATUS = "Status";
export const UPDATE_TEAM_INFO_SUCCESS = "Successfully updated team information";
export const REQUIRED = "Required";
export const VISIBLE = "Visible";
export const SEE_TEAM_DASHBOARD = "See Team Dashboard";
export const SEE_ORG_ADMIN = "See Organization Admin";
export const ORGANIZATION_NAME = "Organization Name";
export const CREATE_NEW_ORGANIZATION = "Create New Organization";
export const VIEW_TEAM_DASHBOARD = "View Team Dashboard";
export const ROLE = "Role";
export const ADD_NEW_ROLE = "Add New Role";
export const EDIT_ROLE_NAME = "Edit Role Name";
export const DELETE_ROLE = "Delete Role";
export const ENTER_ROLE_NAME = "Enter Role Name";
export const EDITED_ROLE_SUCCESS = "Successfully edited the organization role.";
export const EDIT_ROLE_FAILURE = "We encountered a problem updating this role. Please try again.";
export const DELETED_ROLE_SUCCESS = "Successfully deleted the role from the organization.";
export const SUCCESSFULLY_CREATED_ROLE = "Successfully created the role in the organization.";
export const NO_ROLES_AVAILABLE = "No Organization Roles";
export const ADD_NEW_CANDIDATE = "Add New Candidate";
export const REVIEW_CANDIDATES = "Review Candidates";
export const SHARE = "Share";
// Import Organization Page:
export const REMOVE_THIS_COLUMN = "Remove this column";
export const CONFIRM_GO_BACK_AND_START_OVER_PROMPT = "Are you sure? You'll have to start over if you go back";
export const UPLOAD_A_NEW_FILE = "Upload A new file";
export const TODAY = "Today";
export const SNOOZED = "Snoozed";
export const EVENTS = "Events";
export const NOT_AVAILABLE = "Not Available";
export const PARTICIPANTS = "Participants";
export const INSIGHTS_PRIVATE = "Insights are private";
export const NO_INSIGHTS_AVAILABLE = "No insights available";
export const SNOOZE_ALL_PARTICIPANTS = "Snooze All Participants";
export const SNOOZE_EVENT = "Snooze Event";
export const SNOOZE_RECURRING_EVENT = "Snooze Recurring Event";
export const NOT_APPLICABLE = "N/A";
export const EMAIL_SETTINGS = "Email Settings";
export const MANAGE_SNOOZES = "Manage Snoozes";
export const CONNECT_CALENDAR = "Connect New Calendar";
export const INCLUDE_EVENTS = "Include events on these days";
export const SEND_DIGEST_TO = "Send Digest To";
export const FREQUENCY = "Frequency";
export const DISCONNECT_CALENDAR = "Disconnect Calendar";
export const LEARN_MORE = "Learn More";
export const SNOOZE_ATTENDEE_SUCCESS = "Successfully Snoozed Attendee(s)";
export const CLIENT_NAVIGATOR_ATTENDEE_TOOLTIP = "See more insights in your Client Navigator.";
export const SUCCESS = "Success";
export const INVITE_SUCCESS_DESCRIPTION = "Invitation was sent successfully. We can't wait until they get here!";
// Validation errors
export const VALIDATE_REQUIRED = "This field is required";
export const VALIDATE_URL = "Must start with http:// or https://";
export const VALIDATE_URL_PATH = "Must use only lowercase letters, numbers, and \"-\"";
export const VALIDATE_INTEGER = "Must be an integer";
export const VALIDATE_EVALUATOR_REQUIRED = "At least one evaluator is required";
export const VALIDATE_DISC_SCORE_REQUIRED = "All DISC scores are required";
export const VALIDATE_GREATER_THAN_EQUAL_TO = "Value must be greater than or equal to";
export const VALIDATE_LESS_THAN_EQUAL_TO = "Value must be less than or equal to ";
export const VALIDATE_AT_LEAST_ONE_GREATER_THAN_EQUAL_TO = "At least one value must be greater than or equal to";
export const VALIDATE_UNIQUENESS = "Value must be unique";
// Lead Links
export const LEADS = "Leads";
export const LEAD_LINKS = "Lead Links";
export const NEW_LINK = "New Link";
export const ADD_NEW_LINK = "Add New Link";
export const COPY_LINK = "Copy Link";
export const EDIT_LINK = "Edit Link";
export const REMOVE_LEAD = "Remove Lead";
export const CONVERT_TO_CLIENT = "Convert to Client";
export const NEW_LINK_CREATED = "New Link Created:";
export const SUCCESSFULLY_CONVERTED = "Successfully converted lead to user!";
export const LINK_DETAILS_NOT_FOUND = "Link Details Not Found";
export const NO_LEADS_FOUND = "No Leads Found";
export const LINK_NOT_FOUND = "Link not found";
export const SUCCESSFULLY_SAVED = "Successfully Saved!";
export const GENERAL_INFORMATION = "General Information";
export const NEXT_STEP_TASK = "Next Step Task";
export const HERE_ARE_SOME_OF_OUR_SUGGESTIONS = "Here are some of our suggestions!";
export const LINK_TASK_TITLE_1 = "Schedule a Consultation —\u00A0";
export const LINK_TASK_SUGGESTION_1 = "Link to your calendar link. If you don't have a calendar link yet, we suggest acuityscheduling.com, which allows you to offer free or paid consultations.";
export const LINK_TASK_TITLE_2 = "Custom Form —\u00A0";
export const LINK_TASK_SUGGESTION_2 = "Create an enticing title that lets people know about the value you have to offer. We suggest you use typeform.com. Typeform surveys will be displayed directly in Cloverleaf.";
export const LINK_TASK_PROMPT = "Add a task that will be shown to your new leads after they complete their primary assessment.";
export const LINK_NAME = "Link Name";
export const CUSTOM_URL = "Custom URL";
export const PRIMARY_ASSESSMENT = "Primary Assessment";
export const PRIMARY_ASSESSMENT_DETAILS = "Choose the assessment you want people to take first.";
export const ADDITIONAL_ASSESSMENTS = "Additional Assessments";
export const TASK_TITLE = "Task Title";
export const TASK_URL = "Task URL";
export const SELECT_AN_ICON = "Select an Icon";
export const DISABLED_LINKS_MESSAGE = "This feature is not currently enabled. Please contact us if you would like to get started with custom lead links.";
export const JOINED_AT = "Joined At";
export const SUCCESSFULLY_UPDATED = "Successfully Updated!";
export const SUCCESSFULLY_DELETED = "Successfully Deleted!";
export const SKIP_THIS_STEP = "Skip this step";
export const AVATAR_UPLOAD_SUCCESS = "Avatar uploaded successfully";
export const MORE_INFO = "More info";
export const ACCEPT_INVITE = "Accept Invite";
export const REJECT_INVITE = "Reject Invite";
export const SELECT_PEOPLE_FOR_COMPARISON = "Select people for comparison";
export const SELECT_PERSON = "Select Person";
export const UNAUTHORIZED = "UNAUTHORIZED";
export const CLIENT_NAVIGATOR_UNAUTHORIZED = "Oops, how did you find this page? If you want access, schedule a demo!";
export const START_A_TEAM = "Start A Team";
export const CONFIRM_LEAVE_ASSESSMENT = "Are you sure you want to leave this page? You will lose your progress for this assessment";
export const CONFIRM_LEAVE = "Are you sure you want to leave this page?";
export const WING = "Wing";
export const TRIAD = "Triad";
export const GROWTH = "Growth";
export const STRESS = "Stress";
export const VIEW_PROFILE = "View Profile";
export const CLICK_HERE = "Click here";
export const SIGNOUT = "SIGN OUT";
export const YOU_ARE_SIGNED_IN_AS = "You are currently signed in as";
export const INVITE_WAS_SENT_TO = "The invite was sent to";
export const CONTACT_US_IF_BOTH_BELONG = "Contact us if both of these accounts belong to you.";
export const ACCEPTING_INVITE = "accepting invite";
export const LOADING_ORGANIZATION = "We're importing your organization into Cloverleaf. Just a moment.";
export const UH_OH = "Uh oh.";
export const WHAT_YOUR_LOOKING_FOR = "WHAT YOU'RE LOOKING FOR ISN'T WHERE YOU ARE.";
export const GOT_LOST_TEXT = "The bad news is that things change. The good news is that things change. So let's roll with the punches and try to get you back to where you thought you needed to go.";
export const GO_BACK = "Go back";
export const CHOOSE_THE_MODULES = "Choose the modules";
export const YOU_WANT_AVAILABLE = "you want available.";
export const CONTACT_US_FOR_ERRORS = "Contact Cloverleaf for assistance in resolving errors";
export const PENDING_INVITES_TOOLTIP = "Users who have not accepted the invite yet.";
export const MEMBERS = "Members";
export const COMPLETE = "Complete";
export const REMAIN = "Remain";
export const IN_PROGRESS = "In Progress";
export const MEMBERS_TOOLTIP = "People in your organization.";
export const ONBOARDING_ERRORS = "Onboarding Errors";
export const ONBOARDING_STATUS = "Onboarding Status:";
export const CHOOSE = "Choose";
export const ORGANIZATION_CONFIGURATION = "Organization Configuration";
export const GENERAL_DETAILS = "General Details";
export const CONFIGURATION = "Configuration";
export const WHO_CAN_JOIN_YOUR_ORG = "WHO CAN JOIN YOUR ORGANIZATION";
export const TASK_SETUP = "Task Setup";
export const ADD_SF_CODES = "Add strength finder codes";
export const FUNCTIONS = "Functions";
export const PLAN_DETAILS = "Plan Details";
export const LIMITS = "Limits";
export const LIMITS_ADDED = "Limits added successfully";
export const ADMIN_LIMITS = "Admin limits";
export const TEAM_LIMITS = "Team limits";
export const PEOPLE_LIMITS = "People limits";
export const PEOPLE_PER_TEAM_LIMITS = "People per team limits";
export const ORGANIZATION_INVITES = "Organization Invites";
export const RESEND_INVITE_EMAIL = "Resend Invite Email";
export const RESEND_INVITE_SUCCESS = "Successfully resent invite";
export const GROUP_INVITES = "Group Invites";
export const DELETE_GROUP = "Delete Group";
export const DELETE_GROUP_UPPER = "DELETE GROUP";
export const NO_INVITES = "No Invites";
export const DELETE_GROUP_PROMPT = "Are you sure? This will delete any teams and groups below this group, and disassociate group members.";
export const TOP_PARENT_ORGANIZATION = "Top Parent Organization";
export const CLICK_TO_COPY = "Click to Copy";
export const COPIED = "Copied!";
export const TAKE_STRENGTHSFINDER = "We're excited for you to take StrengthsFinder (Top 5 CliftonStrengths)! To do this, you're going to have to leave Cloverleaf.";
export const STRENGTHSFINDER_ADD_CODE_SUCCESS = "Successfully added StrengthsFinder codes to this organization.";
export const STRENGTHSFINDER_CODES = "StrengthsFinder Codes";
export const ENTER_CODES = "Enter Codes";
export const ADMIN_SETTINGS = "Admin Settings";
export const MEMBER_SETTINGS = "Member Settings";
export const CANDIDATE_SETTINGS = "Candidate Settings";
export const NO_CONFIG_AVAILABLE = "You do not have any configurations available";
export const COACH_TIPS = "Coach Tips";
export const ENABLE_AUTHORIZED_DOMAINS = "Enable authorized domains";
export const JOINABLE_TO_ANYONE = "Joinable to anyone";
export const JOINABLE_MODAL = "Are you sure you want to allow your organization to be joinable by anyone? Anyone who finds your organization URL will be able to create an account and join your organzation.";
export const ENABLE_AUTHORIZED_DOMAINS_SUB = "Users will be able to join your organization using any email that includes one of your added domains.";
export const JOINABLE_TO_ANYONE_SUB = "Anyone can join your organization. We do not recommend using this option.";
export const CHANGES_SAVED = "Changes saved";
export const DELETE_DOMAIN = "Delete domain address";
export const DELETE_DOMAIN_MODAL = "Are you sure you want to delete this domain? This will not remove any users existing users, but users will no longer be able to sign up with this domain.";
export const DOMAIN_SUCCESSFULLY_ADDED = "Domain successfully added";
export const DOMAIN_SUCCESSFULLY_DELETED = "Domain successfully deleted";
export const WHITELIST_BANNER_UNCHECKED = "New team members must be invited directly to the organization, or team within the organization.";
export const WHITELIST_BANNER_CHECKED = "New team members can be invited directly to the organization, or join using an email that contains an authorized domain.";
export const ANYONE_CAN_JOIN_WHITELIST = "Anyone can join this organization or team within the organization. We do not recommend using this option.";
export const NO_AUTHORIZED_DOMAINS = "There are currently no authorized domains, so users can only join by direct invite. Add a domain so that users can find your organization.";
export const FCO_TOOLTIP = "\"For Current Organization\", these limits apply directly and only to this organization/group, subgroups wont be affected by this.";
export const FEO_TOOLTIP = "\"For Entire Organization\", these limits apply to this organization/group and all of it's subgroups combined";
export const FD_TOOLTIP = "\"For Descendants\", these limits only apply to each subgroup of this organization/group";
export const FDEO_TOOLTIP = "\"For Descendants Entire Organization\", these limits apply to each subgroup and all it's descendants combined. Similar to FEO but without counting the current organization";
export const SAVE_LIMITS = "Save limits";
export const SELECT_FROM_LIST = "Select from list";
export const SELECT_PEOPLE_PLACEHOLDER = "Select from list, or type email...";
export const INVALID_EMAIL = "Invalid Email";
export const DELETE_THIS_ROLE_ALIGNMENT = "Delete this Role Alignment";
export const ROLE_ALIGNMENT_LABEL = "Label for Role Alignment";
export const ROLE_ALIGNMENT = "Role Alignment";
export const TAKE_ROLE_ALIGNMENT_ASSESSMENT = "Take Role Alignment assessment";
export const CREATE_NEW_ROLE_ALIGNMENT = "Create Role Alignment";
export const TAKE_ASSESSMENT_NOW = "Take Assessment Now!";
export const TAKE_ASSESSMENT_LATER = "Take Assessment Later";
export const MANUALLY_INPUT_DISC_SCORES = "Manually input DISC scores";
export const REQUEST_SELF_ROLE_ALIGNMENT = "Request Self Role Alignment";
export const NOT_IMPORTANT = "Not Important";
export const SLIGHTLY_IMPORTANT = "Slightly Important";
export const MODERATELY_IMPORTANT = "Moderately Important";
export const VERY_IMPORTANT = "Very Important";
export const EXTREMELY_IMPORTANT = "Extremely Important";
export const SEARCH_NAME_ASSESSMENT = "Search by Name or Assessmment";
export const SEARCH_SKILLS = "Search Skills";
export const SEARCH_BY_SKILLS = "Search by Skills";
export const SEARCH_E = "Search...";
export const IMAGE_LABEL = "Image Label";
export const SUB_HEADING = "Sub Heading";
export const SLACK_SERVER_ERROR = "Error in connecting to server on /slack page";
export const SLACK_ERROR_MESSAGE = "An error has occurred while connecting your account, please try again or contact us if the problem persist.";
export const limitLabels = {
  FCO: "FCO",
  FEO: "FEO",
  FD: "FD",
  FDEO: "FDEO",
};
export const VALID_TYPEFORM_LINK = "Valid Typeform Link";
export const TAKE_ONBOARDING_SURVEY = "Take Onboarding Survey";
export const TASK_LABEL = "Task Label";
export const INCLUDE_IN_ONBOARDING = "Include in Onboarding";
export const MAKE_DELAYED_TASK = "Make a Delayed Task (cannot be combined with In Onboarding";
export const INHERIT_GROUPS = "Inherit Groups";
export const DAYS_AFTER_CREATION = "Days after Account Creation";
export const PREVIOUS_TASK = "Previous Task (task will be marked complete when this one is created for users)";
export const ONBOARDING_TASK_DESC = "Create a new Onboarding task. This will be created for new users only, and is not group-specific. Deleting this task will not remove it from any users who already have it assigned. Delayed tasks cannot have In Onboarding turned on. They will be created X number of days after a user account is created.";
export const DELETE_TASK = "Delete Task";
export const SAVED_TASK = "Saved Task!";
export const CLOVERLEAF_TYPEFORM_URL = "https://cloverleaf.typeform.com";
export const ID = "ID";
export const RESULTS_FIRST_STEP = "Results Are Only The First Step.";
export const NO_THANKS_FREE_ASSESSMENTS = "No thanks, take more free assessments.";
export const EXPORT_LEADS = "Export Leads";
// Instinctive Drives
export const USE = "Use";
export const AVOID = "Avoid";
export const TAKE_UNDERSTANDING_DRIVES = "Take your understanding of what drives and motivates you to the next level";
export const WHAT_YOU_NEED_TO_BE_BEST = "What you need to be at your best";
export const YOUR_UNIQUE_TALENTS = "Your unique talents and vulnerabilities";
export const TIPS_FOR_SUCCESS_FULFILLMENT = "Tips for your success and fulfillment";
export const UNLOCK_THESE_STRATEGIES = "Unlock these practical strategies to help you operate at peak performance for just";
export const INSTINCTIVE_DRIVES_REPORT_PRICE = "$99";
export const USE_YOUR_REPORT_TO_LEARN = "Use your report to learn:";
export const DOWNLOAD_REPORT_ME_DASHBOARD = "Download your report from your Me Dashboard";
export const UPDATED_REPORT_BIO_SUCCESS = "Successfully updated Organization Report Biography";
export const YOUR_BIO_FOR_REPORTS = "Your Bio For Reports";
export const HERES_AN_EXAMPLE = "Here's an example:";
export const SET_BIO = "Set Bio";
export const REPORT_BIO_RECOMMENDATION = "This will appear on the final page of reports. We recommend you give a high-level description of the services you offer and a recommended next step.";
// Directory
export const DIRECTORY = "Directory";
export const CONNECT_STRENGTHSCOPE = "I’ve taken this already";
export const STRENGTHSCOPE_FREE_FIVE_DESCRIPTION = "Your Strengthscope Free 5 results are designed to be the first step in helping you build awareness of your unique strengths.";
export const STRENGTHSCOPE_FREE_FIVE_OFFER = "By upgrading, you'll be able to access deeper analysis of all your strengths including:";
export const VISIT_STRENGTHSCOPE_PORTAL = "Visit Strengthscope® Portal";
export const REQUEST_UPGRADE = "Request Upgrade";
export const FULL_DOWNLOADABLE_REPORT = "Full downloadable report";
export const SEVEN_STRENGTHS = "7 significant strengths";
export const STRENGTHS_OVERDRIVE = "Strengths in overdrive";
export const ENERGY_DRAINERS = "Energy drainers";
export const STRENGTHSCOPE_ACTIONS = "Actions to stretch strengths and limit performance risks";
export const STRENGTHSCOPE_LOCKED_CONTENT = "Upgrade to get the most from your strengths every day at work and in life.";
export const STRENGTHSCOPE_USER_UPGRADED = "Successfully upgraded Strengthscope® for this user";
export const RESULTS_ALREADY_UPGRADED = "This user's results have already been upgraded.";
export const COULD_NOT_FIND_A_USER = "Could not find a user.";
export const CONGRATULATIONS = "Congratulations!";
export const UPGRADE_STRENGTHSCOPE = "Upgrade Strengthscope®";
export const ADMIN_REQUESTED_TO_UPGRADE = "Your Cloverleaf admin has been emailed a request to upgrade your account. They will be in touch with you shortly.";
export const STRENGTHSCOPE_OAUTH_MESSAGE = "You will now be taken directly to the Strengthscope's website where you can log in to import your scores";
export const STRENGTHSCOPE_GO_TO = "Go to Strengthscope";
export const PRODUCT_GUIDES = "Product Guides";
export const BLOG = "Blog";
export const COPY_TO_GROUPS = "Copy To Groups";
export const COPY_TO_GROUPS_TOOLTIP = "Copy these settings to all child groups";
export const COPY_ALL_TO_GROUPS = "Copy All Settings To Child Groups";
export const COPY_CONFIGS_SUCCESS = "Successfully copied configuration(s) to child groups.";
export const CREATE_PRESET_SUCCESS = "Successfully saved configuration to new preset.";
export const OVERWRITE_ALL_CONFIGS_WARNING = "This will overwrite ALL configurations for child groups. Are you sure?";
export const OVERWRITE_CONFIGS_WARNING = "This will overwrite this configuration for child groups. Are you sure?";
export const OVERWRITE_CONFIG_WARNING = "This will overwrite any existing configuration. Are you sure?";
export const CREATE_NEW_PRESET_FROM_CONFIGURATION = "Create new preset from configuration";
export const ENTER_PRESET_NAME = "Enter Preset Name";
export const ERROR_SETTING_CONFIGURATION_FROM_PRESET = "Error setting organization configuration from preset";
export const ERROR_CREATING_NEW_PRESET_FROM_CONFIG = "Error creating new preset from organization configuration";
export const CONFIGURATION_PRESETS = "CONFIGURATION PRESETS";
export const YOU_ARE = "You are";
export const OR_SELECT_TYPE = "Or Select any Type";
export const LEARN_TYPES_RELATION = "The Best Way to Learn about Your Type is In Realtion to others!";
// I.D. (Instinctive Drives)
export const SEARCHING_DETAILS = [
  {
    title: "Why?",
    details: "Why didn’t we pinpoint your I.D. result? Our research shows that this can happen when people are at a crossroads, experiencing a life transition, or in an environment causing them stress or frustration.",
    icon: ["fas", "question"],
  },
  {
    title: "What's Next",
    details: "We’re committed to giving you insights and strategies that are exactly right for you. We hope you take this opportunity to reflect upon what’s working and not working in your life right now, and then complete the questionnaire again.",
    icon: ["fas", "file-chart-line"],
  },
  {
    title: "What to do Differently",
    details: "Think of a time in the past when you’ve felt most energized and fulfilled OR imagine a point in the future where you are free to do things your way without being judged, criticized or having to meet others expectations. Answer the questions in this mindset.",
    icon: ["fas", "comment-exclamation"],
  },
];
// Disc
export const MEANS = "Means";
export const GOAL = "Goal";
export const INTERNALLY_DRIVEN = "Internally Driven";
export const EXTERNALLY_DRIVEN = "Externally Driven";
export const LOOSE = "Loose";
export const STRICT = "Strict";
export const COMMUNITY = "Community";
export const PROFESSIONAL = "Professional";
export const APPROACHABLE = "Approachable";
export const GUARDED = "Guarded";
export const SUPPORT = "Support";
export const PERFORMANCE = "Performance";
// END
export const DOMAIN_ADDRESS = "Domain Address";
export const REMOVE_DOMAIN = "Remove Domain";
export const REMOVE_DOMAIN_PROMPT = "This does not remove any existing users. Users can no longer sign up with this domain";
/* Profile Sharing */
export const SHARING_LINK = "Sharing Link";
export const PRIVACY_SETTING = "Privacy Setting";
export const THIS_IS_HOW_PROFILE_LOOKS = "This is how your profile looks to viewers. ";
export const CLICK_HERE_TO_SHARE = "Click here to share it";
export const SHARE_LINK_SUGGESTION = " with others, or better yet, add it to your email signature or LinkedIn profile!";
export const ANYONE_IN_ORG = "Anyone in the organization can view my full profile";
export const ANYONE_IN_TEAM = "Only people I am on a team with can view my full profile";
export const ANYONE_WITH_LINK = "Anyone with my profile link can view my public profile";
export const SHARE_OUTSIDE_ORG_WARNING = "People outside your Organization can't view your profile page";
export const SHARE_TO_NO_ONE_WARNING = "No one can access your profile. Change your privacy setting to \"Everyone\" to share your link with others.";
export const CHANGE_PRIVACY_SETTING = "Change Privacy Setting to";
export const TO_SHARE_PROFILE = "to share your profile";
export const PRIVACY_SETTING_UPDATE_SUCCESS = "Successfully updated privacy settings";
export const EVERYONE = "Everyone";
export const ME = "Me";
export const PASSWORD_ERROR = "Password must be 8 characters and include uppercase and lowercase letters and a number";
export const INVALID_EMAIL_FORMAT = "Invalid email format";
export const INVALID_DOMAIN_FORMAT = "Invalid domain format";
export const COGNITIVE_FUNCTION_PAIR = "Cognitive Function Pair";
export const SELF_SERVICE = "Self Service";
export const helpContent = {
  sixteenTypes: {
    shortDescription: "16 Types assesses four areas of an individual's behavior: where energy is received, how information is taken in, how decisions are made, and outward behavioral tendencies. The results in this box show the average of your team.",
    url: "https://help.cloverleaf.me/en/articles/3005204-what-is-16-types",
  },
  sixteenTypesGrid: {
    shortDescription: "The 16 Types grid places each team member on the grid based on their 16 types results. In this grid, you can see what behaviors are common on your team and where you have gaps.",
    url: "https://help.cloverleaf.me/en/articles/3377929-how-to-navigate-your-team-dashboard-gather-insights",
  },
  teamDisc: {
    shortDescription: "The DISC assessment measures how one responds to favorable and unfavorable situations. Scoring is on a scale of dominance, compliance, influence and steadiness. The results in this box show the average of your team.",
    url: "https://help.cloverleaf.me/en/articles/3005196-what-is-disc",
  },
  teamDiscCircle: {
    shortDescription: "The DISC circle places each team member in a quadrant based on their DISC results. Based on the circle, you can see which behaviors are common and which are less common on your team.",
    url: "https://help.cloverleaf.me/en/articles/8238822-my-teams-overview",
  },
  teamEnneagram: {
    shortDescription: "The Enneagram grid places each team member in a square based on the user's Enneagram results. The grid allows you to see common behavior trends on your team.",
    url: "https://help.cloverleaf.me/en/articles/2998360-what-is-the-enneagram",
  },
  teamRoles: {
    shortDescription: "Roles allow you to see on a high level where your team has natural abilities. In this overview, you are able to see what areas your team excels in and where they are in need of expertise.",
    url: "https://help.cloverleaf.me/en/articles/3581316-how-understanding-your-team-s-roles-can-help-you-build-a-better-team",
  },
  teamRolesGrid: {
    shortDescription: "This grid shows which team members have which strengths. The dots represent how high their skill in this is, with three dots meaning they are naturally skilled at this and 1 dot meaning they would need development to be in this role.",
    url: "https://help.cloverleaf.me/en/articles/3581316-how-understanding-your-team-s-roles-can-help-you-build-a-better-team",
  },
  teamStrengths: {
    shortDescription: "Team Strengths shows the top ways that your team uses its strengths. It highlights the domain from the StrengthsFinder assessment that your team is most accustomed to.",
    url: "https://help.cloverleaf.me/en/articles/3005209-what-is-strengthsfinder",
  },
  teamStrengthsChart: {
    shortDescription: "The Team Strengths Chart shows your teams strengths on a matrix. This allows you to see trends and patterns in the skills your teammates have.",
    url: "https://help.cloverleaf.me/en/articles/3005209-what-is-strengthsfinder",
  },
  teamStrengthsList: {
    shortDescription: "This list shows the number of team members that have each specific strength with each color representing one of the four StrengthsFinder domains.",
    url: "https://help.cloverleaf.me/en/articles/3005209-what-is-strengthsfinder",
  },
  teamViaStrengths: {
    shortDescription: "This shows the strengths of your team based on the VIA assessment. It includes the percentage of your team that has each strength.",
    url: "https://help.cloverleaf.me/en/articles/3005208-what-is-via",
  },
  teamViaChart: {
    shortDescription: "This chart allows you to see patterns in the strengths of your team members based on the VIA assessment.",
    url: "https://help.cloverleaf.me/en/articles/3005208-what-is-via",
  },
  teamViaList: {
    shortDescription: "This list shows the number of team members that have each specific strength with each color representing one of the 6 VIA groups.",
    url: "https://help.cloverleaf.me/en/articles/3005208-what-is-via",
  },
  teamThinkingStyle: {
    shortDescription: "This shows how diverse or similar your team thinking is.",
  },
  teamStrengthscope: {
    shortDescription: "Strengthscope® allows you to bring your best to work, and to life, every single day through the discovery and development of your strengths.  We define strengths as underlying qualities that energize us, and we are great at (or have the potential to become great at).",
    url: "https://help.cloverleaf.me/en/articles/4501808-what-is-strengthscope",
    freeFive: {
      shortDescription: "Only members who have been upgraded to their 'Significant 7' will be shown in the Strenghtshcope Team Results. To request an upgrade, go to your Strengthscope results box on your ME Dashbaord.",
      url: "https://help.cloverleaf.me/en/articles/4501808-what-is-strengthscope",
    },
    clearStrengths: {
      shortDescription: "When 40% or more of team members have a particular strength it is known as a Team Clear Strength. The following clear strengths have been identified as the most energizing for the team. The team should utilize these areas of strength first to deliver team goals and aspirations. The percentage of team members reporting each strength is shown alongside each clear strength.",
      url: "http://help.cloverleaf.me/en/articles/5096333-strengthscope-for-teams",
    },
    energyDrainers: {
      shortDescription: "An energy drainer occurs when 10% or fewer team members report a particular strength as significant for them. These are the areas where the team is least energized. Energy drainers help identify areas where the team might be least effective. Consider whether they risk undermining strong teamwork and delivery of the team's objectives.",
      url: "http://help.cloverleaf.me/en/articles/5096333-strengthscope-for-teams",
    },
    teamWheel: {
      shortDescription: "This graph shows your team's strengths profile across Strengthscope's® four clusters. The bars represent how many team members report each strength as one of their 'Significant 7'. Higher bars represent those strengths which are more natural and energizing for the team to use. Lower bars represent strengths which provide the team with less energy and enjoyment in their work. The percentages in the inner circle show you how the team's strengths are distributed across four energy clusters.",
      url: "http://help.cloverleaf.me/en/articles/5096333-strengthscope-for-teams",
    },
  },
  thinkingStyleComparison: {
    shortDescription: "Use this to compare two team members based on their assessment results.",
  },
  culturePulse: {
    shortDescription: "Culture Pulse shows how employees behave amongst each other. You can view the distribution of behaviors and values. Choose a person from the pull down to highlight their avatar on the maps.",
    url: "https://help.cloverleaf.me/en/articles/3005466-what-is-culture-pulse",
  },
  motivatingValues: {
    shortDescription: "Motivating Values evaluates the values that lead people to work. You can view the distribution of your team below. Choose a person from the pull down to highlight their avatar on the maps.",
    url: "https://help.cloverleaf.me/en/articles/3506118-what-is-motivating-values",
  },
  enneagram: {
    shortDescription: "The Enneagram measures an individual's core behaviors. The goal of this assessment is to build compassion through understanding others and improving communication. ",
    url: "https://help.cloverleaf.me/en/articles/2998360-what-is-the-enneagram",
  },
  culturePulseMeDashboard: {
    shortDescription: "The Culture Pulse assessment measures values, norms, beliefs, and behavior, and demonstrates how culture controls the way employees behave in groups.",
    url: "https://help.cloverleaf.me/en/articles/3005466-what-is-culture-pulse",
  },
  motivatingValuesMeDashboard: {
    shortDescription: "Motivating Values assesses the core values someone has which influences why the person behaves in certain ways. Values are fundamental incentives to motivation.",
    url: "https://help.cloverleaf.me/en/articles/3506118-what-is-motivating-values",
  },
  calendarInsights: {
    shortDescription: "Receive insights on the attendees of your upcoming meetings. You can see your calendar meeting insights here and in an email digest.",
    url: "https://help.cloverleaf.me/en/articles/2707802-connecting-your-calendar-for-insights-alongside-every-meeting",
  },
  sixteenTypesMeDashboard: {
    shortDescription: "16 Types assesses four areas of an individual's behavior: where energy is received, how information is taken in, how decisions are made, and outward behavioral tendencies. These four areas are compiled into a four letter code.",
    url: "https://help.cloverleaf.me/en/articles/3005204-what-is-16-types",
  },
  DISC: {
    shortDescription: "The DISC assessment measures how you respond to favorable and unfavorable situations. Scoring is on a scale of dominance, compliance, influence and steadiness.",
    url: "https://help.cloverleaf.me/en/articles/3005196-what-is-disc",
  },
  strengthsFinder: {
    shortDescription: "StrengthsFinder identifies your top 5 strengths. These strengths are each assigned to one of the four main groupings: Executing, Strategic Thinking, Influencing and Relationship Building. ",
    url: "https://help.cloverleaf.me/en/articles/3005209-what-is-strengthsfinder",
  },
  VIA: {
    shortDescription: "VIA identifies positive character strengths within the following six categories: Wisdom, Courage, Humanity, Justice, Temperance, and Transcendence. The strengths defined are when we are being our true, positive self.",
    url: "https://help.cloverleaf.me/en/articles/3005208-what-is-via",
  },
  skills: {
    shortDescription: "Add in the skills you already have or the skills you are developing so others can learn more about you.",
  },
  instinctiveDrives: {
    shortDescription: "The I.D. Questionnaire reveals a person’s natural and best way of doing things. It gives practical strategies to help achieve greater and more consistent levels of success and fulfillment, and to minimize levels of stress and frustration.",
    url: "https://help.cloverleaf.me/en/articles/4284900-what-is-instinctive-drives",
  },
  energyRhythmMeDashboard: {
    shortDescription: "The Energy Rhythm assessment brings awareness to your patterns of focus and energy, what times of day you feel most alert, and what types of tasks you do best during certain times.",
  },
  energyRhythmTeamDashboard: {
    shortDescription: "The Energy Rhythm Assessment reveals your team patterns of focus and energy, what types of tasks people do best during certain times, and what the flow of your team might look like day-to-day.",
    url: "https://help.cloverleaf.me/en/articles/4340148-what-is-the-energy-rhythm-assessment",
  },
  strengthScopeMeDashboard: {
    shortDescription: "Strengthscope® allows you to bring your best to work, and to life, every single day through the discovery and development of your strengths.  We define strengths as underlying qualities that energize us, and we are great at (or have the potential to become great at).",
    url: "https://help.cloverleaf.me/en/articles/4501808-what-is-strengthscope",
  },
  directory: {
    shortDescription: "Search for colleagues across your organization to view their profile and get valuable insights on how you can best work together.",
    url: "https://help.cloverleaf.me/en/articles/4900608-directory",
  },
};

export const TAKE = "Take";
export const NEVER = "Never";

export const meUpgradeContent = {
  disc: {
    image: {
      src: "https://cloverleaf-app-publicfiles.s3.amazonaws.com/assessment-logos/disc.png",
      alt: "DISC Graphic",
    },
    header: "Reveals how you respond to favorable and unfavorable situations.",
  },
  personality: {
    image: {
      src: "https://cloverleaf-app-publicfiles.s3.amazonaws.com/assessment-logos/16_types.png",
      alt: "16 Types Graphic",
    },
    header: "Identifies how you use your cognitive processes like learning, remembering, and deciding, in your life.",
  },
  enneagram: {
    image: {
      src: "https://cloverleaf-app-publicfiles.s3.amazonaws.com/assessment-logos/enneagram.png",
      alt: "Enneagram Graphic",
    },
    header: "Identify your individual core behaviors.",
  },
  culturePulse: {
    image: {
      src: "https://cloverleaf-app-publicfiles.s3.amazonaws.com/assessment-logos/culture_pulse.png",
      alt: "Culture Pulse Graphic",
    },
    header: "Identifies your cultural values, norms, beliefs, and behaviors.",
  },
  strengthsFinder: {
    image: {
      src: "https://cloverleaf-app-publicfiles.s3.amazonaws.com/assessment-logos/strengthsfinder.png",
      alt: "StrengthsFinder Graphic",
    },
    header: "Identifies your top five strengths.",
  },
  via: {
    image: {
      src: "https://cloverleaf-app-publicfiles.s3.amazonaws.com/assessment-logos/via.png",
      alt: "VIA Graphic",
    },
    header: "Identifies your positive character strengths.",
  },
  motivatingValues: {
    image: {
      src: "https://cloverleaf-app-publicfiles.s3.amazonaws.com/assessment-logos/motivating_values.png",
      alt: "Motivating Values Graphic",
    },
    header: "Reveals your primary influences.",
  },
  strengthscope: {
    image: {
      src: "https://cloverleaf-app-publicfiles.s3.amazonaws.com/assessment-logos/strengthscope.png",
      alt: "StrengthScope Graphic",
    },
    header: "Reveals your unique strengths.",
  },
  instinctiveDrives: {
    image: {
      src: "https://cloverleaf-app-publicfiles.s3.amazonaws.com/assessment-logos/instinctive_drives.png",
      alt: "Instinctive Drives Graphic",
    },
    header: "Reveals your natural and best way of doing things.",
  },
  energyRhythm: {
    image: {
      src: "https://cloverleaf-app-publicfiles.s3.amazonaws.com/assessment-logos/energy_rhythm.png",
      alt: "Energy Rhythm Graphic",
    },
    header: "Identifies your patterns of focus and energy.",
  },
  hbdi: {
    image: {
      src: "https://cloverleaf-app-publicfiles.s3.amazonaws.com/assessment-logos/hbdi.png",
      alt: "dash.hbdi.upgrade-content.image.alt"
    },
    header: "dash.hbdi.upgrade-content.header"
  }
};

export const teamUpgradeContent = {
  disc: {
    image: {
      src: "https://cloverleaf-app-publicfiles.s3.amazonaws.com/assessment-logos/disc.png",
      alt: "DISC Graphic",
    },
    header: "Reveals how you respond to favorable and unfavorable situations.",
  },
  personality: {
    image: {
      src: "https://cloverleaf-app-publicfiles.s3.amazonaws.com/assessment-logos/16_types.png",
      alt: "16 Types Graphic",
    },
    header: "Identifies how you use your cognitive processes like learning, remembering, and deciding, in your life.",
  },
  enneagram: {
    image: {
      src: "https://cloverleaf-app-publicfiles.s3.amazonaws.com/assessment-logos/enneagram.png",
      alt: "Enneagram Graphic",
    },
    header: "Identify your individual core behaviors.",
  },
  culturePulse: {
    image: {
      src: "https://cloverleaf-app-publicfiles.s3.amazonaws.com/assessment-logos/culture_pulse.png",
      alt: "Culture Pulse Graphic",
    },
    header: "Identifies your cultural values, norms, beliefs, and behaviors.",
  },
  strengthsFinder: {
    image: {
      src: "https://cloverleaf-app-publicfiles.s3.amazonaws.com/assessment-logos/strengthsfinder.png",
      alt: "StrengthsFinder Graphic",
    },
    header: "Identifies your top five strengths.",
  },
  via: {
    image: {
      src: "https://cloverleaf-app-publicfiles.s3.amazonaws.com/assessment-logos/via.png",
      alt: "VIA Graphic",
    },
    header: "Identifies your positive character strengths.",
  },
  motivatingValues: {
    image: {
      src: "https://cloverleaf-app-publicfiles.s3.amazonaws.com/assessment-logos/motivating_values.png",
      alt: "Motivating Values Graphic",
    },
    header: "Reveals your primary influences.",
  },
  strengthscope: {
    image: {
      src: "https://cloverleaf-app-publicfiles.s3.amazonaws.com/assessment-logos/strengthscope.png",
      alt: "StrengthScope Graphic",
    },
    header: "Reveals your unique strengths.",
  },
  instinctiveDrives: {
    image: {
      src: "https://cloverleaf-app-publicfiles.s3.amazonaws.com/assessment-logos/instinctive_drives.png",
      alt: "Instinctive Drives Graphic",
    },
    header: "Reveals your natural and best way of doing things.",
  },
  energyRhythm: {
    image: {
      src: "https://cloverleaf-app-publicfiles.s3.amazonaws.com/assessment-logos/energy_rhythm.png",
      alt: "Energy Rhythm Graphic",
    },
    header: "Identifies your patterns of focus and energy.",
  },
};

export const REQUEST_PENDING = "Request Pending";
export const SUCCESSFULLY_ACCEPTED_REQUEST = "Successfully Accepted Request";
export const SUCCESSFULLY_DENIED_REQUEST = "Successfully Denied Request";
// Team Management Page - Members Tab
export const SOMETHING_WENT_WRONG_TRY_AGAIN = "Something went wrong, please try again";
export const USER_TYPE = "User Type";
export const LAST_SENT = "Last Sent";
export const LATEST_REQUEST = "Latest Request";
export const INVITED_BY = "Invited By";

// Admin Group Page
export const ARE_YOU_SURE_YOU_WANT_TO_DELETE_MESSAGE = "Are you sure you want to delete this group? This will delete any teams and groups below this group, and disassociate group members.";

export const INSTINCTIVE_DRIVES_RESOURCES_URL = "https://help.cloverleaf.me/en/articles/4284900-what-is-instinctive-drives";
