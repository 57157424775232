import { useMutation, gql } from "@apollo/client";
import * as Sentry from "@sentry/nextjs";
import {
  alias,
  identify,
  track
} from "..";
import { EVENT } from "../../components/utils/constants";

const registerUserException = ({
  message = "Unknown Error",
  status = "Unknown",
}) => ({
  message,
  status,
});

export const REGISTER_USER_STATUS = {
  INVALID: "INVALID",
  SUCCESS: "SUCCESS",
  INFORMATION_NEEDED: "INFORMATION_NEEDED",
};

export const REGISTER_USER_QUERY = gql`
  mutation registerUser($input: input) {
    registerUser(input: $input)
      @rest( type: "Response", path: "/user/activate", method: "POST") {
        data
        message
        status
    }
  }
`;

export const useRegisterUser = (options) => {
  const [
    registerUser,
    { data, loading, error },
  ] = useMutation(REGISTER_USER_QUERY, { ...options });

  const handleRegisterUser = async ({
    token,
    email,
    firstName,
    lastName,
    role, // value provided with survey inquiry
    company,
    consented,
    password,
    subdomain,
    source,
    timezone,
    title, // value provided at account completion
    locale,
    isCandidate,
    candidateId,
    customLinkId,
    interactionId,
  }) => {
    try {
      const {
        data: response,
      } = await registerUser({
        variables: {
          input: {
            token,
            email,
            firstName,
            lastName,
            role: title,
            company,
            consented,
            password,
            subdomain,
            source,
            timezone,
            locale,
            isCandidate,
            candidateId,
            customLinkId,
            interactionId,
          },
        },
      });

      const {
        message,
        status,
        data: responseData,
      } = response.registerUser;

      // If we successfully signed up, make sure we track/identify the new user.
      if (status === REGISTER_USER_STATUS.SUCCESS && responseData?.user) {
        const { user } = responseData;

        // If the user is using a lead link and signed up to an org we can assume its a coach org.
        const inCoachOrganization = customLinkId && user.organizationId;
        // Created successfully! Identify the user for analytics, then push forward.
        const { ORGANIC } = EVENT.AccountCreated.props.type;
        // Track the user
        alias(user.id); // alias the anonymousId
        // Separate role from user analytics while we survey user
        const { role: _ignore, ...userAnalytics } = user;
        // Set user analytics
        identify(user.id, {
          ...userAnalytics,
          inCoachOrganization,
          email,
          role: title, // venacular for role if title provided
          createdAt: user.created,
          source: user.originalSource || ORGANIC,
          isLead: !!customLinkId,
          inactive: false, // make sure the user is not marked inactive, if they ever were.
          "survey-role": role, // for trial survey data
        }); // Identify the user

        // Attribution - the create endpoint should tell us where the user came from.
        const meta = {
          ...(user.originalSource && { type: user.originalSource }), // conditionally adds 'type' property
          email: user?.email,
          firstName: user?.name,
          lastName: user?.lname,
          role: title,
        }
        track(EVENT.AccountCreated.name, meta); // track the event
      }

      return {
        message,
        status,
        data: responseData,
      };
    }
    catch (errorResponse) {
      const { message, status } = errorResponse?.networkError?.result || {};

      Sentry.captureException(errorResponse, {
        extra: {
          arguments: {
            email,
          },
          message,
          status,
        },
        tags: {
          function: "useRegisterUser",
        },
      });

      throw registerUserException({ message, status });
    }
  };

  return {
    data,
    error,
    loading,
    registerUser: handleRegisterUser,
    registerUserStatus: REGISTER_USER_STATUS,
  };
};
