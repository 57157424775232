import styled from "styled-components";
import { GREEN, WHITE } from "../../utils/theme";

// Span that makes everything inside have a background, matching our branding.

export default styled.span`
  background-color: ${props => props.color || GREEN};
  color: ${WHITE};
  padding-left: 4px; 
  padding-right: 4px;  
`;
