import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  Box,
  FormField,
  Grid,
  RadioButtonGroup,
  Text
} from "grommet";
import { QuestionCard } from "../components/QuestionCard";
import { useLocale } from "@/lib/hooks";

// Very much unlike... very much like
const options = [1, 2, 3, 4, 5];

/**
 * Remove the Label from the CheckBox when rendering our question as buttons
 */
const NoLabelRadioButtonGroup = styled(RadioButtonGroup)`
  /* hide the span tag that gets rendered with the CheckBoxes. */
  & > label > span { display: none; }

  /* remove the margin-right: 12px to space the checkbox and label */
  & > label > div { margin-right: 0px; }
`;

const ViaQuestionCard = styled(QuestionCard)`
  /* Hide the labels for questions except for first on the page */
  &:not(:first-child) > div:first-child {
    /* Keep labels visible for the tutorial question */
    display: ${props => props.tutorial ? undefined : "none"};
  }
`;

const ViaAnswerLabel = ({ children }) => (
  <Box>
    <Text color="green600" size="xsmall" textAlign="center">
      {children}
    </Text>
  </Box>
);

function ViaQuestionComponent({
  onChange = () => undefined,
  question = undefined,
  selected = undefined,
  tutorial = false,
  ...props
}, ref) {
  const { t } = useLocale();

  const [value, setValue] = React.useState(selected || "");

  const handleOnChange = (event) => {
    const answer = parseInt(event.target.value);

    setValue(answer);

    return onChange({
      question: question.number,
      answer,
    });
  };

  if (!question) {
    return null;
  }

  return (
    <ViaQuestionCard
      ref={ref}
      gap="small"
      margin="none"
      width="100%"
      tutorial={tutorial}
      {...props}
    >
      <Grid
        align="end"
        columns={{
          count: 5,
          size: "auto",
        }}
        gap="small"
      >
        <ViaAnswerLabel>
          {t("via.answers.1")}
        </ViaAnswerLabel>
        <ViaAnswerLabel>
          {t("via.answers.2")}
        </ViaAnswerLabel>
        <ViaAnswerLabel>
          {t("via.answers.3")}
        </ViaAnswerLabel>
        <ViaAnswerLabel>
          {t("via.answers.4")}
        </ViaAnswerLabel>
        <ViaAnswerLabel>
          {t("via.answers.5")}
        </ViaAnswerLabel>
      </Grid>
      <FormField
        direction="column"
        margin="none"
        name={question.number.toString()}
        width="100%"
      >
        <NoLabelRadioButtonGroup
          a11yTitle={question.prompt}
          direction="row"
          id={question.number}
          justify="evenly"
          name={question.number.toString()}
          gap="medium"
          onChange={handleOnChange}
          options={options}
          pad={{ vertical: "small" }}
          value={value}
        />
        <Box align="center" pad="small">
          <Text textAlign="center">
            {question.prompt}
          </Text>
        </Box>
      </FormField>
    </ViaQuestionCard>
  );
}

const ViaQuestion = React.forwardRef(ViaQuestionComponent);

ViaQuestion.propTypes = {
  onChange: PropTypes.func,
  question: PropTypes.shape({
    number: PropTypes.number,
    prompt: PropTypes.string,
  }),
  selected: PropTypes.any,
  tutorial: PropTypes.bool,
};

export { ViaQuestion };
