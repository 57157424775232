import React from "react";
import styled from "styled-components";
import {
  GREY,
  GREEN,
  DARK_GREEN
} from "../utils/theme";
import { LAYOUT } from "../utils/zIndex";

const StyledRange = styled.input`
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */
  z-index: ${LAYOUT};

  &:focus {
    outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
  }

  &::-ms-track {
    width: 100%;
    cursor: pointer;

    /* Hides the slider so custom styles can be added */
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    margin-top: -14px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
    border: 2px solid ${DARK_GREEN};
    height: 30px;
    width: 24px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.10);
    border-radius: 5px;
    background: ${GREEN};
    cursor: pointer;
  }

  &::-moz-range-thumb {
    border: 2px solid ${DARK_GREEN};
    height: 30px;
    width: 24px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.10);
    border-radius: 5px;
    background: ${GREEN};
    cursor: pointer;
  }

  &::-ms-thumb {
    border: 2px solid ${DARK_GREEN};
    height: 30px;
    width: 24px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.10);
    border-radius: 5px;
    background: ${GREEN};
    cursor: pointer;
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    background: ${GREY};
  }

  &::-moz-range-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    background: #3071a9;
    border-radius: 1.3px;
    border: 0.4px solid #010101;
  }

  &::-ms-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    border-width: 16px 0;
    color: transparent;
  }
  &::-ms-fill-lower {
    background: #2a6495;
    border: 0.2px solid #010101;
    border-radius: 2.6px;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  }
  &:focus::-ms-fill-lower {
    background: #3071a9;
  }
  &::-ms-fill-upper {
    background: #3071a9;
    border: 0.2px solid #010101;
    border-radius: 2.6px;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  }
  &:focus::-ms-fill-upper {
    background: #367ebd;
  }

  &::-ms-tooltip {
    display: none;
  }

  margin-top: 14px;
  margin-bottom: 14px;
`;

const styledRange = props => (
  <StyledRange type="range" {...props} />
);

export default styledRange;
