import React from "react";
import { Box } from "grommet";
import { Icon } from "@/cloverleaf-ui/components";
import { ButtonLink } from "../../ButtonLink";
import { FADED_WIDTH_TRANSITION } from "../constants";

const SidebarButton = ({ active, icon, label, style, ...rest }) => (
  <ButtonLink
    color={active ? "green700" : "grey700"}
    gap="medium"
    fill="horizontal"
    justify="start"
    primary={active}
    secondary={!active}
    icon={(
      <Box flex={false}>
        <Icon
          fixedWidth
          icon={icon}
          color={active ? "light-1" : "text-sidebar"}
        />
      </Box>
    )}
    label={label}
    logEventProps={{
      elementName: "sidebar-button",
    }}
    style={{
      overflow: "hidden",
      whiteSpace: "nowrap",
      transition: FADED_WIDTH_TRANSITION,
      ...style,
    }}
    {...rest}
  />
);

export { SidebarButton };
