import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  Box,
  Button,
  CheckBoxGroup,
  Text
} from "grommet";
import { Icon } from "@/cloverleaf-ui/components";
import { QuestionCard } from "../components/QuestionCard";
import { useLocale } from "@/lib/hooks";

/**
 * Remove the Label from the CheckBox when rendering our question as buttons
 */
const NoLabelCheckBoxGroup = styled(CheckBoxGroup)`
  /* hide the span tag that gets rendered with the CheckBoxes. */
  & > label > span { display: none; }

  /* remove the margin-right: 12px to space the checkbox and label */
  & > label > div { margin-right: 0px; }
`;

const defaultAnswer = {
  most: {},
  least: {},
};

const isMost = (key, answer) => key === answer?.most?.value;
const isLeast = (key, answer) => key === answer?.least?.value;

function DiscQuestion({
  onChange = () => undefined,
  question = undefined,
  selected = undefined,
}) {
  const { t } = useLocale();

  const initialAnswer = selected || defaultAnswer;
  const initialValue = Object.values(initialAnswer).map(a => a.value);

  const [value, setValue] = React.useState(initialValue);
  const [answer, setAnswer] = React.useState(initialAnswer);

  if (!question) {
    return null;
  }

  const answers = question.answers.map(q => ({
    disabled: answer.most.value && answer.least.value && !isMost(q.descriptionKey, answer) && !isLeast(q.descriptionKey, answer),
    label: q.description,
    value: q.descriptionKey,
  }));

  const handleOnChange = ({ option }) => {
    // Option does not pass the value, so we have to get it.
    const selectedOption = answers.find(a => a.label === option.label);

    let newAnswer = { ...answer };

    if (answer.most.value === selectedOption.value) {
      // Deselect Most
      newAnswer.most = {};
    }
    else if (answer.least.value === selectedOption.value) {
      // Deselect Least
      newAnswer.least = {};
    }
    else if (!answer.most.value) {
      // Select Most
      newAnswer.most = selectedOption;
    }
    else {
      // Select Least
      newAnswer.least = selectedOption;
    }

    setAnswer(newAnswer);

    /**
     * Set value to an array,
     * CheckBoxGroup value is an array of checked options
     */
    setValue(Object.values(newAnswer).map(a => a.value));

    /**
     * Only call onChange for a complete answer
     */
    if (newAnswer.most.value && newAnswer.least.value) {
      return onChange({
        question: question.number,
        answer: newAnswer,
      });
    }
  };

  const handleOnReset = () => {
    setAnswer(defaultAnswer);

    setValue(Object.values(defaultAnswer).map(a => a.value));

    return onChange({
      question: question.number,
      answer: defaultAnswer,
    });
  }

  const promptBackgroundColor = answer.most.value ? "#df6100" : "green500";
  const promptTextKey = answer.most.value ? "disc.disc-question-buttons.least" : "disc.disc-question-buttons.most";

  return (
    <QuestionCard>
      <Box align="center" height="xxsmall">
        {answer.most.value && answer.least.value ? (
          <Button onClick={handleOnReset}>
            <Box direction="column" gap="xxsmall">
              <Icon icon={["far", "check-circle"]} size="2x" />
              <Text>{t("disc.disc-question-buttons.reset")}</Text>
            </Box>
          </Button>
        ) : (
          <>
            <Text>{t("disc.disc-question-buttons.i-am")}</Text>
            <Box
              background={promptBackgroundColor}
              pad="xxsmall"
            >
              <Text weight="bold" uppercase>
                {t(promptTextKey)}
              </Text>
            </Box>
          </>
        )}
      </Box>
      <NoLabelCheckBoxGroup
        align="center"
        direction="row-responsive"
        focusIndicator={false}
        gap="none"
        justify="evenly"
        id={question.number.toString()}
        name={question.number.toString()}
        onChange={handleOnChange}
        options={answers}
        responsive={false}
        value={value}
        valueKey="value"
        wrap
      >
        {(option, { checked }) => {
          let boxProps = {};
          let textProps = {};

          if (checked && isMost(option.value, answer)) boxProps.background = "green600";
          else if (checked && isLeast(option.value, answer)) boxProps.background = "#df6100";

          if (!checked && !answer.most.value) textProps.color = "green600";
          else if (!checked && !answer.least.value) textProps.color = "#df6100";

          const a11yPromptKey = checked ? isMost(option.value, answer) ? "disc.disc-question-buttons.most" : "disc.disc-question-buttons.least" : promptTextKey;

          return (
            <Box
              align="center"
              a11yTitle={`${t("disc.disc-question-buttons.i-am")} ${t(a11yPromptKey)} ${option.label}`}
              border
              elevation="xsmall"
              justify="center"
              pad={{
                horizontal: "medium",
              }}
              margin={{ vertical: "xsmall" }}
              height="xsmall"
              round="xsmall"
              width="small"
              {...boxProps}
            >
              <Text capitalize textAlign="center" {...textProps}>
                {option.label}
              </Text>
            </Box>
          );
        }}
      </NoLabelCheckBoxGroup>
    </QuestionCard>
  );
}

DiscQuestion.propTypes = {
  onChange: PropTypes.func,
  question: PropTypes.shape({
    answers: PropTypes.arrayOf(
      PropTypes.shape({
        description: PropTypes.string,
        descriptionKey: PropTypes.string,
      }),
    ),
    number: PropTypes.number,
  }),
  selected: PropTypes.shape({
    least: PropTypes.shape({}),
    most: PropTypes.shape({}),
  }),
};

export { DiscQuestion };
