import React from "react";
import {
  arrayOf,
  bool,
  func,
  number,
  oneOfType,
  string
} from "prop-types";
import styled from "styled-components";
import {
  DARK_GREY,
  DEFAULT_FONT,
  GREEN,
  GREY,
  MEDIUM_SIZE,
  RED,
  WHITE
} from "../../utils/theme";
import FieldError from "../FieldError";
import { Box, Flex } from "../FlexBox";
import Icon from "../Icon";
import TextButton from "../TextButton";

const StyledInput = styled.input`
  background: none;
  border: none;
  height: 100%;
  width: 100%;
  text-align: ${props => props.textAlign};

  ::placeholder {
    color: ${DARK_GREY};
  }

  &[type=number]::-webkit-inner-spin-button,
  &[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const borderColor = ({ error }) => (error ? RED : GREEN);

const StyledWrapper = styled(Flex)`
  background: ${WHITE};
  border: ${props => (props.noBorder ? "0px" : `1px solid ${GREY};`)};
  border-color: ${props => (props.error ? RED : GREY)};
  border-radius: 5px;
  font-family: ${DEFAULT_FONT};
  font-size: ${MEDIUM_SIZE};
  height: ${props => props.height || "40px"};
  box-sizing: border-box;
  padding: ${props => props.p || "0 10px"};

  &:focus-within {
    border-color: ${borderColor};
    ${props => (props.noBorder
    ? `border-bottom: 1px solid ${borderColor(props)}; border-radius: 0;`
    : "")};
  }

  &:hover {
    ${props => (props.noBorder ? "" : "box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);")}
    ${props => (props.noBorder
    ? `border-bottom: 1px solid ${GREY}; border-radius: 0;`
    : "")};
  }

  ::placeholder {
    color: ${DARK_GREY};
  }
`;

const buildHandleChange = onChange => e => onChange(e.target.value);

const TextInput = React.forwardRef(function TextInput(props, ref) {
  const handleChange = buildHandleChange(props.onChange);

  return (
    <React.Fragment>
      <StyledWrapper
        align="center"
        px={5}
        w={1}
        height={props.height}
        error={props.error}
        noBorder={props.noBorder}
      >
        {props.icon && <Box mr={1}><Icon icon={props.icon} color={DARK_GREY} /></Box>}
        <StyledInput
          ref={ref}
          id={props.id || props.name}
          type={props.type}
          height={props.height}
          textAlign={props.textAlign}
          name={props.name}
          value={props.value}
          defaultValue={props.defaultValue}
          onChange={handleChange}
          placeholder={props.placeholder}
          maxLength={props.maxLength}
          onBlur={props.onBlur}
          onFocus={props.onFocus}
          onDragStart={props.onDragStart}
          onDrop={props.onDrop}
          disabled={props.disabled}
          onKeyPress={props.onKeyPress}
          autoComplete={props.autoComplete}
          autoFocus={props.autoFocus}
          readOnly={props.readOnly}
          data-testid={props.testId}
        />
        {props.onReset && (
          <Box ml={1}>
            <TextButton secondary onClick={props.onReset}>
              <Icon icon="times-circle" color={DARK_GREY} />
            </TextButton>
          </Box>
        )}
      </StyledWrapper>
      {props.error && <Box mt={1}><FieldError error={props.error} /></Box>}
    </React.Fragment>
  );
});

TextInput.propTypes = {
  id: string,
  name: string.isRequired,
  value: string,
  defaultValue: string,
  onChange: func.isRequired,
  placeholder: oneOfType([string, number]),
  maxLength: oneOfType([string, number]),
  error: string,
  disabled: bool,
  type: string,
  onBlur: func,
  onFocus: func,
  onDragStart: func,
  onDrop: func,
  height: string,
  textAlign: string,
  icon: oneOfType([string, arrayOf(string)]),
  onKeyPress: func,
  onReset: func,
  noBorder: bool,
  autoComplete: string,
  autoFocus: bool,
  readOnly: bool,
  testId: string,
};

export default TextInput;
