import styled from "styled-components";
import { withTooltip } from "../hoc/withTooltip";
import { convertHexToRGBA } from "../utils";
import {
  WHITE,
  DEFAULT_FONT,
  DARK_GREY,
  BLACK
} from "../utils/theme";

const StyledButton = styled.button`
  outline: 0;
  ${props => (props.disableHover ? "" : "cursor: pointer")};
  padding: 0 16px;
  height: 30px;
  border: none;
  border-radius: 15px;
  font-family: ${DEFAULT_FONT};
  font-size: 12px;
  font-weight: ${props => props.fontWeight || "normal"};
  background-color: ${props => (!props.toggled ? convertHexToRGBA(props.color, 0.2) : props.color)};
  box-shadow: ${props => (props.toggled ? "0px 1px 4px rgba(0, 0, 0, 0.25)" : "none")};
  color: ${props => (props.toggled ? WHITE : props.color)};
  
  ${props => (props.disableHover ? "" : `
  &:hover {
    background-color: ${props.color};
    color: ${WHITE};
  }
  `)}

  &:disabled {
    background-color: ${convertHexToRGBA(DARK_GREY, 0.2)};
    color: ${BLACK};
  }
`;

const OvalButton = withTooltip(StyledButton);

export default OvalButton;
