import React from "react";
import {
  string,
  bool,
  func
} from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { withTooltip } from "../hoc/withTooltip";
import { iconProp } from "../utils/propTypes";
import {
  WHITE,
  SMALL_SIZE,
  GREY,
  GREEN
} from "../utils/theme";
import { Flex } from "./FlexBox";

const getIconSize = (small, extraLarge) => {
  if (small) {
    return "xs";
  }
  if (extraLarge) {
    return "2x";
  }

  return "lg";
};

const getBackgroundSize = (small, extraLarge) => {
  if (small) {
    return "16px";
  }
  if (extraLarge) {
    return "40px";
  }

  return "30px";
};

const IconBackground = styled(Flex)`
  background-color: ${props => props.color};
  border-radius: ${props => (props.small ? "5px" : "8px")};
  width: ${props => (getBackgroundSize(props.small, props.extraLarge))};
  min-height: ${props => (getBackgroundSize(props.small, props.extraLarge))};
  font-size: ${SMALL_SIZE};
  ${props => (props.pointer ? "cursor: pointer;" : "")}

  &:hover {
    background-color: ${props => (props.onClick ? props.activeColor : props.color)};
  }
`;

function IconWithBackground({
  icon,
  color = GREY,
  small = false,
  extraLarge = false,
  activeColor = GREEN,
  pointer,
  onClick,
}) {
  return (
    <IconBackground justify="center" pointer={pointer} align="center" color={color} activeColor={activeColor} small={small} extraLarge={extraLarge} onClick={onClick}>
      <FontAwesomeIcon icon={icon} color={WHITE} size={getIconSize(small, extraLarge)} style={{ width: "100%" }} />
    </IconBackground>
  );
}

IconWithBackground.propTypes = {
  icon: iconProp.isRequired,
  color: string,
  activeColor: string,
  small: bool,
  extraLarge: bool,
  pointer: bool,
  onClick: func,
};

export default withTooltip(IconWithBackground);
