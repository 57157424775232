import React from "react";
import { arrayOf, func } from "prop-types";
import isEqual from "lodash/fp/isEqual";
import { iconProp } from "../../utils/propTypes";
import { Flex } from "../FlexBox";
import IconRowButton from "./IconRowButton";

function IconRow({ icons, onClick, value }) {
  return (
    <Flex>
      {icons.map(icon => (
        <IconRowButton
          icon={icon}
          key={icon}
          onClick={onClick}
          selected={isEqual(icon, value)}
        />
      ))}
    </Flex>
  );
}

IconRow.propTypes = {
  icons: arrayOf(iconProp).isRequired,
  onClick: func.isRequired,
  value: iconProp.isRequired,
};

export default IconRow;
