import { bindActionCreators } from "@reduxjs/toolkit";
import { addToast } from "./toasts";
import { updateSelect } from "./ui";
import { signout } from "./user";

let toastAction = () => undefined;
let signoutAction = () => undefined;
let setUpdateSelectAction = () => undefined;

export const boundAddToast = (...args) => toastAction(...args);
export const boundSignout = (...args) => signoutAction(...args);
export const boundUpdateSelect = (...args) => setUpdateSelectAction(...args);

const creator = (dispatch) => {
  const actionCreators = bindActionCreators({
    toastAction: addToast,
    signoutAction: signout,
    setUpdateSelectAction: updateSelect,
  }, dispatch);
  /* eslint-disable prefer-destructuring */
  toastAction = actionCreators.toastAction;
  signoutAction = actionCreators.signoutAction;
  setUpdateSelectAction = actionCreators.setUpdateSelectAction;
};

export default creator;
