import React from "react";
import {
  func,
  string,
  node,
  oneOfType,
  bool,
  number
} from "prop-types";
import styled from "styled-components";
import { GREY, DARK_GREY } from "../utils/theme";
import { Flex } from "./FlexBox";
import Text from "./styled/Text";

const Wrapper = styled(Flex)`
  background-color: ${props => props.bgColor};
  border-radius: 5px;
  cursor: ${props => (props.onClick ? "pointer" : "")};
`;

export default class BoxedInfo extends React.PureComponent {
  static propTypes = {
    label: string.isRequired,
    bgColor: string,
    labelColor: string,
    onClick: func,
    children: oneOfType([node, string]).isRequired,
    disabled: bool,
    justify: string,
    height: number,
  }

  static defaultProps = {
    labelColor: DARK_GREY,
    onClick: undefined,
    bgColor: "none",
    disabled: false,
    justify: "center",
    height: undefined,
  }

  render() {
    const {
      label, bgColor, labelColor, onClick, children, disabled, justify, height,
    } = this.props;

    return (
      <Wrapper
        onClick={!disabled ? onClick : () => undefined}
        column
        align="center"
        justify={justify}
        w={90}
        h={height || 90}
        bgColor={disabled ? GREY : bgColor}
      >
        <Text headline uppercase condensed noMargin color={labelColor}>{children}</Text>
        <Text center uppercase condensed noMargin color={labelColor}>{label}</Text>
      </Wrapper>
    );
  }
}
