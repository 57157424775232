import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  RadioButtonGroup,
  Text
} from "grommet";
import { QuestionCard } from "../components/QuestionCard";

function SixteenTypesQuestion({
  onChange = () => undefined,
  question = undefined,
  selected = undefined,
}) {
  const [value, setValue] = React.useState(selected || "");

  const handleOnChange = (event) => {
    setValue(event.target.value);

    return onChange({
      question: question.number,
      answer: event.target.value,
    });
  };

  if (!question) {
    return null;
  }

  const answers = [
    {
      label: question.answerA,
      value: "A",
    }, {
      label: question.answerB,
      value: "B",
    },
  ];

  return (
    <QuestionCard prompt={question.prompt}>
      <RadioButtonGroup
        direction="row-responsive"
        name={question.number.toString()}
        gap="medium"
        onChange={handleOnChange}
        options={answers}
        value={value}
      >
        {(option, { checked, focus }) => {
          let props = { border: true };

          if (checked) props.background = "green600";
          else if (focus) props.background = "light-2";
          else props.background = "white";

          return (
            <Box
              align="center"
              elevation="xsmall"
              justify="center"
              pad={{
                horizontal: "medium",
              }}
              height="xsmall"
              round="xsmall"
              width="small"
              {...props}
            >
              <Text textAlign="center">{option.label}</Text>
            </Box>
          );
        }}
      </RadioButtonGroup>
    </QuestionCard>
  );
}

SixteenTypesQuestion.propTypes = {
  onChange: PropTypes.func,
  question: PropTypes.shape({
    answerA: PropTypes.string,
    answerB: PropTypes.string,
    number: PropTypes.number,
    prompt: PropTypes.string,
  }),
  selected: PropTypes.any,
};

export { SixteenTypesQuestion };
