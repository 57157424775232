import React from "react";
import Link from "next/link";
import { openConsentManager } from "@segment/consent-manager";
import {
  Anchor,
  Box,
  Footer as GrommetFooter,
  Image,
  Text,
} from "grommet";
import LanguageChooser from "../../../../components/composite/Internationalization/LanguageChooser";
import { useLocale, useAuth } from "@/lib/hooks";

function Footer(props) {
  const authenticated = useAuth();

  const { t } = useLocale();

  return (
    <GrommetFooter background="#4A4A4A" fill="horizontal" margin="none" pad="xsmall" {...props}>
      <Box>
        <Text size="xsmall">
          Copyright &reg; {new Date().getUTCFullYear()} Cloverleaf, Inc.
        </Text>
        <Link href="https://cloverleaf.me/terms" passHref legacyBehavior>
          <Anchor color="text" size="xsmall" target="_blank">
            {t("footer.terms-of-service")}
          </Anchor>
        </Link>
        <Box direction="row" wrap>
          <Link href="https://cloverleaf.me/privacy" passHref legacyBehavior>
            <Anchor color="text" margin={{ right: "xsmall" }} size="xsmall" target="_blank">
              {t("footer.privacy-policy")}
            </Anchor>
          </Link>
          {!authenticated && (
            <Anchor color="text" onClick={openConsentManager} size="xsmall">
              {t("footer.data-collection")}
            </Anchor>
          )}
        </Box>
      </Box>
      <LanguageChooser />
      <Box width={{ max: "150px" }}>
        <Image
          fit="contain"
          alt="Powered by Cloverleaf"
          fill={false}
          src="https://cloverleaf-app-publicfiles.s3.amazonaws.com/Cloverleaf-Banner-Inverse.png"
        />
      </Box>
    </GrommetFooter>
  );
}
export { Footer };
