import React from "react";
import {
  string,
  bool,
  oneOfType,
  number,
  node
} from "prop-types";
import styled from "styled-components";
import {
  WHITE,
  BLACK,
  HEADLINE_SIZE,
  LARGE_SIZE
} from "../utils/theme";
import { Flex, Box } from "./FlexBox";
import Heading from "./Heading";
import TextLabel from "./styled/TextLabel";

const Wrapper = styled(Flex)`
  flex-direction: ${props => (props.flip ? "row-reverse" : "row")};
  background-color: ${props => props.color || BLACK};
  padding: 1px;
  border-radius: 5px;
`;

const TitleBox = styled(Box)`
  color: ${WHITE};
  background-color: ${props => props.color || BLACK};
  padding: 4px 8px;
  border-radius: 5px;
  user-select: none;
  width: max-content;
`;

const SubtitleBox = styled(Box)`
  color: ${props => props.color || BLACK};
  background-color: ${WHITE};
  padding: 4px 8px;
  border-radius: ${props => (props.flip ? "5px 0 0 5px" : "0 5px 5px 0")};
  user-select: none;
  width: max-content;
`;

function Title({
  children, subtitle, small, flip, color,
}) {
  return (
    <Wrapper w="fit-content" flip={flip} color={color}>
      <TitleBox color={color}>
        <Heading
          uppercase
          condensed
          noMargin
          fontSize={small ? LARGE_SIZE : HEADLINE_SIZE}
          color={WHITE}
        >
          {children}
        </Heading>
      </TitleBox>
      {!!subtitle && (
        <SubtitleBox flip={flip} color={color}>
          <TextLabel uppercase style={{ fontSize: small ? LARGE_SIZE : HEADLINE_SIZE }}>
            {subtitle}
          </TextLabel>
        </SubtitleBox>
      )}
    </Wrapper>
  );
}

Title.propTypes = {
  children: node.isRequired,
  subtitle: oneOfType([string, number]),
  color: string,
  flip: bool,
  small: bool,
};

export default Title;
