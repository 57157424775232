import { useContext, useEffect } from "react";
import { useRouter } from "next/router";
import { gql, useQuery } from "@apollo/client";
import { EmployeeToolsContext } from "@/components/composite/EmployeeToolbox";

const USER_EMAIL_QUERY = gql`
  query userEmailQuery {
    currentUser {
      id
      organization {
        id
      }
    }
  }
`;

const CLOVERLEAF_ORG_ID_PRODUCTION = "20";
const CLOVERLEAF_ORG_ID_DEVELOPMENT = "1";

/**
 * Note, please do not use this method. It is meant to be an internal check only for
 * this hook and the EmployeeToolbox. It is not meant to be used in any other context.
 * Use the useIsCloverleafEmployee hook instead, which will also check for demo mode.
 */
function useIsCloverleafEmployeeOrganizationCheckOnly_DO_NOT_USE_THIS() {
  const { data } = useQuery(USER_EMAIL_QUERY, {
    // Query may be executed outside authentication
    fetchPolicy: "cache-only",
  });

  if (process.env.NODE_ENV === "production") {
    return data?.currentUser?.organization?.id === CLOVERLEAF_ORG_ID_PRODUCTION;
  }

  if (process.env.NODE_ENV === "development") {
    return data?.currentUser?.organization?.id === CLOVERLEAF_ORG_ID_DEVELOPMENT;
  }

  return false;
}

function useIsCloverleafEmployee() {
  const isCloverleafEmployee = useIsCloverleafEmployeeOrganizationCheckOnly_DO_NOT_USE_THIS();
  const { isDemoMode } = useContext(EmployeeToolsContext);

  return isCloverleafEmployee && !isDemoMode;
}

function useRedirectNonCloverleafEmployee() {
  const router = useRouter();

  const isCloverleafEmployee = useIsCloverleafEmployee();

  useEffect(() => {
    if (isCloverleafEmployee === false) {
      router.push("/my-dashboard");
    }
  }, [router, isCloverleafEmployee]);
}

export {
  useIsCloverleafEmployee,
  useIsCloverleafEmployeeOrganizationCheckOnly_DO_NOT_USE_THIS,
  useRedirectNonCloverleafEmployee,
}
