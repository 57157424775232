import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Meter,
  Text
} from "grommet";

function ProgressBar({
  id = "progress-bar",
  label = undefined,
  labelProps = undefined,
  max = 100,
  size = "medium",
  value = 0,
  color = "brand",
  ...props
}) {
  const contents = (
    <Meter
      aria-valuenow={value}
      aria-valuemin="0"
      aria-valuemax={max}
      color={color}
      id={id}
      role="progressbar"
      round
      max={max}
      value={value}
      size={size}
      {...props}
    />
  );

  const renderedLabel = typeof label !== "string" ? label : (
    <Text as="label" htmlFor={id} {...labelProps}>
      {label}
    </Text>
  );

  return (
    <Box direction="column" gap="small" fill="horizontal">
      {renderedLabel}
      {contents}
    </Box>
  );
}

ProgressBar.propTypes = {
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  labelProps: PropTypes.object,
  size: PropTypes.oneOf([
    "xsmall",
    "small",
    "medium",
    "large",
    "xlarge",
    "full",
  ]),
  max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
};

export { ProgressBar };
