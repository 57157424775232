import styled from "styled-components";
import { GREY } from "../utils/theme";
import Icon from "./Icon";

const FadeCheckmark = styled(Icon).attrs({ size: "lg", icon: ["far", "check-circle"] })`
  color: ${props => props.color || GREY};
  will-change: color;
  transition: color 0.1s ease-in-out;
`;

export default FadeCheckmark;
