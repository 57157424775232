import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Spinner,
  Text
} from "grommet";

/**
 * Icon is defined under spinner properties in @/cloverleaf-ui/theme
 * @param {string} size of the spinner, defined in theme
 * @returns Spinner component
 */
function LoadingIndicator({
  size = "medium",
  style = {},
  text = undefined,
  ...props
}) {
  if (typeof text !== "undefined") {
    return (
      <Box
        align="center"
        alignSelf="center"
        gap="medium"
        margin={{ vertical: "large" }}
        width="medium"
      >
        <Spinner
          data-testid="loading-indicator"
          size={size}
          {...props}
          // TODO: Dig into why z-index is necessary here to see spinner color
          style={{ ...style, position: "relative", zIndex: 1 }}
        />
        <Text textAlign="center" size={size}>
          {text}
        </Text>
      </Box>
    );
  }

  return (
    <Spinner
      data-testid="loading-indicator"
      size={size}
      {...props}
      // TODO: Dig into why z-index is necessary here to see spinner color
      style={{ margin: "0 auto", position: "relative", zIndex: 1, ...style }}
    />
  );
}
LoadingIndicator.propTypes = {
  size: PropTypes.oneOf([
    "small",
    "medium",
    "large",
    "xlarge",
  ]),
  style: PropTypes.shape(),
  text: PropTypes.string,
};

export { LoadingIndicator };
