import React from "react";
import PropTypes from "prop-types";
import { FormField, TextInput } from "grommet";
import { validate as validationTypes } from "@/cloverleaf-ui/utils";
import { useLocale } from "@/lib/hooks";

const EmailFormField = React.forwardRef(function EmailFormField({
  autoComplete = undefined,
  autoFocus = false,
  disabled = false,
  hidden = false,
  inputProps = {},
  label = undefined,
  name = "email",
  onChange = undefined,
  validate = [],
  value = undefined,
  ...props
}, ref) {
  const { t } = useLocale();

  return (
    <FormField
      disabled={disabled}
      htmlFor="email"
      label={label || t("email")}
      name={name}
      validate={[validationTypes.email, ...validate]}
      /**
        Accomodate cases to visually hide username field for accessibility
        https://www.chromium.org/developers/design-documents/form-styles-that-chromium-understands/
      */
      style={hidden ? { display: "none" } : undefined}
      {...props}
    >
      <TextInput
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        disabled={disabled}
        id="email"
        name={name}
        onChange={onChange}
        ref={ref}
        type="email"
        value={value}
        {...inputProps}
      />
    </FormField>
  )
});

EmailFormField.propTypes = {
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
  inputProps: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  validate: PropTypes.array,
  value: PropTypes.string,
};

export { EmailFormField };
