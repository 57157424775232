import { combineReducers } from "@reduxjs/toolkit";
import assessmentsReducer from "./assessments";
import toastsReducer from "./toasts";
import uiReducer from "./ui";

const reducer = combineReducers({
  assessments: assessmentsReducer,
  toasts: toastsReducer,
  ui: uiReducer,
});

export default reducer;
