import { bool } from "prop-types";
import styled from "styled-components";
import {
  GREEN,
  RED,
  DARK_GREY,
  BLACK,
  WHITE,
  textStyleMixin,
} from "../../utils/theme";

const color = ({
  primary = false,
  secondary = false,
  negative = false,
  white = false,
}) => {
  if (primary) {
    return GREEN;
  }
  if (secondary) {
    return DARK_GREY;
  }
  if (negative) {
    return RED;
  }
  if (white) {
    return WHITE;
  }

  return BLACK;
};

const hoverColor = ({
  hoverColor,
  negativeHover = false,
  primary = false,
  secondary = false,
  negative = false,
  white = false,
  disabled = false,
  noHover = false,
}) => {
  if (hoverColor) {
    return hoverColor;
  }

  if (negativeHover) {
    return RED;
  }
  if (primary || secondary || negative) {
    return BLACK;
  }
  if (white) {
    return WHITE;
  }
  if (disabled) {
    return color({ primary, secondary, negative, white });
  }
  if (noHover) {
    return "";
  }

  return GREEN;
};

const StyledA = styled.a`
  font-size: ${(props) => (props.small ? "12px" : "inherit")};
  text-decoration: ${(props) => (props.underline ? "underline" : "none")};
  color: ${color};
  cursor: pointer;

  &:hover {
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    color: ${hoverColor};
  }

  &:active {
    text-decoration: ${(props) => (props.underline ? "none" : "inherit")};
  }
  ${textStyleMixin}
`;

StyledA.propTypes = {
  primary: bool,
  secondary: bool,
  white: bool,
  negative: bool,
  negativeHover: bool,
  underline: bool,
  small: bool,
  disabled: bool,
};

export default StyledA;
