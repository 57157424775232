import PropTypes from "prop-types";

let PropType = {};

if (process.env.NODE_ENV !== "production") {
  PropType = {
    insight: PropTypes.shape({
      id: PropTypes.string,
      message: PropTypes.string,
      personality: PropTypes.string,
      target: PropTypes.string,
      type: PropTypes.string,
    }),
    onComplete: PropTypes.func,
    onLike: PropTypes.func,
    onDislike: PropTypes.func,
    onFeedback: PropTypes.func,
    successMessage: PropTypes.string,
    /**
     * @deprecated - use onComplete instead
     */
    onAnimationComplete: PropTypes.func,
  };
}

export const InsightPropTypes = PropType;
