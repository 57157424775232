import React from "react";
import {
  string,
  bool,
  node,
  func
} from "prop-types";
import { withTooltip } from "../hoc/withTooltip";
import StyledButton from "./styled/Button";
const debug = require("debug")("cloverleaf:Button");

function Button({
  children,
  label = "",
  type = "button",
  disabled = false,
  name = "",
  onClick = () => debug("Button does not have an onClick prop defined"),
  primary = false,
  negative = false,
  clear = false,
  small = false,
  block = false,
  className = undefined,
  ...props
}) {
  return (
    <StyledButton
      type={type}
      disabled={disabled}
      name={name}
      primary={primary}
      negative={negative}
      clear={clear}
      small={small}
      block={block}
      className={className}
      {...props}
      onClick={(e) => {
        if (typeof onClick !== "function") {
          // eslint-disable-next-line no-console
          console.warn("ui/Button was not passed an onClick functon!");
        }
        else {
          onClick(e);
        }
        e.stopPropagation(); // Don't bubble up the event.
      }}
    >
      {children || label}
    </StyledButton>
  );
}

Button.propTypes = {
  children: node,
  label: string,
  type: string,
  disabled: bool,
  name: string,
  onClick: func,
  className: string,

  // Style props
  primary: bool,
  negative: bool,
  clear: bool,
  small: bool,
  block: bool,
};

export default withTooltip(Button);
