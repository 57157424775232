import styled from "styled-components";
import {
  CONDENSED_FONT,
  textStyleMixin,
  BLACK,
  LARGE_SIZE
} from "../../utils/theme";

const Label = styled.label.attrs(props => ({
  font: props.font || CONDENSED_FONT,
  color: props.color || BLACK,
}))`
  font-size: ${LARGE_SIZE};
  display: flex;
  flex-wrap: wrap;
  ${textStyleMixin}
`;

export default Label;
