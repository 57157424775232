import React from "react";
import {
  string,
  arrayOf,
  shape,
  oneOfType,
  func,
  bool
} from "prop-types";
import withOutsideClick from "../../hoc/withOutsideClick";
import { iconProp } from "../../utils/propTypes";
import Icon from "../Icon";
import TextButton from "../TextButton";
import MenuOptions from "./MenuOptions";

const defaultRenderComponent = toggleMenu => (
  <TextButton secondary onClick={toggleMenu}>
    <Icon icon={["fas", "ellipsis-h"]} />
  </TextButton>
);

function MenuComponent({
  options, showMenu = false, toggleMenu, renderComponent = defaultRenderComponent, style,
}) {
  return (
    <React.Fragment>
      {renderComponent(toggleMenu, showMenu)}
      {showMenu && <MenuOptions options={options} onClick={toggleMenu} style={style} />}
    </React.Fragment>
  );
}

MenuComponent.propTypes = {
  options: arrayOf(
    oneOfType([
      func,
      shape({
        label: string.isRequired,
        icon: iconProp,
        onClick: func.isRequired,
        Component: func,
      }),
    ]),
  ).isRequired,
  showMenu: bool,
  toggleMenu: func,
  renderComponent: func,
  style: shape({}),
};

const MenuWithOutsideClick = withOutsideClick(MenuComponent);

class Menu extends React.PureComponent {
  static propTypes = {
    options: arrayOf(
      oneOfType([
        func,
        shape({
          label: string.isRequired,
          icon: iconProp,
          onClick: func.isRequired,
          Component: func,
          disabled: bool,
        }),
      ]),
    ).isRequired,
    renderComponent: func,
    style: shape({}),
  }

  static defaultProps = {
    renderComponent: undefined,
    style: undefined,
  };

  state = {
    showMenu: false,
  }

  toggleMenu = () => {
    this.setState({
      showMenu: !this.state.showMenu,
    });
  }

  outsideClick = () => {
    if (this.state.showMenu) {
      return this.toggleMenu();
    }

    return undefined;
  }

  render() {
    return (
      <MenuWithOutsideClick
        onOutsideClick={this.outsideClick}
        toggleMenu={this.toggleMenu}
        showMenu={this.state.showMenu}
        options={this.props.options}
        renderComponent={this.props.renderComponent}
        style={this.props.style}
      />
    );
  }
}

export default Menu;
