import React from "react";
import PropTypes from "prop-types";
import { Organization, User } from "grommet-icons";
import { validate } from "@/cloverleaf-ui/utils";
import { Avatar, Chip } from "../../atoms";

function Persona({
  // @todo use case
  // eslint-disable-next-line unused-imports/no-unused-vars
  index = undefined,
  value = undefined,
  organization = undefined,
  ...props
}) {
  let error;
  let icon;
  let label;

  if (value?.fullName) {
    label = value.fullName;
  }

  if (value?.avatar) {
    icon = <Avatar user={value} size="xsmall" />;
  }

  // If value is just a string or email...
  if (!label && (typeof value === "string" || value?.email)) {
    label = value?.email || value;

    // ...it should be a valid email
    if (validate.email(label)) {
      error = "Invalid Email";
    }
    // Set icon based on organization whitelist
    else if (organization?.whitelist?.enabled) {
      if (organization?.whitelist?.whitelistDomains?.length > 0
        // Test at least one whitelist alias matches user input
        // TODO: Do better; alias "ups" would false-match value "pullups@domain.com"
        && organization.whitelist.whitelistDomains.some(alias => label.includes(alias))) {
        icon = <Organization size="small" />;
      }
      else {
        icon = <User size="small" />;
      }
    }
  }

  if (!label) {
    label = "Invalid";
    error = "Unknown Input";
  }

  return (
    <Chip
      error={error}
      icon={icon}
      label={label}
      margin="xxsmall"
      key={label}
      {...props}
    />
  );
}

Persona.propTypes = {
  index: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      avatar: PropTypes.string,
      email: PropTypes.string,
      fullName: PropTypes.string,
    }),
  ]),
  organization: PropTypes.shape({
    alias: PropTypes.string,
    bannerLogo: PropTypes.string,
    name: PropTypes.string,
    ssoUrl: PropTypes.string,
    whitelist: PropTypes.shape({
      enabled: PropTypes.bool,
      whitelistDomains: PropTypes.array
    }),
  }),
};

export { Persona };
