import React from "react";
import { useRouter } from "next/router";
import { ConsentManager } from "@segment/consent-manager";
import inRegions from "@segment/in-regions";
import { SEGMENT_PUBLIC_WRITE_KEY, SEGMENT_WRITE_KEY } from "../../../lib/constants";
import { DARK_GREEN } from "../../utils/theme";
import { useAuth } from "@/lib/hooks";

const CloseBehavior = {
  ACCEPT: "accept", // assume across all categories
  DENY: "deny", // deny across all categories
  DISMISS: "dismiss", // just close the banner and do nothing
};

const DestinationBehavior = {
  IMPLY: "imply", // follow the setting for the given category
  DISABLE: "disable", // default to off for new destinations,
  ENABLE: "enable", // default to on for new destinations
};

// For non-restricted users, default everything on.
const defaultPreferences = {
  advertising: true,
  marketingAndAnalytics: true,
  functional: true,
};

// CA defaults exclude advertising
const caDefaultPreferences = {
  advertising: false,
  marketingAndAnalytics: true,
  functional: true,
};

// EU defaults to not tracking anything
const euDefaultPreferences = {
  advertising: false,
  marketingAndAnalytics: false,
  functional: false,
};

const bannerContent = (
  <span>
    We use cookies (and other similar technologies) to collect data to improve your experience on
    our site. By using our website, you’re agreeing to the collection of data as described in our{" "}
    <a
      href="https://cloverleaf.me/privacy"
      target="_blank"
      rel="noopener noreferrer"
    >
      Website Data Collection Policy
    </a>
    .
  </span>
);

const bannerSubContent = (
  <span>
    You can manage your preferences here!
  </span>
);

const preferencesDialogTitle = "Website Data Collection Preferences";

const preferencesDialogContent = (
  <div>
    <p>
      Segment uses data collected by cookies and JavaScript libraries to improve your browsing
      experience, analyze site traffic, deliver personalized advertisements, and increase the
      overall performance of our site.
    </p>
    <p>
      By using our website, you’re agreeing to our{" "}
      <a
        href="https://cloverleaf.me/privacy"
        target="_blank"
        rel="noopener noreferrer"
      >
        Privacy Policy
      </a>
      .
    </p>
    <p>
      The table below outlines how we use this data by category. To opt out of a category of data
      collection, select “No” and save your preferences.
    </p>
  </div>
);

const cancelDialogTitle = "Are you sure you want to cancel?";

const cancelDialogContent = (
  <div>
    Your preferences have not been saved. By continuing to use our website, you’re agreeing to our{" "}
    <a
      href="https://cloverleaf.me/privacy"
      target="_blank"
      rel="noopener noreferrer"
    >
      Privacy Policy
    </a>
    .
  </div>
);

function ConsentBanner() {
  const router = useRouter();
  const isTrialRoute = router.pathname.includes("trial");
  const WRITE_KEY_TO_USE = isTrialRoute ? SEGMENT_PUBLIC_WRITE_KEY : SEGMENT_WRITE_KEY;
  const [hideConsentManager, setHideConsentManager] = React.useState(true);

  const authenticated = useAuth();

  React.useEffect(() => {
    // Explicitly checking query resolved from cache with currentUser === null
    if (!authenticated) {
      setHideConsentManager(false);
    }
  }, [authenticated]);

  if (hideConsentManager) {
    return null;
  }

  const inCA = inRegions(["CA"]);
  const inEU = inRegions(["EU"]);
  // const inCA = () => true; // FOR TESTING, comment out the actual inRegions check, use this to force the region.
  // const inEU = () => true; // FOR TESTING, comment out the actual inRegions check, use this to force the region.
  const shouldRequireConsent = () => inEU() || inCA();

  /* eslint-disable no-nested-ternary */
  const closeBehavior = inCA()
    ? () => caDefaultPreferences
    : inEU()
      ? CloseBehavior.DENY
      : CloseBehavior.ACCEPT;

  const initialPreferences = inCA()
    ? caDefaultPreferences
    : inEU()
      ? euDefaultPreferences
      : defaultPreferences;

  return (
    <ConsentManager
      writeKey={WRITE_KEY_TO_USE}
      otherWriteKeys={isTrialRoute ? [SEGMENT_WRITE_KEY] : []}
      shouldRequireConsent={() => shouldRequireConsent()}
      bannerContent={bannerContent}
      bannerSubContent={bannerSubContent}
      bannerBackgroundColor={DARK_GREEN}
      preferencesDialogTitle={preferencesDialogTitle}
      preferencesDialogContent={preferencesDialogContent}
      cancelDialogTitle={cancelDialogTitle}
      cancelDialogContent={cancelDialogContent}
      closeBehavior={closeBehavior}
      initialPreferences={initialPreferences}
      defaultDestinationBehavior={DestinationBehavior.IMPLY}
      bannerActionsBlock={true}
    />
  );
}

export default ConsentBanner;
