import React from "react";
import PropTypes from "prop-types";
import Link from "next/link";
import {
  Anchor,
  Box,
  Stack,
} from "grommet";
import { themeStyle } from "@/cloverleaf-ui/theme";
import { Icon } from "../Icon";

function IconWithCircleBackground({ backgroundColor, icon }) {
  return (
    <Stack anchor="center">
      <Icon
        icon="circle"
        color={backgroundColor}
        style={{ fontSize: themeStyle.icon.size.medium }}
      />
      <Icon icon={icon} color="white" size="sm" />
    </Stack>
  );
}

IconWithCircleBackground.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  icon: PropTypes.any.isRequired,
};

function Step({
  background = "transparent",
  href = undefined,
  icon = undefined,
  isActive = false,
  isCompleted = false,
  label,
  onClick = undefined,
  ...props
}) {
  let buttonProps = {
    style: {
      whiteSpace: "nowrap",
    },
  };
  let iconProps = {};

  // Current stepper position
  if (isActive) {
    buttonProps = {
      disabled: true,
      color: "grey800",
      weight: "bolder",
      // Overwrite button's disabled state opacity
      style: {
        ...buttonProps.style,
        opacity: "1",
      },
    };

    iconProps = {
      backgroundColor: "green600",
    };
  }

  const AnchorComponent = React.forwardRef(function AnchorComp(linkProps, ref) {
    return (
      <Anchor
        ref={ref}
        color="green600"
        gap="xsmall"
        href={href}
        onClick={onClick}
        icon={(
          <IconWithCircleBackground
            backgroundColor="grey600"
            icon={isCompleted ? ["fa", "check"] : icon}
            {...iconProps}
          />
        )}
        size="medium"
        label={label}
        {...buttonProps}
        {...props}
        {...linkProps}
      />
    )
  });

  if (href?.pathname) {
    return (
      <Box align="center" background={background}>
        <Link
          href={href}
          passHref
          legacyBehavior
        >
          <AnchorComponent />
        </Link>
      </Box>
    );
  }

  return (
    <Box align="center" background={background}>
      <AnchorComponent />
    </Box>
  );
}

Step.propTypes = {
  background: PropTypes.any,
  label: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  isActive: PropTypes.bool,
  isCompleted: PropTypes.bool,
  onClick: PropTypes.func,
  href: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape(),
  ]),
};

export { Step };
