import { css } from "styled-components";
import { convertHexToRGBA } from ".";

/* Colors */
export const GREEN_ALPHA = "#DCF7E3";
export const GREEN = "#009E52";
export const DARK_GREEN_ALPHA = "#CBEED8";
export const DARK_GREEN = "#009E52";
export const DARKEST_GREEN = "#00713A";
export const RED = "#DF6100";
export const DARK_RED = "#B65001";
export const WHITE = "#FFFFFF";
export const BLACK = "#000000";
export const GREY = convertHexToRGBA("#000000", 0.2); // Previously #CCCCCC
export const GREY_OPAQUE = "#CCCCCC";
export const LIGHT_GREY = "#EEEEEE";
export const DARK_GREY = "#9B9B9B";
export const DARKEST_GREY = "#4A4A4A";
export const BLUE = "#008299";
export const LIGHT_BLUE = "#CCEBF1";
export const DARK_BLUE = "#324544";
export const YELLOW = "#FFE25F";
export const GREY_GREEN = "#EAF2F2";
export const PURPLE = "#8B14C4";
export const DARK_PURPLE = "#510077";

/* Gradients */
export const greenBlueToBottomRight = `linear-gradient(to bottom right, ${GREEN}, ${BLUE})`;

export const ASSESSMENT = {
  GREEN: "#009E52",
  RED: "#C45500",
  YELLOW: "#F7C247",
  BLUE: "#009CB8",
  VIA: {
    COURAGE: "#C45500",
    TRANSCENDENCE: "#F7C247",
    WISDOM: "#009E52",
    TEMPERANCE: "#324544",
    HUMANITY: "#510077",
    JUSTICE: "#009CB8",
  },
  INSTINCTIVEDRIVES: {
    VERIFY: "#510077",
    AUTHENTICATE: "#00B85F",
    COMPLETE: "#CD4097",
    IMPROVISE: "#F7C247",
  },
  ENERGYRHYTHM: {
    STARTER: "#F6C24F",
    PACER: "#DD6230",
    ANCHOR: "#289CB5",
  },
  // TODO: Are these the final colors?
  STRENGTHSCOPE: {
    THINKING: "#009CB8",
    EXECUTION: "#CD4097",
    RELATIONAL: "#F06D3A",
    EMOTIONAL: "#00B85F",
  },
};

/* Fonts */
export const DEFAULT_FONT = "'Roboto', sans-serif";
export const CONDENSED_FONT = "'Roboto Condensed', sans-serif";

export const SMALL_SIZE = "0.625rem"; // 10px
export const MEDIUM_SIZE = "0.875rem"; // 14px
export const LARGE_SIZE = "1rem"; // 16px
export const EXTRA_LARGE = "1.25rem"; // 20px
export const EXTRA_EXTRA_LARGE = "1.625rem"; // 26px
export const HEADLINE_SIZE = "2rem"; // 32px
export const HUGE_SIZE = "2.6rem";

/* Media Queries */
export const TWO_COLUMN_WIDTH = "1370px";
export const ONE_COLUMN_WIDTH = "954px";

export const NAVBAR_HEIGHT = "44px";

const getFontWeight = ({ bold, thin, fontWeight }) => {
  if (fontWeight) {
    return fontWeight;
  }
  if (bold) {
    return "bolder";
  }
  if (thin) {
    return "lighter";
  }

  return "normal";
};

const getFontSize = ({
  small, medium, large, extraLarge, headline, xxl,
}) => {
  if (small) {
    return SMALL_SIZE;
  }
  if (medium) {
    return MEDIUM_SIZE;
  }
  if (large) {
    return LARGE_SIZE;
  }
  if (extraLarge) {
    return EXTRA_LARGE;
  }

  if (xxl) {
    return EXTRA_EXTRA_LARGE;
  }

  if (headline) {
    return HEADLINE_SIZE;
  }

  return "";
};

const getTextTransform = ({
  uppercase, capitalize,
}) => {
  if (uppercase) {
    return "uppercase";
  }
  if (capitalize) {
    return "capitalize";
  }

  return "";
};

/* Mixing for adding general text props */
export const textStyleMixin = css`
  color: ${props => (props.color ? props.color : "")};
  text-transform: ${props => getTextTransform(props)};
  text-align: ${props => (props.center ? "center" : "")};
  font-family: ${props => (props.font || (props.condensed ? CONDENSED_FONT : DEFAULT_FONT))};
  font-size: ${props => getFontSize(props)};
  text-align: ${props => (props.center ? "center" : "left")};
  font-weight: ${props => getFontWeight(props)};
  font-style: ${props => (props.italic ? "italic" : "normal")};
  letter-spacing: ${props => (props.letterSpacing || "0")};
  white-space: ${props => (props.whiteSpace ? props.whiteSpace : "")};
  text-overflow: ${props => (props.textOverflow ? props.textOverflow : "")};
  user-select: ${props => (props.noSelect ? "none" : "")};
  margin: ${props => (props.noMargin ? "0" : "")};
`;
