import {
  useMutation,
  useQuery,
  gql
} from "@apollo/client";

const USER_CONFIG_QUERY = gql`
  query userConfigQuery {
    currentUser {
      id
      configuration {
        id
        assessmentName
        configurationId
        onDashboard
        name
        setting
        type
      }
    }
  }
`;

const UPDATE_USER_CONFIG_MUTATION = gql`
  mutation updateUserConfiguration($configurationId: String!, $setting: ConfigurationSettingEnum!) {
      updateUserConfiguration(configurationId: $configurationId, setting: $setting) {
        user {
          id
          configuration {
            id
            configurationId
            name
            setting
            assessmentName
            type
            onDashboard
          }
          stateData {
            componentPreferences {
              userId
              viewType
              preferences {
                componentId
                componentRank
                collapsed
                isCollapsible
                colSpan
              }
            }
          }
        }
      }
    }
`;

/**
 * @returns data = userConfiguration where onDashboard is true
 */
const useUserConfiguration = () => {
  const { data, loading, error } = useQuery(USER_CONFIG_QUERY, {
    fetchPolicy: "cache-and-network",
  });

  const userConfiguration = data?.currentUser?.configuration?.filter(config => !!config.onDashboard);

  const [
    updateUserConfiguration,
  ] = useMutation(UPDATE_USER_CONFIG_MUTATION);

  const handleUpdateUserConfiguration = ({
    configurationId,
    setting,
  }) => updateUserConfiguration({
    variables: {
      configurationId,
      setting,
    },
  });

  return {
    data,
    error,
    loading,
    userConfiguration,
    handleUpdateUserConfiguration,
  };
};

export { useUserConfiguration };
