// =CONCATENATE("export const ", A1, " = ", "`", C1, "`;")

/* BEGIN - General Strings */
export const EMAIL = "Correo electrónico";
export const NEXT = "Siguiente";
export const BACK = "Volver";
export const COMPANY = "Compañía";
export const ROLE = "Rol";
export const CANCEL = "Cancelar";
export const SAVE = "Guardar";
export const TAKE_ASSESSMENT = "Haga la evaluación";
export const MANAGER = "Un(a) gerente.";
export const DOMINANCE = "Dominante";
export const INFLUENCE = "Influyente";
export const STEADINESS = "Estable";
export const CONSCIENTIOUSNESS = "Concienzudo";
export const COMPLIANCE = "Cumplimiento";
export const HELPFUL_QUESTION = "¿Te fue útil?";
/* END - General Strings */

export const INVALID_FORM_TOOLTIP = "Complete toda la información para continuar.";
export const INVALID_EMAIL_FORMAT = "Formato de correo no válido.";
export const REQUIRED = "Requerido";
export const PASSWORD_ERROR = "La contraseña debe tener 8 caracteres e incluir letras mayúsculas y minúsculas y un número.";
export const ME = "YO";
export const TEAM = "EQUIPO";
export const START_TYPING = "Comience a escribir...";
export const SETTINGS = "Configuraciones";
export const ENTER_TO_ADD = "Presione Enter para Agregar.";
export const VIEW_PROFILE = "Ver Perfil.";
export const ROLES = "Roles";
export const STRENGTHS = "Fortalezas";
export const CULTURE = "Cultura";
export const CULTURE_PULSE_LABEL = "Culture Pulse";
export const INCOMPLETE = "Incompleto";
export const FOLLOWING_PEOPLE_DO_NOT_HAVE_SCORES = "Las siguientes personas no tienen las puntuaciones requeridas para esta evaluación:";
export const TEAM_RESULTS_MAY_BE_AFFECTED = "Los resultados del equipo pueden verse afectados hasta que todos los miembros del equipo hayan realizado las evaluaciones.";
export const SEND_EMAIL_REMINDER = "Enviar recordatorio por correo electrónico.";
export const TEAM_MEMBER = "Miembro del equipo";
export const ADMIN = "Administrador";
export const PARTIAL_SHOWN_TOOLTIP = "Se muestra el equipo parcial.";
export const GRAPH_VIEW = "Vista de gráfico";
export const AVERAGE_VIEW = "Vista de promedio";
export const MEANS = "Medios";
export const GOAL = "Meta";
export const INTERNALLY_DRIVEN = "Impulso interno";
export const EXTERNALLY_DRIVEN = "Impulso externo";
export const LOOSE = "Relajada(o)";
export const STRICT = "Estricta(o)";
export const COMMUNITY = "Comunidad";
export const PROFESSIONAL = "Profesional";
export const APPROACHABLE = "Accesible";
export const GUARDED = "Precavida(o)";
export const SUPPORT = "Apoyar";
export const PERFORMANCE = "Desempeño";

/* BEGIN - Help Content */
export const helpContent = {
  VIA: {
    shortDescription: "VIA identifica las fortalezas de carácter positivo dentro de las siguientes seis categorías: Sabiduría, Coraje, Humanidad, Justicia, Templanza y Trascendencia. Las fortalezas definidas están presentes cuando manifestamos nuestro verdadero ser positivo.",
    url: "https://help.cloverleaf.me/en/articles/3005208-what-is-via",
  },
  teamViaStrengths: {
    shortDescription: "Esto muestra las fortalezas de su equipo según la evaluación VIA. Incluye el porcentaje de su equipo que tiene cada fortaleza.",
    url: "https://help.cloverleaf.me/en/articles/3005208-what-is-via",
  },
  teamViaChart: {
    shortDescription: "Este cuadro le permite ver patrones en las fortalezas de los miembros de su equipo según la evaluación VIA.",
    url: "https://help.cloverleaf.me/en/articles/3005208-what-is-via",
  },
  teamViaList: {
    shortDescription: "Esta lista muestra la cantidad de miembros del equipo que tiene cada fortaleza específica, y cada color representa uno de los 6 grupos VIA.",
    url: "https://help.cloverleaf.me/en/articles/3005208-what-is-via",
  },
  culturePulseMeDashboard: {
    shortDescription: "La evaluación Culture Pulse mide los valores, normas, creencias y comportamientos, y demuestra cómo la cultura controla la forma en que los empleados se comportan en grupos.",
    url: "https://help.cloverleaf.me/en/articles/3005466-what-is-culture-pulse",
  },
  culturePulse: {
    shortDescription: "Culture Pulse muestra cómo se comportan los empleados entre sí. Puede ver la distribución de comportamientos y valores. Elija una persona del menú desplegable para resaltar su avatar en los mapas.",
    url: "https://help.cloverleaf.me/en/articles/3005466-what-is-culture-pulse",
  },
  DISC: {
    shortDescription: "La evaluación DISC mide su forma de responder a situaciones favorables y desfavorables. La puntuación se sitúa en una escala de dominio, cumplimiento, influencia y estabilidad.",
    url: "https://help.cloverleaf.me/en/articles/3005196-what-is-disc",
  },
  teamDisc: {
    shortDescription: "La evaluación DISC mide la forma en que uno responde a situaciones favorables y desfavorables. La puntuación se sitúa en una escala de dominio, cumplimiento, influencia y estabilidad. Los resultados de este cuadro muestran el promedio de su equipo.",
    url: "https://help.cloverleaf.me/en/articles/3005196-what-is-disc",
  },
  teamDiscCircle: {
    shortDescription: "El círculo DISC coloca a cada miembro del equipo en un cuadrante según sus resultados DISC. Según el círculo, puede ver qué comportamientos son comunes y cuáles son menos comunes en su equipo.",
    url: "https://help.cloverleaf.me/en/articles/8238822-my-teams-overview",
  },
  /* Entries below are not translated */
  sixteenTypes: {
    shortDescription: "16 Types assesses four areas of an individual's behavior: where energy is received, how information is taken in, how decisions are made, and outward behavioral tendencies. The results in this box show the average of your team.",
    url: "https://help.cloverleaf.me/en/articles/3005204-what-is-16-types",
  },
  sixteenTypesGrid: {
    shortDescription: "The 16 Types grid places each team member on the grid based on their 16 types results. In this grid, you can see what behaviors are common on your team and where you have gaps.",
    url: "https://help.cloverleaf.me/en/articles/3377929-how-to-navigate-your-team-dashboard-gather-insights",
  },
  teamEnneagram: {
    shortDescription: "The Enneagram grid places each team member in a square based on the user's Enneagram results. The grid allows you to see common behavior trends on your team.",
    url: "https://help.cloverleaf.me/en/articles/2998360-what-is-the-enneagram",
  },
  teamRoles: {
    shortDescription: "Roles allow you to see on a high level where your team has natural abilities. In this overview, you are able to see what areas your team excels in and where they are in need of expertise.",
    url: "https://help.cloverleaf.me/en/articles/3581316-how-understanding-your-team-s-roles-can-help-you-build-a-better-team",
  },
  teamRolesGrid: {
    shortDescription: "This grid shows which team members have which strengths. The dots represent how high their skill in this is, with three dots meaning they are naturally skilled at this and 1 dot meaning they would need development to be in this role.",
    url: "https://help.cloverleaf.me/en/articles/3581316-how-understanding-your-team-s-roles-can-help-you-build-a-better-team",
  },
  teamStrengths: {
    shortDescription: "Team Strengths shows the top ways that your team uses its strengths. It highlights the domain from the CliftonStrengths® assessment that your team is most accustomed to.",
    url: "https://help.cloverleaf.me/en/articles/3005209-what-is-strengthsfinder",
  },
  teamStrengthsChart: {
    shortDescription: "The Team Strengths Chart shows your teams strengths on a matrix. This allows you to see trends and patterns in the skills your teammates have.",
    url: "https://help.cloverleaf.me/en/articles/3005209-what-is-strengthsfinder",
  },
  teamStrengthsList: {
    shortDescription: "This list shows the number of team members that have each specific strength with each color representing one of the four CliftonStrengths® domains.",
    url: "https://help.cloverleaf.me/en/articles/3005209-what-is-strengthsfinder",
  },
  teamThinkingStyle: {
    shortDescription: "This shows how diverse or similar your team thinking is.",
  },
  teamStrengthscope: {
    shortDescription: "Strengthscope® allows you to bring your best to work, and to life, every single day through the discovery and development of your strengths.  We define strengths as underlying qualities that energize us, and we are great at (or have the potential to become great at).",
    url: "https://help.cloverleaf.me/en/articles/4501808-what-is-strengthscope",
    freeFive: {
      shortDescription: "Only members who have been upgraded to their 'Significant 7' will be shown in the Strenghtshcope Team Results. To request an upgrade, go to your Strengthscope results box on your ME Dashbaord.",
      url: "https://help.cloverleaf.me/en/articles/4501808-what-is-strengthscope",
    },
    clearStrengths: {
      shortDescription: "When 40% or more of team members have a particular strength it is known as a Team Clear Strength. The following clear strengths have been identified as the most energizing for the team. The team should utilize these areas of strength first to deliver team goals and aspirations. The percentage of team members reporting each strength is shown alongside each clear strength.",
      url: "http://help.cloverleaf.me/en/articles/5096333-strengthscope-for-teams",
    },
    energyDrainers: {
      shortDescription: "An energy drainer occurs when 10% or fewer team members report a particular strength as significant for them. These are the areas where the team is least energized. Energy drainers help identify areas where the team might be least effective. Consider whether they risk undermining strong teamwork and delivery of the team's objectives.",
      url: "http://help.cloverleaf.me/en/articles/5096333-strengthscope-for-teams",
    },
    teamWheel: {
      shortDescription: "This graph shows your team's strengths profile across Strengthscope's® four clusters. The bars represent how many team members report each strength as one of their 'Significant 7'. Higher bars represent those strengths which are more natural and energizing for the team to use. Lower bars represent strengths which provide the team with less energy and enjoyment in their work. The percentages in the inner circle show you how the team's strengths are distributed across four energy clusters.",
      url: "http://help.cloverleaf.me/en/articles/5096333-strengthscope-for-teams",
    },
  },
  thinkingStyleComparison: {
    shortDescription: "Use this to compare two team members based on their assessment results.",
  },
  motivatingValues: {
    shortDescription: "Motivating Values evaluates the values that lead people to work. You can view the distribution of your team below. Choose a person from the pull down to highlight their avatar on the maps.",
    url: "https://help.cloverleaf.me/en/articles/3506118-what-is-motivating-values",
  },
  enneagram: {
    shortDescription: "The Enneagram measures an individual's core behaviors. The goal of this assessment is to build compassion through understanding others and improving communication. ",
    url: "https://help.cloverleaf.me/en/articles/2998360-what-is-the-enneagram",
  },
  motivatingValuesMeDashboard: {
    shortDescription: "Motivating Values assesses the core values someone has which influences why the person behaves in certain ways. Values are fundamental incentives to motivation.",
    url: "https://help.cloverleaf.me/en/articles/3506118-what-is-motivating-values",
  },
  calendarInsights: {
    shortDescription: "Receive insights on the attendees of your upcoming meetings. You can see your calendar meeting insights here and in an email digest.",
    url: "https://help.cloverleaf.me/en/articles/2707802-connecting-your-calendar-for-insights-alongside-every-meeting",
  },
  sixteenTypesMeDashboard: {
    shortDescription: "16 Types assesses four areas of an individual's behavior: where energy is received, how information is taken in, how decisions are made, and outward behavioral tendencies. These four areas are compiled into a four letter code.",
    url: "https://help.cloverleaf.me/en/articles/3005204-what-is-16-types",
  },
  strengthsFinder: {
    shortDescription: "CliftonStrengths® identifies your top 5 strengths. These strengths are each assigned to one of the four main groupings: Executing, Strategic Thinking, Influencing and Relationship Building. ",
    url: "https://help.cloverleaf.me/en/articles/3005209-what-is-strengthsfinder",
  },
  skills: {
    shortDescription: "Add in the skills you already have or the skills you are developing so others can learn more about you.",
  },
  instinctiveDrives: {
    shortDescription: "The I.D. Questionnaire reveals a person’s natural and best way of doing things. It gives practical strategies to help achieve greater and more consistent levels of success and fulfillment, and to minimize levels of stress and frustration.",
    url: "https://help.cloverleaf.me/en/articles/4284900-what-is-instinctive-drives",
  },
  energyRhythmMeDashboard: {
    shortDescription: "The Energy Rhythm assessment brings awareness to your patterns of focus and energy, what times of day you feel most alert, and what types of tasks you do best during certain times.",
  },
  energyRhythmTeamDashboard: {
    shortDescription: "The Energy Rhythm Assessment reveals your team patterns of focus and energy, what types of tasks people do best during certain times, and what the flow of your team might look like day-to-day.",
    url: "https://help.cloverleaf.me/en/articles/4340148-what-is-the-energy-rhythm-assessment",
  },
  strengthScopeMeDashboard: {
    shortDescription: "Strengthscope® allows you to bring your best to work, and to life, every single day through the discovery and development of your strengths.  We define strengths as underlying qualities that energize us, and we are great at (or have the potential to become great at).",
    url: "https://help.cloverleaf.me/en/articles/4501808-what-is-strengthscope",
  },
  directory: {
    shortDescription: "Search for colleagues across your organization to view their profile and get valuable insights on how you can best work together.",
    url: "https://help.cloverleaf.me/en/articles/4900608-directory",
  },
};
/* END - Help Content */
