import React from "react";
import {
  string,
  node,
  oneOfType,
  shape
} from "prop-types";
import { Box, Flex } from "../ui/FlexBox";
import Label from "../ui/styled/Label";
import { withTooltip } from "./withTooltip";

function Inner({ label, ...otherProps }) {
  return typeof label === "string"
    ? <Label medium uppercase {...otherProps}>{label}</Label>
    : label;
}

Inner.propTypes = {
  label: string.isRequired,
};

const LabelWithTooltip = withTooltip(Inner, { arrow: true, position: "right" });

const withLabel = (Component) => {
  const wrapped = ({
    label = "", secondaryLabel, tooltip, labelProps = {}, ...otherProps
  }) => (
    <Label htmlFor={otherProps.id || otherProps.name}>
      <Flex justify="space-between" w={1} align="flex-end">
        <LabelWithTooltip label={label} tooltip={tooltip} {...labelProps} />
        <Label medium {...labelProps}>{secondaryLabel}</Label>
      </Flex>
      <Box w={1}><Component {...otherProps} /></Box>
    </Label>
  );

  wrapped.propTypes = {
    label: oneOfType([string, node]).isRequired,
    tooltip: shape({}),
    secondaryLabel: oneOfType([string, node]),
    labelProps: shape({}), // props to pass on to the label component
  };

  return wrapped;
};

export default withLabel;
