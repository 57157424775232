import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Heading,
  Text
} from "grommet";

function Header({
  headingSize = "large",
  icon = undefined,
  subtitle = undefined,
  textAlign = "center",
  textColor = undefined,
  title = undefined,
  ...props
}) {
  let headerIcon;

  // Handle if icon is a React element, ex. font awesome Icon
  if (React.isValidElement(icon)) {
    headerIcon = React.cloneElement(icon, {
      color: "green600",
      size: "large",
      ...icon?.props,
    });
  }

  if (!headerIcon && icon) {
    headerIcon = React.createElement(icon, {
      color: "green600",
      size: "large",
    });
  }

  return (
    <Box
      a11yTitle={title}
      align="center"
      as="header"
      gap="small"
      margin={{ vertical: "small" }}
      responsive={false}
      {...props}
    >
      {headerIcon}
      {title && (
        <Heading
          color={textColor}
          textAlign={textAlign}
          level="1"
          margin="none"
          responsive={false}
          size={headingSize}
        >
          {title}
        </Heading>
      )}
      {subtitle && (
        <Text
          color={textColor}
          textAlign={textAlign}
          weight="normal"
        >
          {subtitle}
        </Text>
      )}
    </Box>
  );
}

Header.propTypes = {
  headingSize: PropTypes.string,
  icon: PropTypes.object,
  subtitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  textAlign: PropTypes.string,
  textColor: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
};

export { Header };
