import React from "react";
import {
  Box,
  Grommet,
  Layer
} from "grommet";
import { Toast } from "@/cloverleaf-ui/components";
import { themeStyle } from "@/cloverleaf-ui/theme";
import { useToastMessage } from "../../../lib/hooks";

function ToastMessages() {
  const { toasts } = useToastMessage();

  const toastIds = Object.keys(toasts);

  if (!toastIds.length) {
    return null;
  }

  return (
    <Grommet theme={themeStyle}>
      <Layer
        role="log"
        modal={false}
        responsive={false}
        plain
        position="top"
      >
        <Box direction="column" gap="xsmall" margin={{ top: "xsmall" }} width="xlarge">
          {toastIds.map((toastId) => {
            const [toast, destroy] = toasts[toastId];

            let title = toast?.message || toast;

            if (typeof title !== "string") {
              /**
               * If the toast message is not a string...
               * Prevent component from crashing on an object by mistake
               */
              title = "Something happened! Hopefully something good, sorry if it was something bad.";
            }

            return (
              <Toast
                key={toastId}
                onClose={destroy}
                status={toast?.status}
                title={toast?.message || toast}
                visible
                toast={false}
                global
                round="xxsmall"
              />
            );
          })}
        </Box>
      </Layer>
    </Grommet>
  );
}

export { ToastMessages };
