import React from "react";
import { isBrowser } from "../utils";

const useLocalStorage = (
  key,
  initialValue = null,
  raw,
) => {
  if (!isBrowser) {
    return [initialValue, () => undefined];
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [state, setState] = React.useState(() => {
    try {
      const localStorageValue = localStorage.getItem(key);
      if (typeof localStorageValue !== "string") {
        localStorage.setItem(key, raw ? String(initialValue) : JSON.stringify(initialValue));

        return initialValue;
      }

      return raw ? localStorageValue : JSON.parse(localStorageValue ?? "null");
    }
    catch {
      // If user is in private mode or has storage restriction
      // localStorage can throw. JSON.parse and JSON.stringify
      // can throw, too.
      console.debug("useLocalStorage caught an error");

      return initialValue;
    }
  });

  // eslint-disable-next-line react-hooks/rules-of-hooks
  React.useEffect(() => {
    try {
      const serializedState = raw ? String(state) : JSON.stringify(state ?? null);
      localStorage.setItem(key, serializedState);
    }
    catch {
      // If user is in private mode or has storage restriction
      // localStorage can throw. Also JSON.stringify can throw.
      console.debug("useLocalStorage caught an error");
    }
  });

  return [state, setState];
};

export { useLocalStorage };
