import React from "react";
import PropTypes from "prop-types";
import first from "lodash/fp/first";
import flow from "lodash/fp/flow";
import isEmpty from "lodash/fp/isEmpty";
import last from "lodash/fp/last";
import size from "lodash/fp/size";
import range from "lodash/range";
import styled from "styled-components";
import {
  convertHexToRGBA,
  isEven,
  getValue,
  decrement
} from "../utils";
import { GREEN, GREY_OPAQUE } from "../utils/theme";
import { below, LAYOUT } from "../utils/zIndex";
import RangeInput from "./RangeInput";
import TextLabel from "./styled/TextLabel";
import { Flex, Box } from ".";

const buildMarginRight = index => 30 + (index > 0 ? 8.75 : 0);

const timeSlotSizeByIndex = {
  0: 52,
  1: 40,
  2: 40,
  3: 39,
  4: 40,
  5: 40,
  6: 49.5,
};

const timeSlotLeftByIndex = {
  0: 52,
  1: 52,
  2: 46,
  3: 44,
  4: 42.75,
  5: 42.2,
  6: 41.7,
};

const labelLeftByIndex = {
  0: -10,
  1: 43,
  2: 83,
  3: 123,
  4: 163,
  5: 199,
  6: 239,
  7: 288,
};

// Convert the length of items in the options array to an array going from [0..x] where x = (options.length - 1)
const buildSlotBackgrounds = flow(
  size, // 8
  decrement, // 7
  range, // [0, 1, 2, 3, 4, 5, 6]
);

const TimeSlotBackground = styled.div`
  width: ${props => props.size}px;
  height: 20px;
  background-color: ${props => convertHexToRGBA(GREEN, isEven(props.index) ? 0.2 : 0.5)};
`;

const TimeSlotBackgroundWrapper = styled(Box)`
  position: absolute;
  left: ${props => timeSlotLeftByIndex[props.index] * props.index}px;
  top: 16px;
  pointer-events: none;

  /* IE11 specific vendor media query */
  @media all and (-ms-high-contrast: none) {
    & {
      top: 50px;
    }
  }
`;

const TimeLabelBox = styled(Box)`
  position: absolute;
  left: ${props => labelLeftByIndex[props.index]}px;
  top: 5px;

  @media all and (-ms-high-contrast: none) {
    & {
      top: -50px;
    }
  }
`;

const HalfHourMarkerBox = styled(Box)`
  margin-top: -14px;
  z-index: ${below(LAYOUT)};

  @media all and (-ms-high-contrast: none) {
    & {
      margin-top: -60px;
    }
  }
`;

const HalfHourMarker = styled.div`
  width: 1px;
  height: 14px;
  background-color: ${GREY_OPAQUE};
`;

// NOTE: This assumes only 8 options, and a static width of 300 pixels at the time of this comment - making this dynamic is significantly more complex
function TimeSlider({ onChange = () => undefined, options = [], value }) {
  if (isEmpty(options)) return null;

  return (
    <Flex w={300} position="relative" column>
      <RangeInput
        min={getValue(first(options))}
        max={getValue(last(options))}
        step={0.5}
        onChange={event => onChange(event.target.value)}
        value={value}
      />
      <Flex w={300}>
        {options.map((option, index) => (
          <HalfHourMarkerBox key={option.label} mr={buildMarginRight(index)}>
            {index !== 0 && <HalfHourMarker background={GREY_OPAQUE} w="1px" h="14px" />}
          </HalfHourMarkerBox>
        ))}
        {buildSlotBackgrounds(options).map(index => (
          <TimeSlotBackgroundWrapper key={index} index={index}>
            <TimeSlotBackground size={timeSlotSizeByIndex[index]} index={index} />
          </TimeSlotBackgroundWrapper>
        ))}
      </Flex>
      <Flex w={1} position="relative">
        {options.map((option, index) => (
          <TimeLabelBox key={option.label} index={index}>
            <TextLabel small style={{ userSelect: "none" }}>{option.label}</TextLabel>
          </TimeLabelBox>
        ))}
      </Flex>
    </Flex>
  );
}

TimeSlider.displayName = "TimeSlider";

TimeSlider.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
  })),
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default TimeSlider;
