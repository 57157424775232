import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  Form,
  FormField,
  Paragraph,
  Select,
  TextArea,
  ThemeContext,
} from "grommet";
import { InsightRating } from "../../molecules";
import { InsightPropTypes } from "./propTypes";
import { INSIGHT_TARGET } from "@/components/composite/Dashboard/Insights/insights.utils";
import { useLocale } from "@/lib/hooks";
import { useWarningOnExit } from "@/lib/hooks/useWarningOnExit";

const customTheme = {
  button: {
    primary: {
      background: "white",
      border: {
        width: "1px",
        color: "white",
      },
      color: "blue600",
    },
    hover: {
      primary: {
        background: "blue600",
        border: {
          color: "white",
          width: "1px",
        },
        color: "white",
      },
    },
  },
  formField: {
    border: {
      position: "none",
    },
  },
  select: {
    options: {
      text: {
        size: "small",
      },
    },
  },
  textArea: {
    extend: "color: #484848; background: white",
  },
};

export const RATING = {
  LIKE: "like",
  DISLIKE: "dislike",
};

const HELPFUL_FEEDBACK_OPTIONS = [
  { label: "insight-feedback.spot-on", value: "Spot on" },
  { label: "insight-feedback.i-learned-something-new", value: "I learned something new", },
  { label: "insight-feedback.i-will-do-think-different", value: "I will do/think something different" },
  { label: "insight-feedback.other", value: "Other - Custom response" },
];

const TEAM_UNHELPFUL_FEEDBACK_OPTIONS = [
  { label: "insight-feedback.i-dont-know-the-person-well-enough", value: "I don't know this person well enough" },
  { label: "insight-feedback.this-doesnt-seem-accurate", value: "This doesn't seem accurate" },
  { label: "insight-feedback.doesnt-apply", value: "Doesn't apply - Custom response" },
  { label: "insight-feedback.other", value: "Other - Custom response" },
];

const SELF_UNHELPFUL_FEEDBACK_OPTIONS = [
  { label: "insight-feedback.not-enough-info", value: "Not enough Info" },
  { label: "insight-feedback.this-doesnt-seem-accurate", value: "This doesn't seem accurate" },
  { label: "insight-feedback.i-already-know-do-this", value: "I already know/do this" },
  { label: "insight-feedback.doesnt-apply", value: "Doesn't apply - Custom response" },
  { label: "insight-feedback.other", value: "Other - Custom response" },
];

function FeedbackForm({
  loading = false,
  onSubmit = () => undefined,
  selected = undefined,
  target,
  ...props
}) {
  const { t } = useLocale();

  const isHelpful = Boolean(selected === RATING.LIKE || selected === true);

  const labelText = isHelpful ? t("feedback-why-helpful") : t("feedback-why-unhelpful");

  const [showCustomFeedbackField, setShowCustomFeedbackField] = React.useState(false);
  const [customFeedback, setCustomFeedback] = React.useState("");
  const [staticFeedback, setStaticFeedback] = React.useState(null);
  const [submittedFeedback, setSubmittedFeedback] = React.useState(false);

  const shouldShowWarning = staticFeedback?.includes("Custom response") && !submittedFeedback;

  useWarningOnExit(shouldShowWarning, t("unsaved-feedback-warning"), "insight");

  const isSubmitButtonDisabled = !staticFeedback || loading || (
    showCustomFeedbackField
    && !customFeedback.length
    && staticFeedback !== "Doesn't apply - Custom response"
  );

  const handleOnSelect = ({ option }) => {
    setShowCustomFeedbackField(option.value.includes("Custom response"));

    return setStaticFeedback(option?.value);
  };

  const handleOnChange = event => setCustomFeedback(event.target.value);

  const handleOnSubmit = (form) => {
    let feedback = form?.value?.feedback;

    if (showCustomFeedbackField) {
      feedback = `${feedback}: ${form?.value?.customFeedback?.trim()}`;
    }

    setSubmittedFeedback(true);

    return onSubmit({ feedback, isHelpful });
  };

  const options = React.useMemo(() => {
    if (isHelpful) {
      return HELPFUL_FEEDBACK_OPTIONS;
    }

    if (target === INSIGHT_TARGET.TEAM) {
      return TEAM_UNHELPFUL_FEEDBACK_OPTIONS;
    }

    return SELF_UNHELPFUL_FEEDBACK_OPTIONS;
  }, [isHelpful, target]);

  return (
    <Box fill="horizontal" {...props}>
      <ThemeContext.Extend value={customTheme}>
        <Form onSubmit={handleOnSubmit}>
          <Box direction="row" gap="small">
            <Box flex>
              <FormField
                htmlFor="select-feedback"
                label={labelText}
                name="feedback"
              >
                <Box background="white" round="xxsmall">
                  <Select
                    id="select-feedback"
                    labelKey={option => t(option.label)}
                    name="feedback"
                    onChange={handleOnSelect}
                    options={options}
                    placeholder={t("select-feedback-placeholder")}
                    value={staticFeedback}
                    valueKey={{ key: "value", reduce: true }}
                    valueLabel={option => t(option.label)}
                  />
                </Box>
              </FormField>
            </Box>
            <Box
              alignSelf="end"
              margin={{ bottom: "medium" }}
            >
              <Button
                disabled={isSubmitButtonDisabled}
                label={t("submit")}
                primary
                style={{ height: "37px" }}
                type="submit"
                uppercase
              />
            </Box>
          </Box>
          {showCustomFeedbackField && (
            <FormField
              htmlFor="insight-feedback"
              label={t("custom-feedback-input-placeholder")}
              name="feedback"
            >
              <TextArea
                id="insight-feedback"
                name="customFeedback"
                onChange={handleOnChange}
                placeholder={t("custom-feedback-input-placeholder")}
                resize="vertical"
                value={customFeedback}
              />
            </FormField>
          )}
        </Form>
      </ThemeContext.Extend>
    </Box>
  );
}

FeedbackForm.propTypes = {
  selected: PropTypes.oneOf([RATING.LIKE, RATING.DISLIKE, true, false]),
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
};

function Insight({
  insight = {},
  onComplete = undefined,
  onLike = () => Promise.resolve(),
  onDislike = () => Promise.resolve(),
  onFeedback = undefined,
  /**
   * @deprecated - use onComplete instead
   */
  onAnimationComplete = () => Promise.resolve(),
  ...props
}) {
  const [loading, setLoading] = React.useState(false);
  const [selected, setSelected] = React.useState(null);
  const [showFeedback, setShowFeedback] = React.useState(false);

  // SuccessIndicator animation finished callback
  const handleOnComplete = (rating) => {
    if (onComplete) {
      return onComplete(rating);
    }

    return onAnimationComplete(rating);
  };

  const handleLike = () => {
    if (selected === RATING.LIKE) {
      // Don't duplicate submission
      return null;
    }

    setSelected(RATING.LIKE);

    // If feedback is handled, show feedback form
    // eslint-disable-next-line no-constant-condition
    if (typeof onFeedback === "function") {
      setShowFeedback(true);

      return onLike(insight?.id);
    }

    return onLike(insight?.id).then(() => {
      setSelected(null);

      return handleOnComplete(RATING.LIKE);
    });
  };

  const handleDislike = () => {
    if (selected === RATING.DISLIKE) {
      // Don't duplicate submission
      return null;
    }

    setSelected(RATING.DISLIKE);
    // If feedback is handled, show feedback form
    // eslint-disable-next-line no-constant-condition
    if (typeof onFeedback === "function") {
      setShowFeedback(true);

      return onDislike(insight?.id);
    }

    return onDislike(insight?.id)
      .then(() => {
        setSelected(null);

        return handleOnComplete(RATING.DISLIKE);
      });
  };

  // Submit feedback long-text, call onFeedback callback
  const handleOnSubmitFeedback = (feedback) => {
    setLoading(true);

    return onFeedback({ id: insight?.id, feedback })
      .then(() => {
        setShowFeedback(false);
        setLoading(false);

        return handleOnComplete();
      });
  };

  const getIsHelpful = (val) => {
    if (val === RATING.LIKE) return true;
    if (val === RATING.DISLIKE) return false;

    return undefined;
  };

  return (
    <Card
      background="blue600"
      flex={false}
      gap="small"
      pad="medium"
      round="xsmall"
      {...props}
    >
      <CardBody>
        <Paragraph
          color="white"
          fill
          margin="none"
        >
          {insight?.message}
        </Paragraph>
      </CardBody>
      <CardFooter
        direction="column"
        gap="small"
      >
        <InsightRating
          isHelpful={getIsHelpful(selected)}
          onDislike={handleDislike}
          onLike={handleLike}
        />
        {showFeedback && (
          <FeedbackForm
            key={selected}
            selected={selected}
            loading={loading}
            onSubmit={handleOnSubmitFeedback}
            target={insight.target}
          />
        )}
      </CardFooter>
    </Card>
  );
}

Insight.propTypes = InsightPropTypes;

export { FeedbackForm, Insight };
