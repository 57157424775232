import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Tag,
  Text,
} from "grommet";
import { Icon } from "@/cloverleaf-ui/components";

function EmailTag({
  onRemove,
  teamMember,
}) {
  const emailAddress = React.useMemo(() => {
    if (!teamMember?.isTrustedEmail) {
      return (
        <Box align="center" direction="row" gap="xsmall" width={{ max: "medium" }}>
          <Icon color="yellow900" icon={["far", "exclamation-triangle"]} />
          <Text color="yellow900" truncate>{teamMember?.email}</Text>
        </Box>
      );
    }

    return (
      <Box width={{ max: "medium" }}>
        <Text truncate>{teamMember?.email}</Text>
      </Box>
    );
  }, [teamMember]);

  if (!teamMember) {
    return null;
  }

  return (
    <Tag
      background={!teamMember?.isTrustedEmail ? "yellow50" : "grey200"}
      border={false}
      name={emailAddress}
      margin="xxsmall"
      onRemove={() => onRemove(teamMember)}
      size="small"
    />
  );
}

EmailTag.propTypes = {
  teamMember: PropTypes.shape({
    email: PropTypes.string,
    isTrustedEmail: PropTypes.bool,
  }),
  onRemove: PropTypes.func,
};

export { EmailTag };
