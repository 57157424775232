import React from "react";
import {
  node,
  bool,
  string,
  number,
  oneOfType
} from "prop-types";
import styled from "styled-components";
import { space, width } from "./css";
import { DEFAULT_FONT } from "@/components/utils/theme";

const Div = styled.div`
  ${props => (props.flex ? "display: flex;" : "")}
  ${props => (props.flexWrap ? "flex-wrap: wrap;" : "")}
  ${props => (props.column ? "flex-direction: column;" : "")}
  ${props => (props.auto ? "flex: auto;" : "")}
  ${props => (props.initial ? "flex: initial;" : "")}
  ${props => (props.align ? `align-items: ${props.align};` : "")}
  ${props => (props.justify ? `justify-content: ${props.justify};` : "")}
  ${props => (props.background ? `background: ${props.background};` : "")}
  ${props => (props.flexDirection ? `flex-direction: ${props.flexDirection};` : "")}
  ${props => ((props.shrink !== undefined) ? `flex-shrink: ${props.shrink};` : "")}
  ${props => ((props.grow !== undefined) ? `flex-grow: ${props.grow};` : "")}
  ${props => ((props.basis !== undefined) ? `flex-basis: ${width(props.basis)};` : "")}
  ${props => (props.position ? `position: ${props.position};` : "")}
  ${props => (props.zIndex ? `z-index: ${props.zIndex};` : "")}

  ${props => (props.w ? `width: ${width(props.w)};` : "")}
  ${props => (props.h ? `height: ${width(props.h)};` : "")}
  ${props => (props.maxHeight ? `max-height: ${width(props.maxHeight)};` : "")}
  ${props => (props.maxWidth ? `max-width: ${width(props.maxWidth)};` : "")}
  ${props => (props.minWidth ? `min-width: ${width(props.minWidth)};` : "")}
  ${props => (props.minHeight ? `min-height: ${width(props.minHeight)};` : "")}

  ${props => (props.top ? `top: ${props.top};` : "")}
  ${props => (props.bottom ? `bottom: ${props.bottom};` : "")}
  ${props => (props.left ? `left: ${props.left};` : "")}
  ${props => (props.right ? `right: ${props.right};` : "")}

  ${props => (props.m ? space("m", props.m) : "")}
  ${props => (props.mx ? space("mx", props.mx) : "")}
  ${props => (props.my ? space("my", props.my) : "")}
  ${props => (props.mt ? space("mt", props.mt) : "")}
  ${props => (props.mb ? space("mb", props.mb) : "")}
  ${props => (props.ml ? space("ml", props.ml) : "")}
  ${props => (props.mr ? space("mr", props.mr) : "")}

  ${props => (props.p ? space("p", props.p) : "")}
  ${props => (props.px ? space("px", props.px) : "")}
  ${props => (props.py ? space("py", props.py) : "")}
  ${props => (props.pt ? space("pt", props.pt) : "")}
  ${props => (props.pb ? space("pb", props.pb) : "")}
  ${props => (props.pl ? space("pl", props.pl) : "")}
  ${props => (props.pr ? space("pr", props.pr) : "")}
  box-sizing: border-box;
  font-family: ${DEFAULT_FONT};
`;

const Box = React.forwardRef(({
  wrap = false,
  className = "",
  flex = false,
  column = false,
  auto = false,
  initial = false,
  ...props
}, ref) => (
  <Div
    ref={ref}
    flexWrap={wrap}
    className={className}
    flex={flex}
    column={column}
    auto={auto}
    initial={initial}
    {...props}
  >
    {props.children}
  </Div>
));

Box.propTypes = {
  children: node.isRequired,
  className: string,

  /* These props get passed through to the CSS generator */
  w: oneOfType([number, string]),
  h: oneOfType([number, string]),
  maxHeight: oneOfType([number, string]),
  minHeight: oneOfType([number, string]),
  minWidth: oneOfType([number, string]),
  maxWidth: oneOfType([number, string]),
  flex: bool,
  wrap: bool,
  column: bool,
  auto: bool,
  initial: bool,
  align: string,
  justify: string,
  background: string,
  flexDirection: string,

  // Margin - x, y, top, bottom, left, right
  m: oneOfType([number, string]),
  mx: oneOfType([number, string]),
  my: oneOfType([number, string]),
  mt: oneOfType([number, string]),
  mb: oneOfType([number, string]),
  ml: oneOfType([number, string]),
  mr: oneOfType([number, string]),

  // Padding - x, y, top, bottom, left, right
  p: oneOfType([number, string]),
  px: oneOfType([number, string]),
  py: oneOfType([number, string]),
  pt: oneOfType([number, string]),
  pb: oneOfType([number, string]),
  pl: oneOfType([number, string]),
  pr: oneOfType([number, string]),
};

Box.displayName = "Box";

export default Box;
