import styled from "styled-components";
import { Box } from "./FlexBox";

const SingleLineTextBox = styled(Box)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default SingleLineTextBox;
