import React from "react";
import { node, string } from "prop-types";
import styled from "styled-components";
import { BLACK, WHITE } from "../utils/theme";
import { Flex } from "./FlexBox";
import TextLabel from "./styled/TextLabel";

const Wrapper = styled(Flex)`
  border: 1px solid ${props => props.border};
  overflow: hidden;
  border-radius: 5px;
`;

function ContentWithHeader({
  title,
  children,
  boxColor = BLACK,
  titleColor = WHITE,
}) {
  return (
    <Wrapper w={1} column border={boxColor}>
      <Flex background={boxColor} p={1}>
        <TextLabel uppercase color={titleColor}>{title}</TextLabel>
      </Flex>
      <Flex px={1}>
        {children}
      </Flex>
    </Wrapper>
  );
}

ContentWithHeader.propTypes = {
  title: string.isRequired,
  children: node.isRequired,
  boxColor: string,
  titleColor: string,
};

export default ContentWithHeader;
