import { useContext } from "react";
import { gql, useQuery } from "@apollo/client";
import {
  Box,
  Button,
  ResponsiveContext,
  Text
} from "grommet";
import { isMobile } from "@/cloverleaf-ui/utils";
import { EVENT } from "@/components/utils/constants";
import { track } from "@/lib";
import { useLocale } from "@/lib/hooks";

const ASSESSMENT_MAP = {
  hasCompletedDisc: "disc",
  hasCompletedPersonality: "16 Types",
  hasCompletedCulture: "culture-pulse",
  hasCompletedVia: "via",
  hasCompletedMotivatingValues: "motivating-values",
  hasCompletedEnneagram: "enneagram",
  hasCompletedInsitinctiveDrives: "instinctive-drives",
  hasCompletedEnergyRhythm: "energy-rhythm",
}

export const GET_CURRENT_USER_ASSESSMENTS_CONFIG_QUERY = gql`
  query currentUserAssessmentsConfigQuery {
    currentUser {
      id
      hasCompletedDisc: hasCompleted(assessment: DISC)
      hasCompletedPersonality: hasCompleted(assessment: PERSONALITY)
      hasCompletedCulture: hasCompleted(assessment: CULTURE)
      hasCompletedVia: hasCompleted(assessment: VIA)
      hasCompletedMotivatingValues: hasCompleted(assessment: MOTIVATINGVALUES)
      hasCompletedEnneagram: hasCompleted(assessment: ENNEAGRAM)
      hasCompletedInsitinctiveDrives: hasCompleted(assessment: INSTINCTIVEDRIVES)
      hasCompletedEnergyRhythm: hasCompleted(assessment: ENERGYRHYTHM)
      configuration {
        id
        configurationId
        setting
        type
      }
    }
  }
`;

/** @deprecated */
function GettingStartedBanner({ onClick }) {
  const { t } = useLocale();
  const { data, loading } = useQuery(GET_CURRENT_USER_ASSESSMENTS_CONFIG_QUERY);
  const size = useContext(ResponsiveContext);

  const handleOnClick = () => {
    track(EVENT.ChooseAssessment.name, {
      page: EVENT.ChooseAssessment.page.myDashboard,
    })

    return onClick();
  }

  const count = Object.keys(ASSESSMENT_MAP)
    .filter(key =>
      !data?.currentUser?.[key] &&
      data?.currentUser?.configuration?.find(config =>
        config.configurationId === ASSESSMENT_MAP[key]
      )
    )?.length;

  if (loading || !count) {
    return null;
  }

  return (
    <Box
      background="background-gradient"
      round="small"
      direction="row"
    >
      <Box
        gap="small"
        pad="medium"
        width="100%"
        style={!isMobile(size) ? {
          backgroundImage: "url('https://cloverleaf-app-publicfiles.s3.amazonaws.com/graphics/Cloverleaf+Assessment+Partner+3.png')",
          backgroundPosition: "right -30px",
          backgroundRepeat: "no-repeat",
          backgroundSize: "240px",
          paddingRight: "240px",
        } : undefined}
      >
        <Box>
          <Text
            color="white"
            size="large"
          >
            {count < 2
              ? t("dash.getting-started-banner.assessments-available_one")
              : t("dash.getting-started-banner.assessments-available_other", { count })
            }
          </Text>
          <Text color="white">{t("dash.getting-started-banner.banner-subtext")}</Text>
        </Box>
        <Button
          alignSelf={isMobile(size) ? undefined : "start"}
          onClick={handleOnClick}
          label={(
            <Text color="green600">
              {t("dash.getting-started-banner.choose-assessment")}
            </Text>
          )}
          color= "white"
          hoverIndicator={{ background: "white80" }}
          primary
          uppercase
        />
      </Box>
    </Box>
  );
}

export { GettingStartedBanner };
