import { useMemo } from "react";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import identity from "lodash/fp/identity";
import set from "lodash/fp/set";
import { processAndValidateData } from "../components/composite/ImportOrganization/importOrganization.utils";
import { cacheInBrowser } from "../components/utils";
import { saveAssessmentProgress, loadAssessmentProgress } from "../components/utils/assessmentProgress";
import { ASSESSMENT } from "../components/utils/constants";
import applicationReducer from "../modules/application";
import bind from "../modules/boundActionCreators";
import userReducer, { USER_SIGNOUT } from "../modules/user";

let store;

const {
  DISC,
  PERSONALITY,
  ENNEAGRAM,
  CULTURE,
  VIA,
  MOTIVATINGVALUES,
} = ASSESSMENT;

const reducers = combineReducers({
  app: applicationReducer,
  user: userReducer,
});

export const rehydrateStoreItem = (prevState, { cachedItem, statePath, buildStoreValue = identity } = {}) => (
  cacheInBrowser.get(cachedItem)
    ? set(statePath, buildStoreValue(cacheInBrowser.get(cachedItem)), prevState)
    : prevState);

export const rehydrateStore = (rehydrateItems = [], initialState = {}) =>
  rehydrateItems.reduce(rehydrateStoreItem, initialState);

function initStore(preloadedState = {}) {
  // eslint-disable-next-line default-param-last
  const rootReducer = (state = {}, action) => {
    if (action.type === USER_SIGNOUT) {
      return reducers(undefined, action);
    }

    return reducers(state, action);
  };

  const rehydrateStoreItems = [
    {
      cachedItem: "importOrganization",
      statePath: "app.ui.importOrganization",
      buildStoreValue: importOrgCache => processAndValidateData(importOrgCache),
    },
    ...loadAssessmentProgress(DISC),
    ...loadAssessmentProgress(PERSONALITY),
    ...loadAssessmentProgress(ENNEAGRAM),
    ...loadAssessmentProgress(CULTURE),
    ...loadAssessmentProgress(VIA),
    ...loadAssessmentProgress(MOTIVATINGVALUES),
  ];

  const state = rehydrateStore(rehydrateStoreItems, preloadedState);

  // eslint-disable-next-line no-underscore-dangle
  const _store = configureStore({
    reducer: rootReducer,
    preloadedState: state,
  });

  _store.subscribe(() => {
    // eslint-disable-next-line no-underscore-dangle
    const _state = _store.getState();
    const importOrgState = _state?.app?.ui?.importOrganization || {};
    const existing = cacheInBrowser.get("importOrganization");
    if ((existing && existing.rows) || importOrgState) {
      cacheInBrowser.set("importOrganization", {
        ...importOrgState,
        validationErrors: undefined,
        adminCount: undefined,
        teamCount: undefined,
      });
    }

    saveAssessmentProgress(DISC, _store);
    saveAssessmentProgress(PERSONALITY, _store);
    saveAssessmentProgress(ENNEAGRAM, _store);
    saveAssessmentProgress(CULTURE, _store);
    saveAssessmentProgress(VIA, _store);
    saveAssessmentProgress(MOTIVATINGVALUES, _store);
  });

  // Bind some action creator globals.
  bind(_store.dispatch);

  return _store;
}

export const initializeStore = (preloadedState) => {
  // eslint-disable-next-line no-underscore-dangle
  let _store = store ?? initStore(preloadedState);

  // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store
  if (preloadedState && store) {
    _store = initStore({
      ...store?.getState(),
      ...preloadedState,
    });
    // Reset the current store
    store = undefined;
  }

  // For SSG and SSR always create a new store
  if (typeof window === "undefined") return _store;
  // Create the store once in the client
  if (!store) store = _store;

  return _store;
};

export function useStore(preloadedState) {
  // eslint-disable-next-line no-underscore-dangle
  const _store = useMemo(() => initializeStore(preloadedState), [preloadedState]);

  return _store;
}
