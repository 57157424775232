import { useMutation, gql } from "@apollo/client";
import * as Sentry from "@sentry/nextjs";

const validateEmailException = ({
  message = "Unknown Error",
  status = "Unknown",
}) => ({
  message,
  status,
});

export const VALIDATE_EMAIL_STATUS = {
  INVALID: "INVALID",
  SUCCESS: "SUCCESS",
  NEED_ORGANIZATION: "NEED_ORGANIZATION",
};

export const VALIDATE_EMAIL_QUERY = gql`
  mutation validateEmail($input: input) {
    validateEmail(input: $input)
      @rest(type: "Response", path: "/emails/validate", method: "POST") {
        message
        status
        subdomain
    }
  }
`;

export const useValidateEmail = () => {
  const [
    validateEmail,
    { data, loading, error },
  ] = useMutation(VALIDATE_EMAIL_QUERY);

  const handleValidateEmail = async ({ email }) => {
    try {
      const {
        data: response,
      } = await validateEmail({
        variables: {
          input: { email },
        },
      });

      const {
        message,
        status,
        subdomain,
      } = response.validateEmail;

      return {
        message,
        status,
        subdomain,
      };
    }
    catch (errorResponse) {
      const { message, status } = errorResponse?.networkError?.result || {};

      Sentry.captureException(errorResponse, {
        extra: {
          arguments: { email },
          message,
          status,
        },
        tags: {
          function: "useValidateEmail",
        },
      });

      throw validateEmailException({ message, status });
    }
  };

  return {
    data,
    error,
    loading,
    validateEmail: handleValidateEmail,
    validateEmailStatus: VALIDATE_EMAIL_STATUS,
  };
};
