import { useSessionStorage } from "@/cloverleaf-ui/hooks";

/**
 * Returns reactive variable state and setState function
 * @returns [] - [state, setState]
 */
const useAssessmentProgress = (assessmentKey) => {
  const sessionStorageKeyName = `ASSESSMENT-${assessmentKey}`;

  const [progress, setProgress] = useSessionStorage(sessionStorageKeyName);

  const clearProgress = () => {
    return setProgress(null);
  };

  return {
    clearProgress,
    setProgress,
    progress,
  };
};

export { useAssessmentProgress };
