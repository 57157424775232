/**
 * ACTIONS
 */

const ADD_TOAST = "cloverleaf/toasts/ADD_TOAST";
const REMOVE_TOAST = "cloverleaf/toasts/REMOVE_TOAST";

/**
 * ACTION CREATORS
 */

let globalId = 0;

export function addToast(text, type, options = {}) {
  globalId += 1;

  return {
    type: ADD_TOAST,
    payload: {
      text,
      type,
      id: globalId,
      ...options,
    },
  };
}

export function removeToast(id) {
  return {
    type: REMOVE_TOAST,
    payload: id,
  };
}

/**
 * REDUCER
 */

function reducer(state = [], action = {}) {
  const { payload = {}, type } = action;

  switch (type) {
    case ADD_TOAST:
      return [...state, payload];

    case REMOVE_TOAST:
      return state.filter(toast => toast.id !== payload);

    default:
      return state;
  }
}

export default reducer;
