import React from "react";
import {
  string,
  bool,
  func,
  oneOfType,
  arrayOf,
  shape,
  number
} from "prop-types";
import styled from "styled-components";
import { withTooltip } from "../hoc/withTooltip";
import { convertHexToRGBA } from "../utils";
import {
  GREEN,
  WHITE,
  GREY,
  MEDIUM_SIZE,
  CONDENSED_FONT,
  ONE_COLUMN_WIDTH,
} from "../utils/theme";

const StyledInput = styled.input`
  display: none;
`;

const StyledDiv = styled.div`
  display: flex;
  background-color: ${props => (props.disabled ? GREY : GREEN)};
  border: 1px solid ${props => (props.disabled ? GREY : GREEN)};
  color: ${props => (props.disabled ? WHITE : "inherit")};
  border-radius: 10px;
  height: 30px;
  width: max-content;
  line-height: 30px;
  box-sizing: content-box;
  box-shadow: 0px 5px 8px -4px rgba(0,0,0,0.30);
`;

const RadioToggleLabel = styled.label`
  color: ${convertHexToRGBA(WHITE, 0.5)};
  font-size: ${MEDIUM_SIZE};
  font-weight: 300;
  font-family: ${CONDENSED_FONT};
  text-transform: uppercase;
  display: block;
  height: 30px;
  margin: 0px;
  border-radius: 9px;
  padding: ${props => (props.small ? "0 10px" : "0 50px")};
  @media (max-width: ${ONE_COLUMN_WIDTH}) {
    padding: ${props => (props.small ? "0 10px" : "0 30px")};
  }
  cursor: pointer;
  white-space: nowrap;

  ${StyledInput}:checked + & {
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.10);
    color: ${GREEN};
    background-color: ${WHITE};
  }

  ${StyledInput}:disabled + & {
    cursor: default;
  }

  ${StyledInput}:checked:disabled + & {
    color: ${GREY};
  }
`;

const formatOption = option => (typeof option === "string" ? { label: option, value: option } : option);

function RadioToggle({ onChange, ...props }) {
  return (
    <StyledDiv disabled={props.disabled}>
      {props.options.map((option) => {
        const { label, value } = formatOption(option);
        const id = `${props.name}-${value}`;

        return (
          <React.Fragment key={value}>
            <StyledInput
              type="radio"
              id={id}
              name={props.name}
              disabled={props.disabled}
              checked={props.value ? props.value === value : undefined}
              value={value}
              onChange={e => onChange(e.target.value)}
              onBlur={props.onBlur}
              onFocus={props.onFocus}
              onDragStart={props.onDragStart}
              onDrop={props.onDrop}
            />
            <RadioToggleLabel htmlFor={id} small={!!props.small}>
              {label}
            </RadioToggleLabel>
          </React.Fragment>
        );
      })}
    </StyledDiv>
  );
}

RadioToggle.propTypes = {
  name: string.isRequired,
  options: arrayOf(oneOfType([
    string,
    shape({
      label: string.isRequired,
      value: oneOfType([string, number]).isRequired,
    }),
  ])).isRequired,
  value: oneOfType([string, number]),
  onChange: func.isRequired,
  disabled: bool,
  onBlur: func,
  onFocus: func,
  onDragStart: func,
  onDrop: func,
  small: bool,
};

export default withTooltip(RadioToggle);
