import React from "react";
import {
  func,
  string,
  bool,
} from "prop-types";
import styled from "styled-components";
import {
  GREEN,
  DARK_GREEN,
  GREY_OPAQUE
} from "../utils/theme";
import { Flex, Box } from "./FlexBox";
import Label from "./styled/Label";
import TextLabel from "./styled/TextLabel";

const StyledInput = styled.input`
  appearance: none;
  background-color: transparent;
  cursor: pointer;
  width: ${props => (props.small ? 20 : 26)}px;
  height: ${props => (props.small ? 20 : 26)}px;
  border: 2px solid ${GREEN};
  border-radius: 50%;
  position: relative;

  &:active {
    border: 2px solid ${DARK_GREEN};
  }

  @media (hover:hover), screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    &:hover {
      &:after {
        content: "";
        width: ${props => (props.small ? 10 : 14)}px;
        height: ${props => (props.small ? 10 : 14)}px;
        background-color: ${GREEN};
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.20);
        border-radius: 50%;
        position: absolute;
        will-change: transform;
        transform: translate(${props => (props.small ? 3 : 4)}px, ${props => (props.small ? 3 : 4)}px);
      }
    }
  }

  &:checked, &:active {
    &:after {
      content: "";
      width: ${props => (props.small ? 10 : 14)}px;
      height: ${props => (props.small ? 10 : 14)}px;
      background-color: ${GREEN};
      box-shadow: 0 2px 4px 0 rgba(0,0,0,0.20);
      border-radius: 50%;
      position: absolute;
      will-change: transform;
      transform: translate(${props => (props.small ? 3 : 4)}px, ${props => (props.small ? 3 : 4)}px);
    }
  }

  &:active {
    &::after {
      background-color: ${DARK_GREEN};
    }
  }

  &:disabled, &:disabled:active, &:disabled:hover {
    appearance: none;
    background-color: transparent;
    cursor: not-allowed;
    width: ${props => (props.small ? 20 : 26)}px;
    height: ${props => (props.small ? 20 : 26)}px;
    border: 2px solid ${GREY_OPAQUE};
    border-radius: 50%;
    position: relative;

    &:after {
      width: 0;
      height: 0;
      background-color: ${GREY_OPAQUE};
    }
  }
`;

const RadioButtonInput = StyledInput;

class RadioButton extends React.PureComponent {
  static propTypes = {
    onChange: func.isRequired,
    onClick: func,
    name: string.isRequired,
    label: string,
    labelColor: string,
    subLabel: string,
    id: string,
    value: string.isRequired,
    val: string.isRequired,
    small: bool,
    disabled: bool,
  };

  static defaultProps = {
    id: undefined,
    label: undefined,
    labelColor: GREEN,
    subLabel: undefined,
    small: false,
    onClick: () => undefined,
    disabled: false,
  };

  render() {
    const {
      onChange, onClick, label, subLabel, name, id, val, value, small, disabled,
    } = this.props;

    return (
      <Flex column mb={1}>
        <Flex align="center">
          <RadioButtonInput
            type="radio"
            id={id || `${name}-${val}`}
            name={name}
            onChange={onChange}
            onClick={onClick}
            checked={val === value}
            value={val}
            small={small}
            disabled={disabled}
          />
          {label && <Box ml="4px"><Label color={disabled ? GREY_OPAQUE : this.props.labelColor} uppercase htmlFor={id || `${name}-${val}`}>{label}</Label></Box>}
        </Flex>
        {
          subLabel &&
          (
            <Box ml={34}>
              <TextLabel color={GREY_OPAQUE}>{subLabel}</TextLabel>
            </Box>
          )
        }
      </Flex>
    );
  }
}

export default RadioButton;
