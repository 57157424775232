import styled from "styled-components";
import {
  CONDENSED_FONT,
  textStyleMixin,
  LARGE_SIZE
} from "../../utils/theme";

const TextLabel = styled.span.attrs(props => ({
  font: props.font || CONDENSED_FONT,
  fontSize: (props.large ? LARGE_SIZE : props.fontSize),
}))`
  ${textStyleMixin}
`;

export default TextLabel;
