import get from "lodash/fp/get";
import getOr from "lodash/fp/getOr";
import size from "lodash/fp/size";
import cacheInBrowser from "./cacheInBrowser";
import { ASSESSMENT } from "./constants";

const getAssessmentCacheKey = assessment => `cloverleaf-assessment-${assessment}`;

export const isAssessmentInCache = assessment => !!cacheInBrowser.get(getAssessmentCacheKey(assessment));

export const clearAssessmentCache = (assessment) => {
  const assessmentKey = getAssessmentCacheKey(assessment);

  if (cacheInBrowser.get(assessmentKey)) {
    cacheInBrowser.reset(assessmentKey);

    return true;
  }

  return false;
};

// This function is used for taking assessment data from localStorage and putting it into redux - it follows the `rehydrateStoreItem` schema
export const loadAssessmentProgress = assessment => [
  {
    cachedItem: getAssessmentCacheKey(assessment),
    statePath: `app.assessments.${assessment}`,
    buildStoreValue: assessmentData => assessmentData.status,
  },
  {
    cachedItem: getAssessmentCacheKey(assessment),
    statePath: assessment === ASSESSMENT.MOTIVATINGVALUES
      ? "app.assessments.motivatingValues.data"
      : `form.assessments.${assessment}`,
    buildStoreValue: assessmentData => assessmentData.answers,
  },
];

// This function is used for taking assessment data from redux and putting it into localStorage - it only saves if there's data to save
export const saveAssessmentProgress = (assessment, store) => {
  // Get the assessment status from the state
  const status = get(["app", "assessments", assessment], store.getState());

  // Get the assessment form values
  const answers = assessment === ASSESSMENT.MOTIVATINGVALUES
    ? get("app.assessments.motivatingValues.data", store.getState())
    : get(["form", "assessments", assessment], store.getState());

  // Don't cache results if they haven't gotten to the question pages
  const isValidStatus = getOr(0, "status.latestPage", status) > 1;

  // Each assessment stores answers differently, but there must be at least *something* that needs to be stored
  // Otherwise, it's not necessary to cache and will cause resuming assessment bugs
  const isValidAnswers = size(answers) > 0;

  // Merge these under the assessment key
  if (status && answers && isValidStatus && isValidAnswers) {
    cacheInBrowser.set(getAssessmentCacheKey(assessment), { status, answers });
  }
};
