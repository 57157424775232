import { forwardRef } from "react";
import { Button as GrommetButton } from "grommet";
import { EventNames, logEventPropsPropType } from "@/lib/analytics/constants";
import { LogEvent } from "@/lib/analytics/LogEvent";

function getButtonType({ primary, secondary, plain }) {
  if (primary) return "primary";
  if (secondary) return "secondary";
  if (plain) return "plain";

  return "default";
}

const Button = forwardRef(function Button({ logEventProps = {}, sensitive, ...props }, ref) {
  const buttonText = !sensitive && typeof props.label === "string" ? props.label : undefined;

  return (
    <LogEvent
      logEventProps={{
        elementName: "button",
        eventName: EventNames.ButtonEngagement,
        ui_properties: {
          buttonStyle: getButtonType(props),
          buttonText,
          buttonType: props.href ? "link" : "button",
          ...logEventProps?.ui_properties
        },
        ...logEventProps,
      }}
      actionProps={{ onClick: { action: "click" } }}
      elementType="button"
    >
      <GrommetButton
        ref={ref}
        {...props}
      />
    </LogEvent>
  );
});

Button.propTypes = {
  ...GrommetButton.propTypes,
  logEventProps: logEventPropsPropType,
};

export { Button };
