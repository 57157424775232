import {
  shape,
  string,
  arrayOf,
  number,
  bool,
  instanceOf
} from "prop-types";

// GraphQL id type - we use numbers on the backend, but apollo uses strings.
export const idType = string;

export const insightProp = shape({
  message: string.isRequired,
  type: string,
});

export const traitProp = shape({
  name: string.isRequired,
  secondaryName: string,
  description: string,
  value: number,
});

export const titleTraitProp = shape({
  id: string.isRequired,
  name: string.isRequired,
  secondaryName: string,
  description: string.isRequired,
});

export const personalityScore = shape({
  title: shape({
    name: string.isRequired,
    secondaryName: string,
    description: string.isRequired,
  }).isRequired,
  traits: arrayOf(traitProp).isRequired,
  insight: insightProp,
});

export const energyRhythmScore = shape({
  title: shape({
    name: string.isRequired,
    secondaryName: string,
    description: string.isRequired,
  }).isRequired,
  traits: arrayOf(shape({
    name: string.isRequired,
    secondaryName: string,
    description: string,
    value: string,
  })).isRequired,
});

export const assessmentScore = shape({
  title: traitProp,
  traits: arrayOf(traitProp),
  insight: insightProp,
});

export const discScore = shape({
  title: traitProp,
  traits: arrayOf(traitProp),
  insight: insightProp,
});

export const strengthsFinderScore = assessmentScore;

export const motivatingValuesScore = shape({
  traits: arrayOf(traitProp).isRequired,
  insight: insightProp,
});

export const drivingForcesScore = motivatingValuesScore;

export const user = shape({
  id: idType.isRequired,
  fullName: string,
  email: string.isRequired,
  emails: arrayOf(string),
  isCandidate: bool,
  admin: shape({
    tags: arrayOf(shape({
      id: idType.isRequired,
      description: string.isRequired,
    })),
    teams: arrayOf(shape({
      id: idType.isRequired,
      name: string.isRequired,
    })),
  }),
  profile: shape({
    id: string.isRequired,
    relativePublicUrl: string.isRequired,
  }),
});

export const userProp = user;

export const publicUserPropType = shape({
  id: idType.isRequired,
  scores: shape({}),
  email: string,
  firstName: string,
  lastName: string,
  fullName: string,
  avatar: string,
  insights: arrayOf(shape({})),
  profile: shape({
    id: string.isRequired,
    relativePublicUrl: string.isRequired,
  }),
});

export const viaScore = shape({
  title: traitProp.isRequired,
  traits: arrayOf(traitProp).isRequired,
  insight: insightProp,
});

export const culturePulseScore = shape({
  traits: arrayOf(traitProp).isRequired,
  insight: insightProp,
});

export const tagProp = shape({
  id: idType.isRequired,
  description: string.isRequired,
  users: arrayOf(user),
  admins: arrayOf(user),
});

export const teamProp = shape({
  id: idType.isRequired,
  name: string.isRequired,
  description: string,
});

export const skillsProp = arrayOf(shape({
  id: idType,
  name: string,
}));

export const teamStrengthProp = shape({
  name: string.isRequired,
  secondaryName: string.isRequired,
  description: string,
  users: arrayOf(user),
});

export const teamTraitsProp = arrayOf(shape({
  name: string.isRequired,
  description: string.isRequired,
  value: number.isRequired,
}));

export const teamTitleProp = shape({
  name: string.isRequired,
  description: string.isRequired,
});

export const teamRoleMemberProp = shape({
  fit: number.isRequired,
  user: user.isRequired,
});

export const teamRoleProp = shape({
  name: string.isRequired,
  members: arrayOf(teamRoleMemberProp).isRequired,
  description: string.isRequired,
});

export const teamFeaturesProp = shape({
  canCreateUserTeam: bool.isRequired,
  canCreateOrganizationTeam: bool.isRequired,
});

export const dateTimeProp = shape({
  date: instanceOf(Date),
  day: number,
  month: string,
  elapsed: string,
  timestamp: string,
  time: string,
});

export const connectedAccountProp = shape({
  id: idType.isRequired,
  type: string.isRequired,
  email: string.isRequired,
});

export const componentLayoutProp = shape({
  componentId: string,
  isCollapsible: bool,
  openByDefault: bool,
  viewType: number,
  colSpan: number,
});

export const componentLayoutPropDefaults = {
  componentId: undefined,
  isCollapsible: true,
  openByDefault: true,
  viewType: undefined,
  colSpan: 1,
};

export const trackingDataProp = shape({
  action: string,
  component: string,
  target: string,
  teamId: string,
  userId: string,
  targetUserId: string,
  page: string,
});
