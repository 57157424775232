import { captureException } from "@sentry/nextjs";

// Does nothing if running on the server.
export const getBrowserTimezone = () => {
  try {
    if (typeof window !== "undefined") {
      let tz;

      if (typeof Intl !== "undefined") {
        tz = new Intl.DateTimeFormat().resolvedOptions().timeZone || "America/Los_Angeles";
      }
      else { // don't use Intl api
        tz = "America/Los_Angeles" // Default for unsupported browsers
      }

      return tz;
    }
  }
  catch (error) {
    // Do we actually need to capture this?
    captureException(error, { extra: { message: "Error getting timezone for user." } });
  }

  return undefined;
};
