import React from "react";
import { Box, Nav } from "grommet";
import { Icon } from "@/cloverleaf-ui/components";
import { FADED_WIDTH_TRANSITION } from "../constants";
import { SidebarContext } from "./Sidebar.context";
import { Button } from "@/components/v2/Button";
import { Log } from "@/lib/analytics/Log";
import { useLocale } from "@/lib/hooks";

const SidebarFooter = ({ children, ...props }) => {
  const { t } = useLocale();

  const { isSidebarOpen, toggleSidebar } = React.useContext(SidebarContext);

  const label = isSidebarOpen ? t("nav.collapse") : t("nav.expand");
  const icon = isSidebarOpen ? "chevron-left" : "chevron-right";

  return (
    <Log component="SidebarFooter">
      <Nav
        align="center"
        justify="center"
        margin={{ horizontal: "small", vertical: "medium" }}
        {...props}
      >
        {children}
        <Button
          fill="horizontal"
          gap="medium"
          icon={(
            <Box flex={false} width="20px">
              <Icon color="grey700" icon={["fal", icon]} fixedWidth />
            </Box>
          )}
          justify="start"
          label={label}
          logEventProps={{
            elementName: "toggle-sidebar-button"
          }}
          onClick={toggleSidebar}
          plain
          pad={{ horizontal: "10px" }}
          style={{
            overflow: "hidden",
            padding: "8px 10px",
            whiteSpace: "nowrap",
            transition: FADED_WIDTH_TRANSITION,
          }}
        />
      </Nav>
    </Log>
  );
};

export { SidebarFooter };
