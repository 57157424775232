import React from "react";
import {
  oneOfType,
  number,
  func,
  bool,
  string
} from "prop-types";
import range from "lodash/range";
import { GREEN, WHITE } from "../utils/theme";
import { Box, Flex } from "./FlexBox";
import Icon from "./Icon";
import TextButton from "./TextButton";

function Pagination({
  pages, selectedIndex,
  onClick = () => undefined,
  light = false,
  size = "1x",
  dotMargin = "2px",
}) {
  return (
    <Flex w={1} justify="center">
      {range(pages).map((pageIndex) => {
        const id = `pagination-dot-${pageIndex}`;
        const iconType = pageIndex === selectedIndex ? "fas" : "far";

        return (
          <Box m={dotMargin} key={id}>
            <TextButton id={id} underline={false} onClick={() => onClick(pageIndex)}>
              <Icon color={light ? WHITE : GREEN} icon={[iconType, "circle"]} size={size} />
            </TextButton>
          </Box>
        );
      })}
    </Flex>
  );
}

Pagination.propTypes = {
  pages: number.isRequired,
  selectedIndex: number.isRequired,
  onClick: func,
  light: bool,
  dotMargin: oneOfType([
    string,
    number,
  ]),
  size: oneOfType([
    string,
    number,
  ]),
};

export default Pagination;
