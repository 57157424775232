export const DAILY_COACHING_STAGES = {
  explore: "EXPLORE",
  self: "SELF",
  teammate: "TEAMMATE",
};

export const DAILY_COACHING_STAGES_STATUS = {
  active: "ACTIVE",
  complete: "COMPLETE",
};

/**
 * "To prevent creating a new object on each render, the align property should be persistent,
 * either as a static constant outside of the render function or memo-ized using hooks."
 * https://v2.grommet.io/dropbutton#dropAlign
 */
export const DROP_POSITION = {
  top: {
    bottom: "top",
  },
  right: {
    left: "right",
  },
  bottom: {
    top: "bottom",
  },
  left: {
    right: "left",
  },
  topLeft: {
    bottom: "top",
    right: "right",
  },
  topRight: {
    bottom: "top",
    left: "left",
  },
  bottomLeft: {
    top: "bottom",
    right: "right",
  },
  bottomRight: {
    top: "bottom",
    left: "left",
  },
};

export const REQUEST_STATUS = {
  PENDING: "PENDING",
  ACCEPTED: "ACCEPTED",
  CANCELLED: "CANCELLED",
  DENIED: "DENIED",
};

export const COACHING_TIPS_ENABLED_DEFAULT_KEY = "coaching-tips-enabled-default";
