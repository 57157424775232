import React from "react";
import { func, node } from "prop-types";
import compose from "lodash/fp/compose";
import every from "lodash/fp/every";
import get from "lodash/fp/get";
import includes from "lodash/fp/includes";
import isEmpty from "lodash/fp/isEmpty";
import some from "lodash/fp/some";

const isTooltip = compose(
  includes("tippy-popper"),
  get("className"),
);
const pathContainsTooltip = some(isTooltip);

const checkParentContains = (parent, clickedNode) => parent && parent.contains(clickedNode);
const checkEveryParentContains = (parents, clickedNode) => !isEmpty(parents) &&
  every(parent => checkParentContains(parent, clickedNode), parents);

class OutsideAlerter extends React.PureComponent {
  static propTypes = {
    onOutsideClick: func.isRequired,
    children: node.isRequired,
  }

  componentDidMount() {
    document.addEventListener("mouseup", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mouseup", this.handleClickOutside);
  }

  setWrapperRef = (ref) => {
    this.wrapperRef = ref;
  }

  handleClickOutside = (event) => {
    // Exclude our tooltips as an outside click
    if (!event.path) {
      // IE11 doesn't have eventpath so we must use this other one.
      const tooltips = document.getElementsByClassName("tippy-popper");
      if (checkEveryParentContains(tooltips, event.target)) {
        return;
      }
    }
    else if (pathContainsTooltip(event.path)) {
      return;
    }

    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.props.onOutsideClick();
    }
  }

  render() {
    return <span ref={this.setWrapperRef}>{this.props.children}</span>;
  }
}

const withOutsideClick = (Component) => {
  const wrapped = ({ onOutsideClick, ...otherProps }) => (
    <OutsideAlerter onOutsideClick={onOutsideClick}>
      <Component {...otherProps} />
    </OutsideAlerter>
  );

  wrapped.propTypes = {
    onOutsideClick: func.isRequired,
  };

  return wrapped;
};

export default withOutsideClick;
