import get from "lodash/fp/get";
import isEmpty from "lodash/fp/isEmpty";
import { isOrganizationSubdomain } from "./helpers/isOrganizationSubdomain";
import { parseSubdomain } from "./helpers/parseSubdomain";

export const parseSearchParams = (search = "") =>
  search.substring(1).split("&")
    .reduce((result, value) => {
      const parts = value.split("=");
      if (isEmpty(parts[0])) return result;

      return {
        ...result,
        [decodeURIComponent(parts[0])]: decodeURIComponent(parts[1]),
      };
    }, {});

const lastStringOrUndefined = (val) => {
  if (Array.isArray(val)) {
    if (val.length > 0) return [...val].pop();

    return undefined;
  }

  return val;
};

export const getSubdomain = (ctx) => {
  if (get("query.subdomain", ctx)) {
    return lastStringOrUndefined(ctx?.query?.subdomain);
  }

  const req = get("req", ctx);

  // Server-side
  if (req) {
    let subdomain = req.headers["cloverleaf-subdomain"];

    if (typeof subdomain === "undefined") {
      subdomain = parseSubdomain(req?.headers?.host);
    }

    if (subdomain && isOrganizationSubdomain(subdomain)) {
      return lastStringOrUndefined(subdomain);
    }

    return undefined;
  }

  if (!req && typeof window !== "undefined" && window.location) {
    // If there is a query param for 'subdomain' then that takes priority
    const query = parseSearchParams(window.location.search);
    if (query.subdomain) {
      return lastStringOrUndefined(query.subdomain);
    }

    // Next, look at the actual subdomain, except for 'app' or 'www'
    const subdomain = parseSubdomain(window.location.hostname);
    if (subdomain && isOrganizationSubdomain(subdomain)) {
      return lastStringOrUndefined(subdomain);
    }
  }

  return undefined;
};
