/**
 * Encoding strings beyond UTF-8/Latin1
 * https://stackoverflow.com/a/30106551/850291
 */
export const b64EncodeUnicode = str =>
  // first we use encodeURIComponent to get percent-encoded UTF-8,
  // then we convert the percent encodings into raw bytes which
  // can be fed into btoa.
  typeof window !== "undefined" && window.btoa(
    encodeURIComponent(str).replace(
      /%([0-9A-F]{2})/g,
      (match, p1) => String.fromCharCode(`0x${p1}`),
    ),
  );
