import {
  oneOfType,
  string,
  any,
  shape,
  arrayOf,
  bool
} from "prop-types";
import { idType } from "../../../lib/graphql/propTypes";

export const iconProp = oneOfType([string.isRequired, arrayOf(string.isRequired)]);

export const optionProp = shape({
  value: any,
  label: string,
});

export const teamVisibilityProp = shape({
  includedMembers: arrayOf(shape({
    id: idType.isRequired,
    isCandidate: bool,
  })),
  excludedMembers: arrayOf(shape({
    id: idType.isRequired,
    isCandidate: bool,
  })),
});

export const pricingTierProp = oneOfType([bool, string, shape({})]);
