import PropTypes from "prop-types";

let PropType = {};

if (process.env.NODE_ENV !== "production") {
  PropType = {
    disabled: PropTypes.bool,
    icon: PropTypes.node,
    editing: PropTypes.bool,
    onClick: PropTypes.func,
    size: PropTypes.string,
    user: PropTypes.shape({
      id: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      fullName: PropTypes.string,
      avatar: PropTypes.string,
    }),
  };
}

export const AvatarPropTypes = PropType;
