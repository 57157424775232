import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  Box,
  Button,
  Text
} from "grommet";
import { CircleAlert, FormClose } from "grommet-icons";

const CloseButton = styled(Button)`
  border-radius: 28px;
  opacity: 0.75;
`;

const StyledBox = styled(Box)`
  /* set appearance for disabled state */
  ${props => props.disabled && "opacity: 0.75;"}

  /* set appearance for error state */
  ${props => props.error && `
    background-color: #F9DFCC;
    svg {
      fill: #DF6100;
      stroke: #DF6100;
    }

    :hover {
      color: #FFFFFF;
      background-color: #DF6100;

      svg {
        fill: #FFFFFF;
        stroke: #FFFFFF;
      }

      button:hover {
        background-color: #FFFFFF;

        svg {
          fill: #DF6100;
          stroke: #DF6100;
        }
      }
    }
  `}
`;

function ChipComponent({
  disabled = false,
  error = false,
  icon = undefined,
  label = undefined,
  onClick = undefined,
  onDelete = undefined,
  size = undefined,
  ...props
}, ref) {
  let chipIcon = icon;

  // always override icon with error icon
  if (error) {
    chipIcon = <CircleAlert size="small" />;
  }

  const handleOnDelete = (event) => {
    event.preventDefault();
    event.stopPropagation();

    return onDelete(event);
  };

  return (
    <StyledBox
      ref={ref}
      a11yTitle={`chip ${label}`}
      align="center"
      alignContent="center"
      background="#F2F2F2"
      direction="row"
      disabled={disabled}
      error={error}
      focusIndicator
      gap="xsmall"
      hoverIndicator={{
        background: "background-back",
        color: "active-text",
      }}
      onClick={!disabled ? onClick : undefined}
      pad="xxsmall"
      responsive={false}
      round="large"
      {...props}
    >
      <Box
        align="center"
        direction="row"
        fill="horizontal"
        gap="small"
        justify="center"
        pad={{ horizontal: "xsmall" }}
        responsive={false}
      >
        {chipIcon}
        <Text size={size} textAlign="center" truncate>{label}</Text>
      </Box>
      {onDelete && (
        <CloseButton
          href="#" // Force anchor tag
          hoverIndicator="background-mono"
          icon={<FormClose color="status-critical" />}
          onClick={handleOnDelete}
          plain
        />
      )}
    </StyledBox>
  );
}

const Chip = React.forwardRef(ChipComponent);

Chip.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  icon: PropTypes.object,
  label: PropTypes.string,
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
  size: PropTypes.string,
};

export { Chip };
