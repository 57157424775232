import React from "react";
import { useRouter } from "next/router";
import {
  Box,
  Button,
  Nav,
  ResponsiveContext
} from "grommet";
import { Icon } from "@/cloverleaf-ui/components";
import { isMobile } from "@/cloverleaf-ui/utils";
import { HelpMenu } from "../../Navbar/components/HelpMenu";
import { MyOrganizationMenu } from "../../Navbar/components/MyOrganizationsMenu";
import { NotificationBell } from "../../Navbar/components/NotificationBell";
import { UserMenu } from "../../Navbar/components/UserMenu/UserMenu";
import { SidebarContext } from "./Sidebar.context";
import { Log } from "@/lib/analytics/Log";

const Header = ({ showSidebar = false }) => {
  const router = useRouter();
  const isDefaultLocale = router.locale === router.defaultLocale;

  const screenSize = React.useContext(ResponsiveContext);
  const { toggleSidebar } = React.useContext(SidebarContext);

  return (
    <Log section="header">
      <Box
        border="bottom"
        gridArea="header"
        direction="row"
        align="center"
        justify="between"
        pad={{ horizontal: "medium", vertical: "small" }}
        background="white"
        height="56px"
        style={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <Nav align="center" direction="row" border={{ side: "between" }}>
          {isMobile(screenSize) && showSidebar && (
            <Button
              onClick={toggleSidebar}
              label={(
                <Box pad={{ vertical: "small", right: "small", left: "xsmall" }}>
                  <Icon icon="bars" color="grey700" />
                </Box>
              )}
              plain
            />
          )}
          <Log component="MyOrganizationMenu">
            <MyOrganizationMenu />
          </Log>
        </Nav>
        <Nav align="center" direction="row" gap="large">
          <HelpMenu />
          {isDefaultLocale && (
            <NotificationBell />
          )}
          <UserMenu />
        </Nav>
      </Box>
    </Log>
  );
};

export { Header };
