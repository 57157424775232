import React from "react";
import {
  string,
  node,
  bool
} from "prop-types";
import { iconProp } from "../utils/propTypes";
import Button from "./Button";
import Icon from "./Icon";

function ButtonWithIcon({
  icon, children, label, fixedWidth = true, ...props
}) {
  return (
    <Button {...props} style={{ padding: !label && !children && "0 15px" }}>
      {icon && <Icon icon={icon} fixedWidth={fixedWidth} />} {children || label}
    </Button>
  );
}

ButtonWithIcon.propTypes = {
  children: node,
  label: string,
  icon: iconProp,
  fixedWidth: bool,
};

export default ButtonWithIcon;
