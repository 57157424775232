import { useMemo } from "react"
import {
  ApolloClient,
  InMemoryCache,
  from,
  ApolloLink,
  Observable,
  createHttpLink,
} from "@apollo/client"
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { captureException } from "@sentry/nextjs";
import { RestLink } from "apollo-link-rest";
import merge from "deepmerge"
import isEqual from "lodash/isEqual"
import { getSubdomain } from "../components/utils/getSubdomain";
import redirect from "../components/utils/redirect";
import { BASE_URL } from "./constants";
import handleSignout from "./handleSignout";
import possibleTypes from "./possibleTypes.json";
import typePolicies from "./typePolicies";
import { defaultLocale } from "@/i18n";

// Tell ApolloClient to ignore self signed certificates in development
process.env["NODE_TLS_REJECT_UNAUTHORIZED"] = process.env.NODE_ENV === "development" ? "0" : "";

const API_URI = `${BASE_URL}/api`;

const isBrowser = typeof window !== "undefined";

export const APOLLO_STATE_PROP_NAME = "__APOLLO_STATE__";

let apolloClient;

function createApolloClient(ctx) {
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      )
    if (networkError) console.error(`[Network error]: ${networkError}`)

    if (networkError?.statusCode === 401) {
      let redirectPath;

      if (typeof window !== "undefined") {
        redirectPath = new URL("/signin", window.location);

        if (window.location.pathname === redirectPath.pathname) {
          console.warn("Redirect was interrupted, you may be making an unauthorized request.");

          return;
        }

        redirectPath.searchParams.set("ref", `${window.location.pathname}${window.location.search}`);
      }

      handleSignout(ctx, apolloClient, redirectPath)
        .catch((err) => {
          captureException(err, { extra: { message: "Error calling handleSignout" }, tags: { type: "APOLLO_ERROR" }, level: "warning" });
        });
    }
  });

  const authLink = setContext(async (_, { headers }) => {
    // check if we're on the server-side
    if (typeof window === "undefined") {
      return {
        headers: {
          ...headers,
          "accept-language": headers?.cookie?.replace(/(?:(?:^|.*;\s*)NEXT_LOCALE\s*=\s*([^;]*).*$)|^.*$/, "$1") || defaultLocale,
          "cloverleaf-subdomain": getSubdomain(ctx),
        },
      };
    }

    return {
      headers: {
        ...headers,
        "accept-language": document.cookie.replace(/(?:(?:^|.*;\s*)NEXT_LOCALE\s*=\s*([^;]*).*$)|^.*$/, "$1") || defaultLocale,
        "cloverleaf-subdomain": getSubdomain(ctx),
      },
    };
  });

  const restLink = new RestLink({
    uri: API_URI,
    credentials: "include",
    responseTransformer: async (response) => {
      // Intercept to handle redirect, 302 responses.
      if (response?.redirected && response?.url) {
        return redirect({}, response?.url);
      }

      // Return the response as-is, without transforming it
      return response.json(); // have to return a promise
    },
  });

  const httpLink = createHttpLink({
    uri: `${API_URI}/graphql`,
    credentials: "include",
    headers: {
      "cloverleaf-subdomain": getSubdomain(ctx),
      Cookie: ctx?.req?.headers?.cookie ?? "",
    },
  });

  const interceptLink = new ApolloLink((operation, forward) => {
    if (process.env.NODE_ENV !== "production") {
      /**
       * Intercept broken strengthscope request and cancel it in local/test env.
       */
      if (operation?.operationName === "teamStrengthScope") {
        console.info("[Apollo] Aborting Strengthscope query in lower environments");

        return new Observable((subscriber) => {
          subscriber.error("Aborting teamStrengthScope query in lower environments");
        });
      }
    }

    return forward(operation);
  });

  return new ApolloClient({
    connectToDevTools: isBrowser,
    credentials: "include",
    ssrMode: !isBrowser,
    link: from([
      interceptLink,
      authLink,
      errorLink,
      restLink,
      httpLink,
    ]),
    cache: new InMemoryCache({
      possibleTypes,
      typePolicies,
    }),
  })
}

// eslint-disable-next-line default-param-last
export function initializeApollo({ state: initialState = null, ctx } = {}) {
  const _apolloClient = apolloClient ?? createApolloClient(ctx)

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract()

    // Merge the initialState from getStaticProps/getServerSideProps in the existing cache
    const data = merge(existingCache, initialState, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s))
        ),
      ],
    })

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data)
  }

  // For SSG and SSR always create a new Apollo Client
  if (!isBrowser) return _apolloClient;

  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient
}

export function addApolloState(client, pageProps) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return pageProps
}

export function useApollo(pageProps) {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  const store = useMemo(() => initializeApollo({ state }), [state]);

  return store
}
