import React from "react";
import {
  bool,
  shape,
  string
} from "prop-types";
import BaseSelect, { components as selectComponents } from "react-select";
import Async from "react-select/async";
import AsyncCreatable from "react-select/async-creatable";
import Creatable from "react-select/creatable";
import withLabel from "../hoc/withLabel";
import {
  DEFAULT_FONT,
  MEDIUM_SIZE,
  WHITE,
  GREY,
  BLACK,
  LIGHT_BLUE,
  GREEN,
  CONDENSED_FONT
} from "../utils/theme";
import Icon from "./Icon";

function DropdownIndicator(props) {
  // eslint-disable-next-line react/prop-types
  const { color } = props.getStyles("placeholder", props);

  return (
    selectComponents.DropdownIndicator && (
      <selectComponents.DropdownIndicator {...props}>
        <Icon icon="sort" color={color} />
      </selectComponents.DropdownIndicator>
    )
  );
}

function Input(props) {
  return selectComponents.Input && (
    <selectComponents.Input
      {...props}
      autoComplete="off"
    />
  );
}

export const defaultSelectStyles = {
  placeholder: base => ({
    ...base,
    whiteSpace: "nowrap",
    color: BLACK,
    fontFamily: DEFAULT_FONT,
    fontSize: MEDIUM_SIZE,
  }),
  valueContainer: base => ({
    ...base,
    padding: 0,
    margin: 0,
    boxShadow: "none",
  }),
  control: (base, state) => ({
    ...base,
    minHeight: "25px",
    padding: "2px 0 2px 6px",
    backgroundColor: WHITE,
    color: BLACK,
    boxShadow: "none",
    borderRadius: state.menuIsOpen ? "4px 4px 0 0" : "4px",
    "&:hover": {
      borderColor: GREEN,
    },
    border: state.isFocused ? `1px solid ${GREEN}` : `1px solid ${GREY}`,
  }),
  option: (base, state) => ({
    ...base,
    color: state.isDisabled ? GREY : BLACK,
    border: 0,
    fontFamily: DEFAULT_FONT,
    fontSize: MEDIUM_SIZE,
    backgroundColor: state.isFocused ? LIGHT_BLUE : WHITE,
  }),
  indicatorSeparator: base => ({
    ...base,
    visibility: "hidden",
  }),
  menu: (base, state) => ({
    ...base,
    margin: 0,
    padding: 0,
    border: `1px solid ${GREY}`,
    borderRadius: "0 0 4px 4px",
    borderTop: state.isFocused ? "none" : "inherit",
    backgroundColor: WHITE,
    boxShadow: "0 4px 11px hsla(0, 0%, 0%, 0.1)", // Base box shadow without border as shadow
  }),
  singleValue: base => ({
    ...base,
    whiteSpace: "nowrap",
    color: BLACK,
    fontSize: MEDIUM_SIZE,
    fontFamily: DEFAULT_FONT,
    border: 0,
  }),
  groupHeading: base => ({
    ...base,
    color: GREEN,
    fontSize: MEDIUM_SIZE,
    fontFamily: CONDENSED_FONT,
    border: 0,
  }),
};

const getComponent = (creatable, async) => {
  if (creatable && async) {
    return AsyncCreatable;
  }
  if (creatable) {
    return Creatable;
  }
  if (async) {
    return Async;
  }

  return BaseSelect;
};

class Select extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      Component: getComponent(props.creatable, props.async),
    };
  }

  render() {
    const { Component } = this.state;
    const {
      // Remove async, creatable from being passed to Component
      // eslint-disable-next-line unused-imports/no-unused-vars
      async, creatable,
      components = {},
      name,
      ...otherProps
    } = this.props;

    return (
      <Component
        instanceId={name}
        styles={defaultSelectStyles}
        defaultInputValue=""
        isValidNewOption={val => val && val !== ""}
        components={{ DropdownIndicator, Input, ...components }}
        {...otherProps}
      />
    );
  }
}

Select.propTypes = {
  async: bool,
  creatable: bool,
  components: shape(),
  name: string, // Adding a name to the select for now will eliminate the server mismatch error
};

export const LabeledSelect = withLabel(Select);

export default Select;
