import { gql } from "@apollo/client";
import {
  contentDebugFragmentEnneagramSecondaryTrait,
  contentDebugFragmentUserTrait,
  contentDebugFragmentTraitInterface,
} from "./fragments";

export const discScoreFragment = gql`
  fragment DiscScoreFragment on DiscScore {
    title {
      id
      name
      secondaryName
      description
      ...ContentDebugFragmentTraitInterface
    }
    traits {
      id
      name
      secondaryName
      value
      description
      ...ContentDebugFragmentTraitInterface
    }
    tti {
      wheel
    }
  }
  ${contentDebugFragmentTraitInterface}
`;

export const personalityScoreFragment = gql`
  fragment PersonalityScoreFragment on PersonalityScore {
    title {
      id
      name
      secondaryName
      description
      ...ContentDebugFragmentTraitInterface
      characteristics
    }
    traits {
      id
      name
      secondaryName
      secondaryNameKey
      value
      description
      ...ContentDebugFragmentTraitInterface
    }
  }
  ${contentDebugFragmentTraitInterface}
`;

export const scoresFragment = gql`
  fragment ScoresFragment on Scores {
    personality {
      ...PersonalityScoreFragment
    }
    disc {
      ...DiscScoreFragment
    }
    strengths {
      title {
        id
        name
        secondaryName
        description
        ...ContentDebugFragmentTraitInterface
      }
      traits {
        id
        name
        nameKey
        secondaryName
        secondaryNameKey
        description
        ...ContentDebugFragmentTraitInterface
        order
      }
    }
    via {
      title {
        id
        name
        secondaryName
        description
        ...ContentDebugFragmentTraitInterface
      }
      traits {
        id
        name
        nameKey
        secondaryName
        secondaryNameKey
        description
        ...ContentDebugFragmentTraitInterface
        order
      }
    }
    culture {
      traits {
        id
        name
        nameKey
        description
        ...ContentDebugFragmentTraitInterface
        value
        order
      }
    }
    motivatingvalues {
      traits {
        id
        name
        nameKey
        description
        ...ContentDebugFragmentTraitInterface
        value
        order
      }
    }
    drivingforces {
      traits: groupedTraits {
        name
        secondaryName
        scores {
          name
          value
          description
        }
      }
    }
    instinctiveDrives {
      isSearching
      title {
        id
        name
        secondaryName
        description
        ...ContentDebugFragmentUserTrait
        value
      }
      traits {
        id
        name
        nameKey
        secondaryName
        description
        ...ContentDebugFragmentUserTrait
        value
      }
    }
    enneagram {
      graphic
      title {
        id
        name
        secondaryName
        description
        ...ContentDebugFragmentTraitInterface
      }
      triad {
        id
        name
        secondaryName
        description
        ...ContentDebugFragmentEnneagramSecondaryTrait
        type
      }
      wing {
        id
        name
        secondaryName
        description
        ...ContentDebugFragmentTraitInterface
      }
      growth {
        id
        name
        secondaryName
        description
        ...ContentDebugFragmentEnneagramSecondaryTrait
        type
      }
      stress {
        id
        name
        secondaryName
        description
        ...ContentDebugFragmentEnneagramSecondaryTrait
        type
      }
      share {
        twitter
      }
    }
    energyRhythm {
      graphic
      title {
        id
        name
        secondaryName
        secondaryNameKey
        description
        ...ContentDebugFragmentUserTrait
      }
      traits {
        id
        name
        value
        secondaryName
        description
        ...ContentDebugFragmentUserTrait
      }
    }
    strengthscope {
      graphic
      isPremium
      title {
        id
        name
        secondaryName
        description
        ...ContentDebugFragmentTraitInterface
      }
      traits {
        id
        name
        secondaryName
        secondaryNameKey
        iconUrl
        descriptions {
          label
          message
          isPremium
        }
      }
    }
  }
  ${personalityScoreFragment}
  ${discScoreFragment}
  ${contentDebugFragmentTraitInterface}
  ${contentDebugFragmentEnneagramSecondaryTrait}
  ${contentDebugFragmentUserTrait}
`;
