import React from "react";
import PropTypes from "prop-types";
import { Box, Text } from "grommet";
import { Icon } from "@/components/ui";

function InviteSuggestion({
  border = undefined,
  label = undefined,
  selected = false,
  ...props
}) {
  return (
    <Box
      align="center"
      background={{ color: selected ? "green700" : undefined }}
      border={border}
      direction="row"
      gap="medium"
      pad="small"
      {...props}
    >
      <Icon icon={["far", "paper-plane" ]} />
      <Text>{label}</Text>
    </Box>
  );
}

InviteSuggestion.propTypes = {
  border: PropTypes.string,
  label: PropTypes.string,
  selected: PropTypes.bool,
};

export { InviteSuggestion };
