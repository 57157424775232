import React from "react";
import styled from "styled-components";

const StyledSpinner = styled.svg`
  animation: none;

  & .blueCircle {
    animation: blue 3s infinite;
  }

  & .greenCircle {
    animation: green 3s infinite;
  }

  & .redCircle {
    animation: red 3s infinite;
  }

  @keyframes blue {
    33% {
      transform: translateX(-96px);
    }
    66% {
      transform: translate(-48px, -84px);
    }
  }

  @keyframes green {
    33% {
      transform: translate(48px, -84px);
    }
    66% {
      transform: translate(96px, 0px);
    }
  }

  @keyframes red {
    33% {
      transform: translate(48px, 84px);
    }
    66% {
      transform: translate(-48px, 83px);
    }
  }

  /**
   * Fallback - Safari does not support "mix-blend-mode" on SVGs
   * https://caniuse.com/mdn-css_properties_mix-blend-mode_svg
   *
   * CSS Hack from: https://browserstrangeness.bitbucket.io/css_hacks.html#safari
   */
  @supports (-webkit-hyphens:none) {
    & .redCircle {
      stroke: rgba(223, 97, 0, 0.5);
      fill: rgba(223, 97, 0, 0.5);
    }

    & .greenCircle {
      stroke: rgba(0, 184, 95, 0.5);
      fill: rgba(0, 184, 95, 0.5);
    }

    & .blueCircle {
      stroke: rgba(0, 156, 184, 0.5);
      fill: rgba(0, 156, 184, 0.5);
    }

    & g {
      mix-blend-mode: darken !important;
    }
  }
`;

export default (
  <StyledSpinner
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 300 300"
    height="48px"
    width="48px"
  >
    <g style={{ mixBlendMode: "screen" }}>
      <path
        className="greenCircle"
        stroke="#00B85F"
        fill="#00B85F"
        d="M101.809 113.653c42.772 0 77.5 34.728 77.5 77.5s-34.728 77.5-77.5 77.5c-42.773 0-77.5-34.728-77.5-77.5s34.727-77.5 77.5-77.5z"
      />
    </g>
    <g style={{ mixBlendMode: "screen" }}>
      <path
        className="redCircle"
        stroke="#DF6100"
        fill="#DF6100"
        d="M150.059 30.355c42.772 0 77.5 34.728 77.5 77.5s-34.728 77.5-77.5 77.5-77.5-34.728-77.5-77.5 34.728-77.5 77.5-77.5z"
      />
    </g>
    <g style={{ mixBlendMode: "screen" }}>
      <path
        className="blueCircle"
        stroke="#009CB8"
        fill="#009CB8"
        d="M198.383 113.754c42.772 0 77.5 34.728 77.5 77.5s-34.728 77.5-77.5 77.5-77.5-34.728-77.5-77.5 34.728-77.5 77.5-77.5z"
      />
    </g>
  </StyledSpinner>
);
