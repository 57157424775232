import styled from "styled-components";
import { convertHexToRGBA } from "../../utils";
import { GREEN, DARKEST_GREEN } from "../../utils/theme";
import Text from "./Text";

const NotificationText = styled(Text)`
  padding: 0.875em;
  border: 1px solid ${GREEN};
  border-radius: 5px;
  color: ${DARKEST_GREEN};
  background-color: ${convertHexToRGBA(GREEN, 0.05)};
`;

export default NotificationText;
