export const initialState = {};

/**
 * ACTIONS
 */

export const USER_SIGNOUT = "cloverleaf/user/USER_SIGNOUT";

/**
 * ACTION CREATORS
 */

export const signout = () => ({
  type: USER_SIGNOUT,
});

export default function reducer(state = initialState) {
  return state;
}
