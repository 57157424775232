import React from "react";
import { DataTable as GrommetDataTable } from "grommet";
import { getThemeColor, themeStyle } from "@/cloverleaf-ui/theme";

function DataTable(props) {
  return (
    <GrommetDataTable
      tableStyles={{
        borderStyle: "solid",
        borderColor: getThemeColor("grey400"),
        borderWidth: themeStyle.global.edgeSize.hair,
        borderRadius: themeStyle.global.edgeSize.xxsmall,
      }}
      pad={{
        header: "medium",
        body: "medium",
      }}
      {...props}
    />
  );
}

export { DataTable };
