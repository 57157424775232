import { forwardRef } from "react";
import { Menu as GrommetMenu } from "grommet";
import { EventNames, logEventPropsPropType } from "@/lib/analytics/constants";
import { LogEvent } from "@/lib/analytics/LogEvent";

const Menu = forwardRef(function Menu({ logEventProps = {}, sensitive, ...props }, ref) {
  const menuLabel = !sensitive && typeof props.label === "string" ? props.label : undefined;

  return (
    <LogEvent
      logEventProps={{
        elementName: "menu",
        eventName: EventNames.MenuEngagement,
        ui_properties: {
          menuLabel,
          ...logEventProps?.ui_properties
        },
        ...logEventProps,
      }}
      actionProps={{ onClick: { action: "toggle" } }}
      elementType="menu"
    >
      <GrommetMenu
        ref={ref}
        {...props}
      />
    </LogEvent>
  );
});

Menu.propTypes = {
  ...GrommetMenu.propTypes,
  logEventProps: logEventPropsPropType,
};

export { Menu };
