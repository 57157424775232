import React from "react";
import PropTypes from "prop-types";
import Link from "next/link";
import { Button } from "./Button";
import { logEventPropsPropType } from "@/lib/analytics/constants";

function ButtonLink({
  href = undefined,
  ...props
}) {
  if (props.disabled || !href) {
    /**
     * Prevent failure
     */
    if (!href) {
      console.info("The prop `href` expects a `string` or `object` in `<Link>`, but got `undefined` instead. Falling back to a Button component.");
    }

    return <Button {...props} />;
  }

  return (
    <Link href={href} passHref legacyBehavior>
      <Button {...props} />
    </Link>
  );
}

ButtonLink.propTypes = {
  href: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string,
      query: PropTypes.shape(),
    }),
  ]),
  logEventProps: logEventPropsPropType,
};

export { ButtonLink };
