import React from "react";
import { string } from "prop-types";
import { RED, DEFAULT_FONT } from "../utils/theme";
import { Flex } from "./FlexBox";
import TextLabel from "./styled/TextLabel";

function FieldError({ error }) {
  return (
    <Flex w={1} justify="center">
      <TextLabel color={RED} small font={DEFAULT_FONT}>Error: {error}</TextLabel>
    </Flex>
  );
}

FieldError.propTypes = {
  error: string.isRequired,
};

export default FieldError;
