import React from "react";
import PropTypes from "prop-types";
import { useQuery, gql } from "@apollo/client";
import styled from "styled-components";
import { Grommet } from "grommet";
import { Icon } from "@/cloverleaf-ui/components/atoms";
import { DAILY_COACHING_STAGES } from "@/cloverleaf-ui/constants";
import { getThemeColor, themeStyle } from "@/cloverleaf-ui/theme";
import { Banner } from "./components/Banner";
import { useLocale } from "@/lib/hooks";

const DAILY_COACHING_QUERY = gql`
  query dailyCoaching {
    currentUser {
      id
      dailyCoaching {
        id
        currentStage {
          id
          stage
        }
      }
    }
  }
`;

const StyledGrommet = styled(Grommet)`
  margin: 0 auto 10px;
  padding: 0 10px;
  width: 100%;
  @media (min-width: 440px) {
    max-width: 420px;
  }
  @media (min-width: 954px) {
    max-width: 840px;
  }
  @media (min-width: 1370px) {
    max-width: 100%;
  }
`;

const StyledBanner = styled(Banner)`
  @media (min-width: 770px) and (max-width: 954px) {
    flex-wrap: wrap;
    height: 200px;
    justify-content: center;
  }
`;

function DailyCoachingBanner({
  getsDailyCoaching = false,
}) {
  const { t } = useLocale();

  const { data } = useQuery(DAILY_COACHING_QUERY, {
    skip: !getsDailyCoaching,
  });

  if (!getsDailyCoaching) {
    return null;
  }

  const currentStage = data?.currentUser?.dailyCoaching?.currentStage?.stage;

  if (currentStage === DAILY_COACHING_STAGES.explore) {
    return (
      <StyledGrommet theme={themeStyle}>
        <StyledBanner
          icon={<Icon icon={["fas", "check-circle"]} color={getThemeColor("blue700")} style={{ width: "24px", height: "24px", paddingLeft: "8px" }} />}
          link={{
            href: {
              pathname: "/my-dashboard",
              query: { "daily-coaching": true },
            },
            label: t("dash.dc-banner.explore.link-label")?.toUpperCase(),
          }}
          helpTip={t("dash.dc-banner.explore.help-tip")}
          title={t("dash.dc-banner.explore.title")}
        />
      </StyledGrommet>
    );
  }

  if (currentStage === DAILY_COACHING_STAGES.teammate || currentStage === DAILY_COACHING_STAGES.self) {
    return (
      <StyledGrommet theme={themeStyle}>
        <StyledBanner
          icon={<Icon icon={["fas", "stars"]} color={getThemeColor("blue700")} style={{ width: "24px", height: "24px", paddingLeft: "8px" }} />}
          link={{
            href: {
              pathname: "/my-dashboard",
              query: { "daily-coaching": true },
            },
            label: t("dash.dc-banner.teammate.link-label")?.toUpperCase(),
          }}
          helpTip={t("dash.dc-banner.teammate.help-tip")}
          title={t("dash.dc-banner.teammate.title")}
        />
      </StyledGrommet>
    );
  }

  return null;
}

DailyCoachingBanner.propTypes = {
  getsDailyCoaching: PropTypes.bool,
};

export { DailyCoachingBanner };
