import React from "react";
import {
  string,
  bool,
  shape,
  oneOfType
} from "prop-types";
import { Flex } from "./FlexBox";

function ErrorMessage({ error, center = false, hideLabel = false }) {
  return (
    <Flex w={1} p={1} mb={1} justify="center">
      <span
        className="negative"
        style={center ? { textAlign: "center" } : undefined}
      >
        { !hideLabel ? "Error: " : "" }{error && error.message ? error.message : error}
      </span>
    </Flex>
  );
}

ErrorMessage.propTypes = {
  error: oneOfType([string, shape({ message: string })]).isRequired,
  hideLabel: bool,
  center: bool,
};

export default ErrorMessage;
