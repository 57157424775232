import { identify } from "./analytics";

// Segment snippet should have already been initialized through the _document.js <head>

function loadSegment(user) {
  if (typeof window !== "undefined" && window) {
    if (window.analytics) {
      // If we have a user, load analytics - we have consent through creating an account so no need to run it through ConsentManager.

      if (user && user.id) {
        // If we have a user, identify them.
        identify(user.id, user, {
          integrations: {
            Intercom: {
              user_hash: user.intercomId,
            },
          },
        });
      }

      // Set intercom button location
      window.analytics.ready(() => {
        window.intercomSettings = {
          vertical_padding: 50,
          user_hash: user?.intercomId,
        };
      });
    }
  }
}

export default loadSegment;
