import React, { useRef } from "react";
import { node, shape } from "prop-types";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";

class ErrorBoundary extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  static propTypes = {
    children: node.isRequired,
    render: node.isRequired,
  };

  componentDidCatch(error) {
    this.setState({ error });
  }

  render() {
    if (this.state.error) {
      return (
        <div style={{ width: "inherit" }}>
          {this.props.render}
        </div>
      );
    }

    // when there's not an error, render children untouched
    return this.props.children;
  }
}

// All modifiers that alter styles must have a defined execution order before applyStyles - (900)
export const addZIndexModifier = zIndex => ({
  enabled: !!zIndex,
  order: 899,
  fn: data => ({
    ...data,
    styles: {
      ...data.styles,
      zIndex,
    },
  }),
});

const Tooltip = ({
  children,
  html,
  open = false, // should not be used in controlled mode (visible prop)
  theme,
  position,
  title,
  style: _styled, // remove from props
  ...props
}) => {
  const positionRef = useRef(null);
  if (open) {
    props.getReferenceClientRect = () => positionRef?.current?.getBoundingClientRect?.();
    props.showOnCreate = true; // set here so not included in controlled mode (visible = true)
  }

  return (
    <ErrorBoundary render={children}>
      <Tippy
        allowHTML={Boolean(html)}
        content={html || title}
        placement={position}
        theme={theme}
        {...props}
      >
        <div ref={positionRef} style={{ width: "inherit" }}>
          {children}
        </div>
      </Tippy>
    </ErrorBoundary>
  )
};

Tooltip.displayName = "Tooltip";

/**
 * Wrap a component with an optional tooltip.
 * Tooltip only gets added if the component is given the "tooltip" prop, which
 * contains all Tippy props.
 *
 * You can optionally pass in config
 */
const withTooltip = (Component, options = { arrow: true, theme: "cloverleaf" }) => {
  const ForwardComp = React.forwardRef((p, ref) => <Component ref={ref} {...p} />);
  ForwardComp.displayName = Component?.displayName || Component?.name || "ForwardedComponent";

  const Wrapped = React.forwardRef(({
    tooltip = undefined,
    ...props
  }, withRef) => (
    tooltip
      ? (
        <Tooltip
          {...{
            ...options,
            ...tooltip,
          }}
        >
          {<ForwardComp ref={withRef} {...props} />}
        </Tooltip>
      )
      : <ForwardComp ref={withRef} {...props} />
  ));

  Wrapped.propTypes = {
    tooltip: shape({}),
  };

  Wrapped.displayName = "WrappedWithTooltip";

  return Wrapped;
};

export { withTooltip, Tooltip };

export default Tooltip;
