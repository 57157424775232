import React from "react";
import PropTypes from "prop-types";
import { useRouter } from "next/router";
import { useQuery } from "@apollo/client";
import {
  Box,
  Button,
  Grommet,
  Text,
  Image,
} from "grommet";
import { themeStyle } from "@/cloverleaf-ui/theme";
import { getAssessmentKeyByAssessmentType } from "@/cloverleaf-ui/utils";
import { EVENT } from "../../../../components/utils/constants";
import { track } from "../../../../lib";
import { currentUserQuery } from "../../../../lib/graphql/currentUser";
import { LoadingIndicator } from "../../atoms";
import { ASSESSMENT_LINKS } from "@/lib/graphql/assessments";
import { useLocale } from "@/lib/hooks";

function TakeAssessmentButtonGroup({
  assessment = undefined,
  assessmentPayload = undefined,
  children = null,
  onShowResults = () => undefined,
  showViewResultsButton = false,
  team = undefined,
}) {
  const router = useRouter();

  const { t } = useLocale();

  const [showResults, setShowResults] = React.useState(false);
  const { data, loading } = useQuery(currentUserQuery);

  const isTeamAdmin = team?.callerCanManage;
  const isOrgAdmin = data?.currentUser?.hasAdminPermission;
  const isCloverleafAdmin = data?.currentUser?.isCloverleafAdmin;

  const assessmentKey = getAssessmentKeyByAssessmentType(assessment);
  const currentUserScore = data?.currentUser?.scores?.[assessmentKey];
  const renderViewResultsButton = showViewResultsButton
    ?? team?.users?.filter(user => user?.scores?.[assessmentKey]).length >= 1;

  React.useEffect(() => {
    if (showResults) {
      onShowResults();
    }
  }, [onShowResults, showResults]);

  if (!assessment) {
    return null;
  }

  const handleTakeAssessment = () => {
    track(EVENT.TakeAssessmentCTAClicked.name, {
      location: EVENT.TakeAssessmentCTAClicked.props.location.teamDashboard,
      assessment,
    });

    return router.push(ASSESSMENT_LINKS[assessment]);
  };

  const handleViewResultsClick = () => {
    setShowResults(true);

    track(EVENT.ViewResultsCTAClicked.name, {
      location: EVENT.ViewResultsCTAClicked.props.location.teamDashboard,
      assessment,
    });
  };

  if (loading) {
    return (
      <Grommet theme={themeStyle}>
        <LoadingIndicator />
      </Grommet>
    );
  }

  if (showResults) {
    return children;
  }

  if (isTeamAdmin || isOrgAdmin || isCloverleafAdmin) {
    setShowResults(true);

    return children;
  }

  if (currentUserScore) {
    // If there aren't any children, onShowResults callback is expected to determine what is rendered
    setShowResults(true);

    return children;
  }

  return (
    <Grommet theme={themeStyle}>
      <Box
        align="center"
        direction="column"
        pad="large"
        gap="medium"
      >
        {assessmentPayload && (
          <React.Fragment>
            <Box alignContent="center" width="small">
              <Image
                fit="contain"
                fill="horizontal"
                alt="Cloverleaf"
                src={assessmentPayload.image.src}
                a11yTitle={assessmentPayload.image.alt}
              />
            </Box>
            <Text textAlign="center">{assessmentPayload.header}</Text>
          </React.Fragment>
        )}
        <Button
          color="green500"
          label={t("take-assessment")}
          onClick={handleTakeAssessment}
          style={{
            padding: "16px",
          }}
          primary
        />
        {renderViewResultsButton && (
          <Button
            color="green500"
            label={t("view-team-results")}
            onClick={handleViewResultsClick}
            style={{
              padding: "16px",
            }}
            secondary
          />
        )}
      </Box>
    </Grommet>
  );
}

TakeAssessmentButtonGroup.propTypes = {
  assessment: PropTypes.string,
  assessmentPayload: PropTypes.shape({
    header: PropTypes.string,
    image: PropTypes.object,
  }),
  children: PropTypes.any,
  onShowResults: PropTypes.func,
  showViewResultsButton: PropTypes.bool,
  team: PropTypes.shape({
    assessments: PropTypes.object,
    users: PropTypes.array,
  }),
};

export { TakeAssessmentButtonGroup };
