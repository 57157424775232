import React from "react";
import PropTypes from "prop-types";
import {
  AccordionPanel as GrommetAccordionPanel,
  Box,
  Text,
} from "grommet";

const AccordionPanel = React.forwardRef(function AccordionPanel({
  children,
  label,
  ...props
}, ref) {
  return (
    <GrommetAccordionPanel
      ref={ref}
      label={(
        <Box align="center" pad="small">
          <Text size="small">{label}</Text>
        </Box>
      )}
      {...props}
    >
      {children}
    </GrommetAccordionPanel>
  );
});

AccordionPanel.propTypes = {
  children: PropTypes.any.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

export { AccordionPanel };
