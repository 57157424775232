import React from "react";
import {
  string,
  func,
  shape,
  arrayOf,
  oneOfType,
  bool
} from "prop-types";
import isFunction from "lodash/fp/isFunction";
import styled from "styled-components";
import { iconProp } from "../../utils/propTypes";
import { WHITE } from "../../utils/theme";
import { SIDEBAR } from "../../utils/zIndex";
import { Flex } from "../FlexBox";
import MenuOption from "./MenuOption";

// z-index is SIDEBAR because it needs to be ABOVE the Banner on teams.
const FlexContainer = styled(Flex)`
  background-color: ${WHITE};
  box-shadow: 0 10px 10px 0 rgba(0,0,0,0.10);
  border-radius: 5px;
  position: absolute;
  right: 0;
  z-index: ${SIDEBAR};
`;

class MenuOptions extends React.PureComponent {
  static propTypes = {
    options: arrayOf(
      oneOfType([
        func,
        shape({
          label: string.isRequired,
          icon: iconProp,
          onClick: func.isRequired,
          Component: func,
          disabled: bool,
        }),
      ]),
    ).isRequired,
    onClick: func,
    style: shape({}),
  }

  static defaultProps = {
    onClick: () => undefined,
    style: undefined,
  }

  wrapClick = onClick => (e) => {
    if (onClick) onClick(e);
    this.props.onClick(e);
  }

  renderOption = (option, i) => {
    if (isFunction(option)) {
      const Option = option;

      return <Option key={`menuoption_${i}`} />;
    }

    return (
      <MenuOption
        key={option.label}
        label={option.label}
        icon={option.icon}
        onClick={this.wrapClick(option.onClick)}
        Component={option.Component}
        disabled={option.disabled}
      />
    );
  }

  render() {
    return (
      <FlexContainer column style={this.props.style}>
        {this.props.options.map(this.renderOption)}
      </FlexContainer>
    );
  }
}

export default MenuOptions;
