import React from "react";
import { string, shape } from "prop-types";
import Link from "next/link";
import { iconProp } from "../utils/propTypes";
import { BLACK } from "../utils/theme";
import Button from "./Button";
import { Flex } from "./FlexBox";
import Heading from "./Heading";
import Icon from "./Icon";

function MessageWithIcon({
  icon, iconColor, messageText, buttonText, buttonLink, buttonProps,
}) {
  return (
    <Flex column align="center" justify="center">
      <Icon icon={icon} size="6x" color={iconColor} />
      <Heading size={2} color={BLACK} center>{messageText}</Heading>
      <Link href={buttonLink} legacyBehavior>
        <a>
          <Button {...buttonProps}>{buttonText}</Button>
        </a>
      </Link>
    </Flex>
  );
}

MessageWithIcon.propTypes = {
  icon: iconProp.isRequired,
  iconColor: string.isRequired,
  messageText: string.isRequired,
  buttonText: string.isRequired,
  buttonLink: string.isRequired,
  buttonProps: shape(),
};

export default MessageWithIcon;
