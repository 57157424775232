import React from "react";
import { node, number } from "prop-types";
import styled from "styled-components";
import RoundedBox from "./styled/RoundedBox";

const MinHeightBox = styled(RoundedBox)`
  min-height: 550px;
  ${props => (Number.isInteger(props.zIndex) ? `z-index: ${props.zIndex}` : "")}; 

  @media (max-width: 400px) {
    margin-top: 16px;
  }
`;

function ContentBox({
  children,
  w = 400,
  mt = 4,
  pt = 1,
  pb = 4,
  zIndex,
}) {
  return (
    <MinHeightBox
      w={w}
      mt={mt}
      pt={pt}
      pb={pb}
      column
      align="center"
      justify="flex-start"
      zIndex={zIndex}
    >
      {children}
    </MinHeightBox>
  );
}

ContentBox.propTypes = {
  children: node.isRequired,
  w: number,
  mt: number,
  pt: number,
  pb: number,
  zIndex: number,
};

export default ContentBox;
