import React from "react";
import {
  func,
  arrayOf,
  shape,
  string,
  bool
} from "prop-types";
import { TransitionGroup } from "react-transition-group";
import styled from "styled-components";
import Icon from "../../ui/Icon";
import {
  GREEN,
  RED,
  WHITE
} from "../../utils/theme";
import { ABOVE_ALL } from "../../utils/zIndex";
import FadeTransition from "../FadeTransition";

const ToastList = styled.ul`
  position: fixed;
  list-style-type: none;
  z-index: ${ABOVE_ALL};
  padding: 0;
  right: 10px;
  left: 10px;
  margin: auto;
  max-width: ${props => ((props.left || props.right) ? "350px" : "600px")};
`;

const getToastStyle = (type) => {
  switch (type) {
    case "error":
      return (
        `background-color: ${RED};
        color: ${WHITE};`
      );
    default:
      return (
        `background-color: ${GREEN};
        color: ${WHITE}};`
      );
  }
};

const ToastBody = styled.li`
  box-shadow: 0 10px 10px 0 rgba(0,0,0,0.10);
  align-items: flex-start;
  border-radius: 4px;
  display: flex;
  padding: 14px;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: ${props => (props.left ? 0 : "auto")};
  margin-right: ${props => (props.right ? 0 : "auto")};
  ${props => getToastStyle(props.type)}
`;

const ToastContent = styled.p`
  flex: 1 1 auto;
  margin: 0 12px 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ToastDismiss = styled.button`
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  border: 0;
  color: inherit;
  cursor: pointer;
  display: block;
  flex: 0 0 auto;
  font: inherit;
  padding: 0;
`;

class Toast extends React.PureComponent {
  static propTypes = {
    text: string.isRequired,
    type: string,
    right: bool,
    left: bool,
    removeToast: func.isRequired,
  }

  static defaultProps = {
    type: "alert",
    right: false,
    left: false,
  }

  componentDidMount() {
    this.autoClose = setTimeout(() => {
      this.props.removeToast();
    }, 5000);
  }

  componentWillUnmount() {
    clearTimeout(this.autoClose);
  }

  render() {
    const {
      text, type, right, left, removeToast,
    } = this.props;

    return (
      <ToastBody type={type} left={left} right={right}>
        <ToastContent>
          {text}
        </ToastContent>
        <ToastDismiss onClick={removeToast}>
          <Icon icon="times" />
        </ToastDismiss>
      </ToastBody>
    );
  }
}

function Toasts({ removeToast, toasts }) {
  return (
    <ToastList>
      <TransitionGroup>
        {toasts.map((toast) => {
          const {
            id, text, type, left, right,
          } = toast;

          return (
            <FadeTransition key={id} in>
              <Toast
                text={text}
                type={type}
                left={left}
                right={right}
                removeToast={() => removeToast(id)}
              />
            </FadeTransition>
          );
        })}
      </TransitionGroup>
    </ToastList>
  );
}

Toasts.propTypes = {
  removeToast: func.isRequired,
  toasts: arrayOf(shape({})).isRequired,
};

export default Toasts;
