import { string, bool } from "prop-types";
import styled from "styled-components";
import { GREEN } from "../utils/theme";

const FadeDivider = styled.div`
  background: linear-gradient(${props => (props.right ? "90deg" : "-90deg")}, ${props => props.color || GREEN}, rgba(0,0,0,0));
  width: ${props => (props.w ? props.w : "100%")};
  height: 2px;
  ${props => (props.right ? `margin-left: ${props.margin || 16}px;` : `margin-right: ${props.margin || 16}px;`)}
`;

FadeDivider.propTypes = {
  w: string.isRequired,
  right: bool,
};

export default FadeDivider;
