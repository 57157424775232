import { useMutation, gql } from "@apollo/client";
import * as Sentry from "@sentry/nextjs";

const resetPasswordException = ({
  message = "Unknown Error",
  status = "Unknown",
}) => ({
  message,
  status,
});

export const RESET_PASSWORD_STATUS = {
  SUCCESS: "SUCCESS",
};

export const RESET_PASSWORD_QUERY = gql`
  mutation resetPassword($input: input) {
    resetPassword(input: $input)
      @rest(type: "Response", path: "/user/reset-password", method: "POST") {
        message
        status
        success
    }
  }
`;

export const useResetPassword = () => {
  const [
    resetPassword,
    { data, loading, error },
  ] = useMutation(RESET_PASSWORD_QUERY);

  const handleResetPassword = async ({ email, subdomain }) => {
    try {
      const {
        data: response,
      } = await resetPassword({
        variables: {
          input: { email, subdomain },
        },
      });

      const {
        message,
        status,
        success,
      } = response.resetPassword;

      return {
        message,
        status,
        success,
      };
    }
    catch (errorResponse) {
      const { message, status, success } = errorResponse?.networkError?.result || {};

      Sentry.captureException(errorResponse, {
        extra: {
          arguments: { email },
          message,
          status,
          success,
        },
        tags: {
          function: "useResetPassword",
        },
      });

      throw resetPasswordException({ message, status });
    }
  };

  return {
    data,
    error,
    loading,
    resetPassword: handleResetPassword,
    resetPasswordStatus: RESET_PASSWORD_STATUS,
  };
};
