import PropTypes from "prop-types";

export const logEventPropsPropType = PropTypes.shape({
  elementName: PropTypes.string, // identifying string for the element
  eventName: PropTypes.string, // name of the event to be logged
  ui_properties: PropTypes.shape({ // ui properties to be logged
    menuLabel: PropTypes.string, // visible label interaction for the menu
    menuOpen: PropTypes.bool, // whether the menu is open or closed when clicked
    buttonText: PropTypes.string, // visible text of the button
    buttonStyle: PropTypes.string, // style of the button, primary|secondary|plain
    buttonType: PropTypes.string, // type of button, link|button
  }),
  // additional properties may be added for custom logging
});

export const EventNames = {
  ButtonEngagement: "Button Engagement",
  GettingStartedWithSearchClosed: "Getting Started With Search Closed",
  HelpIconClicked: "Help Icon Clicked",
  MenuEngagement: "Menu Engagement",
  UserMenuClicked: "User Menu Clicked",
};
