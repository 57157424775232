import React from "react";
import curry from "lodash/fp/curry";

const withProps = curry((callback, Component) => (function WithProps(props) {
  const newProps = callback(props);
  const mergedProps = { ...props, ...newProps };

  return <Component {...mergedProps} />;
}));

export default withProps;
