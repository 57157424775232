import { locales } from "@/i18n";

/** Waiting on direction for flag icons */
const displayNames = {
  en: "English (US)",
  es: "Español (ES)",
  de: "Deutsche (DE)",
};

const languages = locales.map(locale => {
  return {
    language: locale,
    name: displayNames[locale],
  };
});

export { languages };
