const reduceUsers = (users) => {
  const result = users?.reduce(
    (prev, next) => (next.users ? prev.concat(next.users.filter(user => !prev.includes(user))) : prev),
    [],
  );

  return result;
};

export { reduceUsers };
