import styled from "styled-components";
import {
  WHITE,
  GREEN,
  BLACK,
  textStyleMixin
} from "../../utils/theme";

const TextBlock = styled.div`
  ${textStyleMixin}
  color: ${BLACK};
  background-color: ${props => (props.backgroundColor || WHITE)};
  border-radius: 5px;
  border: 1px solid ${props => props.color || GREEN};
  padding: 10px;
  margin: 8px 0 0 0;
`;

export default TextBlock;
