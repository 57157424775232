import React from "react";
import { Grommet } from "grommet";
import { LoadingIndicator as NewLoadingIndicator } from "@/cloverleaf-ui/components/atoms/LoadingIndicator";
import { themeStyle } from "@/cloverleaf-ui/theme";

/**
 * @deprecated - Use @/cloverleaf-ui/components/LoadingIndicator
 */
function LoadingIndicator() {
  return (
    <Grommet theme={themeStyle}>
      <NewLoadingIndicator />
    </Grommet>
  );
}

export default LoadingIndicator;
