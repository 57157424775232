import { makeVar, useReactiveVar } from "@apollo/client";
import { v4 as uuidv4 } from "uuid";

const toastsVar = makeVar({});

/**
 * Returns reactive variable state and setState function
 * @returns [] - [state, setState]
 */
const useToastMessage = () => {
  const toasts = useReactiveVar(toastsVar);

  const deleteToast = (toastId) => {
    delete toasts[toastId];

    return toastsVar({
      ...toasts,
    });
  }

  /**
   *
   * @param {*} message
   * @param {*} status - one of 'critical', 'warning', 'info', 'normal'
   * @returns Collection of toast messages
   */
  const pushToast = (message, status = "normal") => {
    const toastId = uuidv4();

    const toast = {
      message: message?.message || message,
      status: message?.status || status,
    };

    return toastsVar({
      ...toasts,
      [toastId]: [toast, () => deleteToast(toastId)],
    });
  };

  return {
    pushToast,
    toasts,
  };
};

export { useToastMessage };
