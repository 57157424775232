import PropTypes from "prop-types";
import { Box, Text } from "grommet";
import { Tip } from "@/cloverleaf-ui/components";
import { useLocale } from "@/lib/hooks";

function BetaTag({
  label = "beta",
  toolTip = undefined
}) {
  const { t } = useLocale();

  const content = (
    <Box
      background="blue600"
      justify="center"
      pad={{ horizontal: "xsmall", vertical: "xxsmall" }}
      round="large"
      alignSelf="center"
    >
      <Text
        size="xsmall"
        uppercase
        weight={700}
      >
        {t(label)}
      </Text>
    </Box>
  );

  if (!toolTip) {
    return content;
  }

  let toolTipProps = { dropProps: { width: { max: "small" } } };

  if (typeof toolTip === "string") {
    toolTipProps.message = toolTip;
  } else {
    toolTipProps = {
      ...toolTipProps,
      ...toolTip
    };
  }

  return (
    <Tip {...toolTipProps}>
      {content}
    </Tip>
  )
}

BetaTag.propTypes = {
  toolTip: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
}

export { BetaTag };
