import { useEffect, useRef } from "react";
import Router from "next/router";
import { EVENT } from "../../components/utils/constants";
import { track } from "../analytics";
import { locales } from "@/i18n";

const useWarningOnExit = (shouldWarn, confirmationMessage, source) => {
  const message = confirmationMessage || "Are you sure that you want to leave?";

  const lastHistoryState = useRef(global.history?.state);

  useEffect(() => {
    const storeLastHistoryState = () => {
      lastHistoryState.current = history.state;
    };

    Router.events.on("routeChangeComplete", storeLastHistoryState);

    return () => {
      Router.events.off("routeChangeComplete", storeLastHistoryState);
    };
  }, []);

  useEffect(() => {
    let isWarned = false;

    const routeChangeStart = (pathname) => {
      // check if the path contains a supported locale
      const isLocaleSupported = locales.some((locale) => {
        return pathname.startsWith(`/${locale}/`);
      });

      // only display the warning if the locale is not supported
      if (!isLocaleSupported && shouldWarn && !isWarned) {
        /**
         * @todo use a generic warning tracker
         */
        track(EVENT.UnsubmittedFeedback.name, {
          source,
        });

        isWarned = true;

        if (window.confirm(message)) {
          Router.push(pathname);
        } else {
          isWarned = false;
          Router.events.emit("routeChangeError");

          // HACK
          const state = lastHistoryState.current;

          if (state != null && history.state != null && state.idx !== history.state.idx) {
            history.go(state.idx < history.state.idx ? -1 : 1);
          }

          // eslint-disable-next-line no-throw-literal
          throw "Abort route change. Please ignore this error.";
        }
      }
    };

    const beforeUnload = (e) => {
      if (shouldWarn && !isWarned) {
        /**
         * @todo use a generic warning tracker
         */
        track(EVENT.UnsubmittedFeedback.name, {
          source,
        });

        const event = e || window.event;
        event.returnValue = message;

        return message;
      }

      return null;
    };

    Router.events.on("routeChangeStart", routeChangeStart);
    window.addEventListener("beforeunload", beforeUnload);

    return () => {
      Router.events.off("routeChangeStart", routeChangeStart);
      window.removeEventListener("beforeunload", beforeUnload);
    };
  }, [message, shouldWarn, source]);
};

export { useWarningOnExit };
