import React from "react";
import PropTypes from "prop-types";
import debounce from "lodash/debounce";
import {
  Box,
  FormField,
  RangeInput,
  Text
} from "grommet";
import { QuestionCard } from "../components/QuestionCard";

const DEFAULT_VALUE = "3.5";

function CulturePulseQuestionComponent({
  onChange = () => undefined,
  selected = undefined,
  question = undefined,
}, ref) {
  const [value, setValue] = React.useState(selected || DEFAULT_VALUE);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnChange = React.useCallback(
    /**
     * Usually use /fp/debounce but this was the
     * only way could get this to work.
     */
    debounce((v) => onChange(v), 400)
    , [onChange]);

  const handleOnChange = (event) => {
    const answer = event.target.value;

    setValue(answer);

    return debouncedOnChange({
      question: question.number,
      answer,
    });
  };

  if (!question) {
    return null;
  }

  return (
    <QuestionCard
      ref={ref}
      gap="small"
      width="100%"
    >
      <Box focusIndicator pad="small" round="small">
        <FormField
          contentProps={{
            margin: {
              // Visually, top padding because of flex-direction 'reverse'
              bottom: "xsmall",
            },
          }}
          label={(
            <Box direction="row" gap="large" justify="between" pad={{ horizontal: "small" }}>
              <Text textAlign="start">{question.labelLeft}</Text>
              <Text textAlign="end">{question.labelRight}</Text>
            </Box>
          )}
          margin="none"
          name={question.number.toString()}
          style={{
            flexDirection: "column-reverse",
          }}
        >
          <RangeInput
            border={false}
            name={question.number.toString()}
            onChange={handleOnChange}
            value={value}
            min={0}
            max={7}
            step={0.1}
          />
        </FormField>
      </Box>
    </QuestionCard>
  );
}

const CulturePulseQuestion = React.forwardRef(CulturePulseQuestionComponent);

CulturePulseQuestion.propTypes = {
  onChange: PropTypes.func,
  question: PropTypes.shape({
    number: PropTypes.number,
    labelLeft: PropTypes.string,
    labelRight: PropTypes.string,
  }),
  selected: PropTypes.any,
};

export { CulturePulseQuestion };
