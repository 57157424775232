import React from "react";
import {
  string,
  node,
  func
} from "prop-types";
import { withTooltip } from "../hoc/withTooltip";
import StyledA from "./styled/A";
const debug = require("debug")("cloverleaf:TextButton");

function TextButton({
  label = "",
  onClick = () => debug("TextButton does not have an onClick prop defined"),
  ...props
}) {
  return (
    <StyledA
      {...props}
      role="button"
      onClick={(e) => {
        onClick(e);
        e.stopPropagation(); // Don't bubble up the event.
      }}
    >
      {props.children || label}
    </StyledA>
  );
}

TextButton.propTypes = {
  children: node,
  label: string,
  onClick: func,
};

export default withTooltip(TextButton);
