import React from "react";
import {
  func,
  string,
  bool,
  shape
} from "prop-types";
import styled from "styled-components";
import { withTooltip } from "../hoc/withTooltip";
import {
  GREEN,
  DARK_GREEN,
  GREY_OPAQUE
} from "../utils/theme";
import { Flex, Box } from "./FlexBox";
import Icon from "./Icon";
import TextLabel from "./styled/TextLabel";

const Input = styled.input.attrs({ type: "checkbox" })`
  display: none;
`;

const CheckboxRoundedBox = styled.label`
  position: relative;
  width: 30px;
  height: 30px;
  border: 2px solid ${GREEN};
  border-radius: 10px;
  cursor: pointer;

  ${Input}:disabled + & {
    border: 2px solid ${GREY_OPAQUE};
    cursor: not-allowed;
  }

  &:active {
    border: 2px solid ${DARK_GREEN};
  }
`;

const TickMark = styled(Icon).attrs({ icon: "check", size: "2x" })`
  display: none;
  pointer-events: none;
  position: absolute;
  top: 3px;
  left: 3px;
  color: ${GREEN};
  transform: scale(0.9);

  ${CheckboxRoundedBox}:active ~ & {
    color: ${DARK_GREEN};
  }

  ${Input}:disabled ~ & {
    color: ${GREY_OPAQUE};
  }

  ${Input}:checked ~ & {
    display: inline-block;
  }
`;

const CheckboxContainer = withTooltip(Flex);

class Checkbox extends React.PureComponent {
  static propTypes = {
    id: string,
    name: string.isRequired,
    onChange: func.isRequired,
    onClick: func,
    label: string,
    value: bool.isRequired,
    val: string.isRequired,
    disabled: bool,
    tooltip: shape({}),
  }

  static defaultProps = {
    id: undefined,
    onClick: () => undefined,
    disabled: false,
    tooltip: undefined,
    label: undefined,
  }

  render() {
    const {
      onChange, onClick, name, id, val, value, disabled, tooltip, label,
    } = this.props;

    return (
      <Flex align="center">
        <Box mr={label && 1}>
          <CheckboxContainer position="relative" justify="center" align="center" tooltip={tooltip}>
            <Input
              id={id || `${name}-${val}`}
              name={name}
              onChange={onChange}
              onClick={onClick}
              checked={value}
              value={val}
              disabled={disabled}
            />
            <CheckboxRoundedBox htmlFor={id || `${name}-${val}`} />
            <TickMark />
          </CheckboxContainer>
        </Box>
        {label && <TextLabel color={GREEN}>{label}</TextLabel>}
      </Flex>
    );
  }
}

export default Checkbox;
