import styled from "styled-components";
import { convertHexToRGBA } from "../utils";
import { BLACK } from "../utils/theme";

const Dimmer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${convertHexToRGBA(BLACK, 0.6)};
  z-index: ${props => props.zIndex};
`;

export default Dimmer;
