import React from "react";
import {
  string,
  func,
  bool
} from "prop-types";
import styled from "styled-components";
import { Text } from "..";
import { iconProp } from "../../utils/propTypes";
import {
  WHITE,
  LIGHT_GREY,
  GREEN,
} from "../../utils/theme";
import { Flex, Box } from "../FlexBox";
import Icon from "../Icon";

const MenuButton = styled(Flex)`
  background-color: ${WHITE};

  &:last-child {
    border-radius: 0 0 5px 5px;
  }
  ${({ disabled }) => (disabled ? `
    opacity: 0.5;
  ` : `
    &:hover {
      cursor: pointer;
      color: ${GREEN};
      background-color: ${LIGHT_GREY};
    }
  `)}
`;

function MenuOption({
  label,
  Component = undefined,
  icon = undefined,
  onClick,
  disabled = false,
}) {
  return (
    <MenuButton
      key={label}
      onClick={() => !disabled && onClick()}
      justify="space-between"
      px={Component ? 0 : 2} // Passing in a component means it handles it's own margin/padding
      py={Component ? 0 : 1}
      disabled={disabled}
    >
      {Component ? <Component /> : <Box mr={2}><Text noMargin>{label}</Text></Box>}
      {icon && <Icon icon={icon} />}
    </MenuButton>
  );
}

MenuOption.propTypes = {
  label: string.isRequired,
  icon: iconProp,
  onClick: func.isRequired,
  Component: func,
  disabled: bool,
};

export default MenuOption;
