export const SixteenTypesAnswers = {
  1: "A",
  2: "B",
  3: "A",
  4: "B",
  5: "A",
  6: "A",
  7: "B",
  8: "A",
  9: "B",
  10: "A",
  11: "A",
  12: "B",
  13: "A",
  14: "B",
  15: "A",
  16: "A",
  17: "B",
  18: "A",
  19: "B",
  20: "A",
  21: "A",
  22: "B",
  23: "A",
  24: "B",
  25: "A",
  26: "A",
  27: "B",
  28: "A",
  29: "B",
  30: "A",
  31: "A",
  32: "B",
  33: "A",
  34: "B",
  35: "A",
  36: "A",
  37: "B",
  38: "A",
  39: "B",
  40: "A",
  41: "A",
  42: "B",
  43: "A",
  44: "B",
  45: "A",
  46: "A",
  47: "B",
  48: "A",
  49: "B",
  50: "A",
  51: "A",
  52: "B",
  53: "A",
  54: "B",
  55: "A",
  56: "A",
  57: "B",
  58: "A",
  59: "B",
  60: "A",
  61: "A",
  62: "B",
  63: "A",
  64: "B",
  65: "A",
  66: "A",
  67: "B",
  68: "A",
  69: "B",
  70: "A",
};

export const DiscAnswers = {
  25: {
    most: {
      label: "persuasive",
      value: "persuasive"
    },
    least: {
      label: "original",
      value: "original"
    }
  },
  26: {
    most: {
      label: "even-tempered",
      value: "even-tempered"
    },
    least: {
      label: "jovial",
      value: "jovial"
    }
  },
  27: {
    most: {
      label: "soft-spoken",
      value: "soft-spoken"
    },
    least: {
      label: "gregarious",
      value: "gregarious"
    }
  },
  28: {
    most: {
      label: "careful",
      value: "careful"
    },
    least: {
      label: "life-of-the-party",
      value: "life-of-the-party"
    }
  },
  29: {
    most: {
      label: "disciplined",
      value: "disciplined"
    },
    least: {
      label: "persistent",
      value: "persistent"
    }
  },
  30: {
    most: {
      label: "trusting",
      value: "trusting"
    },
    least: {
      label: "peaceful",
      value: "peaceful"
    }
  },
  31: {
    most: {
      label: "attentive",
      value: "attentive"
    },
    least: {
      label: "likeable",
      value: "likeable"
    }
  },
  32: {
    most: {
      label: "competitive",
      value: "competitive"
    },
    least: {
      label: "harmonious",
      value: "harmonious"
    }
  },
  33: {
    most: {
      label: "moderate",
      value: "moderate"
    },
    least: {
      label: "adventurous",
      value: "adventurous"
    }
  },
  34: {
    most: {
      label: "good-natured",
      value: "good-natured"
    },
    least: {
      label: "cautious",
      value: "cautious"
    }
  },
  35: {
    most: {
      label: "admirable",
      value: "admirable"
    },
    least: {
      label: "forceful",
      value: "forceful"
    }
  },
  36: {
    most: {
      label: "lenient",
      value: "lenient"
    },
    least: {
      label: "sociable",
      value: "sociable"
    }
  },
  37: {
    most: {
      label: "charming",
      value: "charming"
    },
    least: {
      label: "diplomatic",
      value: "diplomatic"
    }
  },
  38: {
    most: {
      label: "playful",
      value: "playful"
    },
    least: {
      label: "obedient",
      value: "obedient"
    }
  },
  39: {
    most: {
      label: "talkative",
      value: "talkative"
    },
    least: {
      label: "decisive",
      value: "decisive"
    }
  },
  40: {
    most: {
      label: "willing",
      value: "willing"
    },
    least: {
      label: "high-spirited",
      value: "high-spirited"
    }
  },
  41: {
    most: {
      label: "accommodating",
      value: "accommodating"
    },
    least: {
      label: "respectful",
      value: "respectful"
    }
  },
  42: {
    most: {
      label: "friendly",
      value: "friendly"
    },
    least: {
      label: "restrained",
      value: "restrained"
    }
  },
  43: {
    most: {
      label: "will power",
      value: "will power"
    },
    least: {
      label: "cheerful",
      value: "cheerful"
    }
  },
  44: {
    most: {
      label: "brave",
      value: "brave"
    },
    least: {
      label: "risk averse",
      value: "risk averse"
    }
  },
  45: {
    most: {
      label: "polished",
      value: "polished"
    },
    least: {
      label: "satisfied",
      value: "satisfied"
    }
  },
  46: {
    most: {
      label: "tolerant",
      value: "tolerant"
    },
    least: {
      label: "assertive",
      value: "assertive"
    }
  },
  47: {
    most: {
      label: "light-hearted",
      value: "light-hearted"
    },
    least: {
      label: "nonchalant",
      value: "nonchalant"
    }
  },
  48: {
    most: {
      label: "restless",
      value: "restless"
    },
    least: {
      label: "popular",
      value: "popular"
    }
  }
};

export const EnneagramAnswers = {
  1: "B",
  2: "A",
  3: "B",
  4: "A",
  5: "B",
  6: "B",
  7: "A",
  8: "B",
  9: "A",
  10: "B",
  11: "B",
  12: "A",
  13: "B",
  14: "A",
  15: "B",
  16: "B",
  17: "A",
  18: "B",
  19: "A",
  20: "B",
  21: "B",
  22: "A",
  23: "B",
  24: "A",
  25: "B",
  26: "A",
  27: "A",
  28: "A",
  29: "A",
  30: "A",
  31: "A",
  32: "A",
  33: "A",
  34: "A",
  35: "B",
  36: "A",
  37: "A",
  38: "A",
  39: "A",
  40: "A",
  41: "A",
  42: "A",
  43: "A",
  44: "A",
  45: "A",
  46: "A",
  47: "A",
  48: "A",
  49: "A",
  50: "B",
  51: "A",
  52: "A",
  53: "A",
  54: "A",
  55: "A",
  56: "A",
  57: "A",
  58: "A",
  59: "A",
  60: "B",
  61: "A",
  62: "A",
  63: "A",
  64: "A",
  65: "A",
  66: "A",
  67: "A",
  68: "A",
  69: "A",
  70: "A",
  71: "A",
  72: "A",
  73: "A",
  74: "A",
  75: "B",
  76: "B",
  77: "B",
  78: "B",
  79: "B",
  80: "A",
  81: "A",
  82: "B",
  83: "A",
  84: "B",
  85: "A",
  86: "A",
  87: "A",
  88: "A",
  89: "A",
  90: "B",
  91: "A",
  92: "A",
  93: "A",
  94: "A",
  95: "A",
  96: "A",
  97: "A",
  98: "A",
  99: "A",
  100: "A",
  101: "A",
  102: "A",
  103: "A",
  104: "A",
  105: "B",
  106: "A",
  107: "A",
  108: "A"
};

export const InstinctiveDriveAnswers = {
  "bfbcd2ea-ff34-496a-aa23-f46992a495cb": {
    answer1: 1,
    answer2: 2,
    answer3: 3,
    answer4: 4
  },
  "81fc60f4-e26b-494e-8fec-770c5cedcd92": {
    answer1: 1,
    answer2: 2,
    answer3: 3,
    answer4: 4
  },
  "602b128b-3a42-47c1-b2a7-94e9631fe310": {
    answer1: 1,
    answer2: 2,
    answer3: 4,
    answer4: 3
  },
  "acf909fb-10b5-46d7-bdb6-30342d69793a": {
    answer1: 3,
    answer2: 2,
    answer3: 4,
    answer4: 1
  },
  "ef0d468a-e8a2-4a20-b6d6-e15500c89caa": {
    answer1: 1,
    answer2: 3,
    answer3: 4,
    answer4: 2
  },
  "cb1d5c0b-9525-47c6-874d-dd65613ff186": {
    answer1: 1,
    answer2: 3,
    answer3: 2,
    answer4: 4
  },
  "67550e91-62b1-4391-8708-75b6bdf511e4": {
    answer1: 1,
    answer2: 2,
    answer3: 3,
    answer4: 4
  },
  "3ca534ca-6cc4-4792-8173-d73216d0d003": {
    answer1: 1,
    answer2: 2,
    answer3: 3,
    answer4: 4
  },
  "ae1a3355-0eed-4e11-b584-a0ec6f5ea10e": {
    answer1: 1,
    answer2: 2,
    answer3: 3,
    answer4: 4
  },
  "18c7c432-7ea7-4ca5-abf9-cdd4e83d2154": {
    answer1: 1,
    answer2: 2,
    answer3: 3,
    answer4: 4
  },
  "983d36cc-e867-46ac-8b47-36be79bc2cec": {
    answer1: 1,
    answer2: 2,
    answer3: 3,
    answer4: 4
  },
  "1de433ac-613b-4306-883c-3ff6cb7d18fc": {
    answer1: 1,
    answer2: 2,
    answer3: 3,
    answer4: 4
  },
  "303e0b54-c9d0-450b-be1e-a809e710b15b": {
    answer1: 1,
    answer2: 2,
    answer3: 3,
    answer4: 4
  },
  "f3cc577b-c33a-4aae-863b-985c8433475c": {
    answer1: 4,
    answer2: 3,
    answer3: 2,
    answer4: 1
  },
  "913035f9-1ee5-49d3-be5b-9592c4e63795": {
    answer1: 1,
    answer2: 2,
    answer3: 3,
    answer4: 4
  },
  "d7aac2c4-3055-4c52-a2ea-5f97003c2e5c": {
    answer1: 1,
    answer2: 2,
    answer3: 3,
    answer4: 4
  },
  "bf710c51-214d-42b6-befa-78f4fc8b0afc": {
    answer1: 1,
    answer2: 2,
    answer3: 3,
    answer4: 4
  },
  "f8306ddb-c68a-4805-a68b-ef8380ea123e": {
    answer1: 1,
    answer2: 2,
    answer3: 4,
    answer4: 3
  },
  "bcb7e8b2-8e5e-4388-820d-f26a1a590d05": {
    answer1: 1,
    answer2: 2,
    answer3: 4,
    answer4: 3
  },
  "450c268e-7bff-4af7-a9d3-2279f0aadc19": {
    answer1: 1,
    answer2: 2,
    answer3: 4,
    answer4: 3
  },
  "7b710db5-32b6-4700-b94b-f10760a3e476": {
    answer1: 1,
    answer2: 3,
    answer3: 2,
    answer4: 4
  },
  "faec97c4-05a9-498e-b959-cd99aedcf29a": {
    answer1: 2,
    answer2: 3,
    answer3: 1,
    answer4: 4
  },
  "98403aef-9c70-48b0-9fcd-dea3b940b145": {
    answer1: 1,
    answer2: 3,
    answer3: 2,
    answer4: 4
  },
  "00764ad3-fac6-448a-987e-c397d7b3aa54": {
    answer1: 2,
    answer2: 3,
    answer3: 4,
    answer4: 1
  },
  "3baa5c91-70c3-4974-aa02-9d1060550f85": {
    answer1: 1,
    answer2: 3,
    answer3: 4,
    answer4: 2
  },
  "15d829d1-4f8f-4073-bff0-c7cbfb834866": {
    answer1: 2,
    answer2: 1,
    answer3: 3,
    answer4: 4
  },
  "36b8fb6c-3488-4cd4-bb28-031cecd8b872": {
    answer1: 2,
    answer2: 4,
    answer3: 1,
    answer4: 3
  },
  "eef281a5-d109-4e42-9ed2-fe8e58d7b2d4": {
    answer1: 2,
    answer2: 3,
    answer3: 4,
    answer4: 1
  },
  "f5eb39c9-4c93-40f0-8c24-cd33ca92acf9": {
    answer1: 1,
    answer2: 4,
    answer3: 3,
    answer4: 2
  },
  "243400c3-3854-47d0-a68b-3667e704a40c": {
    answer1: 2,
    answer2: 1,
    answer3: 3,
    answer4: 4
  },
  "8c2d73ec-9512-45e6-a315-142c12247356": {
    answer1: 2,
    answer2: 1,
    answer3: 3,
    answer4: 4
  },
  "2ec6ac42-cdf7-4011-bcb1-44e5c33d6923": {
    answer1: 4,
    answer2: 2,
    answer3: 3,
    answer4: 1
  }
};

export const ViaAnswers = {
  1: 3,
  2: 5,
  3: 5,
  4: 5,
  5: 5,
  6: 5,
  7: 2,
  8: 4,
  9: 5,
  10: 5,
  11: 5,
  12: 5,
  13: 2,
  14: 2,
  15: 2,
  16: 2,
  17: 2,
  18: 2,
  19: 2,
  20: 2,
  21: 2,
  22: 2,
  23: 2,
  24: 1,
  25: 5,
  26: 5,
  27: 2,
  28: 5,
  29: 5,
  30: 5,
  31: 5,
  32: 5,
  33: 5,
  34: 5,
  35: 2,
  36: 2,
  37: 2,
  38: 2,
  39: 2,
  40: 5,
  41: 2,
  42: 2,
  43: 4,
  44: 2,
  45: 5,
  46: 5,
  47: 5,
  48: 2,
  49: 1,
  50: 2,
  51: 5,
  52: 1,
  53: 5,
  54: 2,
  55: 1,
  56: 5,
  57: 2,
  58: 4,
  59: 2,
  60: 2,
  61: 5,
  62: 5,
  63: 5,
  64: 5,
  65: 5,
  66: 5,
  67: 2,
  68: 2,
  69: 2,
  70: 2,
  71: 2,
  72: 2,
  73: 5,
  74: 5,
  75: 5,
  76: 5,
  77: 5,
  78: 2,
  79: 5,
  80: 5,
  81: 5,
  82: 5,
  83: 5,
  84: 5,
  85: 2,
  86: 2,
  87: 2,
  88: 5,
  89: 2,
  90: 2,
  91: 5,
  92: 5,
  93: 5,
  94: 5,
  95: 5,
  96: 2
};

export const MotivatingValuesAnswers = {
  1: [
    "Ethics",
    "Power",
    "Truth",
    "Compassion",
    "Beauty",
    "Wealth"
  ],
  2: [
    "Intellectual Challenge",
    "Personal Recognition",
    "Seek the Ideal",
    "Help the \"Under-dog\"",
    "Improve our Culture",
    "Money"
  ],
  3: [
    "Board of Directors",
    "Improve Mind",
    "Meditation",
    "Financial Planning",
    "Help Children",
    "Pursue the Arts"
  ],
  4: [
    "Research",
    "Creative / Artistic",
    "Counselor",
    "Entrepreneur",
    "Professional",
    "Manager"
  ],
  5: [
    "Personal Growth",
    "Charitable Works",
    "Personal Investments",
    "Exercise Authority",
    "Freedom of Expression",
    "Analysis of Research"
  ],
  6: [
    "Physical Sciences",
    "Law and Government",
    "Fine Arts",
    "Philosophy and Ethics",
    "Economics",
    "Social Sciences"
  ],
  7: [
    "Rugged Individualist",
    "Millionaire",
    "Good Samaritan",
    "Incorruptible",
    "Celebrity",
    "Intellectual"
  ],
  8: [
    "Community Service",
    "Employer",
    "Public Leader",
    "Entertainer",
    "Scientist",
    "Advisor & Judge"
  ],
  9: [
    "Benefit Others",
    "Accumulate Wealth",
    "Advance Science",
    "Improve Government",
    "Create Beauty",
    "Develop Character"
  ]
};

export const CulturePulseAnswers = {
  24: "3.5",
  25: "3.5",
  26: "3.5",
  27: "3.5",
  28: "3.5",
  29: "3.5",
  30: "3.5",
  31: "3.5",
  32: "3.5",
  33: "3.5",
  34: "3.5",
  35: "3.5",
  36: "3.5",
  37: "3.5",
  38: "3.5",
  39: "3.5",
  40: "3.5",
  41: "3.5",
  42: "3.5",
  43: "3.5",
  44: "3.5",
  45: "3.5",
  46: "3.5",
  47: "3.5"
};

export const StrengthscopeAnswers = {
  1000: 3,
  1001: 3,
  1002: 4,
  1003: 4,
  1004: 4,
  1005: 4,
  1006: 4,
  1007: 4,
  1008: 4,
  1009: 4,
  1010: 4,
  1011: 4,
  1012: 4,
  1013: 4,
  1014: 4,
  1015: 3,
  1016: 4,
  1017: 4,
  1018: 4,
  1019: 4,
  1020: 4,
  1021: 4,
  1022: 4,
  1023: 3,
  1024: 4,
  1025: 4,
  1026: 4,
  1027: 4,
  1028: 4,
  1029: 4,
  1030: 4,
  1031: 4,
  1032: 4,
  1033: 4,
  1034: 4,
  1035: 4,
  1036: 4,
  1037: 4,
  1038: 4,
  1039: 4,
  1040: 4,
  1041: 4,
  1043: 4,
  1044: 4,
  1045: 4,
  1046: 4,
  1047: 4,
  1048: 4,
  1049: 4,
  1050: 4,
  1051: 4,
  1052: 4,
  1053: 4,
  1054: 4,
  1055: 4,
  1056: 4,
  1057: 4,
  1058: 4,
  1059: 4,
  1060: 4,
  1061: 4,
  1062: 4,
  1063: 4,
  1064: 4,
  1065: 4,
  1066: 4,
  1067: 4,
  1068: 4,
  1069: 4,
  1070: 4,
  1071: 4,
  1072: 4,
  1073: 4,
  1074: 4,
  1075: 3,
  1076: 3,
  1077: 4,
  1078: 4,
  1079: 4,
  1080: 4,
  1081: 4,
  1082: 3,
  1083: 3,
  1084: 4,
  1085: 4,
  1086: 4,
  1087: 4,
  1088: 4,
  1089: 4,
  1091: 4,
  1092: 4,
  1093: 4,
  1095: 4,
  1096: 4,
  1097: 4,
  1098: 4,
  1099: 4,
  1100: 4,
  1101: 4,
  1102: 4,
  1103: 4,
  1108: 4,
  1109: 4,
  1110: 4,
  1111: 4,
  1112: 4,
  1113: 4,
  1114: 4,
  1115: 2,
  1116: 3,
  1117: 4,
  1118: 4,
  1119: 4,
  1120: 4,
  1121: 4,
  1122: 4,
  1123: 4,
  1124: 3,
  1125: 4,
  1126: 3,
  1127: 4,
  1128: 4,
  1129: 4,
  1130: 4,
  1131: 2,
  1132: 3,
  1133: 3,
  1134: 3,
  1135: 4,
  1136: 4,
  1137: 4,
  1138: 4,
  1139: 4,
  1140: 4,
  1141: 4,
  1142: 4,
  1143: 4,
  1144: 4,
  1145: 4,
  1146: 3,
  1147: 3,
  1148: 4,
  1149: 4,
  1150: 4,
  1151: 4,
  1152: 4,
  1153: 4,
  1154: 4,
  1155: 4,
  1156: 4,
  1158: 4,
  1159: 4,
  1160: 4,
  1161: 4,
  1162: 4,
  1163: 4,
  1164: 4,
  1165: 4,
  1166: 4,
  1167: 4,
  1168: 4,
  1169: 4,
  1170: 3,
  1171: 4,
  1172: 4,
  1173: 3,
  1174: 3,
  1175: 4,
  1176: 4,
  1177: 4,
  1178: 4,
  1179: 4,
  1180: 4,
  1181: 4,
  1182: 4
};
