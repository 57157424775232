/**
 * Adapted from Reflexbox v.3.0.1
 * https://github.com/jxnblk/reflexbox
 *
 * The actual CSS generation wasn't working for us
 * so this is just an extract of some utility functions for calculating props.
 */

export const config = {
  breakpoints: [40, 52, 64],
  space: [0, 8, 16, 32, 64],
};

const directions = {
  t: ["-top"],
  r: ["-right"],
  b: ["-bottom"],
  l: ["-left"],
  x: ["-left", "-right"],
  y: ["-top", "-bottom"],
};

export const num = n => typeof n === "number" && !Number.isNaN(n);

const dec = args => args.join(":");
const rule = args => args.join(";");

export const px = n => (num(n) ? `${n}px` : n);
export const width = n => (!num(n) || n > 1 ? px(n) : `${n * 100}%`);

export const space = (key, n) => {
  const [a, b] = key.split("");
  const prop = a === "m" ? "margin" : "padding";
  const dirs = directions[b] || [""];
  const neg = n < 0 ? -1 : 1;
  const val = !num(n) ? n : px((config.space[Math.abs(n)] || Math.abs(n)) * neg);

  return `${rule(dirs.map(d => dec([prop + d, val])))};`;
};
