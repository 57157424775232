import React from "react";
import PropTypes from "prop-types";
import { useRouter } from "next/router";
import { gql, useQuery } from "@apollo/client";
import {
  Box,
  Grid,
  Main
} from "grommet";
import Toasts from "../../../../components/composite/Toasts";
import ErrorBoundary from "../../../../components/ui/ErrorBoundary";
import { Header } from "@/components/v2/Layout/components/Header";
import { Sidebar } from "@/components/v2/Layout/components/Sidebar";
import { SidebarProvider } from "@/components/v2/Layout/components/Sidebar.context";

const GET_CURRENT_USER_ORGANIZATION_QUERY = gql`
  query getCurrentUserOrganization {
    currentUser {
      id
      organization {
        id
      }
    }
  }
`;

/**
 * @param {boolean} nested - this is a nested Layout, meaning the old Layout component is a parent to this one - hide the footer and toast components
 */
function Layout({
  background = "background-light-blue",
  contentBackground = undefined,
  children = undefined,
  sidebarOptional = false,
  navbar = false,
  nested = false,
  ...props
}) {
  const router = useRouter();
  const organizationId = router.query.organizationId;

  const { loading, data } = useQuery(GET_CURRENT_USER_ORGANIZATION_QUERY, {
    // This is a protected query, if we do not have an organizationId avoid firing this request.
    /**
     * @todo This will not solve the problem if we ever have an unauthenticated route that passes an organizationId.
     */
    skip: !organizationId,
  });

  const showSidebar = !sidebarOptional || (
    !loading
    && data?.currentUser?.organization?.id
    && data.currentUser.organization.id === organizationId
  );

  return (
    <Grid
      fill
      height="100vh"
      rows={["auto", "flex"]}
      columns={["auto", "flex"]}
      areas={[
        ["header", "header"],
        ["sidebar", "main"],
      ]}
    >
      {navbar && (
        <SidebarProvider>
          <Header showSidebar={showSidebar} />
          {showSidebar && (
            <Sidebar />
          )}
        </SidebarProvider>
      )}
      <Box
        align="center"
        background={background}
        gridArea="main"
        height="100%"
        pad="none"
        style={{
          overflowY: "scroll",
          flexFlow: "wrap",
        }}
      >
        {!nested && (
        /**
          TODO: Remove conditional when parent Layout component is deprecated, or Toast component is updated to cloverleaf-ui
          If nested is true, do not duplicate the Toasts component while the parent Layout is already rendering it.
          This is not an issue now, but prevents potentially duplicating the Toast messages.
        */
          <Toasts />
        )}
        <Main
          align="center"
          background={contentBackground}
          pad={nested ? { vertical: "large" } : "medium"}
          {...props}
        >
          <ErrorBoundary>
            {children}
          </ErrorBoundary>
        </Main>
      </Box>
    </Grid>
  );
}

Layout.propTypes = {
  background: PropTypes.any,
  contentBackground: PropTypes.any,
  children: PropTypes.node,
  navbar: PropTypes.bool,
  nested: PropTypes.bool,
  showFooter: PropTypes.bool,
};

export { Layout };
