import React from "react";
import PropTypes from "prop-types";
import { Button } from "grommet";
import { Tip } from "../../atoms";
import { ConfirmationDialog } from "../../atoms/ConfirmationDialog/OldConfirmationDialog";

function ButtonWithConfirmationComponent({
  a11yTitle = "Remove item",
  children = undefined,
  Component = Button,
  confirmation = "Are you sure?",
  icon = undefined,
  onClick = () => undefined,
  tip = undefined,
  ...props
}, ref) {
  const [confirmAction, setConfirmAction] = React.useState(false);

  const handleOnClick = () => {
    if (confirmation) {
      return setConfirmAction(true);
    }

    return onClick();
  }

  const handleOnConfirm = () => {
    setConfirmAction(false);

    return onClick();
  };

  let button = (
    <Component
      ref={ref}
      a11yTitle={a11yTitle}
      icon={icon}
      onClick={handleOnClick}
      {...props}
    >
      {children}
    </Component>
  );

  if (tip) {
    let message = tip?.message;

    if (!message) {
      message = tip;
    }

    if (typeof message !== "string") {
      // eslint-disable-next-line no-console
      console.warn("Cloverleaf Tip requires a valid message string. You might be confused with the Grommet Tip component");

      message = "";
    }

    button = (
      <Tip
        bubble={tip?.bubble}
        message={message}
        position={tip?.position}
      >
        {button}
      </Tip>
    );
  }

  return (
    <>
      {button}
      {Boolean(confirmAction) && (
        <ConfirmationDialog
          description={confirmation}
          onCancel={() => setConfirmAction(false)}
          onConfirm={handleOnConfirm}
        />
      )}
    </>
  );
}

const ButtonWithConfirmation = React.forwardRef(ButtonWithConfirmationComponent);

ButtonWithConfirmation.propTypes = {
  a11yTitle: PropTypes.string,
  children: PropTypes.any,
  Component: PropTypes.any, // oneOf: Button, Anchor
  confirmation: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  icon: PropTypes.any,
  tip: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      bubble: PropTypes.bool,
      message: PropTypes.string,
      position: PropTypes.oneOf(["top", "bottom", "left", "right"]),
    }),
  ]),
  onClick: PropTypes.func,
};

export { ButtonWithConfirmation };
