import React from "react";
import { useRouter } from "next/router";
import { gql, useQuery } from "@apollo/client";
import {
  Box,
  Card,
  CardBody,
  CardHeader,
  DropButton,
  List,
  Text,
  TextInput
} from "grommet";
import { CaretDownFill, CaretUpFill } from "grommet-icons";
import { Icon, Logo } from "@/cloverleaf-ui/components";
import { DROP_POSITION } from "@/cloverleaf-ui/constants";
import { Configuration } from "@/components/utils/constants";
import { Button } from "@/components/v2/Button";
import { EventNames } from "@/lib/analytics/constants";
import { LogEvent } from "@/lib/analytics/LogEvent";
import { useLocale } from "@/lib/hooks";

const GET_MY_ORGANIZATIONS_MENU_QUERY = gql`
  query getMyOrganizationsMenu {
    currentUser {
      id
      organization {
        id
        logo
        name
        callerCanManage
        configuration {
          id
          configurationId
          enabled
        }
      }
      trustingOrgs {
        organization {
          id
          logo
          name
        }
      }
    }
  }
`;

const GET_ORGANIZATION_CONTEXT_QUERY = gql`
  query getOrganizationContextQuery($organizationId: ID!) {
    organization(id: $organizationId) {
      id
      logo
      name
      topParentOrganization {
        id
        logo
        name
      }
    }
  }
`;

function TrustingOrganizationsMenuItem({
  background,
  icon,
  label = "",
  selected = false,
  ...rest
}) {
  return (
    <Box
      align="center"
      background={background}
      direction="row"
      pad={{ vertical: "xxsmall", horizontal: "medium" }}
      fill="horizontal"
      justify="between"
      {...rest}
    >
      <Box align="center" direction="row" gap="small">
        {icon}
        <Text>{label}</Text>
      </Box>
      {selected && <Icon icon={["fas", "house"]} color={background ? "white" : undefined } />}
    </Box>
  );
}

function TrustingOrganizationsMenu({
  contextOrganizationId,
  currentUserOrganization,
  trustingOrganizations = [],
}) {
  const { t } = useLocale();
  const router = useRouter();

  const [filterByText, setFilterByText] = React.useState("");

  const selectedLiId = router.pathname.includes("my-organizations") ? "my-organizations" : contextOrganizationId;

  const primaryMenuItems = [
    {
      icon: (
        <Box pad={{ vertical: "small" }} width="39px">
          <Icon icon="building" size="1x" color={selectedLiId === "my-organizations" ? "white" : undefined} />
        </Box>
      ),
      label: t("my-organizations"),
    },
    {
      id: currentUserOrganization.id,
      icon: <Logo avatar src={currentUserOrganization?.logo} style={{ verticalAlign: "middle" }} width="39px" />,
      label: currentUserOrganization.name,
      selected: true,
    },
  ];

  const trustingOrganizationsList = trustingOrganizations
    .filter(organization => organization.name.toLowerCase().indexOf(filterByText?.toLowerCase()) !== -1)
    .map(organization => (
      {
        id: organization.id,
        icon: <Logo avatar src={organization?.logo} style={{ verticalAlign: "middle" }} width="39px" />,
        label: organization.name,
      }
    ));

  const handleOnClickItem = ({ item }) => {
    if (item.id) {
      return router.push({
        pathname: "/organization/[organizationId]/settings/configuration/general",
        query: { organizationId: item.id },
      });
    }

    return router.push({
      pathname: "/organization/[organizationId]/my-organizations",
      query: { organizationId: currentUserOrganization.id },
    })
  };

  return (
    <Card
      background="white"
      border
      gap="xsmall"
      round="xxsmall"
      width="medium"
    >
      {trustingOrganizationsList.length > 4 && (
        <CardHeader pad={{ top: "medium", horizontal: "medium" }}>
          <TextInput
            icon={<Icon icon="search" size="1x" />}
            onChange={event => setFilterByText(event.target.value)}
            placeholder={t("search-by-organization-name")}
            value={filterByText}
          />
        </CardHeader>
      )}
      <CardBody>
        <List
          border={false}
          data={[
            ...primaryMenuItems,
            ...trustingOrganizationsList,
          ]}
          itemKey="id"
          onClickItem={handleOnClickItem}
          pad="none"
        >
          {(li) => (
            <TrustingOrganizationsMenuItem
              key={li.id || li.label}
              icon={li.icon}
              label={li.label}
              selected={li.selected}
              background={li.id === selectedLiId ? "green700" : undefined}
            />
          )}
        </List>
      </CardBody>
    </Card>
  );
}

function MyOrganizationMenu() {
  const router = useRouter();
  const organizationId = router.query.organizationId;

  const [open, setOpen] = React.useState(false);

  const { loading, data } = useQuery(GET_MY_ORGANIZATIONS_MENU_QUERY);
  const { loading: loadingCurrentOrganization, data: currentOrganization } = useQuery(GET_ORGANIZATION_CONTEXT_QUERY, {
    variables: { organizationId },
    skip: !organizationId,
  });

  const currentUserOrganization = data?.currentUser?.organization;
  const contextOrganization = currentOrganization?.organization || currentUserOrganization || {};
  const trustingOrganizations = data?.currentUser?.trustingOrgs?.map(trust => trust.organization);

  const showManageOrganizationsDropdown = (
    // User is an admin of their org, and MANAGER_PARTNER_ORGANIZATIONS is enabled
    currentUserOrganization?.callerCanManage
    && currentUserOrganization?.configuration?.some(
      config => config.configurationId === Configuration.MANAGE_PARTNER_ORGANIZATIONS && config.enabled
    ))
    // User has at least one trusting org
    || trustingOrganizations?.length > 0;

  if (!showManageOrganizationsDropdown) {
    return (
      <Button
        busy={loading || loadingCurrentOrganization}
        icon={!currentUserOrganization?.logo ? <Icon icon="building" /> : <Logo src={currentUserOrganization.logo} width="xxsmall" />}
        label={currentUserOrganization?.name}
        onClick={() => router.push("/my-dashboard")}
        plain
      />
    );
  }

  return (
    <LogEvent
      logEventProps={{
        elementName: "my-organizations-menu",
        eventName: EventNames.MenuEngagement,
        ui_properties: {
          menuOpen: !!open,
        },
      }}
      actionProps={{ onClick: { action: "toggle" } }}
      elementType="menu"
    >
      <DropButton
        a11yTitle="Choose organization"
        busy={loading || loadingCurrentOrganization}
        dropAlign={DROP_POSITION.bottom}
        dropContent={(
          <TrustingOrganizationsMenu
            contextOrganizationId={contextOrganization.id}
            currentUserOrganization={currentUserOrganization}
            trustingOrganizations={trustingOrganizations}
          />
        )}
        dropProps={{ style: { width: "auto" } }}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        icon={!contextOrganization.logo ? <Icon icon="building" /> : <Logo src={contextOrganization.logo} width="xxsmall" />}
        label={(
          <Box align="center" direction="row" gap="small">
            {contextOrganization.name}
            {open ? <CaretUpFill /> : <CaretDownFill />}
          </Box>
        )}
        plain
      />
    </LogEvent>
  );
}

export { MyOrganizationMenu };
