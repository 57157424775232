import { useSessionStorage } from "usehooks-ts";

const useDebugAnalytics = () => {
  const [isDebugMode, setIsDebugMode] = useSessionStorage("debug-analytics", false);

  const setDebugMode = (value) => {
    setIsDebugMode(value);
  };

  const toggleDebugMode = () => {
    setIsDebugMode(!isDebugMode);
  };

  return {
    isDebugMode,
    setDebugMode,
    toggleDebugMode,
  };
};

export { useDebugAnalytics };
