import React from "react";
import { useRouter } from "next/router";
import { useApolloClient } from "@apollo/client";
import * as Sentry from "@sentry/nextjs";
import { Box, Text } from "grommet";
import { Icon, Logo } from "@/cloverleaf-ui/components";
import { getBaseUrl, queryStringify } from "@/components/utils";
import {
  identify,
  alias,
  reset,
} from "@/lib/analytics";
import {
  TRUST_ACCOUNTS_QUERY,
  usePersistedCacheQuery,
  useLocale
} from "@/lib/hooks";

function AccountMenuItem ({ account }) {
  const { email, logo, name } = account;

  return (
    <Box
      align="center"
      direction="row"
      flex="grow"
      gap="xsmall"
      justify="start"
    >
      <Logo src={logo} width="xxxsmall" />
      <Text weight="bold">{name}</Text>
      <Text>{email}</Text>
    </Box>
  );
}

function useSwitchAccountMenuItems() {
  const { t } = useLocale();

  const router = useRouter();

  const apolloClient = useApolloClient();

  const [accounts, setAccounts] = React.useState([]);
  const [errorMessage, setErrorMessage] = React.useState("");

  const {
    loading,
    error,
    data: { currentUser } = {},
  } = usePersistedCacheQuery(TRUST_ACCOUNTS_QUERY);

  React.useEffect(() => {
    if (!loading) {
      if (error) {
        setErrorMessage(t("switch-account-menu.get-account-error"));
      }

      if (currentUser) {
        setAccounts(currentUser?.trustAccounts);
      }
    }
  }, [currentUser, error, loading, t]);

  const handleOnSelectAccount = async (targetAccount) => {
    Sentry.addBreadcrumb({
      category: "link-account",
      message: `Switch Account to ${targetAccount.userId}`,
      level: "info",
    });

    // If user selected the current account
    if (targetAccount?.userId === currentUser?.id) {
      // do nothing
      return undefined;
    }

    // Attempt to clear cached data that flashes on account switch
    await apolloClient.clearStore();

    if (targetAccount?.active) {
      // Identify target account for analytics
      reset();
      alias(targetAccount.userId); // alias the anonymous userId
      identify(targetAccount.userId, {
        email: targetAccount.email,
        name: targetAccount.name,
        locale: router?.locale,
      });

      if (typeof window !== "undefined") {
        window.location = getBaseUrl({
          path: "/my-dashboard", // need a path or will redirect out of subdomain on land
          subdomain: targetAccount?.subdomain,
          locale: router.locale,
        });
      }
    }
    // must authenticate
    else {
      window.location.href = getBaseUrl({
        path: "/signin/auth",
        subdomain: targetAccount?.subdomain,
        query: queryStringify({
          email: targetAccount?.email,
          flowEntry: "AddSession",
        }),
        locale: router.locale,
      });
    }

    return null;
  };

  const accountItems = accounts.map((account) => {
    const isActiveUser = Number(currentUser?.id) === Number(account?.userId);

    return ({
      gap: "small",
      justify: "start",
      align: "start",
      icon: isActiveUser ? (
        <Box alignSelf="center" direction="row" fill="vertical" flex={false}>
          <Icon
            fixedWidth
            icon="check"
            color="grey700"
            size="1x"
          />
        </Box>
      ): undefined,
      reverse: isActiveUser,
      label: <AccountMenuItem account={account} />,
      onClick: () => handleOnSelectAccount(account),
    });
  });

  if (errorMessage) {
    accountItems.push({
      gap: "small",
      justify: "start",
      align: "start",
      label: errorMessage,
    });
  }

  return accountItems;
}

export { useSwitchAccountMenuItems };
