import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  Box,
  FormField,
  Grid,
  RadioButtonGroup,
  Text
} from "grommet";
import { QuestionCard } from "../components/QuestionCard";

// Not at all... perfectly
const options = [1, 2, 3, 4, 5];

/**
 * Remove the Label from the CheckBox when rendering our question as buttons
 */
const NoLabelRadioButtonGroup = styled(RadioButtonGroup)`
  /* hide the span tag that gets rendered with the CheckBoxes. */
  & > label > span { display: none; }

  /* remove the margin-right: 12px to space the checkbox and label */
  & > label > div { margin-right: 0px; }
`;

const StrengthscopeAnswerLabel = ({ options, value }) => {
  const label = options.find(opt => opt.id === value)?.prompt;

  return (
    <Box>
      <Text color="green600" size="xsmall" textAlign="center">
        {label}
      </Text>
    </Box>
  );
};

function StrengthscopeQuestionComponent({
  onChange = () => undefined,
  selected,
  question,
}, ref) {
  const [value, setValue] = React.useState(selected || "");

  const handleOnChange = (event) => {
    const answer = parseInt(event.target.value);

    setValue(answer);

    return onChange({
      question: question.questionId,
      answer,
    });
  };

  if (!question) {
    return null;
  }

  return (
    <QuestionCard
      ref={ref}
      gap="small"
      margin="none"
      width="100%"
    >
      <Box align="center" pad="small">
        <Text textAlign="center">
          {question.prompt}
        </Text>
      </Box>
      <Grid
        align="end"
        columns={{
          count: 5,
          size: "auto",
        }}
        gap="small"
        width="100%"
      >
        <StrengthscopeAnswerLabel options={question.answers} value="1" />
        <StrengthscopeAnswerLabel options={question.answers} value="2" />
        <StrengthscopeAnswerLabel options={question.answers} value="3" />
        <StrengthscopeAnswerLabel options={question.answers} value="4" />
        <StrengthscopeAnswerLabel options={question.answers} value="5" />
      </Grid>
      <FormField
        direction="column"
        margin="none"
        name={question.questionId.toString()}
        width="100%"
      >
        <NoLabelRadioButtonGroup
          a11yTitle={question.prompt}
          direction="row"
          id={question.number.toString()}
          justify="evenly"
          name={question.questionId.toString()}
          gap="medium"
          onChange={handleOnChange}
          options={options}
          pad={{ vertical: "small" }}
          value={value}
        />
      </FormField>
    </QuestionCard>
  );
}

const StrengthscopeQuestion = React.forwardRef(StrengthscopeQuestionComponent);

StrengthscopeQuestion.propTypes = {
  onChange: PropTypes.func,
  question: PropTypes.shape({
    id: PropTypes.string,
    questionId: PropTypes.string,
    prompt: PropTypes.string,
    answers: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      prompt: PropTypes.string,
    })),
  }),
  selected: PropTypes.any,
  tutorial: PropTypes.bool,
};

export { StrengthscopeQuestion };
