import React from "react";
import PropTypes from "prop-types";
import { useRouter } from "next/router";
import { gql, useMutation } from "@apollo/client";
import { Text } from "grommet";
import { LoadingIndicator, Message } from "@/cloverleaf-ui/components";
import { TutorialDrop } from "../components/TutorialDrop";
import { InstinctiveDrivesQuestion } from "./Question";
import { useLocale } from "@/lib/hooks";
import { Trans } from "@/lib/hooks/useLocale";

const beginInstinctiveDrivesMutation = gql`
  mutation beginInstinctiveDrives {
    beginInstinctiveDrives {
      alreadyTaken
      user {
        id
        scores {
          instinctiveDrives {
            isSearching
          }
        }
      }
    }
  }
`;

function InstinctiveDrivesExampleQuestion({
  onChange = () => undefined,
}) {
  const router = useRouter();

  const { t } = useLocale();

  const [value, setValue] = React.useState();
  const [isLoading, setIsLoading] = React.useState(true);
  const [walkthrough, setWalkthrough] = React.useState(-1);
  const [refVisible, setRefVisible] = React.useState(false);

  const [beginInstinctiveDrives, { called, loading, error }] = useMutation(beginInstinctiveDrivesMutation);

  React.useEffect(() => {
    if (!called) {
      beginInstinctiveDrives().then(response => {
        if (response?.data?.beginInstinctiveDrives?.alreadyTaken) {
          return router.push({
            pathname: "/take/[assessmentId]/results",
            query: {
              ...router.query,
              alreadyTaken: true,
            },
          });
        }

        return setIsLoading(false);
      }).catch(() => {
        /**
         * API throws an internal server error if ID is already taken
        */

        return setIsLoading(false);
      });
    }
  }, [beginInstinctiveDrives, called, isLoading, loading, router]);

  const targetRef = React.useRef();

  React.useEffect(() => {
    if (!refVisible) {
      return;
    }

    setWalkthrough(0);
  }, [refVisible]);

  const question = React.useMemo(() => ({
    number: "abcd-efgh-ijkl-mnop",
    prompt: t("id.instructions.example.prompt"),
    answerText1: t("id.instructions.example.answerText1"),
    answerText2: t("id.instructions.example.answerText2"),
    answerText3: t("id.instructions.example.answerText3"),
    answerText4: t("id.instructions.example.answerText4"),
  }), [t]);

  const tutorial = [
    {
      tip: t("id.instructions.answer-tooltip"),
    }
  ];

  const handleOnChange = (value) => {
    setValue(value);

    // Tutorial Specific
    const nextStep = walkthrough + 1;

    if (nextStep < tutorial.length) {
      return setWalkthrough(nextStep);
    }

    setWalkthrough(nextStep);

    return onChange(value);
  };

  if (isLoading) {
    return (
      <LoadingIndicator />
    );
  }

  if (error) {
    return (
      <Message
        kind="error"
        label={t("id.already-taken")}
        margin={{ vertical: "small" }}
      />
    );
  }

  return (
    <React.Fragment>
      <Text textAlign="center">
        <Trans i18nKey="common:id.instructions.description" t={t}>
          Rank the four options with a 1 next to the option you
          are <strong>MOST LIKELY</strong> to do, then a 2, 3 and finally a 4
          indicating the option you are <strong>LEAST LIKELY</strong> to do.
        </Trans>
      </Text>
      <InstinctiveDrivesQuestion
        ref={el => {
          targetRef.current = el;
          setRefVisible(true);
        }}
        question={question}
        onChange={handleOnChange}
        selected={value}
        tutorial
      />
      {refVisible && tutorial[walkthrough] && targetRef && (
        <TutorialDrop
          margin={{ top: "small" }}
          target={targetRef.current}
        >
          {tutorial[walkthrough].tip}
        </TutorialDrop>
      )}
    </React.Fragment>
  );
}

InstinctiveDrivesExampleQuestion.propTypes = {
  onChange: PropTypes.func,
};

export { InstinctiveDrivesExampleQuestion };
