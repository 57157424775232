import { useEffect, useState } from "react";
import * as Sentry from "@sentry/nextjs";
import { parseCookies } from "nookies";
import { publicRuntimeConfig } from "../config";
import { BASE_URL } from "../constants";

const validateSessionCookie = async (cookie, cookiesHeader) => {
  if (cookie) {
    try {
      const { userId } = JSON.parse(cookie);

      if (!userId) {
        return false;
      }

      // Check authentication status from API with cookies included
      const response = await fetch(`${BASE_URL}/api/user/is-authenticated`, {
        headers: {
          "Content-Type": "application/json",
          "Cookie": cookiesHeader,
        },
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error("Failed to fetch authentication status");
      }

      const data = await response.json();

      if (!data?.isAuthenticated) {
        return false;
      }

      return userId;
    } catch (error) {
      Sentry.captureException(error, {
        message: "Failed to validate session cookie",
      });
      console.error(error);
    }
  }

  return false;
};

const checkAuthStatus = async (cookie, cookiesHeader) => {
  return validateSessionCookie(cookie, cookiesHeader);
};

const checkAuthStatusFromContext = async (ctx) => {
  const cookies = parseCookies(ctx);

  const sessionCookie = cookies[publicRuntimeConfig.CLOVERLEAF_COOKIE_USER_ID];

  const cookiesHeader = ctx.req ? ctx.req.headers.cookie : "";

  const authenticated = await checkAuthStatus(sessionCookie, cookiesHeader);

  return authenticated;
};

const useAuth = (initialAuthStatus) => {
  const [authenticated, setAuthenticated] = useState(initialAuthStatus);

  useEffect(() => {
    const authenticateUser = async () => {
      const cookies = parseCookies();
      const sessionCookie = cookies[publicRuntimeConfig.CLOVERLEAF_COOKIE_USER_ID];

      const cookiesHeader = document.cookie;

      const userId = await validateSessionCookie(sessionCookie, cookiesHeader);

      if (!userId) {
        setAuthenticated(false);
      } else {
        setAuthenticated(userId);
      }
    };

    authenticateUser();
  }, []);

  return authenticated;
};

export {
  checkAuthStatus,
  checkAuthStatusFromContext,
  useAuth,
};
