import React from "react";
import {
  string,
  func,
  oneOfType,
  arrayOf,
  bool,
  number
} from "prop-types";
import {
  EXTRA_LARGE,
  WHITE,
  BLACK
} from "../utils/theme";
import CircleButton from "./CircleButton";
import { Flex, Box } from "./FlexBox";
import Label from "./styled/Label";

function AddButton({
  label,
  onClick,
  icon = ["fal", "plus"],
  iconSize = "3x",
  light = false,
  circleSize = undefined,
}) {
  return (
    <Flex align="center">
      <Box mr={1}>
        <Label uppercase style={{ fontSize: EXTRA_LARGE, color: light ? WHITE : BLACK }}>{label}</Label>
      </Box>
      <CircleButton
        style={{ borderColor: light ? WHITE : undefined }}
        primary
        onClick={onClick}
        icon={icon}
        iconSize={iconSize}
        circleSize={circleSize}
      />
    </Flex>
  );
}

AddButton.propTypes = {
  label: string.isRequired,
  onClick: func.isRequired,
  icon: oneOfType([arrayOf(string.isRequired), string.isRequired]),
  iconSize: string,
  light: bool,
  circleSize: number,
};

export default AddButton;
