import React from "react";
import {
  arrayOf,
  shape,
  string,
  bool,
  func
} from "prop-types";
import {
  WHITE,
  GREEN,
  DARK_GREY,
  GREY_OPAQUE
} from "../utils/theme";
import CircleContainer from "./CircleContainer";
import Divider from "./Divider";
import { Flex, Box } from "./FlexBox";
import Icon from "./Icon";
import Text from "./styled/Text";

function FormStatus({ sections }) {
  return (
    <Flex w={1} justify="center">
      {sections.map(({ title, complete, expandable, onClick }, index) => (
        <React.Fragment key={title}>
          <Flex column align="center">
            <CircleContainer
              background={complete ? GREEN : WHITE}
              color={complete ? WHITE : DARK_GREY}
              borderColor={complete ? GREEN : DARK_GREY}
              size={3.5}
              expandable={expandable}
              onClick={onClick}
              border
            >
              {complete ? <Icon icon={["fas", "check"]} /> : index + 1}
            </CircleContainer>
            <Box mt={1}>
              <Text color={DARK_GREY} small noMargin>{title}</Text>
            </Box>
          </Flex>
          {index !== (sections.length - 1) && (
            <Box w={75} mt="17px">
              <Divider color={complete ? GREEN : GREY_OPAQUE} />
            </Box>
          )}
        </React.Fragment>
      ))}
    </Flex>
  );
}

FormStatus.propTypes = {
  sections: arrayOf(shape({
    title: string.isRequired,
    complete: bool.isRequired,
    expandable: bool,
    onClick: func,
  })).isRequired,
};

export default FormStatus;
