import { string, bool } from "prop-types";
import styled from "styled-components";
import { GREY_OPAQUE } from "../utils/theme";

const Divider = styled.hr`
  width: 100%;
  margin: 0;
  padding: 0;
  border: ${props => `1px solid ${props.color || GREY_OPAQUE}`};
`;

Divider.propTypes = {
  solid: bool,
  color: string,
};

export default Divider;
