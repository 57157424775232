import React from "react";
import { EnneagramQuestion } from "./Question";
import { useLocale } from "@/lib/hooks";

function EnneagramExampleQuestion({
  onChange,
}) {
  const { t } = useLocale();

  /**
   * Using same translation strings from SixteenTypes
   */
  const question = {
    number: 0,
    answerA: t("instructions.personality-sample.answer-1"),
    answerB: t("instructions.personality-sample.answer-2"),
    prompt: t("instructions.personality-sample.question"),
  };

  const [value, setValue] = React.useState("");

  const handleOnChange = (value) => {
    setValue(value);

    return onChange(value);
  };

  return (
    <EnneagramQuestion
      onChange={handleOnChange}
      question={question}
      selected={value}
    />
  );
}

export { EnneagramExampleQuestion };
