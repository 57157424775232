import { initializeApollo } from "./apolloClient";
import handleSignout, { clearSession } from "./handleSignout";

export const signoutWithRedirect = async (ctx = {}, redirectPath) => {
  // Get the apollo client and then call signout
  const apolloClient = initializeApollo();

  return handleSignout(ctx, apolloClient, redirectPath);
};

export const signout = async () => {
  const apolloClient = initializeApollo();

  return clearSession(apolloClient);
};
