import { useMutation, gql } from "@apollo/client";
import * as Sentry from "@sentry/nextjs";
import { b64EncodeUnicode } from "@/cloverleaf-ui/utils";

const updatePasswordException = ({
  message = "Unknown Error",
  status = "Unknown",
}) => ({
  message,
  status,
});

export const UPDATE_PASSWORD_STATUS = {
  SUCCESS: "SUCCESS",
};

export const UPDATE_PASSWORD_QUERY = gql`
  mutation updatePassword($input: input) {
    updatePassword(input: $input)
      @rest(type: "Response", path: "/user/change-password", method: "POST") {
        message
        status
        success
    }
  }
`;

export const useUpdatePassword = () => {
  const [
    updatePassword,
    { data, loading, error },
  ] = useMutation(UPDATE_PASSWORD_QUERY);

  const handleUpdatePassword = async ({ password, subdomain }) => {
    try {
      const {
        data: response,
      } = await updatePassword({
        variables: {
          input: {
            password: b64EncodeUnicode(password),
            subdomain,
          },
        },
      });

      const {
        message,
        status,
        success,
      } = response.updatePassword;

      return {
        message,
        status,
        success,
      };
    }
    catch (errorResponse) {
      const { message, status, success } = errorResponse?.networkError?.result || {};

      Sentry.captureException(errorResponse, {
        extra: {
          message,
          status,
          success,
        },
        tags: {
          function: "useUpdatePassword",
        },
      });

      throw updatePasswordException({ message, status });
    }
  };

  return {
    data,
    error,
    loading,
    updatePassword: handleUpdatePassword,
    updatePasswordStatus: UPDATE_PASSWORD_STATUS,
  };
};
