import {
  useMutation,
  useQuery,
  gql
} from "@apollo/client";

const TEAM_CONFIG_QUERY = gql`
  query teamConfigQuery($teamId: ID!) {
    team(id: $teamId) {
      id
      configuration {
        id
        configurationId
        name
        setting
        assessmentName
        type
        onDashboard
      }
    }
  }
`;

const UPDATE_TEAM_CONFIG_MUTATION = gql`
  mutation updateTeamConfiguration($teamId: ID!, $examId: AssessmentName!, $setting: ConfigurationSettingEnum!) {
      updateTeamConfiguration(teamId: $teamId, examId: $examId, setting: $setting) {
        team {
          id
          configuration {
            id
            configurationId
            name
            setting
            assessmentName
            type
            onDashboard
          }
        }
      }
    }
`;

/**
 * Hook for querying and updating team configuration
 * @param {string} teamId
 */
const useTeamConfiguration = (teamId) => {
  if (!teamId) {
    // eslint-disable-next-line no-console
    console.warn("useTeamConfiguration requires valid teamId");
  }

  const { data, loading, error } = useQuery(TEAM_CONFIG_QUERY, {
    variables: { teamId },
    fetchPolicy: "cache-and-network",
    skip: !teamId,
  });

  const teamConfiguration = data?.team?.configuration?.filter(
    config => !!config.onDashboard && !!config.assessmentName,
  );

  const [
    updateTeamConfiguration,
  ] = useMutation(UPDATE_TEAM_CONFIG_MUTATION);

  const handleUpdateTeamConfiguration = ({
    examId,
    setting,
  }) => updateTeamConfiguration({
    variables: {
      examId,
      setting,
      teamId,
    },
  });

  return {
    data,
    error,
    loading,
    teamConfiguration,
    handleUpdateTeamConfiguration,
  };
};

export { useTeamConfiguration };
