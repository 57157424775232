import React from "react";
import { node } from "prop-types";
import * as Sentry from "@sentry/nextjs";
import { WERE_SORRY_GONE_WRONG, BEEN_NOTIFIED } from "../utils/strings";

class ErrorBoundary extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  static propTypes = {
    children: node.isRequired,
  };

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry.captureException(error, { extra: errorInfo });
  }

  render() {
    if (this.state.error) {
      return (
        <div>
          <p>{WERE_SORRY_GONE_WRONG}</p>
          <p>{BEEN_NOTIFIED}</p>
        </div>
      );
    }

    // when there's not an error, render children untouched
    return this.props.children;
  }
}

export default ErrorBoundary;
