import React from "react";
import {
  string,
  func,
  node
} from "prop-types";
import styled from "styled-components";
import { iconProp } from "../utils/propTypes";
import {
  MEDIUM_SIZE,
  GREEN,
  DARK_GREEN,
  WHITE,
  GREY,
  RED,
  DARK_RED,
  GREY_OPAQUE
} from "../utils/theme";
import ButtonWithIcon from "./ButtonWithIcon";

const positive = {
  background: {
    default: GREY_OPAQUE,
    hover: GREEN,
    active: DARK_GREEN,
  },
  border: {
    default: GREY,
    hover: DARK_GREEN,
    active: GREEN,
  },
};

const negative = {
  background: {
    default: GREY_OPAQUE,
    hover: RED,
    active: DARK_RED,
  },
  border: {
    default: GREY,
    hover: DARK_RED,
    active: RED,
  },
};

const background = (props, state = "default") => {
  if (props.positive) {
    return positive.background[state];
  }
  if (props.negative) {
    return negative.background[state];
  }

  return GREY;
};

const border = (props, state = "default") => {
  if (props.positive) {
    return positive.border[state];
  }
  if (props.negative) {
    return negative.border[state];
  }

  return GREY;
};

const StyledButtonWithIcon = styled(ButtonWithIcon)`
  padding: 0 5px;
  line-height: 20px;
  height: unset;
  background-color: ${background};
  border-color: ${border};
  ${props => props.disabled && "border: none;"}
  color: ${WHITE};

  &:hover {
    background-color: ${props => background(props, "hover")};
    border-color: ${props => border(props, "hover")};
  }

  &:active {
    background-color: ${props => background(props, "active")};
    border-color: ${props => border(props, "active")};
  }
`;

class BadgeButton extends React.PureComponent {
  static propTypes = {
    tooltipText: string,
    label: string,
    icon: iconProp,
    onClick: func,
    children: node,
  }

  static defaultProps = {
    onClick: () => undefined,
    children: undefined,
    icon: undefined,
    label: "",
    tooltipText: undefined,
  }

  render() {
    const {
      children, tooltipText, label, ...props
    } = this.props;

    return (
      <StyledButtonWithIcon
        {...props}
        tooltip={!tooltipText ? undefined : {
          title: tooltipText,
          arrow: true,
          position: "bottom",
        }}
        small
        fontSize={MEDIUM_SIZE}
      >
        {children || label}
      </StyledButtonWithIcon>
    );
  }
}

export default BadgeButton;
