import React from "react";
import * as Sentry from "@sentry/nextjs";
import { v4 as uuidv4 } from "uuid";
import { Box } from "grommet";
import { Icon } from "@/cloverleaf-ui/components";
import { useCloverleafNonce } from "@/cloverleaf-ui/hooks";
import { getSubdomain } from "@/components/utils";
import {
  useGetTrust,
  useToastMessage,
  useLocale
} from "@/lib/hooks";

function AddAccountMenuItem() {
  const { t } = useLocale();
  const { pushToast } = useToastMessage();
  const { trust } = useGetTrust();
  const [, setNonce] = useCloverleafNonce("la_state");
  const subdomain = getSubdomain();

  const handleOnAddAccount = () => {
    const uuid = uuidv4();

    setNonce(uuid);

    return trust({ sourceSubdomain: subdomain, state: uuid })
      .then(() => {
        Sentry.addBreadcrumb({
          category: "link-account",
          message: "Link a new account",
          level: "info",
        });

        return pushToast(t("switch-account-menu.redirecting"));
      })
      .catch((errorResponse) => {
        Sentry.captureMessage({
          category: "link-account",
          message: `Failed to add a new account: ${errorResponse}`,
          level: "error",
        });

        setNonce(null);

        return pushToast(t("switch-account-menu.add-account-failure"), "critical");
      });
  };

  return ({
    gap: "small",
    icon: (
      <Box alignSelf="center" direction="row" fill="vertical" flex={false}>
        <Icon
          fixedWidth
          icon={["far", "plus"]}
          color="grey700"
          size="1x"
        />
      </Box>
    ),
    label: t("switch-account-menu.add-account"),
    onClick: () => handleOnAddAccount(),
  });
}

export { AddAccountMenuItem };
