import { useQuery } from "@apollo/client";

/**
 * For 'cache-and-network' policies, apollo does not set loading to true until
 * the network request is made, and may simultaneously return an incomplete data object.
 * Components that rely on a query to complete data fetching entirely before rendering will have unexpected side effects.
 * This method returns a loading variable that encompasses the entire data-fetching lifecycle and only sets loading to false when the query is complete.
 */
export const usePersistedCacheQuery = (query, options) => {
  const result = useQuery(query, {
    ...options,
    fetchPolicy: "cache-and-network",
  });

  let isActuallyLoading = true;

  try {
    isActuallyLoading = result.loading &&
      !result?.client?.readQuery({
        query,
        variables: options?.variables,
      });
  }
  catch {
    // failed to read cache because object doesn't exist, still loading
    return { loading: true };
  }

  return { ...result, loading: isActuallyLoading };
};
