import React from "react";
import {
  node,
  shape,
  string
} from "prop-types";
import { GREY_OPAQUE, DEFAULT_FONT } from "../utils/theme";
import { Flex, Box } from "./FlexBox";
import SingleLineTextBox from "./SingleLineTextBox";
import TextLabel from "./styled/TextLabel";

function HorizontalEntity({ entity, label, subLabel, labelProps }) {
  return (
    <Flex align="center">
      <Box mr="4px">
        {entity}
      </Box>
      <Flex column>
        <SingleLineTextBox w={labelProps.maxWidth} h={labelProps.height} style={{ color: labelProps.color }}>
          <TextLabel {...labelProps}>
            {label}
          </TextLabel>
        </SingleLineTextBox>
        {subLabel && (
          <TextLabel uppercase small color={GREY_OPAQUE} font={DEFAULT_FONT}>
            {subLabel}
          </TextLabel>
        )}
      </Flex>
    </Flex>
  );
}

HorizontalEntity.propTypes = {
  entity: node.isRequired,
  label: string,
  labelProps: shape({}).isRequired,
  subLabel: string,
};

export default HorizontalEntity;
