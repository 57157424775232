import curry from "lodash/fp/curry";
import getOr from "lodash/fp/getOr";
import { ASSESSMENT } from "../components/utils/constants";
import { buildActionCreator } from "./utils";

export const initialStatus = {
  currentPage: 0,
  questionPage: 1,
  readyToSubmit: false,
  latestPage: 0,
};

export const initialState = {
  DISC: {
    status: initialStatus,
  },
};

const getOrInitial = getOr(initialStatus);

const clientNavigatorInitial = {};

/**
 * SELECTORS
 */
export const getDiscAssessment = state => getOrInitial("app.assessments.DISC.status", state);

export const getMotivatingValuesData = getOr([], "app.assessments.motivatingValues.data");

export const getClientNavigatorData = getOr(clientNavigatorInitial, "app.assessments.clientNavigator");

export const getAssessmentStatus = curry((assessment, state) =>
  getOrInitial(["app", "assessments", assessment, "status"], state));

/**
 * ACTIONS
 */

export const SET_DISC_STATUS = "cloverleaf/app/assessments/SET_DISC_STATUS";
export const SET_DISC_DATA = "cloverleaf/assessments/SET_DISC_DATA";

export const SET_ASSESSMENT_STATUS = "cloverleaf/app/assessments/SET_ASSESSMENT_STATUS";

export const SET_MOTIVATING_VALUES_DATA = "cloverleaf/app/assessments/SET_MOTIVATING_VALUES_DATA";
export const RESET_MOTIVATING_VALUES_DATA = "cloverleaf/app/assessments/RESET_MOTIVATING_VALUES_DATA";

export const SET_CLIENT_NAVIGATOR_DATA = "cloverleaf/app/assessments/SET_CLIENT_NAVIGATOR_DATA";
export const RESET_CLIENT_NAVIGATOR_DATA = "cloverleaf/app/assessments/RESET_CLIENT_NAVIGATOR_DATA";

/**
 * ACTION CREATORS
 */
export const setDiscStatus = buildActionCreator(SET_DISC_STATUS);

export const resetDiscStatus = () => setDiscStatus(initialState.DISC.status);

export const setDiscData = buildActionCreator(SET_DISC_DATA);

export const setAssessmentStatus = curry((assessment, data) => ({
  type: SET_ASSESSMENT_STATUS,
  data,
  meta: {
    assessment,
  },
}));

export const setClientNavigatorData = data => ({
  type: SET_CLIENT_NAVIGATOR_DATA,
  data,
});

export const resetClientNavigatorData = () => ({
  type: RESET_CLIENT_NAVIGATOR_DATA,
  data: {},
});

export const resetClientNavigator = () => resetClientNavigatorData();

export const setMotivatingValuesData = buildActionCreator(SET_MOTIVATING_VALUES_DATA);

export const resetMotivatingValuesData = buildActionCreator(RESET_MOTIVATING_VALUES_DATA);

// TODO: Make disc use generic reducer?
export const resetAssessmentStatus = (name) => {
  if (name === ASSESSMENT.DISC) {
    return resetDiscStatus();
  }

  return setAssessmentStatus(name, initialStatus);
};

/**
 * REDUCER
 */

export default function reducer(state = {}, action = {}) {
  const { data = {}, meta = {} } = action;

  switch (action.type) {
    case SET_DISC_STATUS: {
      return {
        ...state,
        DISC: {
          ...getOr(initialState.DISC, "DISC", state),
          status: {
            ...getOr(initialState.DISC.status, "DISC.status", state),
            ...data,
          },
        },
      };
    }
    case SET_CLIENT_NAVIGATOR_DATA: {
      return {
        ...state,
        clientNavigator: {
          ...getOr({}, "clientNavigator", state),
          [data.questionPage]: {
            ...getOr({}, ["clientNavigator", data.questionPage], state),
            [data.questionNumber]: {
              ...data.answer,
            },
          },
        },
      };
    }
    case RESET_CLIENT_NAVIGATOR_DATA: {
      return {
        ...state,
        clientNavigator: {},
      };
    }
    case SET_ASSESSMENT_STATUS: {
      return {
        ...state,
        [meta.assessment]: {
          ...state[meta.assessment],
          status: {
            ...getOrInitial([meta.assessment, "status"], state),
            ...data,
          },
        },
      };
    }
    case SET_MOTIVATING_VALUES_DATA: {
      return {
        ...state,
        motivatingValues: {
          ...state.motivatingValues,
          data: {
            ...getOr([], "motivatingValues.data", state),
            [`question-${data.questionNumber}`]: data.rankedOptions,
          },
        },
      };
    }
    case RESET_MOTIVATING_VALUES_DATA: {
      return {
        ...state,
        motivatingValues: {
          ...state.motivatingValues,
          data: {},
        },
      };
    }
    default: return state;
  }
}
