import React from "react";
import PropType from "prop-types";
import styled from "styled-components";
import {
  Button,
  CheckBoxGroup,
  FormField,
  Heading,
  ThemeContext,
} from "grommet";
import { toggleButtonTheme } from "@/cloverleaf-ui/theme";
import { useLocale } from "@/lib/hooks";

/** Remove the Label from the CheckBox when rendering our question as buttons */
const NoLabelCheckBoxGroup = styled(CheckBoxGroup)`
  /* hide the span tag that gets rendered with the CheckBoxes. */
  & > label > span { display: none; }
  /* Give Button full width */
  & > label, & > label div { width: 100% }
`;

function MultiSelectQuestion({
  name,
  options,
  prompt = undefined,
  value: defaultValue = [],
  onChange,
}) {
  const { t } = useLocale();
  const id = `checkboxgroup-${name}`;

  const [value, setValue] = React.useState(defaultValue);

  const handleOnChange = (option) => {
    setValue(option.value);

    return onChange(option.value);
  };

  return (
    <FormField
      contentProps={{ border: false }}
      name={name}
      htmlFor={id}
      label={(
        <Heading
          margin={{ top: "none", bottom: "small" }}
          size="xsmall"
          textAlign="center"
        >
          {prompt || t("multi-select-label")}
        </Heading>
      )}
    >
      <ThemeContext.Extend value={toggleButtonTheme}>
        <NoLabelCheckBoxGroup
          id={id}
          name={name}
          options={options}
          value={value}
          onChange={handleOnChange}
          focusIndicator={false}
        >
          {(option, { checked }) => (
            <Button
              as="div"
              fill
              active={checked}
              key={option.value}
              label={option.label}
              primary
              uppercase
            />
          )}
        </NoLabelCheckBoxGroup>
      </ThemeContext.Extend>
    </FormField>
  );
}

MultiSelectQuestion.propTypes = {
  name: PropType.string.isRequired,
  prompt: PropType.string,
  options: PropType.arrayOf(PropType.shape({
    value: PropType.string,
    label: PropType.string,
  })).isRequired,
  value: PropType.arrayOf(PropType.string),
  onChange: PropType.func.isRequired,
};

export { MultiSelectQuestion };
