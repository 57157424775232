import compact from "lodash/fp/compact";
import curry from "lodash/fp/curry";
import flow from "lodash/fp/flow";
import get from "lodash/fp/get";
import isEmpty from "lodash/fp/isEmpty";
import map from "lodash/fp/map";
import mapKeys from "lodash/fp/mapKeys";
import mapValues from "lodash/fp/mapValues";
import reject from "lodash/fp/reject";
import toLower from "lodash/fp/toLower";
import uniq from "lodash/fp/uniq";
import {
  REPEATED_EMAIL_ERROR,
  NO_COLUMNS_MAPPED_TO,
  TO_MANY_COLUMNS_MAPPED_TO,
  INVALID_EMAIL_FORMAT,
  USER_WITH_NO_EMAIL,
  ORG_ADMIN_MUST_BE,
  REPEATED_TEAM_ERROR,
  REPEATED_GROUP_ERROR,
  EMPTY_FIRST_NAME,
  EMPTY_LAST_NAME,
} from "../../utils/strings/importOrganizationValidation";
import { email as validateEmail } from "../../utils/validate";

/**
 * @typedef {Object} ImportOrganizationUser
 * @property {String} firstName
 * @property {String} lastName
 * @property {String} email
 * @property {String} company
 * @property {String} role
 * @property {Array<Object>} teams
 */

/**
 * @typedef {Object} ImportOrganizationState
 * @property {Object} columnMapping
 * @property {Array<Object>} rows
*/

export const IMPORT_ORG_COLUMN = {
  FIRST_NAME: "First Name",
  LAST_NAME: "Last Name",
  EMAIL: "Email",
  COMPANY: "Company",
  ROLE: "Role",
  TEAM: "Team",
  ORG_ADMIN: "Org Admin",
  GROUP: "Group",
};

export const mapToOptions = [
  IMPORT_ORG_COLUMN.FIRST_NAME,
  IMPORT_ORG_COLUMN.LAST_NAME,
  IMPORT_ORG_COLUMN.EMAIL,
  IMPORT_ORG_COLUMN.ROLE,
  IMPORT_ORG_COLUMN.COMPANY,
  IMPORT_ORG_COLUMN.TEAM,
  IMPORT_ORG_COLUMN.GROUP,
];

// If we are NOT enterprise wide, then we do not allow GROUP columns
export const getColumnOptions = enterpriseWide => (enterpriseWide
  ? mapToOptions
  : reject(val => val === IMPORT_ORG_COLUMN.GROUP, mapToOptions));

const noRepeatedValidationsForEach =
  (r, collectionOfSimilar, errors, globalCollection, errorMessage) => (rawColumnName) => {
    const value = (r[rawColumnName]) && r[rawColumnName].toLowerCase();
    if (value) {
      const matchingValues = collectionOfSimilar[value];

      if (matchingValues) {
        errors.push(
          {
            error: errorMessage,
            errorCells: matchingValues
              .map(matchingValueColumnName => ({ rowId: r.id, rawColumnName: matchingValueColumnName }))
              .concat({ rowId: r.id, rawColumnName }),
          },
        );
        matchingValues.push(rawColumnName);
      }
      else {
        /* eslint-disable-next-line */
        collectionOfSimilar[value] = [rawColumnName];
      }
      if (globalCollection) {
        globalCollection.push(value);
      }
    }
  };

const noRepeatedValidations = (dataMapping, mappedTo, r, globalCollection, errors, errorMessage) => {
  const collectionOfSimilar = {};
  if (dataMapping[mappedTo]) {
    dataMapping[mappedTo]
      .forEach(noRepeatedValidationsForEach(r, collectionOfSimilar, errors, globalCollection, errorMessage));
  }
};

const validateEmails = (dataMapping, mappedTo, r, allEmails, errors) => {
  const emptyEmails = [];
  if (dataMapping[mappedTo]) {
    dataMapping[mappedTo].forEach((rawColumnName, i, arr) => {
      const email = r[rawColumnName] && r[rawColumnName].toLowerCase();

      const matchingEmails = allEmails[email];

      if (email) {
        if (matchingEmails) {
          allEmails[email].push({ rowId: r.id, rawColumnName });
        }
        else {
          /* eslint-disable-next-line */
          allEmails[email] = [{ rowId: r.id, rawColumnName }];
        }
      }

      if (!isEmpty(matchingEmails)) {
        errors.push(
          {
            error: REPEATED_EMAIL_ERROR,
            errorCells: matchingEmails.concat({ rowId: r.id, rawColumnName }),
          },
        );
      }

      if (!email) {
      // Keep Track of howMany Empty values
        emptyEmails.push({ email, rowId: r.id, rawColumnName });
        if (emptyEmails.length >= arr.length) {
          errors.push(
            {
              error: USER_WITH_NO_EMAIL,
              errorCells: emptyEmails,
            },
          );
        }
      }
      // Email columns should enforce valid email format, and display error for invalid format.
      // If email is not a valid Email.
      if (validateEmail(email)) {
        errors.push({ error: INVALID_EMAIL_FORMAT, errorCells: [{ rowId: r.id, rawColumnName }] });
      }
    });
  }
};

const columnCantHaveEmptyValues = (r, dataMapping, mappedTo, errors, errorMessage) => {
  if (dataMapping[mappedTo]) {
    dataMapping[mappedTo].forEach((rawColumnName) => {
      const value = r[rawColumnName];

      if (!value) {
        errors.push(
          {
            error: errorMessage,
            errorCells: [{ rowId: r.id, rawColumnName, showRequiredButton: true }],
          },
        );
      }
    });
  }
};

const mustHaveOneColumnMappedToIt = (arrayOfMappedTo, dataMapping) =>
  arrayOfMappedTo.map(mt => !dataMapping[mt] && { error: `${NO_COLUMNS_MAPPED_TO} ${mt}` });

const oneColumnMaximum = (dataMapping, excluded) =>
  Object.entries(dataMapping).map(([mappedTo, rawColumnArray]) =>
    rawColumnArray.length > 1
    && !excluded.includes(mappedTo)
    && { error: `${TO_MANY_COLUMNS_MAPPED_TO} ${mappedTo}` });

const isDataValid = (data) => {
  if (
    data
    && data.columnMapping
    && data.rows
    && data.fileName
  ) {
    return true;
  }

  return false;
};

// This function needs to be refactored and DRIED.
export const processAndValidateData = (data) => {
  if (!isDataValid(data)) {
    return undefined;
  }
  const errors = [];

  const dataMapping = data.columnMapping.reduce((acc, cm) => {
    if (acc[cm.mappedTo]) {
      return { ...acc, [cm.mappedTo]: [...acc[cm.mappedTo], cm.rawColumn] };
    }

    return { ...acc, [cm.mappedTo]: [cm.rawColumn] };
  }, {});

  const mustHaveValues = [IMPORT_ORG_COLUMN.FIRST_NAME, IMPORT_ORG_COLUMN.LAST_NAME, IMPORT_ORG_COLUMN.EMAIL];

  // Must have at least 'Email', 'First Name', 'Last Name'
  const missingFieldErrors = mustHaveOneColumnMappedToIt(mustHaveValues, dataMapping);

  // Only team, group, and email columns allow more than one, so any other duplicates are considered an Error add an error.
  const allowedToBeDuplicated = [IMPORT_ORG_COLUMN.TEAM, IMPORT_ORG_COLUMN.GROUP];

  const tooManyDuplicatesError = oneColumnMaximum(dataMapping, allowedToBeDuplicated);

  // The email column(s) are unique identifiers - only 1 occurrence of an email is allowed.
  const allEmails = {};
  const allTeams = [];
  let orgAdminCount = 0;

  const rows = data.rows.map((r) => {
    validateEmails(dataMapping, IMPORT_ORG_COLUMN.EMAIL, r, allEmails, errors);

    // Organization Admin column supports only 'Yes', 'No', or empty. Anything else should be marked as an error.
    if (dataMapping[IMPORT_ORG_COLUMN.ORG_ADMIN]) {
      dataMapping[IMPORT_ORG_COLUMN.ORG_ADMIN].forEach((rawColumnName) => {
        const isOrgAdmin = r[rawColumnName] && (r[rawColumnName]).toString();

        if (isOrgAdmin !== undefined && (isOrgAdmin.toLowerCase() === "yes" || isOrgAdmin.toLowerCase() === "y")) {
          orgAdminCount += 1;
        }

        const validOrgAdminValues = ["yes", "no", "y", "n", ""];

        if (isOrgAdmin !== undefined && !(typeof isOrgAdmin === "string" && validOrgAdminValues.includes(isOrgAdmin.toLowerCase()))) {
          errors.push({ error: ORG_ADMIN_MUST_BE, errorCells: [{ rowId: r.id, rawColumnName }] });
        }
      });
    }

    // No repeated Teams per user
    noRepeatedValidations(dataMapping, IMPORT_ORG_COLUMN.TEAM, r, allTeams, errors, REPEATED_TEAM_ERROR);

    // No repeated Groups per user
    noRepeatedValidations(dataMapping, IMPORT_ORG_COLUMN.GROUP, r, null, errors, REPEATED_GROUP_ERROR);

    // First name can't have empty values
    columnCantHaveEmptyValues(r, dataMapping, IMPORT_ORG_COLUMN.FIRST_NAME, errors, EMPTY_FIRST_NAME);

    // Last name cant have empty values
    columnCantHaveEmptyValues(r, dataMapping, IMPORT_ORG_COLUMN.LAST_NAME, errors, EMPTY_LAST_NAME);

    return Object.entries(r).reduce((acc, [k, v]) => {
      const value = typeof v === "string" ? v.trim() : v;

      return { ...acc, [k]: value };
    }, {});
  });

  return {
    ...data,
    rows,
    validationErrors: compact([...errors, ...missingFieldErrors, ...tooManyDuplicatesError]),
    adminCount: orgAdminCount,
    teamCount: uniq(allTeams).length,
  };
};

export const findColumnMatch = (rawColumnName, mappingOptions) => {
  const splitName = rawColumnName.split(" ");

  return mappingOptions.find((mo) => {
    if (
      splitName.map(sn => mo.toLocaleLowerCase().includes(sn.toLowerCase())).includes(true)
    ) {
      return true;
    }

    return false;
  })
  || mappingOptions[mappingOptions.length - 1];
};

export const addColumnAndRebuild = (importOrganization, mappedTo) => {
  const newColumnName = `Column ${importOrganization.columnMapping.length + 2}`;
  const { rows } = importOrganization;

  const replacementRows = rows.map(r => ({ ...r, [newColumnName]: "" }));
  const replacementColumnMapping = importOrganization.columnMapping.concat({ rawColumn: newColumnName, mappedTo });

  return {
    rows: replacementRows,
    columnMapping: replacementColumnMapping,
    fileName: importOrganization.fileName,
    enterpriseWide: importOrganization.enterpriseWide,
  };
};

export const removeColumnAndRebuild = (columnName, importOrganization) => {
  const { rows } = importOrganization;
  const replacementRows = rows.map(r => ({ ...r, [columnName]: undefined }));
  const replacementColumnMapping = reject(["rawColumn", columnName], importOrganization.columnMapping);

  return {
    rows: replacementRows,
    fileName: importOrganization.fileName,
    columnMapping: replacementColumnMapping,
    enterpriseWide: importOrganization.enterpriseWide,
  };
};

export const changeCellAndRebuild = (newCellData, rowIndex, columnName, importOrganization) => {
  const { rows } = importOrganization;
  const data = rows.slice();
  data.splice(rowIndex, 1, { ...rows[rowIndex], [columnName]: newCellData });

  return {
    rows: data,
    columnMapping: importOrganization.columnMapping,
    fileName: importOrganization.fileName,
    enterpriseWide: importOrganization.enterpriseWide,
  };
};

export const mapColumns = (rawColumnNames, mappingOptions) =>
  rawColumnNames.map(rc => ({ rawColumn: rc, mappedTo: findColumnMatch(rc, mappingOptions) }));

export const processRawData = (rawData, mappingOptions, organizationId) => {
  let rawColumns;

  let idCount = 0;
  const rows = rawData.data.map((data) => {
    // if row is completely empty, ignore it!
    if (Object.values(data).filter(value => value !== "" && value !== undefined).length < 1) {
      return undefined;
    }

    // if a cell contains null values as a string - "NULL", replace those for an empty value instead
    const cellData = mapValues(value => (toLower(value) === "null" ? "" : value), data);

    if (idCount === 0) {
      rawColumns = Object.keys(cellData);
    }

    return {
      ...cellData,
      id: idCount++,
    };
  });

  const columnMapping = mapColumns(rawColumns, mappingOptions);

  return { rows: compact(rows), columnMapping, fileName: rawData.fileName, organizationId };
};

export const getColumnsWithNoDuplicates = (csvData) => {
  const userColumns = csvData.split("\n")[0].split(",");
  const columnList = [];
  let indexCount = 1;
  userColumns.forEach((column) => {
    let selectedColumnName = column;

    if (selectedColumnName.toLowerCase() === "id") {
      selectedColumnName = "id column";
    }

    if (columnList.includes(selectedColumnName)) {
      selectedColumnName = `${selectedColumnName} - ${++indexCount}`;
    }
    columnList.push(selectedColumnName);
  });

  return columnList;
};

const columnByInputField = {
  [IMPORT_ORG_COLUMN.FIRST_NAME]: "firstName",
  [IMPORT_ORG_COLUMN.LAST_NAME]: "lastName",
  [IMPORT_ORG_COLUMN.EMAIL]: "email",
  [IMPORT_ORG_COLUMN.ROLE]: "role",
  [IMPORT_ORG_COLUMN.COMPANY]: "company",
  [IMPORT_ORG_COLUMN.TEAM]: "teams",
  [IMPORT_ORG_COLUMN.GROUP]: "groups",
};

// NOTE: This is considerably faster than `_.omit` - https://github.com/lodash/lodash/issues/2930#issuecomment-272298477
const deleteByKey = curry((key, obj) => {
  const clone = obj;
  (delete clone[key]);

  return clone;
});

// Groups all columns of a single column-mapping into a single field.
// For example:
// Appends all of the users teams from the columns mapped to team, as an array ~O(t) where t is number of columns mapped to team
const groupColumnsToField = curry((columns, fieldName, user) => {
  const list = [];

  columns.forEach((column) => {
    if (user[column]) {
      list.push({ name: user[column] });
    }
  });

  return {
    ...user,
    [fieldName]: list,
  };
});

// List of all the columns that are mapped to team (or group) names ~ O(2k) where k is the number of columns
const getColumnsForMapping = (columnName, importOrganizationState) => importOrganizationState.columnMapping
  .filter(column => column.mappedTo === columnName)
  .map(columnMapping => columnMapping.rawColumn);

// Object with keys as old keys, and values as the new keys ~ O(k²) due to spreading
const getNewUserKeys = importOrganizationState => importOrganizationState.columnMapping
  .reduce((prevColumns, column) => ({
    ...prevColumns, // Spreading here makes this problematic but we know the input size is almost always relatively small
    [column.rawColumn]: columnByInputField[column.mappedTo],
  }), { teams: "teams", groups: "groups" }); // This creates a way for us to merge all teams under the same key

/**
 * Creates a list of users with the appropriate shape for the mutation
 *
 * @param {ImportOrganizationState} importOrganizationState
 * @returns {Array<ImportOrganizationUser>}
 */
export const buildImportOrganizationUsers = (importOrganizationState) => {
  const newKeys = getNewUserKeys(importOrganizationState);
  const teamColumns = getColumnsForMapping(IMPORT_ORG_COLUMN.TEAM, importOrganizationState);
  const groupColumns = getColumnsForMapping(IMPORT_ORG_COLUMN.GROUP, importOrganizationState);

  // ~O(n*k) where n is the amount of rows, and k is the amount of columns. Columns are *likely* small, rows are not guaranteed to be small.
  return map(
    flow(
      // Strip out ids, we don't need those
      deleteByKey("id"),
      // Group teams per user into a single value
      groupColumnsToField(teamColumns, "teams"),
      // Group groups per user into a single value
      groupColumnsToField(groupColumns, "groups"),
      // Rename keys to match mutation input
      mapKeys(oldKey => get(oldKey, newKeys)),
    ),
  )(importOrganizationState.rows);
};
