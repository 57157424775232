import React from "react";
import {
  oneOfType,
  string,
  node,
  func,
  shape
} from "prop-types";
import withConfirmAction from "../hoc/withConfirmAction";
import { Flex, Box } from "./FlexBox";
import Icon from "./Icon";
import TextButton from "./TextButton";

const TextButtonWithConfirm = withConfirmAction(TextButton);

function RemoveableListItem({
  tooltip, item, onRemove, prompt,
}) {
  return (
    <Flex mt={1} justify="space-between">
      <Box>{item}</Box>
      <Box>
        <TextButtonWithConfirm prompt={prompt} onClick={onRemove}>
          <Icon
            icon="trash"
            tooltip={tooltip}
          />
        </TextButtonWithConfirm>
      </Box>
    </Flex>
  );
}

RemoveableListItem.propTypes = {
  item: oneOfType([string, node]).isRequired,
  onRemove: func.isRequired,
  tooltip: shape({}), // tooltip props
  prompt: string,
};

export default RemoveableListItem;
