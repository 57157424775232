import React from "react";
import PropTypes from "prop-types";
import { Flex } from "./FlexBox";

const Content = React.forwardRef(({ wrap = true, w = 1, ...props }, ref) => (
  <Flex ref={ref} wrap={wrap} align="flex-start" justify="space-evenly" w={w} h={1} {...props} />
));

Content.propTypes = {
  w: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  wrap: PropTypes.bool,
};

Content.displayName = "Content";

export default Content;
