import React from "react";
import styled from "styled-components";
import { Box, Main } from "grommet";
import Toasts from "../../../../components/composite/Toasts";
import ErrorBoundary from "../../../../components/ui/ErrorBoundary";
import { Footer } from "../../molecules";

const Wrapper = styled(Box)`
  background-attachment: ${props => props?.background?.attachment};
  min-height: ${props => (props.nested ? "0px" : "100vh")};
`;

function AnchoredLayout({
  background = {
    attachment: "fixed",
    color: "white",
    image: "url(\"//cloverleaf-app-publicfiles.s3.amazonaws.com/background-tile.png\")",
    repeat: "repeat",
    size: "contain",
  },
  contentBackground = "white",
  children = undefined,
  nested = false,
  showFooter = true,
  ...props
}) {
  return (
    <Wrapper background={background} nested={nested} width="100%">
      {!nested && (
      /**
        TODO: Remove conditional when parent Layout component is deprecated, or Toast component is updated to cloverleaf-ui
        If nested is true, do not duplicate the Toasts component while the parent Layout is already rendering it.
        This is not an issue now, but prevents potentially duplicating the Toast messages.
      */
        <Toasts />
      )}
      <Main
        align="center"
        background={contentBackground}
        overflow={null}
        pad="40px"
        width="480px"
        alignSelf="start"
        {...props}
      >
        <ErrorBoundary>
          {children}
        </ErrorBoundary>
      </Main>
      {!nested && showFooter && <Footer />}
    </Wrapper>
  );
}

export { AnchoredLayout };
