import { useRouter } from "next/router";
import {
  useApolloClient,
  useMutation,
  gql
} from "@apollo/client";
import { setCookie } from "nookies";
import { Box, Text } from "grommet";
import { Icon } from "@/cloverleaf-ui/components";
import { EVENT } from "../../../../utils/constants";
import { languages } from "@/components/composite/Internationalization/i18n.utils";
import { track } from "@/lib";
import { publicRuntimeConfig } from "@/lib/config";
import { useAuth } from "@/lib/hooks";

const updateUserLocale = gql`
  mutation updateUserProfile($input: UpdateUserProfileInput!) {
    updateUserProfile(input: $input) {
      id
      locale
    }
  }
`;

function useLanguageChooserMenuItems() {
  const router = useRouter();

  const authenticated = useAuth();

  const apolloClient = useApolloClient();

  const [updateUser] = useMutation(updateUserLocale);

  const handleOnChange = ({ language }) => {
    track(EVENT.LanguageChange.name, { language });

    if (authenticated) {
      return updateUser({
        variables: {
          input: {
            locale: language,
          },
        },
      })
        .then(() => {
          setCookie(null, "NEXT_LOCALE", language, {
            domain: publicRuntimeConfig.CLOVERLEAF_COOKIE_DOMAIN,
            maxAge: 30 * 24 * 60 * 60 * 1000, // default to 30 days, in ms
            path: "/",
          });

          const { pathname, asPath, query } = router;

          return router.push({
            pathname,
            query,
          }, `/${language}${asPath}`, {
            locale: language,
          }).then(() => apolloClient.cache.reset());
        });
    }

    setCookie(null, "NEXT_LOCALE", language, {
      domain: publicRuntimeConfig.CLOVERLEAF_COOKIE_DOMAIN,
      maxAge: 30 * 24 * 60 * 60 * 1000, // default to 30 days, in ms
      path: "/",
    });

    const { pathname, asPath, query } = router;

    return router.push({
      pathname,
      query,
    }, `/${language}${asPath}`, {
      locale: language,
    }).then(() => apolloClient.cache.reset());
  };

  const menuItems = languages.map((lang) => ({
    gap: "small",
    justify: "start",
    align: "center",
    icon: lang.language === router.locale ? (
      <Box alignSelf="center" direction="row" fill="vertical" flex={false}>
        <Icon
          fixedWidth
          icon="check"
          color="grey700"
          size="1x"
        />
      </Box>
    ) : undefined,
    label: (
      <Box align="center" direction="row" flex="grow" gap="medium">
        <Text>{lang.name}</Text>
      </Box>
    ),
    reverse: true,
    value: lang.language,
    onClick: () => { handleOnChange(lang); },
  }));

  return menuItems;
}

export { useLanguageChooserMenuItems };
