import { gql } from "@apollo/client";

export const INVITE_TEAMMATES_MODAL_HASH = "invite-teammates-modal";

export const ROLE = {
  ADMIN: "Team Admin",
  MEMBER: "Team Member",
};

export const roleOptions = {
  [ROLE.ADMIN]: {
    label: "team-admin",
    value: ROLE.ADMIN,
  },
  [ROLE.MEMBER]: {
    label: "team-member",
    value: ROLE.MEMBER,
  },
};

export const GET_ORGANIZATION_PERMISSIONS_QUERY = gql`
  query getOrganizationPermissions($organizationId: ID!) {
    currentUser {
      id
      defaultTeam {
        id
      }
      organizations(isMember: true) {
        id
        name
      }
    }
    organization(id: $organizationId) {
      id
      name
      isCoach
      callerCanManage
      inviteOrganizationLink
      users {
        email
      }
      teams(adminOnly: true) {
        id
        inviteLink
        name
        status
      }
      whitelist {
        enabled
        whitelistDomains {
          domain
          status
        }
      }
    }
    check {
      canInviteMembers(organizationId: $organizationId)
    }
  }
`;

export const ADD_USERS_TO_ORGANIZATION_MUTATION = gql`
  mutation addUsersToOrganization($input: AddUsersToOrganizationInput!) {
    addUsersToOrganization(input: $input) {
      organization {
        id
        name
        invitations {
          id
          email
          remindedAt
          user {
            id
            fullName
            profile {
              id
              publicUrl
              relativePublicUrl
            }
          }
        }
      }
    }
  }
`;

export const ADD_ADMINS_TO_ORG_MUTATION = gql`
  mutation addAdminsToOrganization($input: AddAdminsToOrganizationInput!) {
    addAdminsToOrganization(input: $input) {
      organization {
        id
        name
        invitations {
          id
          email
          remindedAt
          user {
            id
            fullName
            profile {
              id
              publicUrl
              relativePublicUrl
            }
          }
        }
      }
    }
  }
`;

export const ADD_USERS_TO_TEAM_MUTATION = gql`
  mutation addUsersToTeam($input: AddUsersToTeamInput!) {
    addUsersToTeam(input: $input) {
      team {
        id
        hidden
        inviteLink
        admins {
          id
          fullName
          firstName
          lastName
          email
          avatar
          profile {
            id
            relativePublicUrl
          }
          teams {
            id
          }
          admin {
            teams {
              id
            }
          }
        }
        users {
          id
          fullName
          avatar
          email
          isCandidate
          isTTI
          admin {
            teams {
              id
              name
            }
          }
          profile {
            id
            publicUrl
            relativePublicUrl
          }
        }
        invitations {
          remindedAt
          email
          user {
            id
            fullName
            profile{
              id
              relativePublicUrl
            }
          }
        }
      }
    }
  }
`;
