import curry from "lodash/fp/curry";

export const buildActionCreator = type => data => ({
  type,
  data,
});

export const buildItemActionCreator = curry((actionType, name, value) => ({
  type: actionType,
  payload: value,
  meta: {
    name,
  },
}));

export const genericItemReducer = (state = {}, { type, meta, payload }) => ({
  ...state,
  [type]: {
    ...state[type],
    [meta.name]: payload,
  },
});
