import React from "react";
import PropTypes from "prop-types";
import { Box, Text } from "grommet";

function Divider({
  children = undefined,
  color = "brand",
  label = undefined,
  ...props
}) {
  if (children || label) {
    const contents = label ? (
      <Text color="black" style={{ textTransform: "uppercase" }}>
        {label}
      </Text>
    ) : children;

    return (
      <Box align="center" direction="row" {...props}>
        <Box flex="grow" background={color} height={{ min: "1px" }} />
        <Box margin={{ horizontal: "large" }}>
          {contents}
        </Box>
        <Box flex="grow" background={color} height={{ min: "1px" }} />
      </Box>
    );
  }

  return (
    <Box width="100%" background={color} height={{ min: "1px" }} {...props} />
  );
}

Divider.propTypes = {
  children: PropTypes.any,
  color: PropTypes.string,
  label: PropTypes.string,
};

export { Divider };
