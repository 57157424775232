import {
  useMutation,
  useLazyQuery,
  gql
} from "@apollo/client";
import * as Sentry from "@sentry/nextjs";
import { publicRuntimeConfig } from "../config";

const trustSelfException = ({
  message = "Unknown Error",
  status = "Unknown",
}) => ({
  message,
  status,
});

export const TRUST_SELF_STATUS = {
  INVALID: "INVALID",
  SUCCESS: "SUCCESS",
};

export const TRUST_SELF_QUERY = gql`
  query trustSelf($code: code) {
    trustSelf(code: $code)
      @rest( type: "getTrustSelf", path: "/trust/self?{args}") {
        accounts
        message
        status
    }
  }
`;

// input { subdomains }
export const TRUST_SELF_MUTATION = gql`
  mutation trustSelf($input: input) {
    trustSelf(input: $input)
      @rest( type: "deleteTrustSelf", path: "/trust/self?{args}", method: "DELETE") {
        accounts
        message
        status
    }
  }
`;

const useGetTrustSelf = (options) => {
  const [
    trustSelf,
    { data, loading, error },
  ] = useLazyQuery(TRUST_SELF_QUERY, { ...options });

  const handleTrustSelf = async ({
    code,
  } = {}) => {
    try {
      const response = await trustSelf({
        variables: {
          code,
        },
      });

      const {
        accounts,
        status,
      } = response?.trustSelf || {};

      return {
        accounts,
        status,
      };
    }
    catch (errorResponse) {
      const { message, status } = errorResponse?.networkError?.result || {};

      Sentry.captureException(errorResponse, {
        extra: {
          message,
          status,
        },
        tags: {
          function: "useGetTrustSelf",
        },
      });

      throw trustSelfException({ message, status });
    }
  };

  return {
    data,
    error,
    loading,
    trustSelf: handleTrustSelf,
    trustSelfStatus: TRUST_SELF_STATUS,
  };
};

const usePostTrustSelf = (options) => {
  const [
    trustSelf,
    { data, loading, error },
  ] = useMutation(TRUST_SELF_MUTATION, { ...options });

  const handleTrustSelf = async ({
    clientId = publicRuntimeConfig.CLOVERLEAF_CLIENT_ID,
    subdomains,
  }) => {
    try {
      const {
        data: response,
      } = await trustSelf({
        variables: {
          input: {
            client_id: clientId,
            subdomains,
          },
        },
      });

      const {
        data: link,
        status,
      } = response.trust;

      return {
        link, // should be undefined
        status,
      };
    }
    catch (errorResponse) {
      const { message, status } = errorResponse?.networkError?.result || {};

      Sentry.captureException(errorResponse, {
        extra: {
          message,
          status,
        },
        tags: {
          function: "usePostTrustSelf",
        },
      });

      throw trustSelfException({ message, status });
    }
  };

  return {
    data,
    error,
    loading,
    trustSelf: handleTrustSelf,
    trustSelfStatus: TRUST_SELF_STATUS,
  };
};

export { useGetTrustSelf, usePostTrustSelf };
