import React, { useContext } from "react";
import PropTypes from "prop-types";
import {
  Box,
  DropButton,
  Stack
} from "grommet";
import { Icon } from "@/cloverleaf-ui/components";
import { useDebugAnalytics } from "../hooks/useDebugAnalytics";
import { Log, LogContext } from "./Log";
import { sendLog } from "./sendLog";

function getEventHandlers(child, eventProps, actionProps, isDebugMode = false) {
  const eventHandlers = {};
  for (const [eventHandlerName, specificHandlerProps] of Object.entries(actionProps)) {
    eventHandlers[eventHandlerName] = (...eventHandlerArgs) => {
      child.props[eventHandlerName]?.apply(child, eventHandlerArgs);
      sendLog({ ...eventProps, ...specificHandlerProps }, isDebugMode);
    };
  }

  return eventHandlers;
}

function ObjectRenderer({ data }) {
  let formattedData;

  try {
    formattedData = JSON.stringify(data, null, 2);
  } catch (error) {
    formattedData = error.message || "Error formatting data";
  }

  return (
    <pre>
      <code>
        {formattedData}
      </code>
    </pre>
  );
}

function LogEvent({
  actionProps = undefined,
  children,
  elementType = undefined,
  logEventProps = undefined,
}) {
  const { isDebugMode } = useDebugAnalytics();

  const consumedProps = useContext(LogContext);

  if (!logEventProps || !actionProps) {
    return children;
  }

  const eventProps = {
    elementType,
    ...logEventProps,
    ...consumedProps,
  };

  if (isDebugMode) {
    return (
      <Log elementType={elementType} {...logEventProps}>
        <Stack anchor="top-right">
          {children && React.Children.map(children, (child) => {
            return React.cloneElement(child, {
              ...getEventHandlers(child, eventProps, actionProps, isDebugMode),
            });
          })}
          <DropButton
            plain
            dropContent={
              <Box
                background="grey300"
                border
                pad="small"
                overflow={{ vertical: "auto" }}
              >
                <ObjectRenderer data={{
                  consumedProps,
                  actionProps,
                  logEventProps,
                  identify: {
                    userId: window?.analytics?.user?.()?.id(),
                    traits: window?.analytics?.user?.()?.traits(),
                  },
                }}
                />
              </Box>
            }
            label={
              <Icon
                icon={["far", "circle"]}
                size="xs"
                beatFade
                style={{
                  animationDuration: "2s",
                  margin: "0 -20px 0 0",
                }}
              />
            }
          />
        </Stack>
      </Log>
    );
  }

  return (
    <Log elementType={elementType} {...logEventProps}>
      {children && React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          ...getEventHandlers(child, eventProps, actionProps, isDebugMode),
        })},
      )}
    </Log>
  );
}

LogEvent.propTypes ={
  actionProps: PropTypes.object,
  children: PropTypes.node.isRequired,
  elementType: PropTypes.string,
  logEventProps: PropTypes.object,
};

export { LogEvent };
