import React from "react";
import { node, string } from "prop-types";
import { createGlobalStyle } from "styled-components";
import Toasts from "../composite/Toasts";
import {
  GREEN,
  BLACK,
  RED,
  DEFAULT_FONT,
  GREY,
  GREY_GREEN,
} from "../utils/theme";
import { Flex } from "./FlexBox";

const GlobalStyle = createGlobalStyle`
  body {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    outline: 0;
    font-family: ${DEFAULT_FONT};
  }

  input {
    outline: 0;
  }

  a {
    outline: 0;
  }

  .center {
    text-align: center;
  }

  .primary {
    color: ${GREEN};
  }

  .dark {
    color: ${BLACK};
  }

  .negative {
    color: ${RED};
  }

  .disabled {
    color: ${GREY};
  }

  .uppercase {
    text-transform: uppercase;
  }

  .fade-enter {
    opacity: 0.01;
  }

  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 200ms ease-in;
  }

  .fade-exit {
    opacity: 1;
  }

  .fade-exit.fade-exit-active {
    opacity: 0.01;
    transition: opacity 500ms ease-in;
  }

  .recharts-surface {
    overflow: visible;
  }
  @media screen and (device-aspect-ratio: 2/3) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="url"]{ font-size: 16px; }
  }

  /* iPhone 5, 5C, 5S, iPod Touch 5g */
  @media screen and (device-aspect-ratio: 40/71) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="url"]{ font-size: 16px; }
  }

  /* iPhone 6, iPhone 6s, iPhone 7 portrait/landscape */
  @media screen and (device-aspect-ratio: 375/667) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="url"]{ font-size: 16px; }
  }

  /* iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus portrait/landscape */
  @media screen and (device-aspect-ratio: 9/16) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="url"]{ font-size: 16px; }
  }
`;

function Page({ background = GREY_GREEN, ...props }) {
  return (
    <Flex wrap {...props} background={background}>
      <Toasts />
      {props.children}
      <GlobalStyle />
    </Flex>
  );
}

Page.propTypes = {
  children: node.isRequired,
  background: string,
};

export default Page;
