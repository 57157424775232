import React from "react";
import { bool } from "prop-types";
import { useRouter } from "next/router";
import { useQuery, gql } from "@apollo/client";
import get from "lodash/fp/get";
import { getSubdomain } from "../utils/getSubdomain";
import Img from "./styled/Img";
import { useLocale } from "@/lib/hooks";

const CLOVERLEAF_LOGO_BANNER_FALLBACK = "https://s3.amazonaws.com/cloverleaf-app-publicfiles/Cloverleaf-Banner.png";
const CLOVERLEAF_LOGO_AVATAR_FALLBACK = "https://s3.amazonaws.com/cloverleaf-app-publicfiles/Cloverleaf.png";

const organizationLogoQuery = gql`
  query organizationLogo($subdomain: String) {
    organizationLogo(subdomain: $subdomain) @connection(key: "organizationLogo")
    organizationBanner(subdomain: $subdomain) @connection(key: "organizationBanner")
  }
`;

const getLogoAvatar = get("organizationLogo");
const getLogoBanner = get("organizationBanner");

function Logo({ avatar }) {
  const { t } = useLocale();
  const router = useRouter();
  // Pulls out subdomain from the router or from the browser APIs on the client
  const subdomain = get("query.subdomain", router) || getSubdomain();
  // Query for the organization `avatar` and `banner` logos using the subdomain
  const { data } = useQuery(organizationLogoQuery, { variables: { subdomain }, fetchPolicy: "cache-first" });

  // Avatar logo either exists or it will fallback to the cloverleaf logo
  const logoAvatar = getLogoAvatar(data) || CLOVERLEAF_LOGO_AVATAR_FALLBACK;
  // Banner logo either exists or it will fallback to the cloverleaf logo
  const logoBanner = getLogoBanner(data) || CLOVERLEAF_LOGO_BANNER_FALLBACK;

  return (
    avatar
      ? <Img src={logoAvatar} alt={t("org-avatar-logo-alt")}/>
      : <Img src={logoBanner} alt={t("org-banner-logo-alt")} />
  );
}

Logo.propTypes = {
  avatar: bool,
};

export default Logo;
