import React from "react";
import PropTypes from "prop-types";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  Anchor,
  Box,
  FormField,
  Text,
  TextInput,
} from "grommet";

const ClipboardTextInput = React.forwardRef(({
  inputProps = {},
  label = undefined,
  name = "copy-text",
  onCopy = () => undefined,
  value = "",
  width = "100%",
  t = () => undefined,
  ...props
}, ref) => {
  const [copied, setCopied] = React.useState(false);

  const timer = React.useRef();

  clearTimeout(timer?.current);

  React.useEffect(() => {
    if (copied) {
      timer.current = setTimeout(() => {
        setCopied(false);
      }, 1000);
    }

    return () => clearTimeout(timer?.current);
  }, [copied]);

  const handleOnCopy = (e) => {
    if (copied) {
      return null;
    }

    setCopied(true);

    // Prevent click from trapping focus inside input field
    e.preventDefault();

    return onCopy(value);
  };

  // Users should not be able to change the value
  const handleOnChange = () => undefined;

  return (
    <Box direction="row" gap="small" margin={{ bottom: "small" }} {...props}>
      <FormField
        htmlFor="copy-input"
        label={(
          <Box direction="row" justify="between">
            <Text>{label || t("common:link")}</Text>
            <CopyToClipboard text={value}>
              <Anchor color="green600" onClick={handleOnCopy}>
                {copied ?
                  t("common:copied") || "Copied!"
                  : t("common:copy-link") || "Copy Link"}
              </Anchor>
            </CopyToClipboard>
          </Box>
        )}
        margin="none"
        name={name}
        width={width}
      >
        <CopyToClipboard text={value}>
          <TextInput
            id="copy-input"
            name={name}
            onChange={handleOnChange}
            onClick={handleOnCopy}
            readOnly
            ref={ref}
            reverse
            type="copy-input"
            value={value}
            {...inputProps}
          />
        </CopyToClipboard>
      </FormField>
    </Box>
  );
});

ClipboardTextInput.propTypes = {
  inputProps: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string,
  onCopy: PropTypes.func,
  value: PropTypes.string,
  width: PropTypes.string,
  t: PropTypes.func,
};

ClipboardTextInput.displayName = "ClipboardTextInput";

export { ClipboardTextInput };
