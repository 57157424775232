import React from "react";
import { Grommet } from "grommet";
import { Footer } from "@/cloverleaf-ui/components/molecules/Footer";
import { themeStyle } from "@/cloverleaf-ui/theme";

/**
 * @deprecated - Use cloverleaf-ui/components/Footer
 */
function NewFooter() {
  return (
    <Grommet style={{ width: "100%" }} theme={themeStyle}>
      <Footer />
    </Grommet>
  );
}

export default NewFooter;
