import React from "react";
import {
  string,
  node,
  number,
  bool
} from "prop-types";
import styled, { css } from "styled-components";
import {
  CONDENSED_FONT,
  BLACK,
  GREEN,
  DEFAULT_FONT
} from "../utils/theme";

const getFont = props => props.font || (props.condensed ? CONDENSED_FONT : DEFAULT_FONT);

const getFontWeight = (props) => {
  if (props.bold) {
    return "500";
  }
  if (props.thin) {
    return "300";
  }

  return "400";
};

const headingStyles = css`
  color: ${(props) => {
    if (props.dark) {
      return BLACK;
    }

    if (props.color) {
      return props.color;
    }

    return GREEN;
  }};
  text-transform: ${props => (props.uppercase ? "uppercase" : "")};
  text-align: ${props => (props.center ? "center" : "")};
  font-family: ${getFont};
  font-size: ${props => (props.fontSize || "")};
  text-align: ${props => (props.center ? "center" : "left")};
  font-weight: ${getFontWeight};
  letter-spacing: 0;
  ${props => (props.noMargin ? "margin: 0;" : "")}
`;

const Heading1 = styled.h1`
  ${headingStyles}
`;

const Heading2 = styled.h2`
  ${headingStyles}
`;

const Heading3 = styled.h3`
  ${headingStyles}
`;

const Heading4 = styled.h4`
  ${headingStyles}
`;

const Heading5 = styled.h5`
  ${headingStyles}
`;

const Heading6 = styled.h6`
  ${headingStyles}
`;

const getHeading = (size) => {
  switch (size) {
    case 1:
      return Heading1;
    case 2:
      return Heading2;
    case 3:
      return Heading3;
    case 4:
      return Heading4;
    case 5:
      return Heading5;
    case 6:
      return Heading6;
    default:
      return Heading1;
  }
};

function Heading({
  children = undefined,
  label = "",
  size = 1,
  uppercase = false,
  center = false,
  dark = false,
  bold = false,
  fontSize = undefined,
  font = undefined,
  condensed = true,
  ...props
}) {
  const H = getHeading(size);

  return (
    <H
      size={size}
      uppercase={uppercase}
      center={center}
      dark={dark}
      bold={bold}
      fontSize={fontSize}
      font={font}
      condensed={condensed}
      {...props}
    >
      {children || label}
    </H>
  );
}

Heading.propTypes = {
  children: node,
  label: string,
  size: number,
  uppercase: bool,
  center: bool,
  dark: bool,
  bold: bool,
  fontSize: string,
  font: string,
  condensed: bool,
};

export default Heading;
