import styled from "styled-components";
import { textStyleMixin } from "../../utils/theme";

const StyledP = styled.p.attrs(props => ({
  fontSize: props.small ? "0.8em" : props.fontSize ?? undefined,
}))`
  ${textStyleMixin}
`;

export default StyledP;
