import {
  createContext,
  useContext,
  useEffect,
  useState
} from "react";
import { useLocalStorage } from "usehooks-ts";
import { ResponsiveContext } from "grommet";
import { isMobile } from "@/cloverleaf-ui/utils";

export const SidebarContext = createContext();

export const SidebarProvider = ({ children }) => {
  const screenSize = useContext(ResponsiveContext);

  const [clSidebarOpen, setClSidebarOpen] = useLocalStorage("cl-sidebar-open", null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [hover, setHover] = useState(false);

  const mobile = isMobile(screenSize);

  useEffect(() => {
    if (mobile) {
      // Close sidebar by default on mobile devices
      const state = clSidebarOpen === null ? false : clSidebarOpen;

      setClSidebarOpen(state);
      setIsSidebarOpen(state);
    }
    else {
      // Sidebar should be opened by default if localStorage is null
      const state = clSidebarOpen === null || clSidebarOpen;

      setClSidebarOpen(state);
      setIsSidebarOpen(state);
    }
  }, [clSidebarOpen, mobile, setClSidebarOpen]);

  const toggleSidebar = () => {
    if (isSidebarOpen) {
      setHover(false);
    }

    setIsSidebarOpen(!isSidebarOpen);
    setClSidebarOpen(!isSidebarOpen);
  };

  return (
    <SidebarContext.Provider
      value={{
        isSidebarOpen,
        toggleSidebar,
        hover,
        setHover,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};
