import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Menu,
  Text
} from "grommet";
import { Avatar, Icon } from "@/cloverleaf-ui/components";
import { DROP_POSITION } from "@/cloverleaf-ui/constants";

function MenuCell(props) {
  const [open, setOpen] = React.useState(false);

  const handleOnClick = (event) => {
    event.stopPropagation();

    return setOpen(!open);
  };

  return (
    <Menu
      icon={<Icon icon={["fass", "ellipsis"]} size="lg" />}
      dropAlign={DROP_POSITION.bottomLeft}
      onClick={handleOnClick}
      open={open}
      plain
      {...props}
    />
  );
}
function UserListItem({
  isCurrentUser = false,
  user,
  description = undefined,
  menuProps = undefined,
}) {
  return (
    <Box justify="between" direction="row">
      <Box gap="small" direction="row">
        <Box alignSelf="start">
          <Avatar size="small" user={user} />
        </Box>
        <Box>
          <Text color="text-brand" weight="bold">
            {`${user?.fullName}${isCurrentUser ? " (You)" : ""}`}
          </Text>
          <Text>{user.email}</Text>
          {description && (
            <Text capitalize>{description}</Text>
          )}
        </Box>
      </Box>
      <Box alignSelf="start">
        {menuProps && (
          <MenuCell {...menuProps} />
        )}
      </Box>
    </Box>
  );
}

const userPropTypes = PropTypes.shape({
  id: PropTypes.string,
  fullName: PropTypes.string,
  email: PropTypes.string,
  avatar: PropTypes.string,
  profile: PropTypes.shape({
    id: PropTypes.string,
  }),
});

UserListItem.propTypes = {
  description: PropTypes.string,
  isCurrentuser: PropTypes.bool,
  menuProps: PropTypes.shape(),
  user: userPropTypes.isRequired,
};

export { UserListItem, userPropTypes };
