import React from "react";
import PropTypes from "prop-types";
import { track } from "../../lib";
import { idType, trackingDataProp } from "../../lib/graphql/propTypes";
import { withTooltip } from "../hoc/withTooltip";
import { convertHexToRGBA, getUserInitials } from "../utils";
import { EVENT } from "../utils/constants";
import { WHITE, DEFAULT_FONT } from "../utils/theme";
import CircleContainer from "./CircleContainer";
import Icon from "./Icon";
import TextLabel from "./styled/TextLabel";

function Avatar({
  user = {},
  size = 3,
  expandable = true,
  border = false,
  onClick = undefined,
  disabled = false,
  editing = false,
  noAnimate = false,
  trackingData = undefined,
  ...otherProps
}) {
  if (!user) return null;
  const { name: eventName } = EVENT.ContentViewed;

  const handleClick = () => {
    if (!disabled) {
      if (trackingData) {
        track(eventName, {
          ...trackingData,
        });
      }
      if (onClick) {
        onClick();
      }
    }
  };

  return (
    <CircleContainer
      align="center"
      justify="center"
      disabled={disabled}
      onClick={handleClick}
      size={size}
      expandable={expandable}
      border={border}
      noAnimate={noAnimate}
      {...otherProps}
    >
      {user.avatar
        // eslint-disable-next-line @next/next/no-img-element
        ? <img height="100%" width="100%" src={user.avatar} alt="Avatar of the user" />
        : (
          <TextLabel thin center uppercase color={WHITE} font={DEFAULT_FONT}>
            {getUserInitials(user)}
          </TextLabel>
        )
      }
      {disabled && (
        <div
          style={{
            position: "absolute",
            backgroundColor: convertHexToRGBA(WHITE, 0.5),
            width: "100%",
            height: "100%",
          }}
        />
      )}
      {editing && (
        <div
          style={{
            position: "absolute",
            bottom: "-5px",
          }}
        >
          <Icon color="white" icon={["fas", "camera"]} style={{ fontSize: "18px" }} />
        </div>
      )}
    </CircleContainer>
  );
}

Avatar.propTypes = {
  user: PropTypes.shape({
    id: idType,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    fullName: PropTypes.string,
    avatar: PropTypes.string,
  }),
  size: PropTypes.number,
  expandable: PropTypes.bool,
  onClick: PropTypes.func,
  border: PropTypes.bool,
  disabled: PropTypes.bool,
  editing: PropTypes.bool,
  noAnimate: PropTypes.bool,
  trackingData: trackingDataProp,
};

export default withTooltip(Avatar);
