import Router from "next/router";
import * as Sentry from "@sentry/nextjs";
import { safeReplace } from ".";
const debug = require("debug")("cloverleaf:redirect");

// If we're on the server, do a 302 redirect. If we aren't, do a client-side route.
const redirect = ({ res }, location) => {
  if (res) {
    // This is server-side, so we do a server-side redirect.
    // But only if the response isn't done - in parallel requests then it's possible this gets called 10 times, but we only need one redirect.
    if (!res.finished) {
      res.writeHead(302, { Location: location });

      return res.end();
    }
    debug("Request is finished, so no need to actually redirect anymore.");

    return null;
  }

  if (typeof window !== "undefined") {
    // Do a client-side redirect.
    return safeReplace(Router, location)();
  }

  Sentry.addBreadcrumb({
    category: "utils/redirect",
    message: "Attempting to redirect on client but no res object. Swallowing.",
    level: "log",
  });

  return null;
};

export const buildRedirect = (ctx, location) => uri => redirect(ctx, uri || location);

/** @deprecated - import from @/components/utils/index.js */
export default redirect;
