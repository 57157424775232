import React from "react";
import PropTypes from "prop-types";
import Link from "next/link";
import styled from "styled-components";
import {
  Anchor,
  Box,
  FormField,
  Text,
  TextInput,
} from "grommet";
import { FormView, FormViewHide } from "grommet-icons";

const Icon = styled(Box)`
  line-height: normal;
  padding: 5px 5px 0px 0px;
`;

const PasswordFormField = React.forwardRef(({
  autoComplete = undefined,
  id = "current-password",
  label = undefined,
  link = undefined,
  name = "password",
  onChange = () => undefined,
  placeholder = undefined,
  validate = [],
  value = undefined,
  ...props
}, ref) => {
  const [showPassword, setShowPassword] = React.useState(false);

  let labelComponent = label;

  if (link) {
    labelComponent = (
      <Box align="center" direction="row" justify="between">
        <Text>{label}</Text>
        <Link
          href={link?.href}
          passHref
          legacyBehavior
        >
          <Anchor size="medium">
            {link?.label}
          </Anchor>
        </Link>
      </Box>
    );
  }

  const toggleView = (e) => {
    e.stopPropagation();
    setShowPassword(!showPassword);
  };

  return (
    <FormField
      contentProps={{ style: { flexDirection: "row" } }}
      htmlFor={id}
      label={labelComponent}
      name={name}
      validate={[...validate]}
      {...props}
    >
      <TextInput
        a11yTitle="password"
        autoComplete={autoComplete}
        id={id}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        ref={ref}
        type={showPassword ? "text" : "password"}
        value={value}
      />
      <Icon onClick={toggleView}>
        {showPassword ? <FormViewHide /> : <FormView /> }
      </Icon>
    </FormField>
  );
});

PasswordFormField.propTypes = {
  autoComplete: PropTypes.oneOf(["current-password", "new-password", "off"]),
  id: PropTypes.oneOf(["current-password", "new-password", "off"]),
  label: PropTypes.string,
  link: PropTypes.shape({
    href: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape(),
    ]),
    label: PropTypes.string,
  }),
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  validate: PropTypes.array,
  value: PropTypes.string,
};

PasswordFormField.displayName = "PasswordFormField";

export { PasswordFormField };
