import React from "react";
import PropTypes from "prop-types";
import { css } from "styled-components";
import {
  Box,
  Button,
  ThemeContext,
} from "grommet";
import { Icon } from "../../atoms";
import { useLocale } from "@/lib/hooks";

const insightButtonTheme = {
  global: {
    focus: {
      shadow: {
        color: "#6fffb0",
        size: "2px",
      },
      border: "none",
    },
  },
  button: {
    active: {
      color: "white",
      default: {
        background: "rgba(221,221,221,0.4)",
      },
    },
    default: {
      background: "transparent",
      border: {
        color: "white",
      },
      color: "white",
      padding: "8px",
      extend: css`
        font-size: 18px;
        line-height: 24px;
        width: 100%;
        [data-icon] {
          color: white;
        };
      `,
    },
    hover: {
      default: {
        background: "rgba(221,221,221,0.4)",
        color: "white",
        extend: css`
          width: 100%;
          [data-icon] {
            color: white;
          };
        `,
      },
    },
  },
};

function InsightRating({
  isHelpful = null,
  onLike,
  onDislike,
}) {
  const { t } = useLocale();

  return (
    <ThemeContext.Extend value={insightButtonTheme}>
      <Box direction="row-responsive" gap="small" fill="horizontal">
        <Button
          a11yTitle="rate insight helpful"
          active={isHelpful === true}
          fill="horizontal"
          icon={<Icon icon={["far", "thumbs-up"]} />}
          label={t("helpful")}
          onClick={onLike}
          style={{
            textTransform: "uppercase",
            whiteSpace: "nowrap",
          }}
        />
        <Button
          a11yTitle="rate insight unhelpful"
          active={isHelpful === false}
          fill="horizontal"
          icon={<Icon icon={["far", "thumbs-down"]} />}
          label={t("unhelpful")}
          onClick={onDislike}
          style={{
            textTransform: "uppercase",
            whiteSpace: "nowrap",
          }}
        />
      </Box>
    </ThemeContext.Extend>
  );
}

InsightRating.displayName = "InsightRating";

InsightRating.propTypes = {
  isHelpful: PropTypes.bool,
  onDislike: PropTypes.func.isRequired,
  onLike: PropTypes.func.isRequired,
};

export { InsightRating };
