import React, { Children } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Carousel,
  ResponsiveContext,
} from "grommet";
import { StatusGoodSmall } from "grommet-icons";
import { isDesktop } from "@/cloverleaf-ui/utils";

function SlideHeader({ children, ...props }) {
  return (
    <CardHeader
      justify="center"
      fill="horizontal"
      {...props}
    >
      {children}
    </CardHeader>
  );
}

SlideHeader.propTypes = {
  children: PropTypes.any.isRequired,
};

function SlideBody({ children, ...props }) {
  return (
    <CardBody
      align="center"
      alignContent="center"
      direction="column"
      fill="horizontal"
      pad={{ horizontal: "large", top: "small" }}
      {...props}
    >
      {children}
    </CardBody>
  );
}

SlideBody.propTypes = {
  children: PropTypes.any.isRequired,
};

function SlideFooter({ children, ...props }) {
  const screenSize = React.useContext(ResponsiveContext);

  return (
    <CardFooter
      alignContent="center"
      direction="column"
      fill="horizontal"
      gap={isDesktop(screenSize) ? "small" : "xsmall"}
      pad={{ horizontal: "large" }}
      {...props}
    >
      {children}
    </CardFooter>
  );
}

SlideFooter.propTypes = {
  children: PropTypes.any.isRequired,
};

function Slide({ children }) {
  return (
    <Card
      elevation="none"
      fill="horizontal"
      round={false}
    >
      {children}
    </Card>
  );
}

Slide.propTypes = {
  children: PropTypes.any.isRequired,
};

/**
 * onNext and onPrevious callbacks are required to
 * render the Next and Back buttons in the carousel.
 * @param {number} activeChild - 0 based index
*/
function Deck({
  activeChild = 0,
  children,
  controlProps = undefined,
  name = "",
  numericIndicator = false,
  onNext = undefined,
  onNextLabel = "NEXT",
  onPrevious = undefined,
  onPreviousLabel = "BACK",
  onSelection = () => undefined,
  ...props
}) {
  const screenSize = React.useContext(ResponsiveContext);

  const [activeIndex, setActiveIndex] = React.useState(activeChild);

  const numSlides = Children?.toArray(children).length;

  React.useEffect(() => {
    if (typeof activeChild !== "undefined") {
      setActiveIndex(activeChild);
    }
  }, [activeChild]);

  const onSelect = index => () => {
    if (activeIndex !== index && index >= 0 && index < numSlides) {
      setActiveIndex(index);
    }

    return onSelection([index, numSlides]);
  };

  const selectors = numSlides > 1 && (!numericIndicator ?
    React.Children.map(children, (slide, index) => (
      <Button
        a11yTitle={`Show carousel slide ${index + 1}`}
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        icon={(
          <StatusGoodSmall
            color={
              activeIndex === index ?
                { light: "green700", dark: "white" }
                : { light: "light-4", dark: "rgba(255, 255, 255, 0.6)" }
            }
            size="small"
            style={{ verticalAlign: "middle" }}
          />
        )}
        onClick={onSelect(index)}
        plain
      />
    )) : `${activeIndex + 1} of ${numSlides}`);

  // Only called if onNext is passed to render Next button
  const next = () => {
    setActiveIndex(activeIndex + 1);

    return onNext([activeIndex + 1, numSlides]);
  };

  // Only called if onPrevious is passed to render Back button
  const prev = () => {
    setActiveIndex(activeIndex - 1);

    return onPrevious([activeIndex - 1, numSlides]);
  };

  return (
    <Card {...props}>
      <CardBody>
        <Carousel
          activeChild={activeIndex}
          controls={false}
          name={name}
          onChild={setActiveIndex}
        >
          {children}
        </Carousel>
      </CardBody>
      <CardFooter
        alignSelf="center"
        alignContent="center"
        fill="horizontal"
        gap={isDesktop(screenSize) ? "small" : "xsmall"}
        justify={numericIndicator ? "center" : "between"}
        pad={{
          top: "large",
          bottom: "small",
          horizontal: isDesktop(screenSize) ? "medium" : "xsmall",
        }}
        {...controlProps}
      >
        {onPrevious && (
          <Button
            disabled={activeIndex === 0}
            label={onPreviousLabel}
            onClick={prev}
            style={{ textTransform: "uppercase" }}
          />
        )}
        <Box
          alignSelf="center"
          direction="row"
          gap="xsmall"
          fill="horizontal"
          justify={numericIndicator ? "center" : "between"}
          pad={{ horizontal: isDesktop(screenSize) ? "small" : "xsmall" }}
          width={{ min: "xsmall", max: "small" }}
        >
          {selectors}
        </Box>
        {onNext && (
          <Button
            disabled={activeIndex === numSlides - 1}
            label={onNextLabel}
            onClick={next}
            style={{ textTransform: "uppercase" }}
          />
        )}
      </CardFooter>
    </Card>
  );
}

Deck.propTypes = {
  activeChild: PropTypes.number,
  children: PropTypes.any.isRequired,
  controlProps: PropTypes.object,
  name: PropTypes.string,
  numericIndicator: PropTypes.bool,
  onNext: PropTypes.func,
  onNextLabel: PropTypes.string,
  onPrevious: PropTypes.func,
  onPreviousLabel: PropTypes.string,
  onSelection: PropTypes.func,
};

Deck.Slide = Slide;
Deck.SlideBody = SlideBody;
Deck.SlideHeader = SlideHeader;
Deck.SlideFooter = SlideFooter;

export { Deck };
