import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Diagram,
  Stack,
  ResponsiveContext,
} from "grommet";
import { isTablet } from "@/cloverleaf-ui/utils";
import { isMobile } from "../../../../components/utils";

/**
 * @example
 * <Stepper>
 *   <Step href="/" onClick={} label="Step 1" icon={['fa', 'icon']} isActive />
 *   <Step href="/" onClick={} label="Step 2" icon={['fa', 'icon']} isCompleted />
 *   <Step href="/" onClick={} label="Step 3" icon={['fa', 'icon']} />
 * </Stepper>
 */
function Stepper({
  background = "white",
  children = undefined,
  ...props
}) {
  const screenSize = React.useContext(ResponsiveContext);

  const childrenArray = React.Children.toArray(children).filter(Boolean);

  let steps = childrenArray.map((step, index) =>
    React.cloneElement(step, {
      ...step.props, // this includes default props
      index,
      id: index,
      background,
      margin: { horizontal: "xsmall" },
    }));

  const connections = steps?.reduce((connectedSteps, _, index) => {
    const hasConnection = index + 1 < steps.length;

    if (hasConnection) {
      connectedSteps.push({
        fromTarget: `${index}`,
        toTarget: `${index + 1}`,
        thickness: "hair",
        color: "grey400",
      });
    }

    return connectedSteps;
  }, []);

  if (isMobile(screenSize) || isTablet(screenSize)) {
    steps = steps.filter(step => step.props.isActive);
  }

  return (
    <Stack guidingChild={1}>
      {connections.length > 0 && (
        <Diagram connections={connections} />
      )}
      <Box
        direction="row-responsive"
        gap="small"
        justify="between"
        {...props}
      >
        {steps}
      </Box>
    </Stack>
  );
}

Stepper.propTypes = {
  background: PropTypes.any,
  children: PropTypes.arrayOf(PropTypes.node),
};

export { Stepper };
