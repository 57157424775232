import { ASSESSMENT } from "../../components/utils/constants";

/**
 * Should match the currentUser.scores.[assessmentId] from the backend
 */
const assessmentMap = {
  [ASSESSMENT.STRENGTHS]: "strengths",
  [ASSESSMENT.DISC]: "disc",
  [ASSESSMENT.PERSONALITY]: "personality",
  [ASSESSMENT.CULTURE]: "culture",
  [ASSESSMENT.VIA]: "via",
  [ASSESSMENT.DRIVINGFORCES]: "drivingforces",
  [ASSESSMENT.MOTIVATINGVALUES]: "motivatingvalues",
  [ASSESSMENT.ENNEAGRAM]: "enneagram",
  [ASSESSMENT.ROLE_ALIGNMENT]: null,
  [ASSESSMENT.SELF_ROLE_ALIGNMENT]: null,
  [ASSESSMENT.INSTINCTIVEDRIVES]: "instinctiveDrives",
  [ASSESSMENT.ENERGYRHYTHM]: "energyRhythm",
  [ASSESSMENT.STRENGTHSCOPE]: "strengthscope",
};

const getAssessmentKeyByAssessmentType = assessment => assessmentMap[assessment];

export { getAssessmentKeyByAssessmentType };
