import * as Sentry from "@sentry/nextjs";
import { v4 as uuidv4 } from "uuid";
import { useGetTrust } from "../../lib/hooks";
import { useCloverleafNonce } from "./useCloverleafNonce";

const useAddNewAccount = ({
  onLoad = () => undefined,
  onError = () => undefined,
} = {}) => {
  const { trust } = useGetTrust();
  const [, setNonce] = useCloverleafNonce("la_state");

  const initialize = () => {
    const uuid = uuidv4();

    setNonce(uuid);

    return trust({ state: uuid })
      .then(() => {
        Sentry.addBreadcrumb({
          category: "link-account",
          message: "Link a new account",
          level: "info",
        });

        return onLoad();
      })
      .catch((e) => {
        Sentry.captureMessage({
          category: "link-account",
          message: `Failed to add a new account: ${e}`,
          level: "error",
        });

        return onError(e);
      });
  };

  return initialize;
};

export { useAddNewAccount };
