import { makeVar, useReactiveVar } from "@apollo/client";

/**
 * Reactive variable storing user visibility for the TeamDashboard.
 * The saved state is an object with teamId key values and arrays of Users
 * { [teamId]: [...users] }
 *
 * The users array is set by the TeamVisibilitySidebar, which appends a `User.visible` property
 * to indicate if the user should be displayed on the dashboard.
 *
 * The next iteration should be that the Apollo typePolicy for User adds a `visible` property.
 */
const teammateVisibilityVar = makeVar({});

/**
 * Returns reactive variable state and setState function
 * @returns [] - [state, setState]
 */
const useTeamDashboardVisibility = (teamId) => {
  if (!teamId) {
    // eslint-disable-next-line no-console
    console.warn("useTeamDashboardVisibility hook requires a team id");
  }

  const teammateVisibility = useReactiveVar(teammateVisibilityVar);

  const getTeammateVisibility = teammateVisibility[teamId] || {};

  const setTeammateVisibility = users =>
    teammateVisibilityVar({
      ...teammateVisibility,
      [teamId]: users,
    });

  return [
    getTeammateVisibility,
    setTeammateVisibility,
  ];
};

export { useTeamDashboardVisibility };
