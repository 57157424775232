import React from "react";
import PropTypes from "prop-types";
import { Box, Text } from "grommet";
import { Avatar } from "../../atoms";

function PeopleSuggestion({
  border = undefined,
  selected = false,
  user = undefined,
  ...props
}) {
  return (
    <Box
      align="center"
      background={{ color: selected ? "green700" : undefined }}
      border={border}
      direction="row"
      gap="medium"
      pad="small"
      {...props}
    >
      <Avatar user={user} />
      <Box>
        {user?.fullName && <Text>{user.fullName}</Text>}
        {user?.email && <Text size="small">{user.email}</Text>}
      </Box>
    </Box>
  );
}

PeopleSuggestion.propTypes = {
  border: PropTypes.string,
  selected: PropTypes.bool,
  user: PropTypes.shape({
    id: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    fullName: PropTypes.string,
    avatar: PropTypes.string,
  }),
};

export { PeopleSuggestion };
