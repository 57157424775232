import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  FormField,
  Keyboard,
  Select,
  Text
} from "grommet";
import { QuestionCard } from "../components/QuestionCard";
import { useLocale } from "@/lib/hooks";

const options = [1, 2, 3, 4];

const defaultValue = {
  answer1: "",
  answer2: "",
  answer3: "",
  answer4: "",
};

function InstinctiveDrivesSelect({ label, name, onChange, ...props }) {
  const { t } = useLocale();

  const handleOnChange = (event) => onChange(event);

  /**
   * Support option select on key press
   */
  const handleOnKeyDown = (event) => {
    if (options.includes(parseInt(event.key))) {
      event.target.name = name;
      event.target.value = event.key;

      return onChange(event);
    }
  };

  return (
    <Keyboard onKeyDown={handleOnKeyDown}>
      <Box
        align="center"
        direction="row"
        gap="small"
      >
        <FormField
          contentProps={{
            width: "xsmall"
          }}
          htmlFor={name}
          margin="none"
          name={name}
          style={{ flex: "none" }}
        >
          <Select
            id={name}
            aria-labelledby={`${name}__label`}
            name={name}
            onChange={handleOnChange}
            options={options}
            placeholder={t("select")}
            {...props}
          />
        </FormField>
        <Text as="label" id={`${name}__label`} htmlFor={name}>{label}</Text>
      </Box>
    </Keyboard>
  );
}

const InstinctiveDrivesQuestion = React.forwardRef(({
  onChange = () => undefined,
  onNext = () => undefined,
  question = undefined,
  selected = undefined,
  tutorial = false,
}, ref) => {
  const { t } = useLocale();

  const [value, setValue] = React.useState(selected || defaultValue);

  // Check all questions are answered
  const isComplete = Object.values(value).every(v => !!v);

  const handleOnNext = () => {
    // Catch keyboard onEnter from continuing when !complete
    if (isComplete) {
      onChange({
        question: question.number,
        answer: value,
      });

      return onNext();
    }
  };

  const handleOnChange = (event) => {
    const answer = parseInt(event.target.value);

    const newValue = { ...value };

    Object.entries(newValue).forEach(([key, val]) => {
      if (answer === val) {
        newValue[key] = "";
      }
    });

    newValue[event.target.name] = answer;

    if (tutorial && Object.values(newValue).every(v => !!v)) {
      onChange({
        question: question.number,
        answer: value,
      });
    }

    return setValue(newValue);
  };

  if (!question) {
    return null;
  }

  return (
    <Keyboard onEnter={handleOnNext}>
      <QuestionCard ref={ref} prompt={question.prompt} width="100%">
        <Box
          border
          direction="column"
          fill="horizontal"
          gap="medium"
          pad="large"
        >
          <InstinctiveDrivesSelect
            label={question.answerText1}
            name="answer1"
            value={value.answer1}
            onChange={handleOnChange}
          />
          <InstinctiveDrivesSelect
            label={question.answerText2}
            name="answer2"
            value={value.answer2}
            onChange={handleOnChange}
          />
          <InstinctiveDrivesSelect
            label={question.answerText3}
            name="answer3"
            value={value.answer3}
            onChange={handleOnChange}
          />
          <InstinctiveDrivesSelect
            label={question.answerText4}
            name="answer4"
            value={value.answer4}
            onChange={handleOnChange}
          />
        </Box>
        {!tutorial && (
          <Button
            disabled={!isComplete}
            fill="horizontal"
            label={t("next")}
            onClick={handleOnNext}
            type="submit"
            primary
          />
        )}
      </QuestionCard>
    </Keyboard>
  );
});

InstinctiveDrivesQuestion.displayName = "InstinctiveDrivesQuestion";

InstinctiveDrivesQuestion.propTypes = {
  onChange: PropTypes.func,
  onNext: PropTypes.func,
  question: PropTypes.shape({
    number: PropTypes.string,
    prompt: PropTypes.string,
    answerText1: PropTypes.string,
    answerText2: PropTypes.string,
    answerText3: PropTypes.string,
    answerText4: PropTypes.string,
  }),
  selected: PropTypes.any,
  tutorial: PropTypes.bool,
};

export { InstinctiveDrivesQuestion };
