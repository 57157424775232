import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Heading,
  Text,
} from "grommet";
import { Modal } from "../Modal";
import { useLocale } from "@/lib/hooks";

/**
 * @deprecated use the new ConfirmationDialog which supports
 * promise chains to close the modal
 */
function ConfirmationDialog({
  description,
  onCancel,
  onConfirm,
}) {
  const { t } = useLocale();
  let header;
  let body;
  let cta;

  if (typeof description === "string") {
    header = description;
  }
  else {
    ({ header, body, cta } = description);
  }

  const cardHeader = typeof header === "string" ? (
    <CardHeader>
      <Heading level="1" margin="none" size="small">{header}</Heading>
    </CardHeader>
  ) : header;

  const cardBody = typeof body === "string" ? (
    <CardBody>
      <Text>
        {body}
      </Text>
    </CardBody>
  ) : body;

  const confirmButtonText = cta || t("confirm-button-text");

  const handleOnClick = () => {
    onConfirm();

    return onCancel(); // Close dialog
  };

  return (
    <Modal
      hideClose
      onClose={onCancel}
      /** @todo - true should make full-screen on mobile devices, but team dashboard has "screen-width" issues */
      responsive={false}
    >
      <Card
        pad="medium"
        gap="medium"
        round="none" // Modal already has rounded corners
        width="large"
      >
        {cardHeader}
        {cardBody}
        <CardFooter
          direction="row"
          gap="large"
          justify="end"
        >
          <Button
            label={<Text color="grey600" uppercase>{t("cancel")}</Text>}
            onClick={onCancel}
            plain
          />
          <Button
            label={<Text color="green600" uppercase>{confirmButtonText}</Text>}
            onClick={handleOnClick}
            plain
          />
        </CardFooter>
      </Card>
    </Modal>
  );
}

ConfirmationDialog.propTypes = {
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      header: PropTypes.any,
      body: PropTypes.any,
      cta: PropTypes.string,
    }),
  ]).isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

/**
 * @deprecated Use @/cloverleaf-ui/components/ConfirmationDialog
 */
export { ConfirmationDialog };
