import React from "react";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/nextjs";
import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Form,
  Heading,
  Text,
} from "grommet";
import { Modal } from "../Modal";
import { useLocale } from "@/lib/hooks";

function ConfirmationDialog({
  children = null,
  description,
  onCancel = () => undefined,
  onClose = () => undefined,
  onConfirm = () => Promise.resolve(),
  width = "large",
}) {
  /**
   * @todo Quick fix for cancel button, but we want to avoid this dependency
   */
  const { t } = useLocale();

  let header = null;
  let cta = null;

  if (typeof description === "string") {
    header = description;
  }
  else {
    ({ header, cta } = description);
  }

  const cardHeader = typeof header === "string" ? (
    <CardHeader>
      <Heading level="3" margin="none" size="small">{header}</Heading>
    </CardHeader>
  ) : header;

  const confirmButtonText = cta || t("confirm-button-text");

  const handleOnCancel = () => {
    try {
      onCancel();
    } catch (e) {
      Sentry.captureException(e, {
        extra: {
          message: "ConfirmationDialog",
          description,
        },
      });
    } finally {
      onClose();
    }
  };

  const handleOnSubmit = ({ value }) => {
    return onConfirm(value)
      .then(() => onClose())
      .catch(error => console.error(error));
  };

  return (
    <Modal
      hideClose
      onClose={onClose}
      /** @todo - true should make full-screen on mobile devices, but team dashboard has "screen-width" issues */
      responsive={false}
    >
      <Card
        pad="medium"
        gap="medium"
        round="none" // Modal already has rounded corners
        width={width}
      >
        {cardHeader}
        <CardBody>
          <Form onSubmit={handleOnSubmit}>
            <Box gap="medium">
              {children}
              <Box
                direction="row"
                gap="large"
                justify="end"
              >
                <Button
                  label={(
                    <Text
                      color="grey600"
                      uppercase
                    >
                      {t("cancel")}
                    </Text>
                  )}
                  onClick={handleOnCancel}
                  plain
                />
                <Button
                  label={(
                    <Text
                      color="green600"
                      uppercase
                    >
                      {confirmButtonText}
                    </Text>
                  )}
                  plain
                  type="submit"
                />
              </Box>
            </Box>
          </Form>
        </CardBody>
      </Card>
    </Modal>
  );
}

ConfirmationDialog.propTypes = {
  children: PropTypes.node,
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      header: PropTypes.any,
      body: PropTypes.any,
      cta: PropTypes.string,
    }),
  ]).isRequired,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  width: PropTypes.string,
};

export { ConfirmationDialog };
