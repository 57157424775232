import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  CheckBox,
  Form,
  FormField,
  Select,
  Text,
} from "grommet";
import { validate } from "@/cloverleaf-ui/utils";
import { Message } from "../../atoms";
import { PeopleDropdownField } from "../../molecules";
import { useLocale } from "@/lib/hooks";

const ROLE = {
  ADMIN: "Team Admin",
  MEMBER: "Member",
};

const roleOptions = [
  {
    description: "role.admin.description",
    label: "role.admin.label",
    value: ROLE.ADMIN,
  },
  {
    description: "role.member.description",
    label: "role.member.label",
    value: ROLE.MEMBER,
  },
];

function AddUsersToTeamForm({
  canInviteMembers = undefined,
  error = undefined,
  loading = false,
  onCancel = () => undefined,
  onMore = () => undefined,
  onSearch = () => undefined,
  onSubmit = () => undefined,
  team = undefined,
  users = [],
}) {
  const { t } = useLocale();
  const [includeMember, setIncludeMember] = React.useState(true);
  const [role, setRole] = React.useState(roleOptions[1]); // ROLE.MEMBER
  const [selectedUsers, setSelectedUsers] = React.useState([]);
  const [options, setOptions] = React.useState(users);

  React.useEffect(() => {
    setOptions(users);
  }, [users]);

  const handleOnSubmit = () => {
    let admins = [];
    let members = [];

    if (role?.value === ROLE.ADMIN) {
      admins = selectedUsers.map(user => ({
        id: user?.id,
        email: user?.email,
      }));
    }

    if (role?.value === ROLE.MEMBER || includeMember) {
      members = selectedUsers.map(user => ({
        id: user?.id,
        email: user?.email,
      }));
    }

    return onSubmit({
      admins,
      members,
      teamId: team?.id,
    });
  };

  const renderRoleOptions = React.useCallback(option => (
    <Box gap="xxsmall" pad="medium" width="medium">
      <Text size="medium">
        {t(option?.label)}
      </Text>
      <Text size="small">
        {t(option?.description)}
      </Text>
    </Box>
  ), [t]);

  return (
    <>
      {error && (
        <Message alignSelf="center" compact kind="error" margin="small">
          {error}
        </Message>
      )}
      <Form onSubmit={handleOnSubmit}>
        <PeopleDropdownField
          membersOnly={!canInviteMembers}
          label={t("role.member.label")}
          options={options}
          onChange={newMember => setSelectedUsers(newMember)}
          onMore={onMore}
          onSearch={onSearch}
          placeholder={t("role.member.placeholder")}
          value={selectedUsers}
          validate={(input) => {
            // Validate manually entered email address and user objects separately, validate.email returns a truthy value if invalid
            if (
              !Array.isArray(input) ||
              input.some(val => (
                typeof val === "string"
                  ? !val || validate.email(val)
                  : !val?.email || validate.email(val?.email)
              )
              )
            ) {
              return { message: t("invalid-input-error"), status: "error" };
            }

            return undefined;
          }}
        />
        <Box width="278px">
          <FormField label={t("role-select-label")} htmlFor="role-select-field">
            <Select
              id="role-select-field"
              onChange={({ option }) => setRole(option)}
              options={roleOptions}
              value={role}
              valueKey="value"
              valueLabel={option => (
                <Box
                  align="center"
                  direction="row"
                  height={{ min: "36px" }}
                  pad={{ horizontal: "xsmall" }}
                  wrap
                >
                  {t(option?.label)}
                </Box>
              )}
            >
              {renderRoleOptions}
            </Select>
          </FormField>
        </Box>
        {role === ROLE.ADMIN && (
          <CheckBox
            checked={includeMember}
            label={t("checkbox-label")}
            onChange={e => setIncludeMember(e.target.checked)}
          />
        )}
        <Box direction="row" gap="large" justify="end" pad={{ top: "large" }}>
          <Button
            disabled={loading}
            label={<Text color="grey600">{t("cancel").toUpperCase()}</Text>}
            onClick={onCancel}
            plain
          />
          <Button
            disabled={selectedUsers.length === 0 || loading}
            label={<Text color="green600">{t("send").toUpperCase()}</Text>}
            plain
            type="submit"
          />
        </Box>
      </Form>
    </>
  );
}

AddUsersToTeamForm.propTypes = {
  canInviteMembers: PropTypes.bool,
  error: PropTypes.string,
  loading: PropTypes.bool,
  onCancel: PropTypes.func,
  onMore: PropTypes.func,
  onSearch: PropTypes.func,
  onSubmit: PropTypes.func,
  team: PropTypes.shape({
    id: PropTypes.string,
    users: PropTypes.array,
    organization: PropTypes.shape({
      topParentOrganization: PropTypes.shape({
        members: PropTypes.array,
      }),
    }),
  }),
  users: PropTypes.array,
};

export { AddUsersToTeamForm };
