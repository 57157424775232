import React from "react";
import PropType from "prop-types";
import {
  Button,
  FormField,
  Heading,
  RadioButtonGroup,
  ThemeContext
} from "grommet";
import { toggleButtonTheme } from "@/cloverleaf-ui/theme";

function SingleSelectQuestion({
  name,
  prompt,
  onChange,
  options,
  value: defaultValue = undefined,
}) {
  const [value, setValue] = React.useState(defaultValue);

  React.useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleOnChange = (event) => {
    setValue(event.target.value);

    return onChange(event.target.value);
  };

  const id = `radiobuttongroup-${name}`;

  return (
    <FormField
      contentProps={{ border: false }}
      gap="small"
      margin="none"
      name={name}
      htmlFor={id}
      label={(
        <Heading
          margin="none"
          size="xsmall"
          textAlign="center"
        >
          {prompt}
        </Heading>
      )}
    >
      <ThemeContext.Extend value={toggleButtonTheme}>
        <RadioButtonGroup
          direction="row-responsive"
          fill="horizontal"
          justify="center"
          id={id}
          name={name}
          options={options}
          value={value}
          onChange={handleOnChange}
          focusIndicator={false}
        >
          {(option, { checked }) => (
            <Button
              as="div"
              key={option.value}
              active={checked}
              label={option.label}
              primary
              uppercase
            />
          )}
        </RadioButtonGroup>
      </ThemeContext.Extend>
    </FormField>
  );
}

SingleSelectQuestion.propTypes = {
  name: PropType.string.isRequired,
  prompt: PropType.string.isRequired,
  options: PropType.arrayOf(PropType.shape({
    value: PropType.string,
    label: PropType.string,
  })).isRequired,
  onChange: PropType.func.isRequired,
  value: PropType.string,
};

export { SingleSelectQuestion };
