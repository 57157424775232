import castArray from "lodash/fp/castArray";
import compose from "lodash/fp/compose";
import curry from "lodash/fp/curry";
import find from "lodash/fp/find";
import get from "lodash/fp/get";
import getOr from "lodash/fp/getOr";
import orderBy from "lodash/fp/orderBy";
import { processAndValidateData } from "../components/composite/ImportOrganization/importOrganization.utils";
import { GRAPH } from "../components/utils/strings";
import {
  buildActionCreator,
  buildItemActionCreator,
  genericItemReducer
} from "./utils";

const initialDataTableState = { expanded: {}, sorted: {} };

export const initialTeamVisibilityState = {
  includedMembers: [],
  excludedMembers: [],
};

/**
 * ACTIONS
 */
export const UPDATE_RADIO_TOGGLE = "cloverleaf/application/UPDATE_RADIO_TOGGLE";
export const UPDATE_SELECT = "cloverleaf/application/UPDATE_SELECT";
export const UPDATE_TABLE_SORT = "cloverleaf/application/UPDATE_TABLE_SORT";
export const UPDATE_TABLE_EXPAND = "cloverleaf/application/UPDATE_TABLE_EXPAND";
export const UPDATE_TABLE_SELECTION = "cloverleaf/application/UPDATE_TABLE_SELECTION";
export const UPDATE_TABLE_FILTER = "cloverleaf/application/UPDATE_TABLE_FILTER";
export const UPDATE_VIEW = "cloverleaf/application/UPDATE_VIEW";
export const SET_COLLAPSIBLE_NUGGET = "cloverleaf/application/SET_COLLAPSIBLE_NUGGET";
export const UPDATE_TEAM_CULTURE_VIEW = "cloverleaf/application/UPDATE_TEAM_CULTURE_VIEW";
export const UPDATE_RELATIONSHIP_MAP_SECONDARY_USER = "cloverleaf/application/UPDATE_RELATIONSHIP_MAP_USER";
export const USER_SEARCH = "cloverleaf/application/USER_SEARCH";
export const SEARCH = "cloverleaf/application/SEARCH";
export const TEAM_DASHBOARD_VISIBILITY = "cloverleaf/application/TEAM_DASHBOARD_VISIBILITY";
export const CLOSE_MODAL = "cloverleaf/application/CLOSE_MODAL";
export const OPEN_MODAL = "cloverleaf/application/OPEN_MODAL";
export const CLEAR_SELECTS = "cloverleaf/application/CLEAR_SELECTS";
export const SET_SELECTED_TEAM = "cloverleaf/application/SET_SELECTED_TEAM";
export const SET_IMPORT_ORGANIZATION_STATE = "cloverleaf/application/SET_IMPORT_ORGANIZATION_STATE";
export const CLEAR_IMPORT_ORGANIZATION_STATE = "cloverleaf/application/CLEAR_IMPORT_ORGANIZATION_STATE";
export const SET_SELECT_COLUMN_MAPPING_VALUE = "cloverleaf/application/SET_SELECT_COLUMN_MAPPING_VALUE";
export const SET_IMPORT_ORGANIZATION_DATA_ROWS = "cloverleaf/application/SET_IMPORT_ORGANIZATION_DATA_ROWS";
export const SET_IMPORT_ORGANIZATION_SHOW_ERROR = "cloverleaf/application/SET_IMPORT_ORGANIZATION_SHOW_ERROR";
export const SET_IMPORT_ORGANIZATION_ENTERPRISE_WIDE = "cloverleaf/application/SET_IMPORT_ORGANIZATION_ENTERPRISE_WIDE";
export const CHANGE_IMPORT_ORGANIZATION_ROW = "cloverleaf/application/CHANGE_IMPORT_ORGANIZATION_ROW";
export const CALENDAR_DAY_INDEX = "cloverleaf/application/CALENDAR_DAY_INDEX";
export const CALENDAR_DAY = "cloverleaf/application/CALENDAR_DAY";
export const COMPONENT_VIEW = "cloverleaf/application/COMPONENT_VIEW";
export const SET_ALERT_MODAL_VISIBILITY = "cloverleaf/application/SET_ALERT_MODAL_VISIBILITY";

/**
 * ACTION CREATORS
 */

export const updateSearch = buildItemActionCreator(SEARCH);
export const setCollapsibleNugget = buildItemActionCreator(SET_COLLAPSIBLE_NUGGET);

export const updateTeamVisibility = buildItemActionCreator(TEAM_DASHBOARD_VISIBILITY);

export const setAlertModalVisibility = buildItemActionCreator(SET_ALERT_MODAL_VISIBILITY);

export const CALENDAR_NAME = "calendarIndex";

export const setCalendarDay = value => ({
  type: CALENDAR_DAY,
  payload: value,
  meta: {
    name: CALENDAR_NAME,
  },
});

// TODO: We already have an updateView method but it's tightly coupled to the org admin screens. Ideally we can re-use that
export const setComponentView = curry((name, value) => ({
  type: COMPONENT_VIEW,
  payload: value,
  meta: {
    name,
  },
}));

export const updateRadioToggle = curry((name, value) => ({
  type: UPDATE_RADIO_TOGGLE,
  payload: value,
  meta: {
    name,
  },
}));

export const updateSelect = curry((name, value) => ({
  type: UPDATE_SELECT,
  payload: value,
  meta: {
    name,
  },
}));

export const updateView = curry((name, value) => ({
  type: UPDATE_VIEW,
  payload: value,
  meta: {
    name,
  },
}));

export const updateTableSort = curry((name, value) => ({
  type: UPDATE_TABLE_SORT,
  payload: value,
  meta: {
    name,
  },
}));

export const updateTableExpand = curry((name, value) => ({
  type: UPDATE_TABLE_EXPAND,
  payload: value,
  meta: {
    name,
  },
}));

export const updateTableSelection = curry((name, value) => ({
  type: UPDATE_TABLE_SELECTION,
  payload: value,
  meta: {
    name,
  },
}));

export const updateTableFilter = curry((name, value) => ({
  type: UPDATE_TABLE_FILTER,
  payload: value,
  meta: {
    name,
  },
}));

export const updateTeamCultureView = buildActionCreator(UPDATE_TEAM_CULTURE_VIEW);

export const closeModal = () => ({ type: CLOSE_MODAL });

export const openModal = buildActionCreator(OPEN_MODAL);

export const clearSelects = buildActionCreator(CLEAR_SELECTS);

export const setSelectedTeam = buildActionCreator(SET_SELECTED_TEAM);

export const setImportOrganization = buildActionCreator(SET_IMPORT_ORGANIZATION_STATE);

export const clearImportOrganizationState = buildActionCreator(CLEAR_IMPORT_ORGANIZATION_STATE);

export const setSelectColumnMapping = buildActionCreator(SET_SELECT_COLUMN_MAPPING_VALUE);

export const setImportOrganizationShowError = buildActionCreator(SET_IMPORT_ORGANIZATION_SHOW_ERROR);

export const setImportOrganizationEnterpriseWide = buildActionCreator(SET_IMPORT_ORGANIZATION_ENTERPRISE_WIDE);

export const setImportOrganizationRows = ({ rows, sortBy, sortDirection }) => ({
  type: SET_IMPORT_ORGANIZATION_DATA_ROWS,
  data: {
    rows: sortDirection
      ? orderBy(sortBy, sortDirection, rows)
      : rows,
    sortBy,
    sortDirection,
  },
});

/**
 * SELECTORS
 */

export const getUi = get("app.ui");

export const getSearch = (name, state) => compose(
  get([SEARCH, name]),
  getUi,
)(state);

export const getTeamVisibility = (name, state) => compose(
  getOr(initialTeamVisibilityState, [TEAM_DASHBOARD_VISIBILITY, name]),
  getUi,
)(state);

export const getAlertModalVisibility = (name, state) => compose(
  get([SET_ALERT_MODAL_VISIBILITY, name]),
  getUi,
)(state);

export const getCalendarDay = compose(
  getOr(1, [CALENDAR_DAY, CALENDAR_NAME]),
  getUi,
);

export const getComponentView = (name, initialView, state) => compose(
  getOr(initialView, [COMPONENT_VIEW, name]),
  getUi,
)(state);

export const getRadioToggleValue = (state, name) => compose(
  get(["radioToggle", name]),
  getUi,
)(state);

export const getViewValue = (state, name) => compose(
  getOr("People", ["views", name]),
  getUi,
)(state);

export const getSelectValue = (state, name) => compose(
  get(["select", name]),
  getUi,
)(state);

export const getDataTable = (state, name) => compose(
  getOr(initialDataTableState, ["dataTable", name]),
  getUi,
)(state);

export const getCollapsibleNugget = (state, name, initial = true) => compose(
  getOr(initial, [SET_COLLAPSIBLE_NUGGET, name, "open"]),
  getUi,
)(state);

export const getTeamCultureView = state => compose(
  getOr(GRAPH, "teamCultureView"),
  getUi,
)(state);

export const getModalValues = (state, name) => compose(
  get(["modal", "modalData", name]),
  getUi,
)(state);

export const getSelectedTeam = state => compose(
  get("selectedTeam"),
  getUi,
)(state);

export const getImportOrganizationState = state => compose(
  get(["importOrganization"]),
  getUi,
)(state);

export const getImportOrganizationSelectValue = (state, name) => compose(
  get("mappedTo"),
  find(cm => cm.rawColumn === name),
  get(["importOrganization", "columnMapping"]),
  getUi,
)(state);

export const getImportOrganizationDataSorting = state => compose(
  get(["importOrganization", "sorting"]),
  getUi,
)(state);

export const getImportOrganizationShowError = state => compose(
  get(["importOrganization", "showErrors"]),
  getUi,
)(state);

/**
 * REDUCER
 */

function reducer(state = {}, action = {}) {
  const { data, payload, type, meta = {} } = action;

  switch (type) {
    case UPDATE_RADIO_TOGGLE: {
      const radioToggle = getOr({}, "radioToggle", state);

      return {
        ...state,
        radioToggle: {
          ...radioToggle,
          [meta.name]: payload,
        },
      };
    }
    case UPDATE_SELECT:
      return {
        ...state,
        select: {
          ...state.select,
          [meta.name]: payload,
        },
      };
    case UPDATE_VIEW: {
      const views = getOr({}, "views", state);

      return {
        ...state,
        views: {
          ...views,
          [meta.name]: payload,
        },
      };
    }
    case UPDATE_TABLE_SORT: {
      const dataTable = getOr({}, ["dataTable", meta.name], state);

      return {
        ...state,
        dataTable: {
          ...state.dataTable,
          [meta.name]: {
            ...dataTable,
            sorted: payload,
          },
        },
      };
    }
    case UPDATE_TABLE_EXPAND: {
      const dataTable = getOr({}, ["dataTable", meta.name], state);

      return {
        ...state,
        dataTable: {
          ...state.dataTable,
          [meta.name]: {
            ...dataTable,
            expanded: payload,
          },
        },
      };
    }
    case UPDATE_TABLE_SELECTION: {
      const dataTable = getOr({}, ["dataTable", meta.name], state);

      return {
        ...state,
        dataTable: {
          ...state.dataTable,
          [meta.name]: {
            ...dataTable,
            selected: payload,
          },
        },
      };
    }
    case UPDATE_TABLE_FILTER: {
      const dataTable = getOr({}, ["dataTable", meta.name], state);

      return {
        ...state,
        dataTable: {
          ...state.dataTable,
          [meta.name]: {
            ...dataTable,
            filter: payload,
          },
        },
      };
    }
    case UPDATE_TEAM_CULTURE_VIEW: {
      return {
        ...state,
        teamCultureView: data,
      };
    }
    case UPDATE_RELATIONSHIP_MAP_SECONDARY_USER: {
      return {
        ...state,
        relationshipMapSecondaryUser: {
          ...state.relationshipMapSecondaryUser,
          [meta.name]: payload,
        },
      };
    }

    case SET_ALERT_MODAL_VISIBILITY:
    case CALENDAR_DAY:
    case COMPONENT_VIEW:
    case SET_COLLAPSIBLE_NUGGET:
    case TEAM_DASHBOARD_VISIBILITY:
    case USER_SEARCH:
    case SEARCH:
      return genericItemReducer(state, action);
    case CLOSE_MODAL: {
      return {
        ...state,
        modal: undefined,
      };
    }
    case OPEN_MODAL: {
      return {
        ...state,
        modal: data,
      };
    }
    case CLEAR_SELECTS: {
      const names = castArray(data);

      const selectsToClear = names.reduce((acc, name) => ({ ...acc, [name]: undefined }), {});

      return {
        ...state,
        select: {
          ...state.select,
          ...selectsToClear,
        },
      };
    }
    case SET_SELECTED_TEAM: {
      return {
        ...state,
        selectedTeam: data,
      };
    }

    case SET_IMPORT_ORGANIZATION_STATE: {
      const newState = processAndValidateData(data);
      newState.showErrors = get(["importOrganization", "showErrors"], state);

      return {
        ...state,
        importOrganization: newState,
      };
    }

    case CLEAR_IMPORT_ORGANIZATION_STATE: {
      return {
        ...state,
        importOrganization: undefined,
      };
    }

    case SET_IMPORT_ORGANIZATION_DATA_ROWS: {
      return {
        ...state,
        importOrganization: {
          ...state.importOrganization,
          rows: data.rows,
          sorting: {
            sortBy: data.sortBy,
            sortDirection: data.sortDirection,
          },
        },

      };
    }

    case SET_IMPORT_ORGANIZATION_SHOW_ERROR: {
      return {
        ...state,
        importOrganization: { ...state.importOrganization, showErrors: data },
      };
    }

    case SET_IMPORT_ORGANIZATION_ENTERPRISE_WIDE: {
      return {
        ...state,
        importOrganization: { ...state.importOrganization, enterpriseWide: data },
      };
    }

    case SET_SELECT_COLUMN_MAPPING_VALUE: {
      const { columnMapping } = state.importOrganization;
      columnMapping.find(cm => cm.rawColumn === data.rawColumn).mappedTo = data.value;

      return {
        ...state,
        importOrganization: processAndValidateData({ ...state.importOrganization, columnMapping }),
      };
    }

    default:
      return state;
  }
}

export default reducer;
