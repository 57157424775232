import React from "react";
import { isBrowser } from "../utils";

const useSessionStorage = (
  key,
  initialValue = null,
  raw,
) => {
  if (!isBrowser) {
    return [initialValue, () => undefined];
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [state, setState] = React.useState(() => {
    try {
      const sessionStorageValue = sessionStorage.getItem(key);
      if (typeof sessionStorageValue !== "string") {
        sessionStorage.setItem(key, raw ? String(initialValue) : JSON.stringify(initialValue));

        return initialValue;
      }

      return raw ? sessionStorageValue : JSON.parse(sessionStorageValue ?? "null");
    }
    catch {
      // If user is in private mode or has storage restriction
      // sessionStorage can throw. JSON.parse and JSON.stringify
      // can throw, too.
      console.debug("useSessionStorage caught an error");

      return initialValue;
    }
  });

  // eslint-disable-next-line react-hooks/rules-of-hooks
  React.useEffect(() => {
    try {
      const serializedState = raw ? String(state) : JSON.stringify(state ?? null);
      sessionStorage.setItem(key, serializedState);
    }
    catch {
      // If user is in private mode or has storage restriction
      // sessionStorage can throw. Also JSON.stringify can throw.
      console.debug("useSessionStorage caught an error");
    }
  });

  return [state, setState];
};

export { useSessionStorage };
