import React from "react";
import { TutorialDrop } from "../components/TutorialDrop";
import { ViaQuestion } from "./Question";
import { useLocale } from "@/lib/hooks";

function ViaExampleQuestion({
  onChange,
}) {
  const { t } = useLocale();

  const [value, setValue] = React.useState("");
  const [walkthrough, setWalkthrough] = React.useState(-1);

  const targetRef = React.useRef();

  React.useEffect(() => {
    setWalkthrough(0);
  }, []);

  const question = {
    number: 0,
    prompt: t("via.instructions.example.question")
  };

  const tutorial = [
    {
      tip: t("via.instructions.example.tooltip"),
    }
  ];

  const handleOnChange = (value) => {
    setValue(value);

    // Tutorial Specific
    const nextStep = walkthrough + 1;

    if (nextStep < tutorial.length) {
      return setWalkthrough(nextStep);
    }

    setWalkthrough(nextStep);

    return onChange(value);
  };

  return (
    <React.Fragment>
      <ViaQuestion
        ref={targetRef}
        margin={{ top: "small" }}
        onChange={handleOnChange}
        question={question}
        selected={value}
        tutorial
      />
      {tutorial[walkthrough] && targetRef && (
        <TutorialDrop target={targetRef.current}>
          {tutorial[walkthrough].tip}
        </TutorialDrop>
      )}
    </React.Fragment>
  );
}

export { ViaExampleQuestion };
