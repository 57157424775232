import React from "react";
import PropTypes from "prop-types";
import { Box, Drop } from "grommet";
import { DROP_POSITION } from "@/cloverleaf-ui/constants";

function TutorialDrop({ children = null, width = undefined, ...props }) {
  return (
    <Drop
      align={DROP_POSITION.bottom}
      background="yellow500"
      elevation="large"
      trapFocus={false}
      {...props}
    >
      <Box pad="large" width={width}>
        {children}
      </Box>
    </Drop>
  )
}

TutorialDrop.propTypes = {
  children: PropTypes.any,
  width: PropTypes.string,
};

export { TutorialDrop };
