import getOr from "lodash/fp/getOr";
import { WHITE, DARK_GREY } from "../../../utils/theme";

export const INSIGHT_THEME = {
  DARK: "dark",
  LIGHT: "light",
};

export const INSIGHT_TARGET = {
  BOSS: "boss",
  SELF: "self",
  TEAM: "team",
};

export const getInsightsIconColor = ({ theme }) => {
  let textColor = WHITE;
  let thumbsUpColor = WHITE;
  let thumbsDownColor = WHITE;

  if (theme === INSIGHT_THEME.DARK) {
    textColor = DARK_GREY;
    thumbsUpColor = DARK_GREY;
    thumbsDownColor = DARK_GREY;
  }

  return {
    textColor,
    thumbsUpColor,
    thumbsDownColor,
  };
};

export const getPremiumContentFeature = getOr(false, "check.hasPremiumContent");
