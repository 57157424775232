import React from "react";
import { css } from "styled-components";
import { Grommet, Tabs } from "grommet";
import { deepMerge } from "grommet/utils";
import { Divider } from "@/cloverleaf-ui/components";
import { themeStyle } from "@/cloverleaf-ui/theme";

const customTheme = deepMerge(themeStyle, {
  tab: {
    active: {
      background: "green600",
      color: "white",
    },
    background: "green200",
    border: undefined,
    color: "black",
    hover: {
      background: "green700",
      color: "white",
    },
    margin: undefined,
    pad: {
      vertical: "xsmall",
      horizontal: "medium",
    },
    extend: ({ theme }) => css`
      border-radius: ${theme.global.control.border.radius};
      text-align: center;
    `,
  },
  tabs: {
    gap: undefined,
    header: {
      border: undefined,
      extend: ({ theme }) => css`
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: 1fr 1fr;
        grid-row-gap: ${theme.global.edgeSize.xsmall};
        grid-column-gap: ${theme.global.edgeSize.xsmall};
        grid-template-areas:
          "a b"
          "c c";
      `,
    },
    panel: {
      extend: ({ theme }) => css`
        padding: ${theme.global.edgeSize.xsmall};
        padding-bottom: 0px;
      `,
    },
  },
});

function ButtonTabs({ children, dividerProps, ...props }) {
  const tabs = React.Children.map(children, (child) => (
    child ? React.cloneElement(child, {
      style: {
        borderRadius: themeStyle.global.edgeSize.xxsmall,
      },
    }) : child
  ));

  return (
    <Grommet theme={customTheme}>
      <Tabs {...props}>
        {tabs}
        <Divider
          alignSelf="center"
          color="green600"
          gridArea="c"
          margin={{ horizontal: "auto" }}
          width="120px"
          {...dividerProps}
        />
      </Tabs>
    </Grommet>
  );
}

export { ButtonTabs };
