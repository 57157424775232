import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  CheckBox,
  Text
} from "grommet";

function Toggle({
  // If checkedProp is undefined this will behave as an uncontrolled component
  checked: checkedProp = undefined,
  defaultValue = undefined,
  onChange = () => undefined,
  showState = false,
  stateLabels = ["Off", "On"],
  useOnChangeEvent = false,
  ...props
}) {
  if (typeof defaultValue !== "undefined" && typeof checkedProp !== "undefined") {
    // eslint-disable-next-line no-console
    console.warn("Improperly using both controlled and uncontrolled component props.");
  }

  const [checked, setChecked] = React.useState(checkedProp ?? defaultValue);

  React.useEffect(() => {
    setChecked(checkedProp);
  }, [checkedProp]);

  /**
   * @param {*} event
   * @returns {Boolean} event.target.checked
   */
  const handleOnChange = (event) => {
    // Handle state update for uncontrolled
    if (typeof checkedProp === "undefined") {
      setChecked(event.target.checked);
    }

    if (useOnChangeEvent) {
      return onChange(event);
    }

    return onChange(event.target.checked);
  };

  if (showState) {
    const id = props?.id || "toggle-id";

    return (
      <Box
        align="center"
        direction="row"
        gap="small"
      >
        <CheckBox
          {...props}
          id={id}
          checked={checked}
          onChange={handleOnChange}
          toggle
        />
        {/* maintain equal width for ON / OFF */}
        <Box width="xsmall" style={{ display: "contents" }}>
          <Text as="label" htmlFor={id} style={{ textTransform: "uppercase" }}>
            {checked ? stateLabels[1] : stateLabels[0]}
          </Text>
        </Box>
      </Box>
    );
  }

  return (
    <CheckBox
      {...props}
      checked={checked}
      onChange={handleOnChange}
      toggle
    />
  );
}

Toggle.propTypes = {
  checked: PropTypes.bool,
  defaultValue: PropTypes.bool,
  id: PropTypes.string,
  onChange: PropTypes.func,
  showState: PropTypes.bool,
  stateLabels: PropTypes.arrayOf(PropTypes.string),
  useOnChangeEvent: PropTypes.bool,
};

export { Toggle };
