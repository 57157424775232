import React from "react";
import { func, string } from "prop-types";
import ButtonWithIcon from "../ui/ButtonWithIcon";
import { Flex, Box } from "../ui/FlexBox";
import TextLabel from "../ui/styled/TextLabel";
import { ARE_YOU_SURE } from "../utils/strings";

class ConfirmPrompt extends React.PureComponent {
  static propTypes = {
    prompt: string,
    additionalInfo: string,
    onPositiveClick: func.isRequired,
    onNegativeClick: func.isRequired,
    positiveTooltip: string,
    negativeTooltip: string,
  }

  static defaultProps = {
    prompt: ARE_YOU_SURE,
    additionalInfo: undefined,
    positiveTooltip: undefined,
    negativeTooltip: undefined,
  }

  render() {
    const {
      prompt, additionalInfo, onPositiveClick, onNegativeClick, positiveTooltip, negativeTooltip,
    } = this.props;

    return (
      <Flex p={1} column>
        {additionalInfo && (
          <Box pb={1} style={{ maxWidth: "300px" }}>
            <TextLabel>{additionalInfo}</TextLabel>
          </Box>
        )}
        <Flex align="center" justify="flex-end" style={{ overflow: "hidden" }} wrap>
          <Box style={{ maxWidth: "200px" }} shrink={0} mr={1}>
            <TextLabel uppercase>{prompt}</TextLabel>
          </Box>
          <Box w={45} mr={1}>
            <ButtonWithIcon
              primary
              block
              icon={["fas", "check"]}
              fixedWidth={false}
              onClick={onPositiveClick}
              tooltip={positiveTooltip && { title: positiveTooltip }}
            />
          </Box>
          <Box w={45}>
            <ButtonWithIcon
              negativePrimary
              block
              icon={["fas", "times"]}
              fixedWidth={false}
              onClick={onNegativeClick}
              tooltip={negativeTooltip && { title: negativeTooltip }}
            />
          </Box>
        </Flex>
      </Flex>
    );
  }
}

export default ConfirmPrompt;
