import React from "react";
import {
  number,
  func,
  node
} from "prop-types";
import { Flex, Box } from "./FlexBox";
import Icon from "./Icon";
import TextLabel from "./styled/TextLabel";
import TextButton from "./TextButton";

class ArrowPagination extends React.PureComponent {
  static propTypes = {
    currentPage: number.isRequired,
    totalPages: number.isRequired,
    onClickBack: func.isRequired,
    onClickForward: func.isRequired,
    label: node,
    initialPageIndex: number,
    t: func.isRequired,
  };

  static defaultProps = {
    label: undefined,
    initialPageIndex: 1,
  }

  renderLabel = () => {
    const { label, currentPage, totalPages, i18n } = this.props;
    const { t } = i18n;

    if (label) {
      return label;
    }

    return (
      <TextLabel>
        {t("dash.pagination-label", { currentPage, totalPages })}
      </TextLabel>
    );
  }

  render() {
    const {
      currentPage, totalPages, onClickBack, onClickForward, initialPageIndex,
    } = this.props;

    return (
      <Flex justify="center" align="center">
        {currentPage !== initialPageIndex && (
          <TextButton onClick={onClickBack}>
            <Icon icon={["fal", "arrow-circle-left"]} size="lg" />
          </TextButton>
        )}
        <Box mx={1}>
          {this.renderLabel()}
        </Box>
        {currentPage !== totalPages && (
          <TextButton onClick={onClickForward}>
            <Icon icon={["fal", "arrow-circle-right"]} size="lg" />
          </TextButton>
        )}
      </Flex>
    );
  }
}

export default ArrowPagination;
