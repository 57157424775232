import React from "react";
import * as Sentry from "@sentry/nextjs";
import { useAuth } from "@/lib/hooks";

function SentryUserManager() {
  const userId = useAuth();

  React.useEffect(() => {
    if (userId) {
      Sentry.setUser({
        id: userId ?? undefined,
      });
    }
  }, [userId]);

  return null;
}

export { SentryUserManager };
