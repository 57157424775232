/**
 * Convert a date string into a "LongDay, LongMonth DD, YYYY" format
 * Example: '2022-03-18' => Friday, March 18, 2022
 *
 * @param {string} [date] - date will be assumed as UTC time, default today's date
 * @returns string
 */

export function getDailyCoachingDate(date, locale = "en-us") {
  return new Date(date).toLocaleDateString(locale, {
    timeZone: "UTC", weekday: "long", year: "numeric", month: "long", day: "numeric",
  });
}
