import {
  createContext,
  createRef,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import PropTypes from "prop-types";
import { useDebugAnalytics } from "../hooks/useDebugAnalytics";

const Context = createContext("");
export const LogContext = Context;

function Log ({ children, logImpression = false, ...props }) {
  const logDomElementRef = createRef();

  const consumedProps = useContext(LogContext);

  const { isDebugMode } = useDebugAnalytics();

  const combinedProps = useMemo(() => ({
    platform: "in-app",
    ...consumedProps,
    ...props,
  }), [consumedProps, props]);

  const [hasLoggedImpression, setHasLoggedImpression] = useState(false);
  const [isInViewport, setIsInViewport] = useState(false);

  const observerCallback = useCallback((entries) => {
    const entry = entries[0];

    if (entry !== undefined && isInViewport !== entry.isIntersecting) {
      setIsInViewport(entry.isIntersecting);
    }
  }, [isInViewport]);

  const setupObserver = useCallback(() => {
    const observer = new IntersectionObserver(observerCallback, {
      root: null,
      rootMargin: "0px",
      threshold: 0,
    });

    const wrappedDOMElements = logDomElementRef?.current?.childNodes;
    const firstVisibleElement = find(wrappedDOMElements, (el) => el.offsetParent !== null);

    if (firstVisibleElement) {
      observer.observe(firstVisibleElement);
    }
  }, [logDomElementRef, observerCallback]);

  useEffect(() => {
    if (logImpression) {
      setupObserver();
    }
  }, [logImpression, setupObserver]);

  useEffect(() => {
    if (logImpression && isInViewport && !hasLoggedImpression) {
      /**
       * @todo call sendLog with impression specific event name
       */
      if (isDebugMode) {
        console.log("track impression", combinedProps);
      }

      setHasLoggedImpression(true);
    }
  }, [isInViewport, hasLoggedImpression, logImpression, combinedProps]);

  return (
    <Context.Provider value={combinedProps}>
      <div style={{ display: "contents" }} ref={logDomElementRef}>
        {children}
      </div>
    </Context.Provider>
  );
}

Log.propTypes = {
  channel: PropTypes.string,
  children: PropTypes.node.isRequired,
  component: PropTypes.string,
  elementType: PropTypes.string,
  logImpression: PropTypes.bool,
  page: PropTypes.string,
  section: PropTypes.string,
};

export { Log };
