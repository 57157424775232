import * as Sentry from "@sentry/nextjs";
const debug = require("debug")("cloverleaf:cacheInBrowser");

/**
 * Feature Detect SessionStorage
 * https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#feature-detecting_localstorage
 */
export const isSessionStorageAvailable = () => {
  const type = "sessionStorage";
  let storage;

  try {
    if (typeof window === "undefined") {
      return false;
    }

    storage = window[type];
    const x = "__storage_test__";
    storage.setItem(x, x);
    storage.removeItem(x);

    return true;
  }
  catch (e) {
    return e instanceof DOMException && (
      // everything except Firefox
      e.code === 22 ||
      // Firefox
      e.code === 1014 ||
      // test name field too, because code might not be present
      // everything except Firefox
      e.name === "QuotaExceededError" ||
      // Firefox
      e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
      // acknowledge QuotaExceededError only if there's something already stored
      (storage && storage.length !== 0);
  }
};

const cacheInBrowser = {
  set: (itemName, value) => {
    if (typeof window !== "undefined"
      && isSessionStorageAvailable()
      && typeof value !== "undefined") {
      try {
        sessionStorage.setItem(itemName, JSON.stringify(value));
      }
      catch (error) {
        const message = "Error saving to sessionStorage";
        debug(message, error);
        // We ARE NOT logging it to Raven because it happens in a loop and logs a ton.
      }
    }
  },
  get: (itemName) => {
    if (typeof window !== "undefined"
      && isSessionStorageAvailable()
      && itemName) {
      try {
        return JSON.parse(sessionStorage.getItem(itemName) || "null");
      }
      catch (error) {
        const message = "Error retrieving from sessionStorage";
        debug(message, error);
        Sentry.captureMessage(error, { extra: { itemName, message } });
      }
    }

    return undefined;
  },
  reset: (itemName) => {
    if (typeof window !== "undefined"
      && isSessionStorageAvailable()
      && itemName) {
      try {
        sessionStorage.removeItem(itemName);
      }
      catch (error) {
        const message = "Error resetting sessionStorage";
        Sentry.captureMessage(error, { extra: { itemName, message } });
      }
    }
  },
};

export default cacheInBrowser;
