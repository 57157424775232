import React from "react";
import { getThemeColor } from "@/cloverleaf-ui/theme";

export const leftCaret = (
  <svg key="caret" viewBox="0 0 22 22" version="1.1" width="22px" height="22px">
    <polygon
      fill={getThemeColor("grey300")}
      points="6 2 18 12 6 22"
      transform="matrix(-1 0 0 1 30 0)"
    />
  </svg>
);

export const rightCaret = (
  <svg key="caret" viewBox="0 0 22 22" version="1.1" width="22px" height="22px">
    <polygon
      fill={getThemeColor("grey300")}
      points="6 2 18 12 6 22"
      transform="translate(-11)"
    />
  </svg>
);

export const topCaret = (
  <svg key="caret" viewBox="0 0 22 22" version="1.1" width="22px" height="12px">
    <polygon
      fill={getThemeColor("grey300")}
      points="22 8 12 20 2 8"
      transform="translate(0, -8)"
    />
  </svg>
);

export const bottomCaret = (
  <svg key="caret" viewBox="0 0 22 22" version="1.1" width="22px" height="12px">
    <polygon
      fill={getThemeColor("grey300")}
      points="22 4 12 16 2 4"
      transform="matrix(1 0 0 -1 0 20) translate(0, -8)"
    />
  </svg>
);
