import React from "react";
import { Box, Heading } from "grommet";

function QuestionCardComponent({
  children,
  headingProps,
  prompt,
  ...props
}, ref) {
  return (
    <Box
      ref={ref}
      align="center"
      alignSelf="center"
      margin={{ top: "medium", bottom: "xxsmall" }}
      gap="medium"
      {...props}
    >
      {prompt && (
        <Heading
          textAlign="center"
          margin="none"
          size="small"
          {...headingProps}
        >
          {prompt}
        </Heading>
      )}
      {children}
    </Box>
  )
}

const QuestionCard = React.forwardRef(QuestionCardComponent);

export { QuestionCard };
