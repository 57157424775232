import React from "react";
import PropTypes from "prop-types";
import { useMutation, gql } from "@apollo/client";
import * as Sentry from "@sentry/nextjs";
import {
  Anchor,
  Box,
  Button,
} from "grommet";
import { EVENT } from "../../../../components/utils/constants";
import { LoadingIndicator, Message } from "../../atoms";
import { ClipboardTextInput } from "../../molecules";
import { track } from "@/lib";
import { useLocale } from "@/lib/hooks";

const createLinkMutation = gql`
  mutation createLinkMutation($organizationId: ID!) {
    createOrganizationLink(organizationId: $organizationId) {
      id,
      inviteOrganizationLink
    }
  }
`;

const deleteLinkMutation = gql`
  mutation deleteOrganizationLink($organizationId: ID!) {
    deleteOrganizationLink(organizationId: $organizationId) {
      id
      inviteOrganizationLink
    }
  }
`;

function OrgInviteLinkForm({
  onCopy = () => undefined,
  onCreate = () => undefined,
  onDelete = () => undefined,
  orgId,
  orgLink = undefined,
  ...props
}) {
  const { t } = useLocale();

  const [link, setLink] = React.useState(orgLink);
  const [errorMessage, setErrorMessage] = React.useState("");

  const [createLink, { error: createError, loading: creatingLink }] = useMutation(createLinkMutation);
  const [deleteLink, { error: deleteError, loading: deletingLink }] = useMutation(deleteLinkMutation);

  React.useEffect(() => {
    setErrorMessage(deleteError);
  }, [deleteError]);

  React.useEffect(() => {
    setErrorMessage(createError);
  }, [createError]);

  React.useEffect(() => {
    setLink(orgLink);
  }, [orgLink]);

  if (!orgId) {
    return null;
  }

  const handleOnCreate = () => {
    setErrorMessage("");

    track(EVENT.OrgInviteLinkCreated.name);

    return createLink({
      variables: {
        organizationId: orgId,
      },
    }).then((result) => {
      const inviteLink = result?.data?.createOrganizationLink?.inviteOrganizationLink;

      setLink(inviteLink);

      return onCreate(inviteLink);
    }).catch((error) => {
      Sentry.captureException(error, {
        extra: {
          message: "Error creating org invite link",
        },
      });

      return setErrorMessage(t("create-org-invite-link-failure"));
    });
  };

  const handleOnDelete = () => {
    setErrorMessage("");

    track(EVENT.OrgInviteLinkDeleted.name);

    return deleteLink({
      variables: {
        organizationId: orgId,
      },
    }).then(() => {
      setLink(null);

      return onDelete();
    }).catch((error) => {
      Sentry.captureException(error, {
        extra: {
          message: "Error deleting org invite link",
        },
      });

      return setErrorMessage(t("delete-org-invite-link-failure"));
    });
  };

  return (
    <Box direction="column" gap="small" {...props}>
      {!link && !creatingLink && (
        <Button
          label={t("common:button.create-org-invite-link")}
          onClick={handleOnCreate}
          primary
        />
      )}
      {errorMessage && (
        <Message kind="error">{errorMessage}</Message>
      )}
      {!link && (creatingLink || deletingLink) && (
        <LoadingIndicator style={{ zIndex: 0 }} />
      )}
      {link && (
        <React.Fragment>
          <ClipboardTextInput
            inputProps={{
              disabled: creatingLink || deletingLink,
            }}
            label={t("common:link")}
            onCopy={onCopy}
            value={link}
            t={t}
          />
          <Anchor
            color="green600"
            disabled={creatingLink || deletingLink}
            label={t("common:disable-invite-link")}
            onClick={handleOnDelete}
            style={{ width: "max-content" }}
          />
        </React.Fragment>
      )}
    </Box>
  );
}

OrgInviteLinkForm.propTypes = {
  orgId: PropTypes.string.isRequired,
  orgLink: PropTypes.string,
  onCopy: PropTypes.func,
  onCreate: PropTypes.func,
  onDelete: PropTypes.func,
};

export { OrgInviteLinkForm };
