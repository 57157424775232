/**
 * Access to runtime configurations in next.config.js
 * @see https://nextjs.org/docs/api-reference/next.config.js/runtime-configuration
 */

const env = process.env.NODE_ENV || "development";

const DEFAULT_CONSTANTS = {
  development: {
    apiPort: 3001,
    clientPort: 3000,
    subDomain: "www-local",
    domain: "cloverleaf.me",
    protocol: "http",
  },
  test: {
    apiPort: 443,
    clientPort: 443,
    subDomain: "test",
    domain: "cloverleaf.ninja",
    protocol: "https",
  },
  production: {
    apiPort: 443,
    clientPort: 443,
    subDomain: "app",
    domain: "cloverleaf.me",
    protocol: "https",
  },
};

const defaults = DEFAULT_CONSTANTS[env];

const CLIENT_URL_WITH_PORT = `${defaults.protocol}://${defaults.domain}:${defaults.clientPort}`;
const BASE_URL = `${defaults.protocol}://${defaults.subDomain}.${defaults.domain}`;

const defaultConfig = {
  env,

  // Ports
  CLIENT_PORT: process.env.NEXT_RUNTIME_CLIENT_PORT || defaults.clientPort,
  API_PORT: process.env.NEXT_RUNTIME_API_PORT || defaults.apiPort,

  // Segment.io
  SEGMENT_WRITE_KEY: process.env.NEXT_RUNTIME_SEGMENT_WRITE_KEY || "hCvPDbvFLrecJizcsfwIXh82JiRaTG8m",
  SEGMENT_PUBLIC_WRITE_KEY: process.env.NEXT_RUNTIME_SEGMENT_PUBLIC_WRITE_KEY || "MPUJklg9oGBup7WNnIPFN4HcbTr5pIWI",

  // Chargebee
  CHARGEBEE_SITE: process.env.NEXT_RUNTIME_CHARGEBEE_SITE || "cloverleaf-test",
  CHARGEBEE_ENTERPRISE_PLAN_NAME: process.env.NEXT_RUNTIME_CHARGEBEE_ENTERPRISE_PLAN_NAME || "enterprise",
  CHARGEBEE_TEAM_PLAN_NAME: process.env.NEXT_RUNTIME_CHARGEBEE_TEAM_PLAN_NAME || "team_plan",

  // Cloverleaf Env, for AWS deployment
  CLOVERLEAF_ENV: env,

  // Cloverleaf API URL
  CLOVERLEAF_API_URL: process.env.NEXT_RUNTIME_CLOVERLEAF_API_URL
    || `${BASE_URL}:${defaults.apiPort}`,

  // Set URL for the environment
  CLOVERLEAF_CLIENT_URL: process.env.NEXT_RUNTIME_CLOVERLEAF_CLIENT_URL
    || `${BASE_URL}:${defaults.clientPort}`,

  // Match cookie name with API. If changing domain, set COOKIE_DOMAIN on API too
  CLOVERLEAF_COOKIE_DOMAIN: process.env.NEXT_RUNTIME_CLOVERLEAF_COOKIE_DOMAIN || `.${defaults.subDomain}.${defaults.domain}`,
  CLOVERLEAF_COOKIE_NAME: process.env.NEXT_RUNTIME_CLOVERLEAF_COOKIE_NAME || "clsess",
  CLOVERLEAF_COOKIE_USER_ID: "cl_user_id",
  CLOVERLEAF_DOMAIN: process.env.NEXT_RUNTIME_CLOVERLEAF_DOMAIN || defaults.domain,
  CLOVERLEAF_SUBDOMAIN: process.env.NEXT_RUNTIME_CLOVERLEAF_SUBDOMAIN || defaults.subDomain,

  // Force org subdomains over query param in lower environments, i.e. sub.www-local.cloverleaf.me
  CLOVERLEAF_FORCE_ORG_SUBDOMAINS: process.env.NEXT_RUNTIME_CLOVERLEAF_FORCE_ORG_SUBDOMAINS || "true",

  // Used as a default UUID to add trust for multiple accounts
  CLOVERLEAF_CLIENT_ID: process.env.NEXT_RUNTIME_CLOVERLEAF_CLIENT_ID || "a146feba-9dc3-4988-91ac-3f661a8ca7e6",

  // pages/via/index.js
  // VIAs APIs has issues more often than we'd like, we can simply and quickly prevent users
  // from accessing the assessment and running into errors by flipping the toggle below. The
  // user will just be greeted with a message letting them know that we are addressing the problem.
  CLOVERLEAF_TOGGLE_VIA_UNAVAILABLE: process.env.NEXT_RUNTIME_CLOVERLEAF_TOGGLE_VIA_UNAVAILABLE || "false",

  // List of org subdomains available in the test environment
  CLOVERLEAF_TEST_ORG_SUBDOMAINS: process.env.NEXT_RUNTIME_CLOVERLEAF_TEST_ORG_SUBDOMAINS
    || "test,artillery,bigco,schoch,testorglievertz,teststeven",
};

/**
 * Set environment specific default values
 */
const environmentConfig = {
  test: {
    ...defaultConfig,
    CLOVERLEAF_ENV: process.env.NEXT_RUNTIME_CLOVERLEAF_ENV || "test",
    CLOVERLEAF_API_URL: process.env.NEXT_RUNTIME_CLOVERLEAF_API_URL || BASE_URL,
    CLOVERLEAF_CLIENT_URL: process.env.NEXT_RUNTIME_CLOVERLEAF_CLIENT_URL || CLIENT_URL_WITH_PORT,
  },
  development: {
    ...defaultConfig,
    CLOVERLEAF_ENV: process.env.NEXT_RUNTIME_CLOVERLEAF_ENV || "development",
  },
  production: {
    ...defaultConfig,
    CHARGEBEE_SITE: process.env.NEXT_RUNTIME_CHARGEBEE_SITE || "cloverleaf",
    CLOVERLEAF_ENV: process.env.NEXT_RUNTIME_CLOVERLEAF_ENV || "production",
    CLOVERLEAF_API_URL: process.env.NEXT_RUNTIME_CLOVERLEAF_API_URL || BASE_URL,
    CLOVERLEAF_CLIENT_URL: process.env.NEXT_RUNTIME_CLOVERLEAF_CLIENT_URL || CLIENT_URL_WITH_PORT,
    CLOVERLEAF_COOKIE_DOMAIN: process.env.NEXT_RUNTIME_CLOVERLEAF_COOKIE_DOMAIN || `.${defaults.domain}`,
    SEGMENT_WRITE_KEY: process.env.NEXT_RUNTIME_SEGMENT_WRITE_KEY || "qorehuTOdCYUNXAg2ekRGCyUabdiq0hb",
    SEGMENT_PUBLIC_WRITE_KEY: process.env.NEXT_RUNTIME_SEGMENT_PUBLIC_WRITE_KEY || "mnm5XCetSSxteFGKFwo5j87RAqk6wS6d",
  },
};

module.exports = {
  ...environmentConfig[env],
};
