import React, { memo } from "react";
import {
  node,
  number,
  bool,
  func
} from "prop-types";
import compose from "lodash/fp/compose";
import styled from "styled-components";
import { withTooltip } from "../hoc/withTooltip";
import {
  getCircleImgSize,
  getCircleSizePx,
  getCircleImgSizePx,
} from "../utils";
import { GREEN, GREY_OPAQUE } from "../utils/theme";
import { Flex } from "./FlexBox";

// Base size, in pixels, used to multiple by scale
const BASE_FONT_SIZE = 5;

const getFontSizePx = getCircleSizePx(BASE_FONT_SIZE);

const getTransformScale = size => (getCircleImgSize(size + 2)) / (getCircleImgSize(size));

const Container = styled(Flex)`
  position: relative;
  border-radius: 50%;
  background-color: ${props => props.background || GREY_OPAQUE};
  color: ${props => props.color || "unset"};
  overflow: hidden;
  font-size: ${props => getFontSizePx(props.size)};
  width: ${props => getCircleImgSizePx(props.size)};
  height: ${props => getCircleImgSizePx(props.size)};
  border: ${props => (props.border ? `2px solid ${GREEN}` : "")};
  border-color: ${props => props.borderColor || GREEN};
  transition: ${props => (!props.noAnimate && "transform 0.2s")};
  user-select: none;
  pointer-events: all;

  &:hover {
    transform: ${props => (props.expandable ? `scale(${getTransformScale(props.size)})` : "")};
    cursor: ${props => (props.onClick ? "pointer" : "default")}
  }
`;

function CircleContainer({
  children,
  size = 3,
  expandable = true,
  border = false,
  ...otherProps
}) {
  return (
    <Container align="center" justify="center" size={size} expandable={expandable} border={border} {...otherProps}>
      {children}
    </Container>
  );
}

CircleContainer.propTypes = {
  children: node.isRequired,
  size: number,
  expandable: bool,
  onClick: func,
  border: bool,
};

export default compose(
  memo,
  withTooltip,
)(CircleContainer);
