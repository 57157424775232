import { useMutation, gql } from "@apollo/client";
import * as Sentry from "@sentry/nextjs";

const forgotOrganizationException = ({
  message = "Unknown Error",
  status = "Unknown",
}) => ({
  message,
  status,
});

export const FORGOT_ORGANIZATION_STATUS = {
  SUCCESS: "SUCCESS",
};

export const FORGOT_ORGANIZATION_QUERY = gql`
  mutation forgotOrganization($input: input) {
    forgotOrganization(input: $input)
      @rest(type: "Response", path: "/organizations/forgot", method: "POST") {
        message
        status
        success
    }
  }
`;

export const useForgotOrganization = () => {
  const [
    forgotOrganization,
    { data, loading, error },
  ] = useMutation(FORGOT_ORGANIZATION_QUERY);

  const handleForgotOrganization = async ({ email }) => {
    try {
      const {
        data: response,
      } = await forgotOrganization({
        variables: {
          input: { email },
        },
      });

      const {
        message,
        status,
        success,
      } = response.forgotOrganization;

      return {
        message,
        status,
        success,
      };
    }
    catch (errorResponse) {
      const { message, status, success } = errorResponse?.networkError?.result || {};

      Sentry.captureException(errorResponse, {
        extra: {
          arguments: { email },
          message,
          status,
          success,
        },
        tags: {
          function: "useForgotOrganization",
        },
      });

      throw forgotOrganizationException({ message, status });
    }
  };

  return {
    data,
    error,
    loading,
    forgotOrganization: handleForgotOrganization,
    forgotOrganizationStatus: FORGOT_ORGANIZATION_STATUS,
  };
};
