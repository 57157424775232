import React from "react";
import PropTypes from "prop-types";
import { Notification as GrommetNotification } from "grommet";

function Notification({
  // Array of Anchor prop objects
  actions = undefined,
  message = undefined,
  onClose = undefined,
  status = "normal",
  title = undefined,
  toast = true,
  visible = undefined,
  ...props
}) {
  const content = { message, title };

  /**
   * If actions are included without a message, pass the title as the
   * message prop for more consistent horizontal alignment.
  */
  if (actions?.length && !content.message) {
    content.message = content.title;

    delete content.title;
  }

  return visible ? (
    <GrommetNotification
      {...props}
      actions={actions}
      onClose={onClose}
      status={status}
      time={status === "critical" ? 5000 : undefined}
      toast={toast}
      {...content}
    />
  ) : null;
}

Notification.propTypes = {
  actions: PropTypes.array,
  message: PropTypes.any,
  onClose: PropTypes.func,
  status: PropTypes.oneOf(["normal", "critical", "warning", "info"]),
  title: PropTypes.string,
  toast: PropTypes.bool,
  visible: PropTypes.bool,
};

export { Notification };
