import React from "react";
import PropTypes from "prop-types";
import { useMutation, gql } from "@apollo/client";
import * as Sentry from "@sentry/nextjs";
import {
  Anchor,
  Box,
  Button,
} from "grommet";
import { EVENT } from "../../../../components/utils/constants";
import { track } from "../../../../lib";
import { LoadingIndicator, Message } from "../../atoms";
import { ClipboardTextInput } from "../../molecules";
import { useLocale } from "@/lib/hooks";

const createLinkMutation = gql`
  mutation createLinkMutation($teamId: ID!) {
    createTeamLink(teamId: $teamId) {
      id
      inviteLink
    }
  }
`;

const deleteLinkMutation = gql`
  mutation deleteLinkMutation($teamId: ID!) {
    deleteTeamLink(teamId: $teamId) {
      id
      inviteLink
    }
  }
`;

function TeamInviteLinkForm({
  onCopy = () => undefined,
  onCreate = () => undefined,
  onDelete = () => undefined,
  teamId = undefined,
  teamLink = "",
  ...props
}) {
  const { t } = useLocale();
  const [link, setLink] = React.useState(teamLink);
  const [errorMessage, setErrorMessage] = React.useState("");

  const [createLink, { error: createError, loading: creatingLink }] = useMutation(createLinkMutation);
  const [deleteLink, { error: deleteError, loading: deletingLink }] = useMutation(deleteLinkMutation);

  React.useEffect(() => {
    setErrorMessage(deleteError);
  }, [deleteError]);

  React.useEffect(() => {
    setErrorMessage(createError);
  }, [createError]);

  React.useEffect(() => {
    setLink(teamLink);
  }, [teamLink]);

  if (!teamId) {
    return null;
  }

  const handleOnCreate = () => {
    setErrorMessage("");

    track(EVENT.TeamInviteLinkCreated.name);

    return createLink({
      variables: {
        teamId,
      },
    }).then((result) => {
      const inviteLink = result?.data?.createTeamLink?.inviteLink;

      setLink(inviteLink);

      return onCreate(inviteLink);
    }).catch((error) => {
      Sentry.captureException(error, {
        extra: {
          message: "Error creating team invite link",
        },
      });

      return setErrorMessage(t("common:invite-link.error"));
    });
  };

  const handleOnDelete = () => {
    setErrorMessage("");

    track(EVENT.TeamInviteLinkDeleted.name);

    return deleteLink({
      variables: {
        teamId,
      },
    }).then(() => {
      setLink(null);

      return onDelete();
    }).catch((error) => {
      Sentry.captureException(error, {
        extra: {
          message: "Error deleting team invite link",
        },
      });

      return setErrorMessage("common:invite-link.delete-error");
    });
  };

  return (
    <Box direction="column" gap="small" {...props}>
      {!link && !creatingLink && (
        <Button
          label={t("common:invite-link.button-label")}
          onClick={handleOnCreate}
          primary
        />
      )}
      {errorMessage && (
        <Message compact pad={{ vertical: "small" }} kind="error">{errorMessage}</Message>
      )}
      {!link && (creatingLink || deletingLink) && (
        <LoadingIndicator style={{ zIndex: 0 }} />
      )}
      {link && (
        <React.Fragment>
          <ClipboardTextInput
            inputProps={{
              disabled: creatingLink || deletingLink,
            }}
            onCopy={onCopy}
            value={link}
            t={t}
          />
          <Anchor
            color="green600"
            disabled={creatingLink || deletingLink}
            label={t("common:invite-link.disable-label")}
            onClick={handleOnDelete}
            style={{ width: "max-content" }}
          />
        </React.Fragment>
      )}
    </Box>
  );
}

TeamInviteLinkForm.propTypes = {
  teamId: PropTypes.string,
  teamLink: PropTypes.string,
  onCopy: PropTypes.func,
  onCreate: PropTypes.func,
  onDelete: PropTypes.func,
};

export { TeamInviteLinkForm };
