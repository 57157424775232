import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  FormField,
  Select,
  Text,
} from "grommet";

/**
 * @param options Can be an array of strings or objects
 * @param labelKey if options are objects, the key value for the label
 * @param valueKey if options are objects, the key value for the value
 */
function DropdownFormFieldComponent({
  children,
  descriptionKey = undefined,
  label = undefined,
  labelKey = undefined,
  name = "dropdown-field",
  onChange = () => undefined,
  onSearch = undefined,
  options = [],
  placeholder = "Select...",
  selectProps = undefined,
  value = undefined,
  valueKey = undefined,
  valueLabel = undefined,
  ...props
}, ref) {
  const defaultLabelKey = "label";
  const defaultValueKey = "value";
  const defaultDescriptionKey = "description";

  // If options are objects, manually render
  const useCustomRender = options?.length > 0 && typeof options[0] === "object";

  const handleOnChange = selected => onChange?.(selected?.value);

  const renderOption = (option, state) => {
    const description = option[descriptionKey ?? defaultDescriptionKey];
    const optionValue = option[valueKey ?? defaultValueKey];
    let optionLabel = option[labelKey ?? defaultLabelKey];

    // If no label, try to fallback
    if (!optionLabel) {
      optionLabel = optionValue;
    }

    let background;

    if (state.selected) {
      background = "selected";
    }

    return (
      <Box pad="small" background={{ color: background, opacity: "medium" }} focusIndicator={false}>
        <Text>{optionLabel}</Text>
        {description && <Text size="small">{description}</Text>}
      </Box>
    );
  };

  return (
    <FormField
      htmlFor="dropdown-form-field"
      label={label}
      name={name}
      {...props}
    >
      <Select
        id="dropdown-form-field"
        labelKey={useCustomRender ? labelKey ?? defaultLabelKey : undefined}
        name={name}
        onChange={handleOnChange}
        onSearch={onSearch}
        options={options}
        placeholder={placeholder}
        ref={ref}
        value={value}
        valueKey={useCustomRender ? valueKey ?? defaultValueKey : undefined}
        valueLabel={valueLabel}
        {...selectProps}
      >
        {children ?? (useCustomRender ? renderOption : undefined)}
      </Select>
    </FormField>
  );
}

const DropdownFormField = React.forwardRef(DropdownFormFieldComponent);

DropdownFormField.propTypes = {
  children: PropTypes.any,
  descriptionKey: PropTypes.string,
  label: PropTypes.string,
  labelKey: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  selectProps: PropTypes.object,
  value: PropTypes.any,
  valueKey: PropTypes.string,
  valueLabel: PropTypes.any,
};

export { DropdownFormField };
