import React from "react";
import {
  string,
  arrayOf,
  node,
  shape,
  func,
  oneOfType
} from "prop-types";
import { iconProp } from "../utils/propTypes";
import ContentBoxHeaderRow from "./ContentBoxHeaderRow";
import { Box } from "./FlexBox";
import Menu from "./Menu";
import TextLabel from "./styled/TextLabel";

class ContentBoxHeader extends React.PureComponent {
  static propTypes = {
    title: node,
    children: node,
    menuOptions: arrayOf(
      oneOfType([
        func,
        shape({
          label: string.isRequired,
          icon: iconProp.isRequired,
          onClick: func.isRequired,
        }),
      ]),
    ),
  }

  static defaultProps = {
    children: undefined,
    title: undefined,
    menuOptions: undefined,
  }

  renderLeftHeader = () => (
    <Box px={10}>
      <TextLabel uppercase>
        {this.props.title || this.props.children}
      </TextLabel>
    </Box>
  )

  renderRightHeader = () => (
    this.props.menuOptions && (
      <Box px={10}>
        <Menu options={this.props.menuOptions} />
      </Box>
    )
  )

  render() {
    return (
      <ContentBoxHeaderRow>
        {this.renderLeftHeader()}
        {this.props.menuOptions && this.props.menuOptions.length ? this.renderRightHeader() : undefined}
      </ContentBoxHeaderRow>
    );
  }
}

export { ContentBoxHeaderRow };
export default ContentBoxHeader;
