import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import {
  gql,
  useMutation,
  useQuery
} from "@apollo/client";
import {
  Box,
  Button,
  CheckBox,
  Grommet,
  Text
} from "grommet";
import { Modal } from "@/cloverleaf-ui/components";
import { useCookieStorage } from "@/cloverleaf-ui/hooks";
import { themeStyle } from "@/cloverleaf-ui/theme";
import { getBrowserTimezone } from "@/lib";
import { useLocale, useToastMessage } from "@/lib/hooks";

const VERIFY_USER_TIMEZONE = gql`
  query verifyUserTimezone($browserTimezone: String!) {
    verifyUserTimezone(browserTimezone: $browserTimezone) {
      timezoneDiffers
      savedTimezone
      browserTimezone
    }
  }
`;

const UPDATE_TIMEZONE_MUTATION = gql`
  mutation updateUserProfile($input: UpdateUserProfileInput!) {
    updateUserProfile(input: $input) {
      id
      timezone
    }
  }
`;

const DONT_ASK_AGAIN_COOKIE_KEY = "dont_ask_again_timezone";

function TimezoneModal() {
  const { pushToast } = useToastMessage();
  const router = useRouter();
  const { t } = useLocale();

  const [cookie, setCookie] = useCookieStorage(
    DONT_ASK_AGAIN_COOKIE_KEY,
    false,
    // 90 days, in ms
    { maxAge: 90 * 24 * 60 * 60 * 1000, }
  );
  const [showModal, setShowModal] = useState(false);
  const [dontAskAgainChecked, setDontAskAgainChecked] = useState(true);

  const browserTimezone = getBrowserTimezone()
  const { data } = useQuery(VERIFY_USER_TIMEZONE, {
    variables: {
      browserTimezone
    },
  });

  const [updateTimezone] = useMutation(UPDATE_TIMEZONE_MUTATION);

  const handleSaveBrowserTimezone = () => updateTimezone({
    variables: {
      input: {
        timezone: data?.verifyUserTimezone?.browserTimezone,
      },
    },
  })
    .then(() => pushToast(t("update-timezone-modal.timezone-updated-success")))
    .catch(() => pushToast(t("update-timezone-modal.timezone-updated-failure"), "critical"))
    .finally(() => setShowModal(false));

  useEffect(() => {
    setShowModal(
      !cookie &&
      data &&
      data?.verifyUserTimezone?.timezoneDiffers
    );
  }, [
    cookie,
    data,
  ]);

  if (!showModal) return null;

  return (
    <Grommet theme={themeStyle}>
      <Modal hideClose={true}>
        <Box
          width={{ min: "large" }}
          margin={{
            vertical: "medium",
            horizontal: "large"
          }}
          gap="xsmall"
        >
          <Text size="xlarge">{t("update-timezone-modal.header")}</Text>
          <Text>{t("update-timezone-modal.saved-timezone", { savedTimezone: data.verifyUserTimezone?.savedTimezone })}</Text>
          <Text>{t("update-timezone-modal.location-timezone", { browserTimezone: data.verifyUserTimezone?.browserTimezone })}</Text>
          <Box gap="xxsmall" direction="row">
            <CheckBox
              checked={dontAskAgainChecked}
              onClick={() => setDontAskAgainChecked(v => !v)}
            />
            <Text>{t("update-timezone-modal.dont-ask-again")}</Text>
          </Box>
        </Box>
        <Box margin="medium" justify="between" direction="row">
          <Button
            label={t("update-timezone-modal.manage-settings")}
            uppercase
            plain
            onClick={() => {
              setShowModal(false);
              router.push("/account/settings");

              return setCookie(dontAskAgainChecked);
            }}
          />
          <Box gap="medium" direction="row">
            <Button
              label={t("update-timezone-modal.no-thanks")}
              onClick={() => {
                setCookie(dontAskAgainChecked);

                return setShowModal(false);
              }}
              plain
              uppercase
            />
            <Button
              label={<Text color="green600">{t("update-timezone-modal.yes-update")}</Text>}
              onClick={() => {
                handleSaveBrowserTimezone();

                return setCookie(dontAskAgainChecked);
              }}
              plain
              uppercase
            />
          </Box>
        </Box>
      </Modal>
    </Grommet>
  )
}

export { TimezoneModal };
