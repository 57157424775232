import { useRouter } from "next/router";
import { Locale } from "@/lib";

const useUserHasInsightSearch = () => {
  const router = useRouter();

  const isEnglishLocale = Locale.English === router.locale;
  const hasInsightSearch = isEnglishLocale;

  return { hasInsightSearch };
};

export { useUserHasInsightSearch };
