import { useCookieStorage } from "./useCookieStorage";

const useCloverleafNonce = (key, value, options = {}) => {
  const maxAge = 3 * 60 * 60 * 1000; // default to 3 hours, in ms;

  return useCookieStorage(key, value, {
    sameSite: "strict",
    ...options,
    maxAge,
  });
};

export { useCloverleafNonce };
