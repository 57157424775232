import React from "react";
import { TutorialDrop } from "../components/TutorialDrop";
import { CulturePulseQuestion } from "./Question";
import { useLocale } from "@/lib/hooks";

function CulturePulseExampleQuestion({
  onChange,
}) {
  const { t } = useLocale();

  const question = {
    number: 0,
    labelLeft: t("culture-pulse.instructions.example.label-left"),
    labelRight: t("culture-pulse.instructions.example.label-right"),
  };

  const [value, setValue] = React.useState("");
  const [walkthrough, setWalkthrough] = React.useState(-1);
  const [refVisible, setRefVisible] = React.useState(false);

  const targetRef = React.useRef();

  React.useEffect(() => {
    if (!refVisible) {
      return;
    }

    setWalkthrough(0);
  }, [refVisible]);

  const tutorial = [
    {
      tip: t("culture-pulse.instructions.tooltip"),
    },
  ];

  const handleOnChange = (value) => {
    setValue(value);

    // Tutorial Specific
    const nextStep = walkthrough + 1;

    if (nextStep < tutorial.length) {
      return setWalkthrough(nextStep);
    }

    setWalkthrough(nextStep);

    return onChange(value);
  };

  return (
    <React.Fragment>
      <CulturePulseQuestion
        ref={el => {
          targetRef.current = el;
          setRefVisible(true);
        }}
        onChange={handleOnChange}
        question={question}
        selected={value}
        tutorial
      />
      {tutorial[walkthrough] && targetRef && (
        <TutorialDrop
          margin={{ top: "small" }}
          target={targetRef.current}
        >
          {tutorial[walkthrough].tip}
        </TutorialDrop>
      )}
    </React.Fragment>
  );
}

export { CulturePulseExampleQuestion };
