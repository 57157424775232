import React from "react";
import PropTypes from "prop-types";
import { useMutation, useQuery } from "@apollo/client";
import {
  Box,
  Button,
  Form,
  FormField,
  Heading,
  ResponsiveContext,
  Select,
  Text,
  TextInput,
} from "grommet";
import { FormClose } from "grommet-icons";
import {
  Divider,
  Message,
  Modal,
  TeamInviteLinkForm
} from "@/cloverleaf-ui/components";
import { OrgInviteLinkForm } from "@/cloverleaf-ui/components/organisms/OrgInviteLinkForm";
import { isMobile, validate } from "@/cloverleaf-ui/utils";
import { EmailTag } from "../OrganizationInvitationModal/components/EmailTag";
import {
  ADD_USERS_TO_ORGANIZATION_MUTATION,
  ADD_USERS_TO_TEAM_MUTATION,
  GET_ORGANIZATION_PERMISSIONS_QUERY,
  ROLE,
  roleOptions
} from "./utils";
import { EVENT } from "@/components/utils/constants";
import { track } from "@/lib";
import { useLocale } from "@/lib/hooks";

function AddUsersToOrgTeamModal({
  onCancel = () => undefined,
  onFailure = () => undefined,
  onSuccess = () => undefined,
  organizationId = undefined,
  inviteChannel = EVENT.InviteSent.props.channel.adminDashboard,
  ...props
}) {
  const { t } = useLocale();

  const [addUsersToOrganization, { loading: addingToOrganization }] = useMutation(ADD_USERS_TO_ORGANIZATION_MUTATION);
  const [addUsersToTeam, { loading: addingToTeam }] = useMutation(ADD_USERS_TO_TEAM_MUTATION);
  const { data, loading } = useQuery(GET_ORGANIZATION_PERMISSIONS_QUERY, {
    variables: { organizationId },
  });

  const addingUsers = addingToOrganization || addingToTeam;
  const organizationName = data?.organization?.name;
  const organizationTeams = data?.organization?.teams || [];
  const canInviteMembers = data?.check?.canInviteMembers;
  const defaultTeamId = data?.currentUser?.defaultTeam?.id;
  const canManageOrganization = data?.organization?.callerCanManage;
  const orgLink = data?.organization?.inviteOrganizationLink;
  const showOrgInviteLink = canManageOrganization || (canInviteMembers && orgLink);
  const showTeamInviteLink = canInviteMembers && selectedTeam?.status && selectedTeam?.status !== 3
  const currentOrgUsers = data?.organization?.users;
  const isWhiteListedEnabled = data?.organization?.whitelist?.enabled;
  const whiteList = data?.organization?.whitelist?.whitelistDomains;
  const hasActiveDomains = whiteList?.some(domain => domain.status === "ACTIVE");

  const [error, setError] = React.useState();
  const [emailInput, setEmailInput] = React.useState("");
  const [isTrustedDomains, setIsTrustedDomains] = React.useState(true);
  const [newMemberList, setNewMemberList] = React.useState([]);
  const [selectedTeam, setSelectedTeam] = React.useState();
  const [role, setRole] = React.useState(ROLE.MEMBER);
  const screen = React.useContext(ResponsiveContext);

  const handleOnSubmit = () => {
    const users = newMemberList.map(item => ({ email: item.email }));

    const isAdmin = role === ROLE.ADMIN;

    if (selectedTeam) {
      return addUsersToTeam({
        variables: {
          input: {
            admins: isAdmin ? users : undefined,
            members: !isAdmin ? users : undefined,
            teamId: selectedTeam.id,
            channel: inviteChannel,
          },
        },
      })
        .then(() => onSuccess())
        .catch(() => onFailure());
    }

    return addUsersToOrganization({
      variables: {
        input: {
          admins: isAdmin ? users : undefined,
          members: !isAdmin ? users : undefined,
          organization: {
            id: organizationId,
          },
          channel: inviteChannel,
        },
      },
    })
      .then(() => onSuccess())
      .catch(() => onFailure());
  };

  const onRemove = (item) => {
    const newEmailList = newMemberList.filter(i => i !== item);
    if (!newEmailList.some(email => email.isTrustedEmail === false)) {
      setIsTrustedDomains(true);
    }

    setNewMemberList(newEmailList);
  };

  const handleOnChange = event =>
    setEmailInput(event.target.value);

  const handleOnCopyOrgInviteLink = () =>
    track(EVENT.OrgInviteLinkCreated.name, {
      channel: "addUserToOrganization",
    });

  const handleOnCopyTeamInviteLink = () =>
    track(EVENT.TeamInviteLinkCopied.name, {
      channel: "addUserToOrganization",
    });

  /**
   * Validates and adds email input at specific keys
   */
  const handleOnKeyDown = (event) => {
    if (event.key === "Enter"
      || event.key === ","
      || event.key === " "
      || event.key === "Tab"
    ) {
      // preventDefault()...
      // on "Enter" - prevents onSubmit form submission
      // on "comma" or "space" - prevents onChange from adding input to state
      // on "tab" - prevents focus from leaving input
      event.preventDefault();

      if (!emailInput || validate.email(emailInput)) {
        const errorMessage = t("add-user-to-org-modal.error.invalid-input");

        return setError(errorMessage);
      }

      if (currentOrgUsers?.some(email => email?.email === emailInput)) {
        const errorMessage = t("add-user-to-org-modal.error.user-in-org");

        return setError(errorMessage);
      }

      if (newMemberList.some(user => user?.email && user.email.toLowerCase() === emailInput.toLowerCase())) {
        const errorMessage = t("add-user-to-org-modal.error.email-in-list");

        return setError(errorMessage);
      }

      /**
       * "badcheck@whitelisted.domain.xyz.com".includes("whitelisted.domain") will unexpectedly return true
       * @todo - create a utility to accurately validate email against whitelisted domains
       */
      if (isWhiteListedEnabled && hasActiveDomains) {
        if (whiteList?.some(email => emailInput.includes(email?.domain) && email?.status === "ACTIVE")) {
          setNewMemberList([...newMemberList, { email: emailInput, isTrustedEmail: true }]);
        }
        else {
          setIsTrustedDomains(false);
          setNewMemberList([...newMemberList, { email: emailInput, isTrustedEmail: false }]);
        }
      }
      else {
        setNewMemberList([...newMemberList, { email: emailInput, isTrustedEmail: true }]);
      }

      setError("");
      setEmailInput("");
    }

    return null;
  };

  const renderRoleOptions = React.useCallback(option => (
    <Box gap="xxsmall" pad="medium" width="medium">
      <Text size="medium">
        {t(roleOptions[option]?.label)}
      </Text>
      {roleOptions[option]?.description && (
        <Text size="small">
          {t(roleOptions[option]?.description)}
        </Text>
      )}
    </Box>
  ), [t]);

  return (
    <Modal
      background={isMobile(screen) ? "white" : "transparent"}
      loading={loading}
      hideClose
      {...props}
    >
      <Box
        background="white"
        gap="large"
        margin={{ bottom: "large" }}
        pad="large"
        round="xsmall"
        width="xlarge"
      >
        <Box direction="column" gap="medium" justify="between">
          <Box direction="row" justify="between">
            <Heading level="2" margin="none">{t("invite-teammates-to", { name: organizationName })}</Heading>
            <Button icon={<FormClose />} onClick={onCancel} plain />
          </Box>
        </Box>
        <Form onSubmit={handleOnSubmit}>
          <FormField
            error={error}
            htmlFor="email-input-field"
            label={t("add-user-to-org-modal.email-input.label")}
          >
            <Box
              align="center"
              direction="row"
              wrap
            >
              {newMemberList.map(email =>
                <EmailTag key={email?.email} teamMember={email} onRemove={onRemove} />)
              }
              <Box flex width={{ min: "small" }}>
                <TextInput
                  autoFocus
                  focusIndicator={false}
                  onChange={event => handleOnChange(event)}
                  onKeyDown={event => handleOnKeyDown(event)}
                  value={emailInput}
                  placeholder={t("add-user-to-org-modal.email-input.placeholder")}
                  plain
                />
              </Box>
            </Box>
          </FormField>
          {organizationTeams.length > 0 && (
            <Box width="278px">
              <FormField label={`${t("team")} (${t("optional")})`} htmlFor="team-select-field">
                <Select
                  id="team-select-field"
                  clear
                  labelKey={option =>
                    option.id === defaultTeamId
                      ? `${option.name} (${t("default-team-label")})`
                      : option.name
                  }
                  onChange={({ option }) => setSelectedTeam(option)}
                  options={organizationTeams}
                  placeholder={t("select-a-team")}
                  primary
                  value={selectedTeam}
                />
              </FormField>
            </Box>
          )}
          {selectedTeam && (
            <Box width="278px">
              <FormField label={t("role-label")} htmlFor="role-select-field">
                <Select
                  id="role-select-field"
                  onChange={({ option }) => setRole(option)}
                  options={Object.keys(roleOptions)}
                  value={role}
                >
                  {renderRoleOptions}
                </Select>
              </FormField>
            </Box>
          )}
          {!isTrustedDomains && (
            <Message alignSelf="center" compact kind="warning" margin={{ bottom: "xlarge" }}>
              {t("add-user-to-org-modal.trusted-doamin-warning")}
            </Message>
          )}
          <Box direction="row" gap="medium" justify="end">
            <Button
              label={(
                <Text color="grey800" style={{ textTransform: "uppercase" }}>
                  {t("cancel")}
                </Text>
              )}
              onClick={onCancel}
              plain
            />
            <Button
              disabled={newMemberList.length < 1 || addingUsers}
              label={(
                <Text color="green600" style={{ textTransform: "uppercase" }}>
                  {t("invite")}
                </Text>
              )}
              onClick={handleOnSubmit}
              plain
            />
          </Box>
        </Form>
      </Box>
      {isMobile(screen) && (
        <Divider color="grey600" />
      )}
      {(showOrgInviteLink || showTeamInviteLink) && (
        <Box
          background="white"
          gap="large"
          pad="large"
          round="xsmall"
        >
          <Box gap="xsmall">
            <Heading level="2" margin="none">
              {t("add-user-to-org-modal.shareable-invite-link.header")}
            </Heading>
            <Text>
              {showOrgInviteLink ?
                t("add-user-to-org-modal.shareable-invite-link.org-message") :
                t("add-user-to-org-modal.shareable-invite-link.message")}
            </Text>
          </Box>
          {selectedTeam ? (
            <TeamInviteLinkForm
              onCopy={handleOnCopyTeamInviteLink}
              teamId={selectedTeam.id}
              teamLink={selectedTeam.inviteLink}
            />
          ) : (
            <OrgInviteLinkForm
              onCopy={handleOnCopyOrgInviteLink}
              orgId={organizationId}
              orgLink={orgLink}
            />
          )}
        </Box>
      )}
    </Modal>
  );
}

AddUsersToOrgTeamModal.propTypes = {
  inviteChannel: PropTypes.string,
  onCancel: PropTypes.func,
  onFailure: PropTypes.func,
  onSuccess: PropTypes.func,
  organizationId: PropTypes.string,
};

export { AddUsersToOrgTeamModal };
