/* eslint-disable no-nested-ternary */
import React from "react";
import PropTypes from "prop-types";
import useTranslation from "next-translate/useTranslation";
import {
  Box,
  List as GrommetList,
  Text,
  TextInput,
} from "grommet";
import { Icon } from "../Icon";
import { LoadingIndicator } from "../LoadingIndicator";

/**
 * Default filters by text
 * @param {*} item
 * @param {*} query - search string
 * @returns {array} - filtered items
 */
const defaultFilterFn = (item, query) =>
  item?.toLowerCase().indexOf(query?.toLowerCase()) !== -1;

function List({
  action = undefined,
  autoFocus = false,
  children = undefined,
  data = undefined,
  filterFn: customFilterFn = undefined,
  filterInputPlaceholder = undefined,
  listProps = {},
  loading = false,
  // Capture onClick
  // eslint-disable-next-line unused-imports/no-unused-vars
  onClick = undefined,
  onFilter = undefined,
  searchable: showSearchInput = false,
  searchContainerProps = {},
  emptyListMessage = undefined,
  ...props
}) {
  const [listItems, setListItems] = React.useState(data);
  const [filterString, setFilterString] = React.useState("");
  const { t } = useTranslation();

  const filterList = (search, list) => {
    if (!showSearchInput && !onFilter) {
      // No filtering involved
      return list;
    }

    let filtered = [];

    if (typeof list === "undefined" || list.length === 0) {
      return filtered;
    }

    if (typeof customFilterFn === "function") {
      filtered = list.filter(item => customFilterFn(item, search));
    }
    else {
      filtered = list.filter((item) => {
        if (typeof item === "string") {
          return defaultFilterFn(item, search);
        }

        if (typeof item[listProps?.primaryKey] === "string") {
          return defaultFilterFn(item[listProps?.primaryKey], search);
        }

        // eslint-disable-next-line no-console
        console.warn("Cannot filter against non-string item. Check data prop or pass a custom filterFn.");

        return true;
      });
    }

    return filtered;
  };

  const handleOnFilter = (search) => {
    const filtered = filterList(search, data);

    setListItems(filtered);

    if (typeof onFilter === "function") {
      onFilter(search);
    }

    return setFilterString(search);
  };

  React.useEffect(() => {
    /**
     * Automatically filter list.
     * Empty filter will render the entire list as would be expected when not searchable.
     */
    setListItems(filterList(filterString, data));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Box {...props}>
      {(showSearchInput || onFilter) && (
        <Box
          background="white"
          flex={false}
          {...searchContainerProps}
        >
          <TextInput
            autoFocus={autoFocus}
            a11yTitle={filterInputPlaceholder || t("search-items")}
            icon={<Icon color="grey600" icon="search" size="1x" />}
            onChange={e => handleOnFilter(e.target.value)}
            placeholder={filterInputPlaceholder}
            value={filterString}
          />
        </Box>
      )}
      {loading ? (
        <LoadingIndicator />
      ) : listItems?.length === 0 ? (
        <Box
          pad={{
            vertical: "small",
            // Cleaner spaced sidebar panels
            top: !showSearchInput ? "none" : undefined,
          }}
        >
          <Text alignSelf="center" color="grey500">{emptyListMessage || t("empty-list")}</Text>
        </Box>
      ) : (
        <GrommetList
          action={action}
          border={false}
          data={listItems}
          // item container margin
          margin={showSearchInput ? "xsmall" : "none"}
          // item padding
          pad="xsmall"
          {...listProps}
        >
          {children}
        </GrommetList>
      )}
    </Box>
  );
}

List.propTypes = {
  action: PropTypes.any,
  autoFocus: PropTypes.bool,
  children: PropTypes.any,
  data: PropTypes.array,
  filterFn: (props, propName) => {
    const fn = props[propName];
    if (fn && (typeof fn !== "function" || fn?.length !== 2)) {
      return new Error(`${propName} must be an Array.filter callbackFn of fn(element, queryString)`);
    }

    return null;
  },
  filterInputPlaceholder: PropTypes.string,
  listProps: PropTypes.object,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  onFilter: PropTypes.func,
  searchable: PropTypes.bool,
  searchContainerProps: PropTypes.object,
  emptyListMessage: PropTypes.string,
};

export { List };
