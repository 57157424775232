import React from "react";
import {
  string,
  func,
  oneOfType,
  arrayOf,
  number
} from "prop-types";
import noop from "lodash/noop";
import styled from "styled-components";
import { withTooltip } from "../hoc/withTooltip";
import { Flex, Box } from "./FlexBox";
import Icon from "./Icon";
import StyledButton from "./styled/Button";
import TextLabel from "./styled/TextLabel";

const CircularButton = styled(StyledButton)`
  border-radius: 50%;
  min-height: unset;
  width: ${props => (props.circleSize ? `${props.circleSize}px` : "60px")};
  height: ${props => (props.circleSize ? `${props.circleSize}px` : "60px")};
  padding: 0;
  font-size: 1rem;
`;

const CircleButton = withTooltip(({
  onClick, label, icon = ["fal", "plus"], iconSize = "3x", circleSize, uppercase, ...otherButtonProps
}) => (
  <Flex onClick={(otherButtonProps.disabled ? noop : onClick)} justify="flex-start" align="center" role="button" style={{ cursor: "pointer" }}>
    <Box shrink={0}>
      <CircularButton primary {...otherButtonProps} circleSize={circleSize}>
        <Icon icon={icon} size={iconSize} />
      </CircularButton>
    </Box>
    {label && <Flex justify="center" align="center" ml={1}><TextLabel uppercase={uppercase}>{label}</TextLabel></Flex>}
  </Flex>
));

CircleButton.propTypes = {
  onClick: func.isRequired,
  icon: oneOfType([arrayOf(string.isRequired), string.isRequired]),
  iconSize: string,
  circleSize: number,
};

export default CircleButton;
