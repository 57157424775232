import React, { useMemo } from "react";
import { useRouter } from "next/router";
import { gql, useLazyQuery } from "@apollo/client";
import { debounce } from "lodash";
import { Box, TextInput } from "grommet";

const EMPLOYEE_SEARCH = gql`
  query employeeSearch($search: String!) {
    employeeSearch(search: $search) {
      results {
        text
        link
      }
    }
  }
`;

function EmployeeSearch() {
  const router = useRouter();
  const [search, setSearch] = React.useState("");
  const [searchResults, setSearchResults] = React.useState([]);

  const [runEmployeeSearch] = useLazyQuery(EMPLOYEE_SEARCH);

  const handleKeyChange = useMemo(() => debounce((search) => {
    if (!search) {
      setSearchResults([]);

      return;
    }

    runEmployeeSearch({
      variables: {
        search,
      },
    })
      .then(({ data }) => {
        setSearchResults(data?.employeeSearch?.results || []);
      })
      .catch(e => {
        // debugger
        console.log(e);
      });
  }, 200), []);

  return (
    <Box background="black" pad="large" round="xsmall">
      <TextInput
        width="600px"
        autoFocus
        value={search}
        onChange={e => {
          setSearch(e.target.value);
          handleKeyChange(e.target.value);
        }}
        onSuggestionSelect={(sel) => {
          const selectedResult = searchResults[sel.suggestion.value];
          if (sel.metaKey) {
            window.open(selectedResult.link, "_blank", "noopener,noreferrer");
          } else {
            router.push(selectedResult.link);
          }
        }}
        suggestions={searchResults.map(({ text }, i) => ({
          label: text,
          value: i,
        }))}
        dropProps={{
          plain: true,
          background: "black",
        }}
      />
    </Box>
  );
}

export default EmployeeSearch;
