import React from "react";
import { Button } from "@/components/v2/Button";

/**
 * Grommet Button that handles closing ToolTip onClick
 * @param {GrommetButtonProps} props
 */
export function TipButton(props) {
  const [over, setOver] = React.useState(false);

  const handleOnMouseEnter = React.useCallback(() => setOver(true), [setOver]);
  const handleOnMouseLeave = React.useCallback(() => setOver(false), [setOver]);

  const handleOnClick = () => {
    props.onClick();
    setOver(false);
  };

  return (
    <Button
      {...props}
      onClick={props.onClick ? handleOnClick : undefined}
      onMouseLeave={handleOnMouseLeave}
      onMouseEnter={handleOnMouseEnter}
      tip={{
        ...props.tip,
        content: over ? props.tip?.content : undefined,
        dropProps: over ? props.tip?.dropProps : undefined,
      }}
    />
  );
}
