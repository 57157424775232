import { gql } from "@apollo/client";

export const contentDebugFragmentTraitInterface = gql`
  fragment ContentDebugFragmentTraitInterface on TraitInterface {
    contentDebug {
      id
      locale
      message
    }
  }
`;

export const contentDebugFragmentEnneagramSecondaryTrait = gql`
  fragment ContentDebugFragmentEnneagramSecondaryTrait on EnneagramSecondaryTrait {
    contentDebug {
      id
      locale
      message
    }
  }
`;

export const contentDebugFragmentUserTrait = gql`
  fragment ContentDebugFragmentUserTrait on UserTrait {
    contentDebug {
      id
      locale
      message
    }
  }
`;

export const contentDebugFragmentTraitContent = gql`
  fragment ContentDebugFragmentTraitContent on TraitContent {
    contentDebug {
      id
      locale
      message
    }
  }
`;
