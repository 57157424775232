import { Children } from "react";
import { default as NextTrans } from "next-translate/Trans";
import useTranslation from "next-translate/useTranslation";

const Trans = ({ children, tOptions, ...props }) => {
  const components = Children.toArray(children);

  return <NextTrans components={components} values={tOptions} {...props} />;
};

const useLocale = (namespace) => {
  const { t, lang } = useTranslation(namespace);

  return { t, lang };
};

const serverSideTranslations = () => undefined;

export { serverSideTranslations, Trans, useLocale };
