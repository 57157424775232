import React from "react";
import { func } from "prop-types";
import { GREY } from "../utils/theme";
import Icon from "./Icon";
import TextButton from "./TextButton";

function EditIconButton({ onClick }) {
  return (
    <TextButton onClick={onClick}>
      <Icon icon="edit" size="1x" color={GREY} />
    </TextButton>
  );
}

EditIconButton.propTypes = {
  onClick: func.isRequired,
};

export default EditIconButton;
