import React from "react";
import PropTypes from "prop-types";
import { Notification } from "grommet";

function Toast({
  // Array of Anchor prop objects
  actions = undefined,
  autoClose = true,
  message = undefined,
  onClose = undefined,
  status = "normal",
  time = 3000,
  title = undefined,
  toast = false,
  ...props
}) {
  const [visible, setVisible] = React.useState(true);

  const content = { message, title };

  /**
   * If actions are included without a message, pass the title as the
   * message prop for more consistent horizontal alignment.
  */
  if (actions?.length && !content.message) {
    content.message = content.title;

    delete content.title;
  }

  const close = React.useCallback(
    (event) => {
      setVisible(false);
      if (onClose) onClose(event);
    },
    [onClose],
  );

  React.useEffect(() => {
    if (autoClose) {
      const timer = setTimeout(
        close,
        time,
      );

      return () => clearTimeout(timer);
    }

    return undefined;
  }, [autoClose, close, time]);

  return (
    <Notification
      {...props}
      actions={actions}
      onClose={onClose}
      status={status}
      toast={toast}
      visible={visible}
      {...content}
    />
  );
}

Toast.propTypes = {
  actions: PropTypes.array,
  autoClose: PropTypes.bool,
  message: PropTypes.string,
  onClose: PropTypes.func,
  status: PropTypes.oneOf(["normal", "critical", "warning", "info"]),
  title: PropTypes.string,
  toast: PropTypes.bool,
  time: PropTypes.number,
};

export { Toast };
