import React from "react";
import {
  bool,
  string,
  func,
  oneOfType,
  number
} from "prop-types";
import styled from "styled-components";
import {
  WHITE,
  GREY,
  GREEN,
  RED,
  DEFAULT_FONT,
  DARK_GREY,
  MEDIUM_SIZE
} from "../../utils/theme";
import { Flex } from "../FlexBox";

const StyledTextArea = styled.textarea`
  background: none;
  border: none;
  min-height: ${props => props.minHeight || "32px"};
  width: 100%;
  text-align: ${props => props.textAlign};
  resize: vertical;
  outline: none;

  &:focus {
    outline: none;
  }

  ::placeholder {
    color: ${DARK_GREY};
  }
`;

const StyledWrapper = styled(Flex)`
  background: ${WHITE};
  border: 1px solid ${GREY};
  border-color: ${props => (props.error ? RED : GREY)};
  border-radius: 5px;
  font-family: ${DEFAULT_FONT};
  font-size: ${MEDIUM_SIZE};
  box-sizing: border-box;
  padding: 6px;

  &:focus-within {
    border-color: ${GREEN};
  }

  &:hover {
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  }

  ::placeholder {
    color: ${DARK_GREY};
  }
`;

const buildHandleChange = onChange => e => onChange(e.target.value);

function TextArea(props) {
  const handleChange = buildHandleChange(props.onChange);

  return (
    <StyledWrapper align="center" px={5} w={1} error={props.error}>
      <StyledTextArea
        id={props.id || props.name}
        minHeight={props.height}
        textAlign={props.textAlign}
        name={props.name}
        value={props.value}
        onChange={handleChange}
        placeholder={props.placeholder}
        maxLength={props.maxLength}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        onDragStart={props.onDragStart}
        onDrop={props.onDrop}
        disabled={props.disabled}
      />
    </StyledWrapper>
  );
}

TextArea.propTypes = {
  id: string,
  name: string.isRequired,
  value: string.isRequired,
  onChange: func.isRequired,
  placeholder: string,
  maxLength: oneOfType([string, number]),
  error: bool,
  disabled: bool,
  onBlur: func,
  onFocus: func,
  onDragStart: func,
  onDrop: func,
  height: string,
  textAlign: string,
};

export default TextArea;
