import React, { useRef, useState } from "react";
import { string } from "prop-types";
import styled from "styled-components";
import { copyToClipboard, canCopyProgrammatically } from "../utils";
import { COPIED, CLICK_TO_COPY } from "../utils/strings";
import { DARK_GREY, GREEN } from "../utils/theme";
import { Flex } from "./FlexBox";
import Text from "./styled/Text";

const CopyTextContainer = styled(Flex)`
  cursor: pointer;
  border: 2px solid ${GREEN};
  border-radius: 5px;
`;

const InvisibleTextArea = styled.textarea`
  outline: none;
  border: 0;
  margin: 0;
  resize: none;
  cursor: pointer;
  text-align: center;
  width: 100%;
`;

function CopyText({ value }) {
  const [copySuccess, setCopySuccess] = useState(false);
  const textAreaRef = useRef(null);

  return (
    <CopyTextContainer p={1} justify="center" align="center" column onClick={copyToClipboard(textAreaRef, () => setCopySuccess(true))}>
      <Text center noMargin large style={{ width: "100%" }}>
        <InvisibleTextArea ref={textAreaRef} value={value} readOnly />
      </Text>
      {canCopyProgrammatically() && (
        <Text center color={DARK_GREY} small noMargin style={{ userSelect: "none" }}>
          {copySuccess ? COPIED : CLICK_TO_COPY}
        </Text>
      )}
    </CopyTextContainer>
  );
}

CopyText.displayName = "CopyText";

CopyText.propTypes = {
  value: string.isRequired,

};

export default CopyText;
