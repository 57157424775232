import add from "lodash/fp/add";
/* new z-index */
const addOne = add(1);
const subtractOne = add(-1);

export const sameAs = z => z;
export const above = z => addOne(z);
export const below = z => subtractOne(z);

export const BACKGROUND = 0;
export const LAYOUT = 100;
export const DROPDOWNS = 200;
export const SIDEBAR = 300;
export const MODAL = 400;
export const ABOVE_ALL = 2147482999; // 1 below last known value of Intercom
