import { css } from "styled-components";
import { themeStyle } from "../theme";

export const getFilledIcon = css`
  path[fill="none"] {
    fill: ${props => themeStyle.global.colors[props?.color] || themeStyle.global.colors.brand};
  }
  fill: #fff;
  stroke: #fff;
`;
