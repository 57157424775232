import React from "react";
import { string, node } from "prop-types";
import { GREY_OPAQUE } from "../utils/theme";
import { Box } from "./FlexBox";
import Icon from "./Icon";
import A from "./styled/A";
import Text from "./styled/Text";

function QuestionMark({ text, html, url }) {
  const renderIcon = () => (
    <Icon
      icon={["fas", "question-circle"]}
      color={GREY_OPAQUE}
      tooltip={{
        html: html || (<Box maxWidth={320}><Text small>{text}</Text></Box>),
        theme: "light",
      }}
    />
  );

  if (!url) {
    return renderIcon();
  }

  return (
    <A href={url} target="_blank" aria-label={text}>
      {renderIcon()}
    </A>
  );
}

QuestionMark.propTypes = {
  text: string.isRequired,
  url: string,
  html: node,
};

export default QuestionMark;
