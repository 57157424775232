import { track } from "../analytics";

function flattenAnalyticsObject(obj, parentKey = "") {
  let flattenedObject = {};

  for (let key in obj) {
    if (typeof obj[key] === "object" && obj[key] !== null) {
      const nestedKeys = flattenAnalyticsObject(obj[key], `${parentKey}${key}_`);
      flattenedObject = { ...flattenedObject, ...nestedKeys };
    } else {
      if (obj[key] !== undefined) {
        flattenedObject[`${parentKey}${key}`] = obj[key];
      }
    }
  }

  return flattenedObject;
}

export const sendLog = ({ eventName, ...props }, isDebugMode = false) => {
  const flatten = flattenAnalyticsObject(props);

  if (isDebugMode) {
    console.log("sending log:", eventName, flatten);
  }

  return track(eventName, flatten);
};
