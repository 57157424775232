import React from "react";
import PropTypes from "prop-types";
import Link from "next/link";
import styled from "styled-components";
import {
  Anchor,
  Box,
  FormField,
  Text,
  TextInput,
} from "grommet";
import { getThemeColor } from "@/cloverleaf-ui/theme";

const Suffix = styled(Text)`
  line-height: normal;
  padding: 9px 9px 9px 0;
`;

const Prefix = styled(Text)`
  line-height: normal;
  padding: 9px 0px 9px 9px;
  text-wrap: nowrap;
`;

const OrganizationFormField = React.forwardRef(function OrganizationFormField({
  defaultValue = undefined,
  disabled = undefined,
  error = undefined,
  inputProps = {},
  label = undefined,
  link = undefined,
  name = undefined,
  onChange = undefined,
  prefix = undefined,
  suffix = undefined,
  validate = undefined,
  value = undefined,
  placeholder = undefined,
  ...props
}, ref) {
  let labelComponent = label;

  const handleOnChange = (event) => {
    const e = {
      ...event,
      target: {
        ...event.target,
        value: event.target.value?.trim(),
      },
    };

    return onChange?.(e);
  }

  if (link) {
    labelComponent = (
      <Box align="center" direction="row" justify="between">
        <Text>{label}</Text>
        <Link
          href={link?.href}
          passHref
          legacyBehavior
        >
          <Anchor>
            {link?.label}
          </Anchor>
        </Link>
      </Box>
    );
  }

  return (
    <FormField
      contentProps={{ style: { flexDirection: "row" } }}
      disabled={disabled}
      error={error && <Text color="status-critical" size="xsmall">{error}</Text>}
      htmlFor="organization-form-field"
      label={labelComponent}
      name={name}
      validate={disabled ? [] : validate}
      {...props}
    >
      {prefix && <Prefix>{prefix}</Prefix>}
      <TextInput
        readOnly={disabled}
        id="organization-form-field"
        name={name}
        onChange={handleOnChange}
        placeholder={placeholder}
        ref={ref}
        style={{
          color: getThemeColor("status-ok"),
          lineHeight: "normal",
          paddingRight: suffix ? 0 : undefined,
          paddingLeft: prefix ? 0 : undefined,
        }}
        textAlign={suffix ? "end" : "start"}
        value={value}
        defaultValue={defaultValue}
        {...inputProps}
      />
      {suffix && <Suffix>{`.${suffix}`}</Suffix>}
    </FormField>
  );
});

OrganizationFormField.propTypes = {
  defaultValue: PropTypes.string,
  error: PropTypes.string,
  inputProps: PropTypes.object,
  label: PropTypes.string,
  link: PropTypes.shape({
    href: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape(),
    ]),
    label: PropTypes.string,
  }),
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  validate: PropTypes.array,
  value: PropTypes.string,
};

export { OrganizationFormField };
