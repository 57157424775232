import { useMutation, gql } from "@apollo/client";
import * as Sentry from "@sentry/nextjs";
import { getBaseUrl, queryStringify } from "../../components/utils";
import { publicRuntimeConfig } from "../config";
import { BASE_URL } from "../constants";

const trustException = ({
  message = "Unknown Error",
  status = "Unknown",
}) => ({
  message,
  status,
});

export const TRUST_STATUS = {
  INVALID: "INVALID",
  SUCCESS: "SUCCESS",
};

export const TRUST_ACCOUNTS_QUERY = gql`
  query trustAccounts {
    currentUser {
      id
      trustAccounts {
        active
        email
        logo
        name
        subdomain
        userId
      }
    }
  }
`;

export const TRUST_REST_QUERY = gql`
  query trust(
    $client_id: client_id,
    $redirect_uri: redirect_uri,
    $response_type: response_type,
    $scope: scope,
    $state: state,
  ) {
    trust(
      client_id: $client_id,
      redirect_uri: $redirect_uri,
      response_type: $response_type,
      scope: $scope,
      state: $state,
    )
      @rest( type: "getTrust", path: "/trust?{args}", method: "GET")
  }
`;

export const TRUST_MUTATION = gql`
  mutation trust($input: input) {
    trust(input: $input)
      @rest( type: "postTrust", path: "/trust", method: "POST") {
        accounts
        message
        status
    }
  }
`;

export const TRUST_DELETE_MUTATION = gql`
  mutation trust($code: code) {
    trust(code: $code)
      @rest( type: "deleteTrust", path: "/trust?{args}", method: "DELETE") {
        deleted
        status
    }
  }
`;

/**
 * Summary
 * The client generates a state.nonce and encoded redirect_uri
 * Sends to API and receives a 302 to the redirect_uri for auth with a code
 * Once the account is authenticated the client POSTS to confirm the trust
 *
 * GET
 * Example: http://{{url}}/api/trust?client_id=a146feba-9dc3-4988-91ac-3f661a8ca7e6&response_type=code&state=arb&scope=crossaccount&redirect_uri=d3d3LWxvY2FsLmNsb3ZlcmxlYWYubWU6MzAwMC9hY2NvdW50L3NpZ25pbj9yZWY9YXBwLmNsb3ZlcmxlYWYubWUvYWNjb3VudC9saW5rJmZsb3dFbnRyeT1BZGRTZXNzaW9u
 * client_id      = 'a146feba-9dc3-4988-91ac-3f661a8ca7e6' <constant uuid, use this value>
 * response_type  = 'code'
 * state          = 'arb' <generated by ui, and later confirmed by UI>
 * scope          = 'crossaccount'
 * redirect_uri   = 'd3d3LWxvY2FsLmNsb3ZlcmxlYWYubWU6MzAwMC9hY2NvdW50L3NpZ25pbj9yZWY9YXBwLmNsb3ZlcmxlYWYubWUvYWNjb3VudC9saW5rJmZsb3dFbnRyeT1BZGRTZXNzaW9u'
 *                  <base64encodedUri>
 *                  www-local.cloverleaf.me:3000/signin?ref=app.cloverleaf.me/account/link&flowEntry=AddSession
 * Response
 *  302 to: www-local.cloverleaf.me:3000/signin?ref=app.cloverleaf.me/account/link&flowEntry=AddSession&state=arb&code=yB23bZS6USgk2OCk_3ZEncQNboBuuqtlkfVp9t8K-3WpLCv0cdAQS5FJd1LkjTFKcdb_BrEH8ZDjzy0G
 *
 * POST
 * client_id      = 'a146feba-9dc3-4988-91ac-3f661a8ca7e6' <constant uuid, use this value>
 * code           = <code generated by api response above>
 *
 * DELETE
 */
const useGetTrust = () => {
  // const [
  //   trust,
  //   { data, loading, error },
  // ] = useLazyQuery(TRUST_REST_QUERY, { ...options });

  const handleTrust = async ({
    clientId = publicRuntimeConfig.CLOVERLEAF_CLIENT_ID,
    redirectUrl = `${getBaseUrl()}signin?ref=/account/link-accounts&flowEntry=AddSession`,
    responseType = "code",
    scope = "crossaccount",
    state = "arb", // default for test, should be generated and stored
    sourceSubdomain,
  } = {}) => {
    try {
      let encodedRedirectUrl;

      if (typeof redirectUrl === "string") {
        const src = queryStringify({ sourceSubdomain });

        encodedRedirectUrl = window.btoa(`${redirectUrl}&${src}`);
      }
      else {
        // support redirectUrl as an object

        const {
          pathname = "/signin",
          query = {},
          subdomain,
        } = redirectUrl;

        const builtUrl = getBaseUrl({
          subdomain,
          path: pathname,
          query: queryStringify({ flowEntry: "AddSession", sourceSubdomain, ...query }),
        });

        encodedRedirectUrl = window.btoa(builtUrl);
      }

      // const response = await trust({
      //   variables: {
      //     client_id: clientId,
      //     redirect_uri: encodedRedirectUrl,
      //     response_type: responseType,
      //     scope,
      //     state,
      //   },
      // });

      // return response; // return response, but should be a 302

      window.location.href = `${BASE_URL}/api/trust?client_id=${clientId}&&redirect_uri=${encodedRedirectUrl}&scope=${scope}&state=${state}&response_type=${responseType}`;
    }
    catch (errorResponse) {
      const { message, status } = errorResponse?.networkError?.result || {};

      Sentry.captureException(errorResponse, {
        extra: {
          message,
          status,
        },
        tags: {
          function: "useGetTrust",
        },
      });

      throw trustException({ message, status });
    }
  };

  return {
    // data,
    // error,
    // loading,
    trust: handleTrust,
    trustStatus: TRUST_STATUS,
  };
};

const usePostTrust = (options) => {
  const [
    trust,
    { data, loading, error },
  ] = useMutation(TRUST_MUTATION, { ...options });

  const handleTrust = async ({
    clientId = publicRuntimeConfig.CLOVERLEAF_CLIENT_ID,
    code,
  }) => {
    try {
      const {
        data: response,
      } = await trust({
        variables: {
          input: {
            clientId,
            code,
          },
        },
      });

      const {
        accounts,
        message,
        status,
      } = response.trust;

      return {
        accounts,
        message,
        status,
      };
    }
    catch (errorResponse) {
      const { message, status } = errorResponse?.networkError?.result || {};

      Sentry.captureException(errorResponse, {
        extra: {
          message,
          status,
        },
        tags: {
          function: "usePostTrust",
        },
      });

      throw trustException({ message, status });
    }
  };

  return {
    data,
    error,
    loading,
    trust: handleTrust,
    trustStatus: TRUST_STATUS,
  };
};

const useDeleteTrust = (options) => {
  const [
    trust,
    { data, loading, error },
  ] = useMutation(TRUST_DELETE_MUTATION, { ...options });

  const handleTrust = async ({
    code,
  } = {}) => {
    try {
      const {
        data: response,
      } = await trust({
        variables: {
          code,
        },
      });

      const {
        deleted,
        status,
      } = response.trust;

      return {
        deleted,
        status,
      };
    }
    catch (errorResponse) {
      const { message, status } = errorResponse?.networkError?.result || {};

      Sentry.captureException(errorResponse, {
        extra: {
          message,
          status,
        },
        tags: {
          function: "useDeleteTrust",
        },
      });

      throw trustException({ message, status });
    }
  };

  return {
    data,
    error,
    loading,
    trust: handleTrust,
    trustStatus: TRUST_STATUS,
  };
};

export { useGetTrust, usePostTrust, useDeleteTrust };
