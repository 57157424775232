import React from "react";
import {
  string,
  bool,
  func,
  oneOfType
} from "prop-types";
import styled from "styled-components";
import { withTooltip } from "../hoc/withTooltip";
import {
  GREEN,
  WHITE,
  GREY,
  DARKEST_GREEN,
} from "../utils/theme";
import Icon from "./Icon";

const StyledDiv = styled.div`
  position: relative;
  width: 60px;
  user-select: none;
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select: none;
`;

const StyledInput = styled.input`
  display: none;
`;

const Label = styled.label`
  display: block; overflow: hidden; cursor: pointer;
  height: 30px; padding: 0; line-height: 30px;
  border-radius: 10px;
  background-color: ${GREY};
  transition: background-color 0.15s ease-in;
  color: ${WHITE};
  font-size: 20px;
  padding: 0 7px;
  text-align: right;

  &:before {
    content: "";
    display: block;
    width: 28px;
    height: 28px;
    margin: 0px;
    background: ${WHITE};
    /*TODO: Use transform rather than position changes to ensure 60fps animation as position changes do not support GPU acceleration */
    position: absolute; top: 0; bottom: 0;
    right: 29px;
    border: 1px solid ${GREY};
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.10);
    border-radius: 10px;
    transition: all 0.15s ease-in 0s;
    color: ${WHITE};
  }

  ${StyledInput}:checked + & {
    background-color: ${props => props.color || GREEN};
  }

  ${StyledInput}:checked + &, ${StyledInput}:checked + &:before {
    border-color: ${props => props.color || GREEN};
    color: ${props => props.color || DARKEST_GREEN};
    text-align: left;
  }

  ${StyledInput}:checked + &:before {
    right: 0px;
  }
`;

function ToggleSwitch(props) {
  return (
    <StyledDiv>

      <StyledInput
        type="checkbox"
        id={props.id || props.name}
        name={props.name}
        disabled={props.disabled}
        checked={props.value === "" ? false : props.value}
        onChange={props.onChange}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        onDragStart={props.onDragStart}
        onDrop={props.onDrop}
      />
      <Label htmlFor={props.id || props.name} color={props.color}>
        {props.disabled && !props.hideDisabledIcon && <Icon icon="lock" />}
      </Label>
    </StyledDiv>
  );
}

ToggleSwitch.propTypes = {
  id: string,
  name: string.isRequired,
  color: string,
  value: oneOfType([bool, string]).isRequired,
  onChange: func.isRequired,
  disabled: bool,
  onBlur: func,
  onFocus: func,
  onDragStart: func,
  onDrop: func,
  hideDisabledIcon: bool,
};

export default withTooltip(ToggleSwitch);
