import React from "react";
import PropTypes from "prop-types";
import { Box, Text } from "grommet";
import {
  Alert,
  CircleInformation,
  Info,
} from "grommet-icons";
import { getThemeColor, themeStyle } from "@/cloverleaf-ui/theme";
import { ButtonLink } from "@/components/v2/ButtonLink";

const messageKinds = {
  BANNER: "banner", /** @todo differentiate type from kind */
  OK: "ok",
  INFO: "info",
  WARNING: "warning",
  ERROR: "error",
};

const kindToIconMap = {
  [messageKinds.OK]: Info,
  [messageKinds.INFO]: CircleInformation,
  [messageKinds.WARNING]: Alert,
  [messageKinds.ERROR]: CircleInformation,
};

function Message({
  children = undefined,
  icon = undefined,
  label = undefined,
  link = undefined,
  kind = "ok",
  contentGap = "medium",
  messageTheme: messageThemProp = undefined,
  ...props
}) {
  const messageTheme = messageThemProp || themeStyle.message?.kind[kind];

  let messageIcon;

  // Handle if icon is a React element, ex. font awesome Icon
  if (React.isValidElement(icon)) {
    messageIcon = React.cloneElement(icon, {
      color: messageTheme?.foreground,
      size: "sm", // font awesome icon size
      ...icon?.props,
    });
  }

  if (!messageIcon && kindToIconMap[kind]) {
    messageIcon = React.createElement(kindToIconMap[kind], {
      color: messageTheme?.foreground,
      size: "small",
    });
  }

  const { href, ...buttonProps } = link || {};

  let content = label || children;

  if (
    content
    && !Array.isArray(content)
    && typeof content !== "string"
    && !React.isValidElement(content)
  ) {
    try {
      // eslint-disable-next-line no-console
      console.error(JSON.stringify(content));
    }
    catch { /* swallow stringify */ }

    content = content?.message || "We're sorry, something went wrong.";
  }

  return (
    <Box
      align="center"
      background={{
        color: messageTheme?.background,
      }}
      color={messageTheme?.foreground}
      data-testid={`${kind}-message`}
      direction="row"
      justify={link ? "between" : undefined}
      pad="medium"
      round="4px"
      width="full"
      {...props}
    >
      <Box
        align="center"
        justify="center"
        direction="row"
        gap={contentGap}
      >
        {messageIcon}
        <Text color={messageTheme?.foreground}>
          {content}
        </Text>
      </Box>
      {link && <Box pad="small" />}
      {link && (
        <ButtonLink
          color={messageTheme?.foreground}
          href={href}
          plain
          style={{
            // TODO: Remove default from theme.button, fix buttons
            color: getThemeColor(messageTheme?.foreground),
            fontSize: "16px",
            whiteSpace: "nowrap",
          }}
          {...buttonProps}
        />
      )}
    </Box>
  );
}

Message.propTypes = {
  children: PropTypes.node,
  contentGap: PropTypes.string,
  icon: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.string , PropTypes.object]),
  link: PropTypes.object,
  kind: PropTypes.oneOf(Object.values(messageKinds)),
  messageTheme: PropTypes.object,
};

export { Message };
