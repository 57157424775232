import React from "react";
import { node, bool } from "prop-types";
import { CSSTransition } from "react-transition-group";

class FadeTransition extends React.PureComponent {
  static propTypes = {
    children: node.isRequired,
    in: bool,
  }

  static defaultProps = {
    in: false,
  }

  render() {
    return (
      <CSSTransition
        classNames="fade"
        timeout={{
          enter: 200,
          exit: 500,
        }}
        in={this.props.in}
        unmountOnExit
      >
        {this.props.children}
      </CSSTransition>
    );
  }
}

export default FadeTransition;
